import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { AuthFlows } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  CloseButton,
  FormContainer,
  MainContentContainer,
  SignUpButtonsContainer,
  SignUpFooter,
  SignUpSocialButton,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { currentFlowAtom } from "./atoms";

export interface StartSignUpProps {
  onClose: () => void;
}

export const StartSignUp = ({ onClose }: StartSignUpProps) => {
  const theme = useTheme();
  const setFlow = useSetAtom(currentFlowAtom);
  const { nextStep } = useAuthNavigationSteps();
  const { handleGoogleClick } = useGoogleAuth();

  return (
    <FormContainer>
      <CloseButton>
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          color={theme.palette.text.primary}
          onClick={onClose}
        />
      </CloseButton>
      <MainContentContainer>
        <SignUpButtonsContainer>
          <Text variant={TextStyleVariant.H6}>{"Create a new account"}</Text>
          <Text>
            {"Already a member?"}
            <Button
              style={{
                display: "inline",
                marginLeft: "4px",
                color: theme.palette.text.primary,
              }}
              variant={ButtonVariant.TEXT}
              onClick={() => {
                setFlow(AuthFlows.LOGIN);
              }}
            >
              {"Sign in"}
            </Button>
          </Text>
          <SignUpSocialButton
            onClick={handleGoogleClick}
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
          >
            <img
              alt={"google logo icon"}
              src={GoogleLogoIcon}
              width={18}
              height={18}
            />
            Continue with Google
          </SignUpSocialButton>
          <Button
            fullWidth={true}
            onClick={() => {
              nextStep();
            }}
          >
            Continue with email
          </Button>
          {/*<Text weight={TEXT_WEIGHT.SEMI_BOLD} color={TextColor.TERTIARY}>
          OR
        </Text>
        <SignUpSocialButtonsContainer>
          <SignUpSocialButton fullWidth={true} variant={ButtonVariant.OUTLINED}>
            <AppleLogo icon={faApple} />
            Apple
          </SignUpSocialButton>
          <SignUpSocialButton fullWidth={true} variant={ButtonVariant.OUTLINED}>
            <FacebookLogo icon={faFacebookF} />
            Facebook
          </SignUpSocialButton>
        </SignUpSocialButtonsContainer>*/}
        </SignUpButtonsContainer>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
