import { CHANNEL_ID_PREFIX } from "../constants/chat";

interface GetChannelIdProps {
  creatorId: number;
  prefix: CHANNEL_ID_PREFIX;
  relatedId: number;
  extraMembers?: number[];
}

// Replica of create_channel_name: engineears/stream/utils.py
// If the logic on BE is updated, this should be updated as well
export const getChannelIdAndMembers = ({
  creatorId,
  prefix,
  relatedId,
  extraMembers = [],
}: GetChannelIdProps) => {
  const isDirectMessage = prefix === CHANNEL_ID_PREFIX.DIRECT_MESSAGE;
  const isStudioMessage = prefix === CHANNEL_ID_PREFIX.STUDIO_DIRECT_MESSAGE;
  const sortedUniqueUserIds = Array.from(
    new Set([
      ...extraMembers,
      creatorId,
      ...(isDirectMessage ? [relatedId] : []),
    ]).values(),
  );
  sortedUniqueUserIds.sort(function (a, b) {
    return a - b;
  });

  const channelId = [
    prefix,
    ...(isDirectMessage
      ? sortedUniqueUserIds
      : isStudioMessage
        ? [relatedId, creatorId]
        : [relatedId]),
  ].join("-");
  return {
    channelId,
    members: sortedUniqueUserIds,
  };
};
