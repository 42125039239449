import EntityPhoto from "../../../store/models/entityPhoto";
import { Studio, StudioRoom } from "../../../store/models/studio";
import {
  SupportedEntityTypes,
  useEntityPhotos,
} from "../../../hooks/useEntityPhotos";
import "./EntityPhotosScreen.css";
import {
  faPenToSquare,
  faTrash,
  faClose,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState, FC, useEffect, useCallback } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { Pill } from "../../elements/Pill/Pill";
import { DetailImageCarousel } from "../../components/DetailImageCarousel/DetailImageCarousel";
import { ShareButton } from "../../elements/ShareButton/ShareButton";
import {
  AddEntityPhotoModal,
  DeleteEntityPhotoModal,
} from "../../components/PrimaryEntityImage/PrimaryEntityImage";
import { useAppDispatch } from "../../../store/hooks";
import {
  getEntityPhotos,
  getEntityPhotosParams,
} from "../../../store/actions/entityPhotoStore";
import { getResizeURL } from "../../../store/utils";
import { entityPhotoErrorHandler } from "../../../hooks/useImageURLFromPath";
import {
  EntityPhotosScreenContent,
  EntityPhotosScreenHeader,
} from "./EntityPhotosScreen.styles";
import { useAtomValue } from "jotai";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";

export interface EntityPhotosScreenProps {
  studio?: Studio;
  studioRoom?: StudioRoom;
  canManageStudio: boolean;
  username?: string;
  selectedPhoto?: EntityPhoto;
  slideUp?: boolean;
  onClose?: () => void;
  shareUrl?: string;
}

export const EntityPhotosScreen: FC<EntityPhotosScreenProps> = ({
  studio,
  studioRoom,
  canManageStudio,
  selectedPhoto,
  slideUp = false,
  onClose,
  shareUrl,
}) => {
  const navHeight = useAtomValue(topNavSpacerHeightAtom);
  const supportedEntityType = studio
    ? SupportedEntityTypes.Studio
    : SupportedEntityTypes.StudioRoom;
  const entityId = studio ? studio.id : studioRoom?.id;
  const { entityPhotoData } = useEntityPhotos(supportedEntityType, entityId);
  const [hidePhotoScreen, setHidePhotoScreen] = useState(false);
  const { isDesktop } = useMediaQueryBreakpoint();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showDetailPhotoCarousel, setShowDetailPhotoCarousel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePhoto, setDeletePhoto] = useState<EntityPhoto | undefined>();
  const [replacePhoto, setReplacePhoto] = useState(false);
  const [photoToReplace, setPhotoToReplace] = useState<
    EntityPhoto | undefined
  >();
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const dispatch = useAppDispatch();
  const [loadingImages, setLoadingImages] = useState(false);

  useEffect(() => {
    if (slideUp) {
      document.body.style.overflow = "hidden";
      document.body.style.height = `${window.innerHeight}px`;
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
    }
  }, [slideUp]);

  const handleViewMore = useCallback(() => {
    if (entityId === undefined || supportedEntityType === undefined) return;
    if (entityPhotoData && entityPhotoData.page < entityPhotoData.total_pages) {
      const data: getEntityPhotosParams =
        supportedEntityType === SupportedEntityTypes.Studio
          ? {
              studio_id: entityId,
              page: entityPhotoData.page + 1,
            }
          : {
              studio_room_id: entityId,
              page: entityPhotoData?.page + 1,
            };
      setLoadingImages(true);
      void dispatch(getEntityPhotos(data))
        .unwrap()
        .finally(() => {
          setLoadingImages(false);
        });
    }
  }, [entityId, supportedEntityType, entityPhotoData, dispatch]);

  useEffect(() => {
    if (showDetailPhotoCarousel) return;
    const entityPhotoBodyElement = document.getElementsByClassName(
      "entity-photos-screen__body",
    )[0];
    if (!selectedPhoto) {
      entityPhotoBodyElement?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    const element = document.getElementById(`photo-${selectedPhoto.id}`);
    if (entityPhotoBodyElement && element) {
      const elementHeightCenter = element.offsetHeight / 1.5;
      entityPhotoBodyElement.scrollTo({
        top: element.offsetTop - elementHeightCenter,
        behavior: "smooth",
      });
    }
  }, [selectedPhoto, showDetailPhotoCarousel]);

  const data = useMemo(() => {
    const photos = entityPhotoData?.photos || [];
    if (photos.length <= 2) {
      return photos.reduce((acc, photo) => {
        acc.push([photo]);
        return acc;
      }, [] as EntityPhoto[][]);
    }
    const groupedPhotos = [];
    let currentIndex = 0;
    while (currentIndex < photos.length) {
      if (currentIndex === 0 || currentIndex % 3 === 0) {
        groupedPhotos.push([photos[currentIndex]]);
        currentIndex++;
      } else {
        const lastIndex = currentIndex - 1;
        if (lastIndex === 0 || lastIndex % 3 === 0) {
          groupedPhotos.push([]);
        }
        groupedPhotos[groupedPhotos.length - 1].push(photos[currentIndex]);

        currentIndex++;
      }
    }
    return groupedPhotos;
  }, [entityPhotoData]);

  const handleOnClose = () => {
    const animationDuration = 500;
    setHidePhotoScreen(true);
    setTimeout(() => {
      onClose && onClose();
      setHidePhotoScreen(false);
    }, animationDuration);
  };

  return (
    <div
      style={{
        height: window.innerHeight,
      }}
      className={"entity-photos-screen "
        .concat(slideUp ? " sliding-up " : "")
        .concat(hidePhotoScreen ? " sliding-down " : "")}
    >
      <DetailImageCarousel
        slideUp={showDetailPhotoCarousel}
        isMobile={!isDesktop}
        entityId={entityId}
        photoData={entityPhotoData}
        index={selectedIndex}
        onClose={() => {
          setShowDetailPhotoCarousel(false);
        }}
        shareUrl={shareUrl}
        updateIndex={(updatedIndex) => {
          const newIndex = Math.abs(
            updatedIndex % (entityPhotoData?.photos?.length ?? 0),
          );
          setSelectedIndex(newIndex);
        }}
      />
      <EntityPhotosScreenHeader
        $navHeight={navHeight}
        $empty={!data.length}
        className="entity-photos-screen__header"
      >
        {shareUrl && (
          <div className="entity-photos-screen__header__item">
            <ShareButton url={shareUrl} />
          </div>
        )}
        {canManageStudio && (
          <div
            className="entity-photos-screen__header__item"
            onClick={() => {
              setShowAddPhotoModal(true);
            }}
            style={data.length ? undefined : { margin: "auto 0" }}
          >
            <p>Add Photo</p>
            <FontAwesomeIcon
              size={!isDesktop ? "1x" : "2x"}
              color="black"
              icon={faPlusCircle}
            />
          </div>
        )}
        <div
          className="entity-photos-screen__header__item"
          style={{ height: 36, width: 36 }} // Makes this the same size/spacing as the share icon on mobile
        >
          <FontAwesomeIcon
            color="var(--black)"
            onClick={handleOnClose}
            icon={faClose}
            fixedWidth
            className={"photo-screen-close-icon"}
            size={!isDesktop ? "1x" : "2x"}
          />
        </div>
      </EntityPhotosScreenHeader>
      {data.length && (
        <div className="entity-photos-screen__body">
          <EntityPhotosScreenContent
            $navHeight={navHeight}
            className="container entity-photos-screen__body__content"
          >
            {data.map((photos, rowIndex) => (
              <div
                key={rowIndex}
                style={{
                  height: photos.length === 1 && isDesktop ? 435 : 435 / 2,
                }}
                className="detail-image-row"
              >
                {photos.map((photo) => {
                  const indexToSelect = entityPhotoData?.photos.findIndex(
                    (p) => p.id === photo.id,
                  );
                  const room_name = photo?.room_name;
                  return (
                    <div
                      id={`photo-${photo.id}`}
                      key={photo.id}
                      style={{
                        height:
                          photos.length === 1 && isDesktop ? 435 : 435 / 2,
                      }}
                      className="detail-image-row__item"
                    >
                      <EntityImage
                        onClick={() => {
                          setSelectedIndex(indexToSelect ?? 0);
                          setTimeout(() => {
                            // scroll to top

                            setShowDetailPhotoCarousel(true);
                          }, 500);
                        }}
                        entityPhoto={photo}
                      />
                      {room_name && (
                        <Pill
                          label={room_name}
                          className="entity-photo-room-name"
                        />
                      )}
                      {canManageStudio && (
                        <div className="entity-photo-modification-buttons">
                          <div
                            className="entity-photo-button"
                            onClick={() => {
                              setShowAddPhotoModal(true);
                              setReplacePhoto(true);
                              setPhotoToReplace(photo);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              color="var(--black)"
                            />
                          </div>
                          <div
                            className="entity-photo-button"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setDeletePhoto(photo);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              color="var(--black)"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
            {entityPhotoData &&
              entityPhotoData.page < entityPhotoData.total_pages && (
                <button
                  className="load-more-link"
                  onClick={handleViewMore}
                  disabled={loadingImages}
                >
                  {loadingImages ? "Loading ..." : "View More"}
                </button>
              )}
          </EntityPhotosScreenContent>
        </div>
      )}
      <AddEntityPhotoModal
        isMobile={!isDesktop}
        showModal={showAddPhotoModal}
        onClose={() => {
          setShowAddPhotoModal(false);
          setPhotoToReplace(undefined);
          setReplacePhoto(false);
        }}
        entityId={entityId}
        entityType={supportedEntityType}
        canManageStudio={canManageStudio}
        photoToReplace={photoToReplace ?? null}
        replacePhoto={replacePhoto}
      />
      <DeleteEntityPhotoModal
        photo={deletePhoto ?? null}
        showModal={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setDeletePhoto(undefined);
        }}
        entityId={entityId}
        entityType={supportedEntityType}
      />
    </div>
  );
};

export const EntityImage = ({
  entityPhoto,
  onClick,
  fullScreen = false,
}: {
  entityPhoto: EntityPhoto;
  onClick?: () => void;
  fullScreen?: boolean;
}) => {
  return (
    <img
      onClick={onClick}
      className={fullScreen ? "entity-image-full-screen" : "entity-image"}
      src={getResizeURL(true, entityPhoto.path)}
      alt={"studio photo"}
      onError={(e) => entityPhotoErrorHandler(e, entityPhoto.path)}
    />
  );
};
