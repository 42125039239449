import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  DefaultGenerics,
  ExtendableGenerics,
  OwnUserResponse,
  StreamChat,
  TokenOrProvider,
  UserResponse,
} from "stream-chat";
import { connectionEstablishedAtom } from "./chatHooks/atoms";

export const useCreateChatClient = <
  SCG extends ExtendableGenerics = DefaultGenerics,
>({
  apiKey,
  tokenOrProvider,
  userData,
}: {
  apiKey: string;
  tokenOrProvider: TokenOrProvider;
  userData?: OwnUserResponse<SCG> | UserResponse<SCG>;
}) => {
  const [chatClient, setChatClient] = useState<
    StreamChat<SCG> | null | undefined
  >(null);
  const setConnectionEstablished = useSetAtom(connectionEstablishedAtom);
  useEffect(() => {
    if (!userData || !tokenOrProvider) {
      setChatClient(null);
      return;
    }
    const client = new StreamChat<SCG>(apiKey);
    let didUserConnectInterrupt = false;

    const connectionPromise = client
      .connectUser(userData, tokenOrProvider)
      .then(() => {
        if (!didUserConnectInterrupt) {
          setChatClient(client);
          setConnectionEstablished(true);
        }
      })
      .catch(() => {
        console.error("Failed to connect user to chat client.");
        if (!didUserConnectInterrupt) {
          setChatClient(undefined);
          setConnectionEstablished(false);
        }
      });

    return () => {
      didUserConnectInterrupt = true;
      // If the connection is interrupted, we want to set the chat client to undefined.
      setChatClient(undefined);
      setConnectionEstablished(false);
      void connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log(`Connection for user "${userData.id}" has been closed`);
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, userData?.id, tokenOrProvider, setConnectionEstablished]);

  return chatClient;
};
