import React from "react";
import arrow from "../../assets/arrow.svg";
import Link from "../../elements/Link/Link";

export interface AuthScreenHeaderContentProps {
  prompt: string;
  linkText: string;
  path: string;
}
export const AuthScreenHeaderContent = ({
  prompt,
  linkText,
  path,
}: AuthScreenHeaderContentProps) => {
  return (
    <div className="app-bar-content-container">
      <p className="b2" style={{ marginRight: 18.86, marginLeft: "auto" }}>
        {prompt}
      </p>
      <Link className="b2-semi-bold auth-screen-header-text" to={path}>
        {linkText}{" "}
        <span>
          <img alt="" src={arrow} />
        </span>
      </Link>
    </div>
  );
};
