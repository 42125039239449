import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useImageURLFromPath } from "../../../../hooks/useImageURLFromPath";
import { useAppSelector } from "../../../../store/hooks";
import defaultUser from "../../../../stories/assets/defaultuser.jpg";
import {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../../core-ui/components/Text/Text";
import "./NavProfileButton.css";

export interface NavProfileButtonProps {
  onClick?: () => void;
  isMobile: boolean;
  expanded: boolean;
}
export const NavProfileButton: React.FC<NavProfileButtonProps> = ({
  onClick,
  isMobile,
  expanded,
}) => {
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { studio, user } = selectedProfile;
  const [loadImageFailed, setLoadImageFailed] = React.useState(false);

  const { username, displayName, path } = useMemo(() => {
    if (!studio && !user) {
      return {
        username: loggedInUser?.username,
        displayName: loggedInUser?.profile?.display_name,
        path: loggedInUser?.photo?.path,
      };
    }
    if (studio) {
      return {
        username: studio.username,
        displayName: studio.studio_profile?.display_name,
        path: studio.photo?.path,
      };
    }
    return {
      username: user?.username,
      displayName: user?.profile?.display_name,
      path: user?.photo?.path,
    };
  }, [studio, user, loggedInUser, studio?.username]);

  const source = useImageURLFromPath(Boolean(studio), path);

  return (
    <button
      className={`profile-nav-header ${expanded ? "show" : ""}`}
      name="profile-menu"
      onClick={onClick}
      type="button"
    >
      <div className="profile-nav-header-left">
        <img
          className="profile-nav-header-image"
          src={loadImageFailed ? defaultUser : source}
          onError={() => setLoadImageFailed(true)}
          alt="profile"
        />
        <div
          className={`profile-nav-header-text-container ${expanded ? "show" : ""}`}
        >
          <Text
            className="profile-nav-header-text"
            color={TEXT_COLOR.TERTIARY}
            size={TEXT_SIZE.XS}
          >
            @{username}
          </Text>
          {displayName && (
            <Text
              className="profile-nav-header-text"
              color={TEXT_COLOR.SECONDARY}
              size={TEXT_SIZE.SMALL}
              weight={TEXT_WEIGHT.BOLD}
            >
              {displayName}
            </Text>
          )}
        </div>
      </div>
      {!isMobile && (
        <FontAwesomeIcon
          icon={faChevronDown}
          color="var(--text-primary-color)"
          className={"profile-nav-header-icon ".concat(
            expanded ? "expanded" : "",
          )}
        />
      )}
    </button>
  );
};
