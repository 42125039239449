import { SVGAttributes } from "react";

export enum IconType {
  Bell,
  Checkmark,
  Group,
  House,
  Envelope,
  MusicFolder,
  Sliders,
}

const icons: Record<IconType, string[]> = {
  [IconType.Bell]: [
    "M13.5 0C12.6703 0 12 0.670312 12 1.5V2.4C8.57811 3.09375 5.99999 6.12187 5.99999 9.75V10.9406C5.99999 13.0687 5.27343 15.1359 3.94686 16.7953L3.24843 17.6719C2.97655 18.0094 2.92499 18.4734 3.11249 18.8625C3.29999 19.2516 3.69374 19.5 4.12499 19.5H22.875C23.3062 19.5 23.7 19.2516 23.8875 18.8625C24.075 18.4734 24.0234 18.0094 23.7516 17.6719L23.0531 16.8C21.7266 15.1359 21 13.0687 21 10.9406V9.75C21 6.12187 18.4219 3.09375 15 2.4V1.5C15 0.670312 14.3297 0 13.5 0ZM13.5 4.5C16.4016 4.5 18.75 6.84844 18.75 9.75V10.9406C18.75 13.1859 19.4016 15.375 20.6109 17.25H6.38905C7.59843 15.375 8.24999 13.1859 8.24999 10.9406V9.75C8.24999 6.84844 10.5984 4.5 13.5 4.5ZM16.5 21H13.5H10.5C10.5 21.7969 10.8141 22.5609 11.3766 23.1234C11.9391 23.6859 12.7031 24 13.5 24C14.2969 24 15.0609 23.6859 15.6234 23.1234C16.1859 22.5609 16.5 21.7969 16.5 21Z",
  ],
  [IconType.Checkmark]: [
    "M2.72581 4.73511L1.14029 3.14959L0.459473 3.83041L2.72581 6.09674L7.39955 1.423L6.71873 0.742188L2.72581 4.73511Z",
  ],
  [IconType.Group]: [
    "M21.2774 0.960504C19.9866 -0.320168 17.7177 -0.320168 16.427 0.960504C15.7917 1.5958 15.4185 2.47311 15.4185 3.37059C15.4185 4.26807 15.7816 5.14538 16.427 5.78067C17.0723 6.42605 17.9396 6.77899 18.8471 6.77899C19.7547 6.77899 20.6219 6.42605 21.2774 5.78067C21.9127 5.14538 22.2858 4.26807 22.2858 3.37059C22.2858 2.47311 21.9228 1.5958 21.2774 0.960504ZM19.9866 3.37059C19.9866 3.67311 19.8656 3.94538 19.6538 4.15714C19.2303 4.57059 18.4639 4.57059 18.0404 4.15714C17.8286 3.94538 17.7076 3.66303 17.7076 3.37059C17.7076 3.07815 17.8286 2.78572 18.0404 2.58403C18.2522 2.37227 18.5446 2.27143 18.8471 2.27143C19.1496 2.27143 19.4421 2.37227 19.6538 2.58403C19.8656 2.7958 19.9866 3.07815 19.9866 3.37059Z",
    "M14.0976 13.858H9.9026C6.95806 13.858 4.56815 16.2277 4.56815 19.1521C4.56815 19.9891 5.25386 20.6748 6.10092 20.6748H17.8992C18.7463 20.6748 19.432 19.9891 19.432 19.1521C19.432 16.2378 17.0421 13.858 14.0976 13.858ZM6.95806 18.3857C7.30092 17.0849 8.52109 16.147 9.9026 16.147H14.0976C15.4791 16.147 16.6892 17.0849 17.0421 18.3857H6.95806Z",
    "M8.13788 0.960504C6.84712 -0.320168 4.5883 -0.320168 3.28746 0.960504C2.65216 1.5958 2.27905 2.47311 2.27905 3.37059C2.27905 4.26807 2.64208 5.14538 3.28746 5.78067C3.93283 6.42605 4.80006 6.77899 5.71771 6.77899C6.63536 6.77899 7.4925 6.42605 8.13788 5.78067C8.78326 5.14538 9.14628 4.26807 9.14628 3.37059C9.14628 2.47311 8.78326 1.5958 8.13788 0.960504ZM6.85721 3.37059C6.85721 3.66303 6.7362 3.94538 6.52443 4.15714C6.1009 4.57059 5.33452 4.57059 4.91099 4.15714C4.69922 3.94538 4.57821 3.66303 4.57821 3.37059C4.57821 3.07815 4.69922 2.7958 4.91099 2.58403C5.12275 2.37227 5.41519 2.27143 5.71771 2.27143C6.02023 2.27143 6.31267 2.37227 6.52443 2.58403C6.7362 2.7958 6.85721 3.07815 6.85721 3.37059Z",
    "M15.7009 7.94877C15.4992 7.46474 15.2068 7.03113 14.8337 6.65802C14.4606 6.28491 14.027 6.00255 13.5328 5.80087C12.5547 5.39751 11.4454 5.39751 10.4673 5.80087C9.98326 6.00255 9.53956 6.28491 9.16645 6.65802C8.79334 7.03113 8.50091 7.46474 8.29922 7.94877C8.09754 8.43281 7.9967 8.94709 7.9967 9.47146C7.9967 9.99583 8.09754 10.5 8.28914 10.974V10.9942C8.50091 11.4782 8.79334 11.9118 9.16645 12.2849C9.53956 12.658 9.97318 12.9404 10.4673 13.1421C10.9513 13.3437 11.4656 13.4446 12.0001 13.4446C12.5345 13.4446 13.0387 13.3437 13.5328 13.1421C14.0169 12.9404 14.4606 12.6479 14.8337 12.2849C15.2068 11.9118 15.4992 11.4782 15.7009 10.9942C15.9026 10.5101 16.0034 9.99583 16.0034 9.47146C16.0034 8.94709 15.9026 8.43281 15.7009 7.94877ZM13.7043 9.47146C13.7043 9.92524 13.5228 10.3488 13.2102 10.6614C12.5749 11.2866 11.4253 11.2866 10.79 10.6614C10.4673 10.3387 10.2959 9.91516 10.2959 9.47146C10.2959 9.02776 10.4774 8.59415 10.79 8.28155C11.1127 7.95886 11.5463 7.78743 12.0001 7.78743C12.4538 7.78743 12.8875 7.96894 13.2102 8.28155C13.5328 8.59415 13.7043 9.01768 13.7043 9.47146Z",
    "M19.4219 7.76722H16.6992L16.8807 8.48319C16.9614 8.80588 17.0017 9.13865 17.0017 9.47143V10.0462H19.4118C20.6723 10.0462 21.6908 11.0546 21.6908 12.295C21.6908 12.9202 22.2051 13.4345 22.8404 13.4345C23.4757 13.4345 23.99 12.9202 23.99 12.295C23.99 9.79412 21.9328 7.75714 19.4118 7.75714L19.4219 7.76722Z",
    "M7.10932 8.48324L7.29083 7.76727H4.56814C2.04713 7.76727 -0.0100098 9.80425 -0.0100098 12.3051C-0.0100098 12.9303 0.504276 13.4446 1.13957 13.4446C1.77486 13.4446 2.28915 12.9303 2.28915 12.3051C2.28915 11.0648 3.30764 10.0563 4.56814 10.0563H6.97823V9.48156C6.97823 9.14879 7.01856 8.81601 7.09924 8.49332L7.10932 8.48324Z",
  ],
  [IconType.House]: [
    "M12.7734 0.267187C13.1953 -0.0890625 13.8094 -0.0890625 14.2312 0.267187L26.6062 10.7672C27.0797 11.1703 27.1406 11.8781 26.7375 12.3516C26.3344 12.825 25.6266 12.8812 25.1531 12.4828L24 11.5078V20.25C24 22.3219 22.3219 24 20.25 24H6.74999C4.67812 24 2.99999 22.3219 2.99999 20.25V11.5078L1.85155 12.4828C1.37812 12.8859 0.665615 12.825 0.267178 12.3516C-0.13126 11.8781 -0.0750097 11.1656 0.398428 10.7672L12.7734 0.267187ZM13.5 2.60156L5.24999 9.6V20.25C5.24999 21.0797 5.9203 21.75 6.74999 21.75H8.99999V14.625C8.99999 13.5891 9.83905 12.75 10.875 12.75H16.125C17.1609 12.75 18 13.5891 18 14.625V21.75H20.25C21.0797 21.75 21.75 21.0797 21.75 20.25V9.6L13.5 2.60156ZM11.25 21.75H15.75V15H11.25V21.75Z",
  ],
  [IconType.Envelope]: [
    "M5 5.25C4.5875 5.25 4.25 5.5875 4.25 6V7.03594L12.3359 13.6734C13.3062 14.4703 14.6984 14.4703 15.6688 13.6734L23.75 7.03594V6C23.75 5.5875 23.4125 5.25 23 5.25H5ZM4.25 9.94687V18C4.25 18.4125 4.5875 18.75 5 18.75H23C23.4125 18.75 23.75 18.4125 23.75 18V9.94687L17.0938 15.4125C15.2937 16.8891 12.7016 16.8891 10.9062 15.4125L4.25 9.94687ZM2 6C2 4.34531 3.34531 3 5 3H23C24.6547 3 26 4.34531 26 6V18C26 19.6547 24.6547 21 23 21H5C3.34531 21 2 19.6547 2 18V6Z",
  ],
  [IconType.MusicFolder]: [
    "M3 0.5C1.34531 0.5 0 1.84531 0 3.5V18.5C0 20.1547 1.34531 21.5 3 21.5H21C22.6547 21.5 24 20.1547 24 18.5V6.5C24 4.84531 22.6547 3.5 21 3.5H13.5891L11.5781 1.48906C10.9453 0.85625 10.0875 0.5 9.19219 0.5H3ZM2.25 3.5C2.25 3.0875 2.5875 2.75 3 2.75H9.19219C9.49219 2.75 9.77813 2.86719 9.98906 3.07812L12.1125 5.20156C12.4641 5.55313 12.9422 5.75 13.4391 5.75H21C21.4125 5.75 21.75 6.0875 21.75 6.5V18.5C21.75 18.9125 21.4125 19.25 21 19.25H3C2.5875 19.25 2.25 18.9125 2.25 18.5V3.5ZM16.5 8C16.5 7.75625 16.3781 7.52188 16.1766 7.38125C15.975 7.24063 15.7172 7.20781 15.4875 7.29688L9.4875 9.54688C9.19687 9.65469 9 9.93594 9 10.25V11.75V15.5844C8.76562 15.5281 8.5125 15.5 8.25 15.5C7.00781 15.5 6 16.1703 6 17C6 17.8297 7.00781 18.5 8.25 18.5C9.49219 18.5 10.5 17.8297 10.5 17V12.2703L15 10.5828V14.0844C14.7656 14.0281 14.5125 14 14.25 14C13.0078 14 12 14.6703 12 15.5C12 16.3297 13.0078 17 14.25 17C15.4922 17 16.5 16.3297 16.5 15.5V9.5V8Z",
  ],
  [IconType.Sliders]: [
    "M12.1549 2.66668C12.5568 1.13319 13.9747 0 15.6623 0C17.3497 0 18.7676 1.13319 19.1695 2.66668H21.0938C21.5942 2.66668 21.9998 3.06116 21.9998 3.55552C21.9998 4.04646 21.5946 4.44452 21.0938 4.44452H19.1695C18.7676 5.97801 17.3497 7.1112 15.6623 7.1112C13.9748 7.1112 12.5569 5.97801 12.1549 4.44452H1.17695C0.676612 4.44452 0.271027 4.04989 0.271027 3.55552C0.271027 3.06459 0.676147 2.66668 1.17695 2.66668H12.1549ZM3.10147 11.5555C3.50341 10.022 4.92131 8.8888 6.60873 8.8888C8.29625 8.8888 9.71416 10.022 10.1161 11.5555H21.0941C21.5944 11.5555 22 11.9501 22 12.4445C22 12.9354 21.5949 13.3333 21.0941 13.3333H10.1161C9.71419 14.8668 8.29629 16 6.60873 16C4.92135 16 3.50345 14.8668 3.10147 13.3333H1.17718C0.676845 13.3333 0.27126 12.9388 0.27126 12.4445C0.27126 11.9535 0.67638 11.5555 1.17718 11.5555H3.10147ZM6.60873 14.2222C7.60874 14.2222 8.41949 13.4263 8.41949 12.4445C8.41949 11.4626 7.60874 10.6666 6.60873 10.6666C5.60871 10.6666 4.79812 11.4626 4.79812 12.4445C4.79812 13.4263 5.60871 14.2222 6.60873 14.2222ZM15.6625 5.33336C16.6625 5.33336 17.4731 4.53736 17.4731 3.55552C17.4731 2.57369 16.6625 1.77784 15.6625 1.77784C14.6625 1.77784 13.8518 2.57369 13.8518 3.55552C13.8518 4.53736 14.6625 5.33336 15.6625 5.33336Z",
  ],
};

export type SVGProps = {
  className?: string;
  fill?: string;
  height?: string;
  type: IconType;
  width?: string;
} & Pick<SVGAttributes<SVGElement>, "viewBox" | "xmlns">;

export const Icon: React.FC<SVGProps> = (props: SVGProps) => {
  const {
    height = "24",
    width = "24",
    fill = "var(--text-primary-color)",
  } = props;
  return (
    <svg
      className={`bg-transparent shrink-0 ${height} ${width} ${props.className}`}
      viewBox={props.viewBox || "0 0 24 24"}
      xmlns={props.xmlns}
      style={{ height, width }}
    >
      {icons[props.type].map((path, index) => (
        <path key={`vector_${index}`} d={path} fill={fill} />
      ))}
    </svg>
  );
};
