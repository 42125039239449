import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { WorkingHours, WEEKDAYS } from "../models/workingHours";
import { WORKING_HOURS_API } from "../utils/routes";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { receiveErrors } from "./errorStore";

interface UserWorkingHours {
  [user_id: number]: WorkingHours[] | undefined;
}

interface StudioRoomWorkingHours {
  [studio_room_id: number]: WorkingHours[] | undefined;
}

interface WorkingHoursState {
  userWorkingHours: UserWorkingHours;
  studioRoomWorkingHours: StudioRoomWorkingHours;
}

const initialState: WorkingHoursState = {
  userWorkingHours: {},
  studioRoomWorkingHours: {},
};

interface workingHoursArgs {
  studio_room_id?: number;
  user_id?: number;
}

export const getWorkingHours = createAsyncThunk(
  WORKING_HOURS_API + "/get",
  async (args: workingHoursArgs, thunkAPI) => {
    let params = "";
    if (args.studio_room_id) {
      params = `?studio_room_id=${args.studio_room_id}`;
    }
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<WorkingHours[]>(
      WORKING_HOURS_API,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export type workingHoursParam = {
  [day in WEEKDAYS]: string | undefined;
};

export interface createWorkingHoursParams {
  working_hours: workingHoursParam;
  studio_room_id?: number;
}

export const createWorkingHours = createAsyncThunk(
  WORKING_HOURS_API + "/post",
  async (args: createWorkingHoursParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<WorkingHours[]>(
      WORKING_HOURS_API,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const workingHoursState = createSlice({
  name: "workingHours",
  initialState,
  reducers: {
    clearWorkingHours: (state) => {
      state.userWorkingHours = {};
      state.studioRoomWorkingHours = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkingHours.fulfilled, (state, action) => {
      const payload = action.payload;
      const meta = action.meta;
      if (meta.arg.studio_room_id) {
        state.studioRoomWorkingHours[meta.arg.studio_room_id] = payload;
      } else if (meta.arg.user_id) {
        state.userWorkingHours[meta.arg.user_id] = payload;
      } else if (payload.length > 0 && payload[0].user) {
        const user = payload[0].user;
        state.userWorkingHours[user.id] = payload;
      }
    });
  },
});
export const { clearWorkingHours } = workingHoursState.actions;
export default workingHoursState.reducer;
