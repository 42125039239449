import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { atomWithStore } from "jotai-redux";
import storage from "redux-persist/lib/storage";
import { ThunkMiddleware } from "redux-thunk";

import abPlayerStore from "./actions/abPlayerStore";
import accountInfo from "./actions/accountInfo";
import availability from "./actions/availability";
import bookingStore from "./actions/booking";
import calendarService from "./actions/calendarService";
import dashboard from "./actions/dashboard";
import engineerRecommendationStore from "./actions/engineerRecommendation";
import engineerRecordingServices from "./actions/engineerRecordingServices";
import engineers from "./actions/engineers";
import entityPhotoStore from "./actions/entityPhotoStore";
import errorStore from "./actions/errorStore";
import favorites from "./actions/favorites";
import fileVersionCommentsSlice from "./actions/fileVersionComments";
import fileVersionStore from "./actions/fileVersions";
import generateBookingStore from "./actions/generateBookingStore";
import homepageStore from "./actions/homepage";
import mapSearch from "./actions/mapSearch";
import marketingDataStore from "./actions/marketing";
import alphaMasteringProjects from "./actions/masteringAlphaProjects";
import mixMasterCartsStore from "./actions/mixMasterCartsStore";
import alphaMixingProjects from "./actions/mixingAlphaProjects";
import musoSearchStore from "./actions/muso";
import musoAssociationStore from "./actions/musoProfile";
import notificationsStore from "./actions/notifications";
import paginatedRecordingSessions from "./actions/paginatedRecordingSessions";
import paginatedScheduledProjects from "./actions/paginatedScheduledProjects";
import portfolioService from "./actions/portfolio";
import projectComments from "./actions/projectComments";
import projectsStore from "./actions/projects";
import projectsMapStore from "./actions/projectsMap";
import recordingSessionReducer from "./actions/recording";
import recordingBookingMobileState from "./actions/recordingBookingMobileState";
import recordingCartsStore from "./actions/recordingCartsStore";
import scheduledProjectPurchaseOrdersSlice from "./actions/scheduledProjectPurchaseOrders";
import scheduledProjectUpdateStore from "./actions/scheduledProjectUpdateStore";
import scheduledSessions from "./actions/scheduledSessions";
import scheduledProjectsStore from "./actions/scheduledprojects";
import searchStore from "./actions/search";
import searchMusoCredits from "./actions/searchMusoCredits";
import selectedComment from "./actions/selectedComment";
import selectedProfileSlice from "./actions/selectedProfile";
import engineerServices from "./actions/services";
import shoppingCart from "./actions/shoppingCart";
import statService from "./actions/stats";
import studiosSlice from "./actions/studio";
import studioRoomSearch from "./actions/studioRoomSearch";
import subscriptionStore from "./actions/subscriptions";
import teamState from "./actions/team";
import transactionStore from "./actions/transactions";
import trophyService from "./actions/trophies";
import unauthenticatedUserStateSlice from "./actions/unauthenticatedUser";
import userInterface from "./actions/userInterface";
import userSearch from "./actions/userSearch";
import usersService from "./actions/users";
import workingHours from "./actions/workingHours";
import abPlayerSubsetFilter from "./transforms/abPlayerStoreFilter";
import { isProd } from "./utils";

const loggingMiddleware: ThunkMiddleware = (store) => (next) => (action) => {
  console.log("action:", action);
  const result = next(action);
  const state = store.getState();
  console.log("state after action:", state);
  return result;
};

const appReducer = combineReducers({
  abPlayerStore,
  accountInfo,
  alphaMasteringProjects,
  alphaMixingProjects,
  availability,
  bookingStore,
  calendarService,
  dashboard,
  engineerRecommendationStore,
  engineers,
  engineerServices,
  entityPhotoStore,
  errorStore,
  favorites,
  fileVersionCommentsSlice,
  fileVersionStore,
  generateBookingStore,
  homepageStore,
  mapSearch,
  marketingDataStore,
  mixMasterCartsStore,
  musoAssociationStore,
  musoSearchStore,
  notificationsStore,
  paginatedRecordingSessions,
  paginatedScheduledProjects,
  portfolioService,
  projectComments,
  projectsMapStore,
  projectsStore,
  recordingBookingMobileState,
  recordingCartsStore,
  recordingSessionReducer,
  scheduledProjectPurchaseOrdersSlice,
  scheduledProjectsStore,
  scheduledProjectUpdateStore,
  scheduledSessions,
  searchStore,
  searchMusoCredits,
  selectedComment,
  selectedProfileSlice,
  shoppingCart,
  statService,
  studioRoomSearch,
  studiosSlice,
  subscriptionStore,
  teamState,
  transactionStore,
  trophyService,
  unauthenticatedUserStateSlice,
  userInterface,
  userSearch,
  usersService,
  workingHours,
  engineerRecordingServices,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["abPlayerStore"],
  transforms: [abPlayerSubsetFilter],
};

const rootReducer = (state: any, action: any) => {
  if (action.type === "accountInfo/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistReducerInstance = persistReducer(
  persistConfig,
  rootReducer,
) as typeof rootReducer;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // add options here to exclude certain actions from being sent to sentry
});

const store = configureStore({
  reducer: persistReducerInstance,
  devTools: !isProd,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(!isProd ? [loggingMiddleware] : []);
  },
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
});

export type QueryParamsType = Record<
  string,
  number | string | string[] | number[] | boolean
>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const reduxAtom = atomWithStore(store);

export default store;
