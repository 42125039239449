import { useFlags } from "flagsmith/react";
import {
  TEXT_COLOR,
  TEXT_LINE_HEIGHT,
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../../../../core-ui/components/Text/Text";

export const AnnouncementText: React.FC = () => {
  const flags = useFlags(["announcement_banner"]);
  // TODO: Add API to get announcement string and link
  const announcementBannerFlagData =
    flags.announcement_banner.enabled && flags.announcement_banner.value
      ? (JSON.parse(flags.announcement_banner.value as string) as {
          text: string;
          url: string;
        })
      : {
          text: "Learn more about our brand new booking experience!",
          url: "https://blog.engineears.com/streamline-projects-on-engineears-with-upgrades-to-how-you-book/",
        };
  const text = announcementBannerFlagData.text;
  const link = announcementBannerFlagData.url;

  return (
    <div
      style={{
        display: "flex",
        minWidth: "fit-content",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Text
        color={TEXT_COLOR.INVERT}
        lineHeight={TEXT_LINE_HEIGHT.SHORT}
        size={TEXT_SIZE.SMALL}
        style={{ textAlign: "center" }}
        weight={TEXT_WEIGHT.BOLD}
      >
        {text}
      </Text>
      {link && (
        <a
          className="announcement-header-link"
          style={{ marginLeft: "0.38rem" }}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <Text
            color={TEXT_COLOR.INVERT}
            lineHeight={TEXT_LINE_HEIGHT.SHORT}
            size={TEXT_SIZE.XXS}
            weight={TEXT_WEIGHT.BOLD}
          >
            Learn more
          </Text>
        </a>
      )}
    </div>
  );
};
