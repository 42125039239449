import { Dispatch, SetStateAction, useCallback } from "react";
import { Discounts } from "../../../../../store/models/recording";
import {
  allDiscountHourOptions,
  getHourOptionsWithMinimumDuration,
} from "../../../../../store/utils/serviceUtils";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import {
  TEXT_COLOR,
  TEXT_SIZE,
  Text,
} from "../../../../core-ui/components/Text/Text";
import { OptionType } from "../../../../elements/DropDownSelector/DropdownSelector";
import { ToolTipTextArea } from "../../../ToolTipTextArea/ToolTipTextArea";
import { DiscountRateService } from "../../AddRecordingService";
import "./AddBlockDiscountButton.css";

interface AddBlockDiscountButtonProps {
  discountRates: DiscountRateService[];
  minimumSessionTimeValue: number;
  maximumSessionTimeValue: number;
  pendingServicePrice: number;
  setDiscountRate: Dispatch<SetStateAction<DiscountRateService[]>>;
  setShowDiscount: Dispatch<SetStateAction<boolean>>;
}

export const AddBlockDiscountButton = ({
  discountRates,
  minimumSessionTimeValue,
  maximumSessionTimeValue,
  pendingServicePrice,
  setDiscountRate,
  setShowDiscount,
}: AddBlockDiscountButtonProps) => {
  // Get the valid possible applicable discount rates for the recording service.
  const discountHourOptions = getHourOptionsWithMinimumDuration(
    minimumSessionTimeValue,
    maximumSessionTimeValue,
  );

  const canAddDiscountRate =
    discountHourOptions.length &&
    discountRates.length !== discountHourOptions.length;

  const isMaxBlockAmount =
    discountRates.length === allDiscountHourOptions.length;

  const addDiscountRate = useCallback(() => {
    if (!canAddDiscountRate) return;

    let nextValidDiscountRate: OptionType | undefined = discountHourOptions[0];

    if (discountRates.length) {
      // Map the values to get an array of applied discount rates.
      const appliedDiscountRates = discountRates.map(
        (discountRate) => discountRate.minTimeSelected.value,
      );

      // Get the next valid discount rate that can be applied to the recording service.

      nextValidDiscountRate = discountHourOptions.find(
        (discountHourOption) =>
          !appliedDiscountRates.includes(discountHourOption.value),
      );
      if (!nextValidDiscountRate) {
        return;
      }
    }

    const minAddDiscountSessionTime = nextValidDiscountRate.value * 60;

    const newDiscount: Discounts = {
      minimum_time_to_enable_rate: minAddDiscountSessionTime,
      price: pendingServicePrice,
    };
    setShowDiscount(true);
    setDiscountRate(
      [
        ...discountRates,
        {
          minTimeSelected: nextValidDiscountRate,
          discount: newDiscount,
        },
      ].sort(
        (a, b) =>
          a.discount.minimum_time_to_enable_rate -
          b.discount.minimum_time_to_enable_rate,
      ),
    );
  }, [
    canAddDiscountRate,
    discountRates,
    discountHourOptions,
    pendingServicePrice,
    setShowDiscount,
    setDiscountRate,
  ]);

  return (
    <div className="add-block-discount-button-container">
      <Button
        className="add-block-discount-button-button"
        disabled={!canAddDiscountRate}
        onClick={addDiscountRate}
        variant={ButtonVariant.UNSTYLED}
      >
        <Text
          color={canAddDiscountRate ? TEXT_COLOR.PRIMARY : TEXT_COLOR.TERTIARY}
          size={TEXT_SIZE.XS}
        >
          + Add Block Discount
        </Text>
      </Button>
      {!canAddDiscountRate && (
        <ToolTipTextArea
          text={
            isMaxBlockAmount
              ? "You have reached the maximum number of discounts."
              : "Increase the maximum session time to add a block discount."
          }
          tooltipTextStyle={{
            minWidth: "15rem",
            textAlign: "left",
          }}
        />
      )}
    </div>
  );
};
