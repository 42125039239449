import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./";
import { useCallback, useEffect, useMemo } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { logout } from "./actions/accountInfo";
import { SCREENS } from "../routes";
import { useHistory } from "react-router-dom";
import { emitAnalyticsTrackingEvent } from "../utils/analyticsUtils";
import { fetchProfile } from "./actions/users";
import useRevokeTokenMutation from "../hooks/chatHooks/useRevokeTokenMutation";
import { FileVersion } from "./models/fileVersion";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useLogout = (screenToNavigateTo?: SCREENS) => {
  const { mutateAsync: revokeTokenAsync } = useRevokeTokenMutation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const { instance } = useMsal();
  const isUmgAuthenticated = useIsAuthenticated();

  return useCallback(async () => {
    if (!user) return;
    emitAnalyticsTrackingEvent("clicked_log_out", {}, user.id);
    if (isUmgAuthenticated) {
      try {
        await instance.logoutPopup();
      } catch (e) {
        return toast.error("Error logging out of UMG");
      }
    }
    await revokeTokenAsync();
    dispatch(logout());
    if (screenToNavigateTo) history.push(screenToNavigateTo);
  }, [
    user,
    isUmgAuthenticated,
    instance,
    dispatch,
    history,
    revokeTokenAsync,
    screenToNavigateTo,
  ]);
};

export const useUser = (userId: number | undefined) => {
  const users = useAppSelector((state) => state.usersService);
  const user = users[userId ?? -1];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (user) {
      return;
    }

    if (!userId) {
      return;
    }
    void dispatch(fetchProfile({ user_id: userId }));
  }, [user, dispatch, userId]);

  return user;
};

export const useIsFileUploadedByUser = (file: FileVersion) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  return useMemo(() => {
    if (!user) return false;
    return user.id === file.created_by_user?.id;
  }, [file, user]);
};

export const useProjectFromProjectMap = (projectId: number) => {
  const projects = useAppSelector((state) => state.projectsMapStore.projects);
  return projects[projectId];
};
