import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useResizeObserver } from "usehooks-ts";
import {
  showTopNavAtom,
  topNavContainerHeightAtom,
  topNavHeightAtom,
} from "../../../atoms/navAtoms";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes";
import {
  SelectedProfileState,
  setSelectedProfile,
} from "../../../store/actions/selectedProfile";
import { getMyStudios } from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIsCurrentUserEngineerSelector,
  selectIsUserStudioManager,
} from "../../../store/selectors/userInfoSelectors";
import logo from "../../assets/header-logo.svg";
import whiteLogo from "../../assets/header-white.svg";
import Link from "../../elements/Link/Link";
import { NavSpacer } from "../../screens/NavSpacer/NavSpacer";
import { AuthModal } from "../Auth/AuthModal";
import { AuthScreenHeaderContent } from "../AuthScreenHeaderContent/AuthScreenHeaderContent";
import { LoggedInHeaderContent } from "../LoggedInHeaderContent/LoggedInHeaderContent";
import { AnnouncementHeader } from "../Navigation/AnnouncementHeader/AnnouncementHeader";
import { NavProfileSidePanel } from "../Navigation/NavProfileSidePanel/NavProfileSidePanel";
import { Size } from "../SessionScheduleGuide/SessionScheduleGuide";
import "./AppBarHeader.css";

export interface AppBarHeaderProps {
  isEmptyHeader?: boolean;
}

export const AppBarHeader = ({ isEmptyHeader }: AppBarHeaderProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const showHeader = useAtomValue(showTopNavAtom);
  const [height, setHeight] = useAtom(topNavHeightAtom);
  const elementRef = useRef<HTMLDivElement>(null);
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setHeight(size.height || 0);
      }, 0);
    },
    [setHeight],
  );
  useResizeObserver({
    ref: elementRef,
    box: "border-box",
    onResize,
  });
  const setHeightContainer = useSetAtom(topNavContainerHeightAtom);
  const elementContainerRef = useRef<HTMLDivElement>(null);
  const onResizeContainer = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setHeightContainer(size.height || 0);
      }, 0);
    },
    [setHeightContainer],
  );
  useResizeObserver({
    ref: elementContainerRef,
    box: "border-box",
    onResize: onResizeContainer,
  });
  const { isMobile } = useMediaQueryBreakpoint();
  const { user } = useAppSelector((state) => state.accountInfo);
  const darkMode = useAtomValue(darkModeAtom);

  const userStudios = useAppSelector((state) => state.accountInfo.userStudios);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const userIsEngineer = useAppSelector(selectIsCurrentUserEngineerSelector);

  const isStudioEnabled = useAppSelector(selectIsUserStudioManager);
  useEffect(() => {
    if (!isStudioEnabled || isEmptyHeader) {
      return;
    }
    dispatch(getMyStudios()).catch(() => toast.error("Error loading studios"));
  }, [dispatch, isStudioEnabled, isEmptyHeader]);

  // sets default profile when user logs in
  useEffect(() => {
    if (!user || isEmptyHeader) return;
    const serializedSelectedProfile = localStorage.getItem("selectedProfile");
    if (serializedSelectedProfile) {
      const parsedSelectedProfile = JSON.parse(
        serializedSelectedProfile,
      ) as SelectedProfileState;
      dispatch(setSelectedProfile(parsedSelectedProfile));
    } else {
      dispatch(setSelectedProfile({ user, studio: undefined }));
    }
  }, [dispatch, user, isEmptyHeader]);

  const isSettingsScreen = useMemo(() => {
    return location.pathname.includes(SCREENS.SETTINGS_SCREEN);
  }, [location.pathname]);

  // if a user is not an engineer, but is a studio manager we set their studio as their default
  // but if they have more than one studio, we set their user profile as default
  useEffect(() => {
    if (isEmptyHeader) {
      return;
    }

    if (
      !(selectedProfile.studio || selectedProfile.user) &&
      !isSettingsScreen &&
      !userIsEngineer &&
      userStudios.length != 0
    ) {
      if (userStudios.length > 1) {
        dispatch(setSelectedProfile({ studio: undefined, user }));
      } else {
        const studio = userStudios[0];
        // Set studio profile as default if user is not an engineer
        dispatch(setSelectedProfile({ studio, user: undefined }));
      }
    }
  }, [
    userStudios,
    userIsEngineer,
    dispatch,
    selectedProfile,
    user,
    isSettingsScreen,
    isEmptyHeader,
  ]);

  return (
    <>
      <NavSpacer variant="top" />
      <div
        className="sticky-header-container"
        style={{
          top: showHeader ? "0" : `${height * -1}px`,
        }}
        ref={elementRef}
      >
        <AnnouncementHeader userId={user?.id} />
        <div
          className="app-bar-main-content-container"
          ref={elementContainerRef}
        >
          <div className="app-bar-main-content">
            {isMobile && (
              <NavProfileSidePanel darkMode={darkMode} user={user} />
            )}
            <Link
              className="engineears-logo"
              to={SCREENS.SIGNED_OUT_HOME_SCREEN}
            >
              <img alt="engineears" src={darkMode ? whiteLogo : logo} />
            </Link>
            {!isMobile && !isEmptyHeader && (
              <AppBarContent pathname={location.pathname} />
            )}
          </div>
        </div>
      </div>
      {!isMobile && <AuthModal />}
    </>
  );
};

interface AppBarContentProps {
  pathname: string;
}

const AppBarContent = ({ pathname }: AppBarContentProps) => {
  return useMemo(() => {
    switch (pathname) {
      case SCREENS.SIGN_IN_SCREEN:
        return (
          <AuthScreenHeaderContent
            prompt={"Not a member yet?"}
            linkText={"Sign up here"}
            path={SCREENS.SIGN_UP_SCREEN}
          />
        );
      case SCREENS.SIGN_UP_SCREEN:
        return (
          <AuthScreenHeaderContent
            prompt={"Already a member?"}
            linkText={"Sign in here"}
            path={SCREENS.SIGN_IN_SCREEN}
          />
        );
      default:
        return <LoggedInHeaderContent />;
    }
  }, [pathname]);
};
