import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import {
  PAGINATED_ADMIN_ACTIONS,
  PAGINATED_PLATFORM_ACTIVITY_API,
} from "../utils/routes";
import { PurchaseOrderWithTransactions } from "../models/PurchaseOrderWithTransactions";
import { PlatformActivity } from "../models/platformActivity";
import { receiveErrors } from "./errorStore";
import queryString from "query-string";

export interface PaginatedAdminActionItemsArgs {
  page: number;
  completed: boolean;
  searchQuery?: string;
  page_size?: number;
}

export interface PaginatedAdminActionsResponse {
  data: PurchaseOrderWithTransactions[];
  num_pages: number;
  count: number;
}

export const getPaginatedAdminActionItems = createAsyncThunk(
  PAGINATED_ADMIN_ACTIONS,
  async (args: PaginatedAdminActionItemsArgs, thunkAPI) => {
    const params = `?${queryString.stringify({ ...args })}`;
    const response =
      await makeBackendGetCallWithJsonResponse<PaginatedAdminActionsResponse>(
        PAGINATED_ADMIN_ACTIONS,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface PaginatedPlatformActivity {
  page: number;
  page_size?: number;
  team_id?: number;
}

export interface PlatformActivityResponse {
  data: PlatformActivity[];
  num_pages: number;
  count: number;
}

export const getPaginatedPlatformActivities = createAsyncThunk(
  PAGINATED_PLATFORM_ACTIVITY_API,
  async (args: PaginatedPlatformActivity, thunkAPI) => {
    const params = `?${queryString.stringify({ ...args })}`;
    const response =
      await makeBackendGetCallWithJsonResponse<PlatformActivityResponse>(
        PAGINATED_PLATFORM_ACTIVITY_API,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
