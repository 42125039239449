import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { FieldContainer } from "../SignInForm/SignInForm.styles";
import { useState } from "react";
import { updateProfile } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { getDocUrl } from "../../../constants/googleStorage";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useGeolocationGoogleAppEngine } from "../../../hooks/useGeolocationCountryCode";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { DISCIPLINE_TYPE, getPrimaryDiscipline } from "../../../hooks/user";

export const AddPhone = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { nextStep } = useAuthNavigationSteps();
  const { countryCode, loading } = useGeolocationGoogleAppEngine();
  const { isUpdatingProfile } = useAppSelector((state) => state.accountInfo);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const { user } = useAppSelector((state) => state.accountInfo);
  const primaryDiscipline = getPrimaryDiscipline(user);

  const handleAddPhoneNumber = () => {
    dispatch(updateProfile({ phone_number: phoneNumber }))
      .unwrap()
      .then(() => {
        nextStep();
      });
  };

  const signUpFooter = (
    <Box>
      <Text color={TextColor.SECONDARY}>
        {"By signing up, you are agreeing to the "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsTermsOfUse.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </Link>
        {" and "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Text>
    </Box>
  );

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Add your phone number</Text>
        <Text variant={TextStyleVariant.P1}>
          Add your phone number and receive real-time booking notifications and
          project progress through text message. Your digital personal
          assistant.
        </Text>
        <Text variant={TextStyleVariant.P1}>
          *Message and data rates may apply.
        </Text>
        <FieldContainer>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Phone number</Text>
          <PhoneInput
            disabled={isUpdatingProfile || loading}
            isValidPhoneNumber={isValidPhoneNumber}
            defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
            value={phoneNumber}
            onChange={(value: string) => {
              setPhoneNumber(value);
              setIsValidPhoneNumber(matchIsValidTel(value));
            }}
          />
        </FieldContainer>
        <Button
          fullWidth={true}
          loading={isUpdatingProfile}
          variant={ButtonVariant.PRIMARY}
          disabled={
            !phoneNumber || !isValidPhoneNumber || isUpdatingProfile || loading
          }
          onClick={handleAddPhoneNumber}
        >
          Add phone number
        </Button>
        {primaryDiscipline === DISCIPLINE_TYPE.ARTIST && (
          <Button
            variant={ButtonVariant.UNSTYLED}
            style={{
              textDecoration: "underline",
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
            }}
            onClick={() => nextStep({ skipVerification: true })}
          >
            Remind me later
          </Button>
        )}
      </MainContentContainer>
      <SignUpFooter>{signUpFooter}</SignUpFooter>
    </FormContainer>
  );
};
