import EntityPhoto from "../../../store/models/entityPhoto";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./PrimaryEntityImage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { updateEntityPhotos } from "../../../store/actions/entityPhotoStore";
import { BaseModal } from "../BaseModal/BaseModal";
import { useDropzone } from "react-dropzone";
import {
  getBase64,
  maxFiles,
} from "../../elements/UserProfileImage/UserProfileImage";
import { getResizeURL } from "../../../store/utils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { Button } from "../../elements/Button/button";
import { toast } from "react-toastify";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { Studio, StudioRoom } from "../../../store/models/studio";
import {
  SupportedEntityTypes,
  useEntityPhotos,
} from "../../../hooks/useEntityPhotos";
import { removeEntityPhoto } from "../../../store/actions/entityPhotoStore";
import { entityPhotoErrorHandler } from "../../../hooks/useImageURLFromPath";

export interface PrimaryEntityImageProps {
  studio?: Studio;
  studioRoom?: StudioRoom;
  primaryPhoto: EntityPhoto | null;
  editMode: boolean;
  imageClassName?: string;
  canManageStudio: boolean;
  onClickViewAll?: () => void;
}

const accept = [".jpg", ".jpeg", ".png"];

export const PrimaryEntityImage = ({
  primaryPhoto,
  editMode,
  imageClassName = "",
  canManageStudio,
  onClickViewAll,
  studio,
  studioRoom,
}: PrimaryEntityImageProps) => {
  const [localPrimaryPhoto, setLocalPrimaryPhoto] =
    useState<EntityPhoto | null>(primaryPhoto);
  const supportedEntityType = studioRoom
    ? SupportedEntityTypes.StudioRoom
    : SupportedEntityTypes.Studio;
  const entityId = studioRoom ? studioRoom.id : studio?.id;
  const { isDesktop } = useMediaQueryBreakpoint();
  const { entityPhotoData } = useEntityPhotos(supportedEntityType, entityId);

  useEffect(() => {
    if (entityPhotoData) {
      const photos = entityPhotoData.photos;
      const primaryPhoto = photos.length > 0 ? photos[0] : null;
      setLocalPrimaryPhoto(primaryPhoto);
    }
  }, [entityPhotoData]);

  if (
    (!supportedEntityType === undefined || entityId === undefined) &&
    !canManageStudio
  ) {
    return null;
  }

  if (
    !primaryPhoto &&
    !editMode &&
    (!canManageStudio || imageClassName === "studio-room-image")
  ) {
    return (
      <div className={"image-carousel-container"}>
        <div>
          <div
            className={"image-carousel-no-photos-container ".concat(
              imageClassName,
            )}
          >
            <FontAwesomeIcon icon={faImage} size="3x" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={"image-carousel-container"}>
        {!localPrimaryPhoto && (
          <div className={"image-carousel-empty-container"}>
            {canManageStudio && (
              <EntityPhotoUploader
                entityId={entityId}
                entityType={supportedEntityType}
                canManageStudio={canManageStudio}
                isMobile={!isDesktop}
              />
            )}
          </div>
        )}
        {localPrimaryPhoto && (
          <div>
            <div className={imageClassName}>
              <img
                alt={"carousel image"}
                width={"100%"}
                height={"100%"}
                className="primary-entity-photo"
                src={getResizeURL(true, localPrimaryPhoto?.path)}
                onError={(e) =>
                  entityPhotoErrorHandler(e, localPrimaryPhoto?.path)
                }
              />
            </div>
            {!editMode &&
              (entityPhotoData?.total_photos ?? 0) > 0 &&
              onClickViewAll && (
                <div
                  className="view-all-photos-button"
                  onClick={onClickViewAll}
                >
                  <p className="view-all-photos-text b1-semi-bold">
                    View all {entityPhotoData?.total_photos} photos
                  </p>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  );
};

interface EntityPhotoModalProps {
  photo: EntityPhoto | null;
  showModal: boolean;
  onClose: () => void;
  entityId?: number;
  entityType?: SupportedEntityTypes;
}

export const DeleteEntityPhotoModal: FC<EntityPhotoModalProps> = ({
  photo,
  showModal,
  onClose,
  entityId,
  entityType,
}) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();

  const deletePhoto = useCallback(() => {
    if (!photo) return;
    const object = {
      photo_id: photo.id,
    };
    if (entityId === undefined || entityType === undefined) return;
    setDeleting(true);
    dispatch(updateEntityPhotos(object))
      .unwrap()
      .then(() => {
        toast.success("Image removed successfully");
        dispatch(
          removeEntityPhoto({
            photoId: photo.id,
            entityType: entityType,
            entityId: entityId,
          }),
        );
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again later.");
      })
      .finally(() => {
        onClose();
        setDeleting(false);
      });
  }, [dispatch, photo, entityId, entityType]);
  return (
    <BaseModal
      modalIsOpen={showModal}
      label={"Delete Photo"}
      hideCloseButton={true}
    >
      <div className="delete-photo-modal">
        <p className="h6-semi-bold delete-photo-modal-text">Are you sure?</p>
        <div className="delete-photo-modal-buttons">
          <Button
            onClick={onClose}
            style={{ width: "100%" }}
            label="Cancel"
            primary={false}
            disabled={deleting}
            customClassName="image-carousel-secondary-button"
          />
          <Button
            onClick={deletePhoto}
            style={{ width: "100%" }}
            label={"Delete"}
            primary={true}
            loading={deleting}
            gradient
          />
        </div>
      </div>
    </BaseModal>
  );
};

export interface EntityPhotoUploaderProps {
  entityId?: number;
  entityType?: SupportedEntityTypes;
  onUploadComplete?: () => void;
  canManageStudio?: boolean;
  isMobile?: boolean;
  replacePhoto?: boolean;
  photoToReplace?: EntityPhoto | null;
}

export const EntityPhotoUploader: FC<EntityPhotoUploaderProps> = ({
  entityId,
  entityType,
  onUploadComplete,
  canManageStudio,
  isMobile,
  replacePhoto,
  photoToReplace,
}) => {
  const dispatch = useAppDispatch();
  const [localIsLoading, setLocalIsLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      if (acceptedFiles.length > 1 || acceptedFiles.length < 1) {
        return alert("Please upload one image at a time");
      }
      if (entityId === undefined || entityType === undefined) return;
      try {
        setLocalIsLoading(true);
        const base64Data = (await getBase64(acceptedFiles[0])) as string;
        const data = base64Data.split("base64,")[1];
        const object =
          entityType === SupportedEntityTypes.StudioRoom
            ? {
                studio_room_id: entityId,
                data: data,
                ...(replacePhoto && photoToReplace
                  ? {
                      photo_id: photoToReplace.id,
                      replace_photo: true,
                    }
                  : {}),
              }
            : {
                studio_id: entityId,
                data: data,
                ...(replacePhoto && photoToReplace
                  ? {
                      photo_id: photoToReplace.id,
                      replace_photo: true,
                    }
                  : {}),
              };
        dispatch(updateEntityPhotos(object)).finally(() => {
          setLocalIsLoading(false);
          onUploadComplete && onUploadComplete();
        });
      } catch (error) {
        toast.error("Something went wrong. Please try again later.");
        setLocalIsLoading(false);
      }
    },
    [dispatch, entityId, entityType],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });

  return (
    <div
      className={"image-carousel-container ".concat(
        localIsLoading ? "loading" : "",
      )}
    >
      <input {...getInputProps()} disabled={localIsLoading} />
      {
        <>
          <div>
            {canManageStudio && (
              <div
                {...getRootProps()}
                className={"image-carousel-dropzone-more-photos-container"}
              >
                <input {...getInputProps()} disabled={localIsLoading} />
                {
                  <div className="image-carousel-modal-placeholder-container">
                    {localIsLoading ? (
                      <>
                        <p className="label-semi-bold placeholder-text">
                          Uploading...
                        </p>
                        <SoundWaveLoader
                          width={isMobile ? 100 : 125}
                          height={isMobile ? 100 : 125}
                        />
                      </>
                    ) : (
                      <div className="image-carousel-modal-placeholder-container">
                        <div className="image-carousel-placeholder-image">
                          <FontAwesomeIcon
                            size={"1x"}
                            color={"white"}
                            icon={faImage}
                          />
                        </div>
                        {isMobile ? (
                          <p className="h3">+ Add Images</p>
                        ) : (
                          <>
                            <p className="h4 placeholder-text">
                              Upload an Image!
                            </p>
                            <p className="placeholder-text">
                              <u>Click here to browse</u> for one
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
          </div>
        </>
      }
    </div>
  );
};

interface AddEntityPhotoModalProps {
  canManageStudio: boolean;
  isMobile: boolean;
  showModal: boolean;
  onClose: () => void;
  entityId?: number;
  entityType?: SupportedEntityTypes;
  replacePhoto?: boolean;
  photoToReplace?: EntityPhoto | null;
}

export const AddEntityPhotoModal: FC<AddEntityPhotoModalProps> = ({
  showModal,
  onClose,
  canManageStudio,
  isMobile,
  entityId,
  entityType,
  replacePhoto,
  photoToReplace,
}) => {
  return (
    <BaseModal
      modalIsOpen={showModal}
      label={"Add Photos"}
      closeModal={onClose}
    >
      <EntityPhotoUploader
        entityId={entityId}
        entityType={entityType}
        onUploadComplete={onClose}
        canManageStudio={canManageStudio}
        isMobile={isMobile}
        replacePhoto={replacePhoto}
        photoToReplace={photoToReplace}
      />
    </BaseModal>
  );
};
