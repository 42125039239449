import { Redirect, useParams } from "react-router-dom";
import { ScheduledProjectStage } from "../../../store/models/scheduledproject";
import { ProjectTableTab } from "../../../store/utils/projectTableUtils";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";

const ProjectsScreen = () => {
  const { tab } = useParams<{ tab: string | undefined }>();

  switch (tab) {
    case ProjectTableTab.TODAY:
      return <Redirect to={getMyBookingsRoute("projects")} />;
    case ProjectTableTab.ACTIVE:
      return (
        <Redirect
          to={
            getMyBookingsRoute("projects") +
            `?stages=${ScheduledProjectStage.ACTIVE}`
          }
        />
      );
    case ProjectTableTab.SESSIONS:
      return <Redirect to={getMyBookingsRoute("sessions")} />;
    case ProjectTableTab.PENDING:
      return (
        <Redirect
          to={
            getMyBookingsRoute("projects") +
            `?stages=${ScheduledProjectStage.PENDING}`
          }
        />
      );
    default:
      return <Redirect to={getMyBookingsRoute("projects")} />;
  }
};

export default ProjectsScreen;
