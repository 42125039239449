import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useFetchUserQuery from "../../../hooks/authHooks/useFetchUserQuery";
import useSendEmailVerificationCodeMutation from "../../../hooks/authHooks/useSendEmailVerificationCodeMutation";
import { verifyAccountEmail } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import VerificationCodeInput from "./VerificationCodeInput";

const VerifyEmail = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const { nextStep } = useAuthNavigationSteps();
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useSendEmailVerificationCodeMutation();

  const { data: polledUser } = useFetchUserQuery(
    user?.id.toString() ?? "",
    3000,
  );

  useEffect(() => {
    mutation.mutate();
  }, []);

  useEffect(() => {
    if (polledUser?.email_verified) {
      nextStep();
    }
  }, [polledUser?.email_verified, nextStep]);

  const handleVerified = () => {
    setVerificationError(false);
    setEmailVerified(true);
  };

  const handleVerificationError = () => {
    setEmailVerified(false);
    setVerificationError(true);
  };

  const onComplete = (verificationCode: string) => {
    if (!user?.id) return;
    setIsLoading(true);
    dispatch(
      verifyAccountEmail({
        verification: verificationCode,
        user_id: user.id.toString(),
        send_verification_code: true,
      }),
    )
      .unwrap()
      .then(() => {
        handleVerified();
        nextStep();
      })
      .catch(() => {
        handleVerificationError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (emailVerified) {
      nextStep();
    }
  };

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Confirm your email</Text>
        <Text variant={TextStyleVariant.P1}>
          Enter the verification code we emailed to:
          <br />
          {user?.email}
        </Text>
        <VerificationCodeInput codeLength={6} onComplete={onComplete} />
        {verificationError && (
          <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
            Invalid verification code
          </Text>
        )}
        {emailVerified && (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verified!
          </Text>
        )}
        <Button
          loading={isLoading}
          fullWidth
          onClick={handleSubmit}
          disabled={!emailVerified}
        >
          Submit
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};

export default VerifyEmail;
