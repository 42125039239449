import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getCalendarIntegrationStatus } from "../../../store/actions/calendarService";
import { updateStudioRoomService } from "../../../store/actions/studio";
import { useAppDispatch } from "../../../store/hooks";
import { StudioRoom } from "../../../store/models/studio";

import { AddRecordingService } from "../AddRecordingService/AddRecordingService";
import { BaseModal } from "../BaseModal/BaseModal";
import "./StudioModals.css";

export interface RecordingRoomServiceModalProps {
  studioRoom: StudioRoom | undefined;
  studioId: number;
  onHide: () => void;
  show: boolean;
  showXButton: boolean;
  canManageStudio: boolean;
}

export const RecordingRoomServiceModal = ({
  studioRoom,
  studioId,
  onHide,
  show,
  showXButton,
  canManageStudio,
}: RecordingRoomServiceModalProps) => {
  useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCalendarIntegrationStatus({ studio_id: studioId }))
      .then(() => {})
      .catch(() => {});
  }, [studioId, dispatch]);

  return (
    <BaseModal
      hideCloseButton={true}
      modalIsOpen={show}
      label={"studio room recording service modal"}
    >
      <div className={"studio-modal"}>
        <div className={"modal-header"}>
          <h2 className={"h3 mx-4"}>
            {studioRoom?.recording_service
              ? "Edit Booking Parameters"
              : "Set up Booking Parameters"}
          </h2>
          {showXButton && (
            <FontAwesomeIcon
              width={30}
              height={30}
              color={"var(--black)"}
              icon={faTimes}
              size={"2xl"}
              className={"close-modal-button mx-2"}
              onClick={onHide}
            />
          )}
        </div>
        <div className={"modal-body"}>
          <AddRecordingService
            hideGoogleCalendar={true}
            canManageStudio={canManageStudio}
            studioRoom={studioRoom}
            studioId={studioId}
            onSuccessfulSubmit={(updatedService) => {
              if (updatedService && studioRoom) {
                dispatch(
                  updateStudioRoomService({
                    studio_room_id: studioRoom?.id,
                    studio_id: studioId,
                    recording_service: updatedService,
                  }),
                );
              }
              if (updatedService?.deleted) {
                onHide();
              }
              if (!updatedService) {
                onHide();
              }
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};
