import { useEffect, useState } from "react";
import User from "../store/models/user";
import { MusoCredit } from "../store/models/muso";

export enum DISCIPLINE_TYPE {
  NO_TYPE,
  ENGINEER,
  ARTIST,
  PRODUCER,
  A_AND_R,
  STUDIO_MANAGER,
  ADMIN,
  LISTENER,
  OTHER,
}

export const getUserDisciplines = (user: User | null | undefined) => {
  if (!user) {
    return [];
  }
  const {
    engineer,
    artist,
    producer,
    aandr,
    studio_manager,
    admin,
    listener,
    other,
  } = user;
  const isEngineer = engineer && !engineer.deleted;
  const isArtist = artist && !artist.deleted;
  const isProducer = producer && !producer.deleted;
  const isListener = listener && !listener.deleted;
  const isOther = other && !other.deleted;
  const isAandR = aandr && !aandr.deleted;
  const isStudioManager = studio_manager && !studio_manager.deleted;
  const isAdmin = admin && !admin.deleted && admin.admin_verified;

  const primaryDiscipline = getPrimaryDiscipline(user);

  const result: DISCIPLINE_TYPE[] =
    primaryDiscipline === DISCIPLINE_TYPE.NO_TYPE ? [] : [primaryDiscipline];

  if (isEngineer && primaryDiscipline !== DISCIPLINE_TYPE.ENGINEER) {
    result.push(DISCIPLINE_TYPE.ENGINEER);
  }
  if (isArtist && primaryDiscipline !== DISCIPLINE_TYPE.ARTIST) {
    result.push(DISCIPLINE_TYPE.ARTIST);
  }
  if (isProducer && primaryDiscipline !== DISCIPLINE_TYPE.PRODUCER) {
    result.push(DISCIPLINE_TYPE.PRODUCER);
  }
  if (isListener && primaryDiscipline !== DISCIPLINE_TYPE.LISTENER) {
    result.push(DISCIPLINE_TYPE.LISTENER);
  }
  if (isOther && primaryDiscipline !== DISCIPLINE_TYPE.OTHER) {
    result.push(DISCIPLINE_TYPE.OTHER);
  }
  if (isAandR && primaryDiscipline !== DISCIPLINE_TYPE.A_AND_R) {
    result.push(DISCIPLINE_TYPE.A_AND_R);
  }
  if (isStudioManager && primaryDiscipline !== DISCIPLINE_TYPE.STUDIO_MANAGER) {
    result.push(DISCIPLINE_TYPE.STUDIO_MANAGER);
  }
  if (isAdmin) {
    result.push(DISCIPLINE_TYPE.ADMIN);
  }
  return result;
};

export interface AccountTypeDetails {
  title: string;
  type: DISCIPLINE_TYPE;
  isSelected: boolean;
}

export const getDisciplineString = (type: DISCIPLINE_TYPE): string => {
  switch (type) {
    case DISCIPLINE_TYPE.ENGINEER:
      return "Engineer";
    case DISCIPLINE_TYPE.ARTIST:
      return "Artist";
    case DISCIPLINE_TYPE.PRODUCER:
      return "Producer";
    case DISCIPLINE_TYPE.LISTENER:
      return "Listener";
    case DISCIPLINE_TYPE.OTHER:
      return "Other";
    case DISCIPLINE_TYPE.A_AND_R:
      return "A & R";
    case DISCIPLINE_TYPE.STUDIO_MANAGER:
      return "Studio Manager";
    case DISCIPLINE_TYPE.ADMIN:
      return "Admin";
  }
  return "";
};

// Used on the Edit Profile Screen.
// showAdminAccountType default to false, as we currently manually
// assign/unassign the admin role to users.
export const useUserDisciplines = (
  user: User | null | undefined,
  showAdminAccountType = false,
) => {
  const [accountTypeDetails, setAccountTypeDetails] = useState<
    AccountTypeDetails[]
  >([]);

  useEffect(() => {
    if (!user) {
      setAccountTypeDetails([]);
      return;
    }
    const allTypes = Object.entries(DISCIPLINE_TYPE)
      .map(([, value]) => value)
      .filter(
        (g: string | DISCIPLINE_TYPE) =>
          typeof g !== "string" && g !== DISCIPLINE_TYPE.NO_TYPE,
      ) as DISCIPLINE_TYPE[];

    const userDisciplines = getUserDisciplines(user);
    const details = allTypes
      .filter((accountType) => {
        if (accountType === DISCIPLINE_TYPE.A_AND_R && !user.aandr_qualified) {
          return false;
        }
        if (accountType === DISCIPLINE_TYPE.ADMIN && !showAdminAccountType) {
          return false;
        }
        return true;
      })
      .map((accountType) => {
        return {
          title: getDisciplineString(accountType),
          type: accountType,
          isSelected: userDisciplines.includes(accountType),
        };
      });
    setAccountTypeDetails(details);
  }, [user]);
  return [accountTypeDetails] as [AccountTypeDetails[]];
};

export const useUserDisciplinesString = (user?: User | null) => {
  const [userDisciplines, setDisciplines] = useState<string>("");

  useEffect(() => {
    if (!user) {
      return setDisciplines("");
    }

    const disciplinesArray = getUserDisciplines(user);
    let result = "";
    for (let i = 0; i < disciplinesArray.length; i++) {
      result += getDisciplineString(disciplinesArray[i]);
      if (i === disciplinesArray.length - 1) {
        // Last element.
        break;
      }
      result += " / ";
    }

    setDisciplines(result);
  }, [user]);

  return [userDisciplines] as [string];
};

export const getPrimaryDiscipline = (user?: User): DISCIPLINE_TYPE => {
  if (!user) return DISCIPLINE_TYPE.NO_TYPE;
  if (
    user.engineer &&
    user.engineer.deleted === null &&
    user.engineer.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.ENGINEER;
  if (
    user.artist &&
    user.artist.deleted === null &&
    user.artist.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.ARTIST;
  if (
    user.producer &&
    user.producer.deleted === null &&
    user.producer.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.PRODUCER;
  if (
    user.listener &&
    user.listener.deleted === null &&
    user.listener.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.LISTENER;
  if (
    user.other &&
    user.other.deleted === null &&
    user.other.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.OTHER;
  if (
    user.aandr &&
    user.aandr.deleted === null &&
    user.aandr.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.A_AND_R;
  if (
    user.studio_manager &&
    user.studio_manager.deleted === null &&
    user.studio_manager.is_primary_type !== null
  )
    return DISCIPLINE_TYPE.STUDIO_MANAGER;
  if (user.admin && !user.admin.deleted) return DISCIPLINE_TYPE.ADMIN;
  return DISCIPLINE_TYPE.NO_TYPE;
};

export const getTopCredits = (res: { data: MusoCredit[] }): Set<string> => {
  const verifiedSet = new Set<string>();
  res.data.map((credit: MusoCredit) => {
    const splitArtist = credit.artist.split(",");
    splitArtist.forEach((artist) => {
      if (verifiedSet.has(artist)) {
        return;
      }
      verifiedSet.add(artist);
    });
  });
  return verifiedSet;
};
