import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  determineIfProjectType,
  getArtistFromProject,
  getEngineerFromProject,
} from "../../utils/projectUtils";
import { FooterFileTrackType } from "../actions/abPlayerStore";
import { PortfolioFeatureData } from "../models/portfolio";
import { Project, ProjectById, ProjectType } from "../models/project";
import { porfolioCoverArtPathPrefix } from "../utils";
import { getDisplayableNameForUser } from "../utils/entityUtils";

const selectABPlayerStore = (state: RootState) => state.abPlayerStore;

export const determineIfPortfolioFeatureData = (
  track?: Project | ProjectById | PortfolioFeatureData,
): track is PortfolioFeatureData => {
  const trackAsPortfolioFeatureData = track as PortfolioFeatureData;
  return trackAsPortfolioFeatureData?.spotify_preview_url !== undefined;
};

export const getCurrentTrackFromPlaylist = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    if (abPlayerStore.playlist.length === 0) {
      return undefined;
    }
    return abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
  });

export const getTrackInfo = (index: number) =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (
      !abPlayerStore.playlist ||
      index > abPlayerStore.playlist.length - 1 ||
      index < 0
    ) {
      return {
        artist: "",
        title: "",
        engineer: "",
        artworkSource: undefined,
        serviceType: undefined,
      };
    }
    const track = abPlayerStore.playlist[index];
    let artist = "";
    let artistUsername = "";
    let title = "";
    let engineer = "";
    let engineerUsername = "";
    let artworkSource: string | undefined = undefined;
    let serviceType: ProjectType | undefined = undefined;
    if (determineIfPortfolioFeatureData(track)) {
      const { muso_credit, featured_track_artwork } = track;
      const source = featured_track_artwork?.path
        ? porfolioCoverArtPathPrefix + featured_track_artwork?.path
        : undefined;
      const externalSource = muso_credit?.album_art;
      artworkSource = source || externalSource;
      artist = track.artist;
      artistUsername = track.artist;
      title = track.title;
      engineer = getDisplayableNameForUser(track?.user);
      engineerUsername = track.user?.username || "";
    } else if (determineIfProjectType(track)) {
      artist = getDisplayableNameForUser(track?.artist);
      title = track.title;
      engineer = getDisplayableNameForUser(track?.engineer);
      artistUsername = track?.artist?.username || "";
      engineerUsername = track?.engineer?.username || "";
      serviceType = track.service_type;
    } else {
      const artistUser = getArtistFromProject(track);
      const engineerUser = getEngineerFromProject(track);
      artist = getDisplayableNameForUser(artistUser);
      title = track.title;
      engineer = getDisplayableNameForUser(engineerUser);
      artistUsername = artistUser?.username || "";
      engineerUsername = engineerUser?.username || "";
      serviceType = track.service_type;
    }

    return {
      artist,
      title,
      engineer,
      artistUsername,
      engineerUsername,
      artworkSource,
      serviceType,
    };
  });

export const getDownloadedTrackObjFromIndex = (index: number) =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist || abPlayerStore.playlist.length === 0) {
      return undefined;
    }
    if (index > abPlayerStore.playlist.length - 1 || index < 0) {
      return undefined;
    }
    const track = abPlayerStore.playlist[index];
    if (determineIfPortfolioFeatureData(track)) {
      return undefined;
    }
    return abPlayerStore.downloadedPlaylistTrack[track.id];
  });

export const determineIfTrackIsDownloaded = (index: number) =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist || abPlayerStore.playlist.length === 0) {
      return false;
    }
    if (index > abPlayerStore.playlist.length - 1 || index < 0) {
      return false;
    }
    const track = abPlayerStore.playlist[index];
    if (determineIfPortfolioFeatureData(track)) {
      // TODO: Implement this for portfolio feature data
      return true;
    }
    return Boolean(
      abPlayerStore.downloadedPlaylistTrack[track.id]
        ?.trackGeneratedMP3BlobUrl ||
        abPlayerStore.downloadedPlaylistTrack[track.id]?.trackBlobUrl,
    );
  });

export const getCurrentTrackDownloadedProjectBlobMp3s = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return "";
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return "";
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.trackGeneratedMP3BlobUrl;
  });

export const getCurrentTrackDownloadedProjectBlob = () =>
  createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return "";
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return "";
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]?.trackBlobUrl;
  });

export const canTrackBeDownloaded = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist.length) return;
    const { footerFileTrackType } = abPlayerStore;
    switch (footerFileTrackType) {
      case FooterFileTrackType.SCHEDULED_PROJECT:
        return true;
      default:
        return false;
    }
  });
};

export const getCurrentTrackBlobUrlIsLoading = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return false;
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return false;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.isTrackBlobUrlLoading;
  });
};

export const getCurrentTrackBlobMp3UrlIsLoading = () => {
  return createSelector(selectABPlayerStore, (abPlayerStore) => {
    if (!abPlayerStore.playlist) return;
    const currentTrack =
      abPlayerStore.playlist[abPlayerStore.currentTrackIndex];
    if (!currentTrack) {
      return false;
    }
    if (determineIfPortfolioFeatureData(currentTrack)) {
      return false;
    }
    return abPlayerStore.downloadedPlaylistTrack[currentTrack.id]
      ?.isTrackGeneratedMP3BlobUrlLoading;
  });
};
