import { MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IsCurrentUserStep } from "../../../hooks/useIsCurrentUserStep";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { downloadFinalFiles } from "../../../store/actions/projects";
import { toggleAssetReviewModal } from "../../../store/actions/userInterface";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Project,
  ProjectQueryParam,
  getStepActionLabel,
} from "../../../store/models/project";
import {
  redirectToProjectListenWithCode,
  redirectToProjectUploadWithCode,
  userIsEngineerOnProject,
} from "../../../store/utils/projectTableUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopConfirm } from "../../core-ui/components/PopConfirm/PopConfirm";

interface ProjectActionButtonProps {
  project: Project;
  code?: string;
  handlePaymentFlow: () => void;
  disabled: boolean;
  isAssetReview: boolean;
  isInProgressProject: boolean;
  isListenStep: boolean;
  isUploadStep: boolean;
  isLabelProject?: boolean;
}

const ProjectActionButton = ({
  project,
  code,
  handlePaymentFlow,
  disabled,
  isInProgressProject,
  isUploadStep,
  isAssetReview,
  isListenStep,
  isLabelProject = false,
}: ProjectActionButtonProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.accountInfo.user?.id);
  const query = useQueryParam(ProjectQueryParam.PROJECT_ID);

  const viewProjectButton = (
    <Button
      disabled={disabled}
      variant={
        project.assets_approved ? ButtonVariant.SUCCESS : ButtonVariant.PRIMARY
      }
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        emitAnalyticsTrackingEvent(
          "go_to_project_from_table",
          {
            project_id: `${project.id}`,
          },
          userId,
        );

        query.set(project.id.toString());
      }}
    >
      {project.refunded ? "Cancelled" : "View Project"}
    </Button>
  );

  if (code && !isInProgressProject) {
    if (isUploadStep || isListenStep) {
      return (
        <Button
          variant={ButtonVariant.PRIMARY}
          disabled={disabled}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (isUploadStep) {
              emitAnalyticsTrackingEvent(
                "redirect_to_project_upload_with_code",
                {
                  project_id: `${project.id}`,
                },
                userId,
              );
              redirectToProjectUploadWithCode(project.id, code, history);
            } else {
              emitAnalyticsTrackingEvent(
                "redirect_to_project_listen_with_code",
                {
                  project_id: `${project.id}`,
                },
                userId,
              );
              redirectToProjectListenWithCode(project.id, code, history);
            }
          }}
        >
          {getStepActionLabel(project.step, project.service_type) || "N/A"}
        </Button>
      );
    } else if (isAssetReview && !project.assets_approved) {
      return (
        <PopConfirm
          side={"top"}
          title={"Asset Approval"}
          description={"Approve or request final asset re-uploads"}
          okText={"Submit QC"}
          additionalContent={
            <Button
              variant={ButtonVariant.TERTIARY}
              onClick={(e) => {
                e.stopPropagation();
                if (isInProgressProject && handlePaymentFlow) {
                  handlePaymentFlow();
                } else if (
                  // Only label projects with billing info submitted
                  // can download project files.
                  isLabelProject &&
                  !project.has_pending_purchase_order
                ) {
                  void dispatch(
                    downloadFinalFiles({
                      project_id: `${project.id}`,
                      code: code,
                    }),
                  );
                } else {
                  toast.error(
                    "Download of assets is not available until billing info is submitted.",
                  );
                }
              }}
            >
              Download files
            </Button>
          }
          onConfirm={(e) => {
            e.stopPropagation();
            if (isInProgressProject && handlePaymentFlow) {
              handlePaymentFlow();
            } else {
              void dispatch(
                toggleAssetReviewModal({
                  showAssetReviewModal: true,
                  project: project,
                }),
              );
            }
          }}
          onCancel={(e) => e.stopPropagation()}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            className={"table-button"}
            variant={ButtonVariant.OUTLINED}
          >
            Submit QC Result
          </Button>
        </PopConfirm>
      );
    } else {
      return viewProjectButton;
    }
  }

  // By now, since `code` is not present, userId should be defined
  if (
    IsCurrentUserStep(
      userIsEngineerOnProject(userId!, project),
      project.service_type,
      project.step,
    )
  ) {
    return (
      <Button
        variant={
          project.assets_approved
            ? ButtonVariant.SUCCESS
            : ButtonVariant.PRIMARY
        }
        disabled={disabled}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          query.set(project.id.toString());
        }}
      >
        {getStepActionLabel(project.step, project.service_type) || "N/A"}
      </Button>
    );
  } else {
    return viewProjectButton;
  }
};

export default ProjectActionButton;
