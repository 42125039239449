import { UIEvent, useCallback, useMemo, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

export interface useOnScrollProps {
  forceShowAtTop?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  onToggle?: (show: boolean) => void;
  upDelta?: number; // The up delta configures how much scroll distance upward needs to occur to trigger an effect
  downDelta?: number; // The down delta configures how much scroll distance downward needs to occur to trigger an effect
}

/**
 * Originally built to hide the Navigation Header when scrolling down, but
 * show the header when scrolling up. This util could be useful for other
 * components like the Bottom Nav.
 */
const useOnScrollCallback = ({
  onHide = () => {},
  onShow = () => {},
  onToggle = () => {},
  upDelta = 30,
  downDelta = 200,
  forceShowAtTop = true,
}: useOnScrollProps) => {
  const [scrollTopPivot, setScrollTopPivot] = useDebounceValue(0, 500);
  const [show, setShow] = useState(true);

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const currentScrollTop = e.currentTarget.scrollTop;
      const hasExceededUpDelta =
        Math.abs(currentScrollTop - scrollTopPivot) > upDelta;
      const hasExceededDownDelta =
        Math.abs(scrollTopPivot - currentScrollTop) > downDelta;
      const isAtTopMatters = forceShowAtTop && currentScrollTop == 0;
      if (hasExceededUpDelta || isAtTopMatters) {
        const hasScrolledUp = currentScrollTop < scrollTopPivot;
        if ((hasScrolledUp || isAtTopMatters) && !show) {
          setShow(true);
          try {
            onShow();
          } catch {
            /* empty */
          }
          try {
            onToggle(true);
          } catch {
            /* empty */
          }
        }
      }

      if (hasExceededDownDelta) {
        const hasScrolledDown = currentScrollTop > scrollTopPivot;
        if (hasScrolledDown && show) {
          setShow(false);
          try {
            onHide();
          } catch {
            /* empty */
          }
          try {
            onToggle(false);
          } catch {
            /* empty */
          }
        }
      }
      setScrollTopPivot(currentScrollTop);
    },
    [
      show,
      setShow,
      setScrollTopPivot,
      scrollTopPivot,
      upDelta,
      downDelta,
      onShow,
      onHide,
      onToggle,
      forceShowAtTop,
    ],
  );

  return useMemo(() => ({ show, handleScroll }), [show, handleScroll]);
};

export default useOnScrollCallback;
