import { useEffect, useRef } from "react";
import { useAppSelector } from "../../store/hooks";
import { default as WaveSurferRef } from "wavesurfer.js";
import { getFooterWaveformRef } from "./waveformHooks";

export const useGetFooterPlayerRef = () => {
  const { isFooterReady } = useAppSelector((store) => store.abPlayerStore);
  const footerPlayerRef = useRef<WaveSurferRef | null>(null);
  useEffect(() => {
    if (!isFooterReady) return;
    footerPlayerRef.current = getFooterWaveformRef();
  }, [isFooterReady]);

  return footerPlayerRef;
};
