export const STORAGE_URL = "https://storage.googleapis.com/engineears-static/";
export const VIDEO_STORAGE_URL = `${STORAGE_URL}videos/`;
export const DOCUMENT_STORAGE_URL = `${STORAGE_URL}docs/`;

export const getVideoUrl = (filename: string) => {
  return `${VIDEO_STORAGE_URL}${filename}`;
};

export const getDocUrl = (filename: string) => {
  return `${DOCUMENT_STORAGE_URL}${filename}`;
};
