import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { GET_ENTITY_PHOTOS, UPDATE_ENTITY_PHOTO } from "../utils/routes";
import { receiveErrors } from "./errorStore";
import EntityPhoto from "../models/entityPhoto";
import { SupportedEntityTypes } from "../../hooks/useEntityPhotos";

export interface getEntityPhotosParams {
  studio_id?: number;
  studio_room_id?: number;
  page?: number;
}

export interface PaginatedEntityPhotoDataPayload {
  page: number;
  total_pages: number;
  total_photos: number;
  photos: EntityPhoto[];
}

export const getEntityPhotos = createAsyncThunk(
  GET_ENTITY_PHOTOS,
  async (args: getEntityPhotosParams, thunkAPI) => {
    let params = "";
    if (args.studio_id) {
      params += `?studio_id=${args.studio_id}`;
    } else if (args.studio_room_id) {
      params += `?studio_room_id=${args.studio_room_id}`;
    }
    if (args.page) {
      params += `&page=${args.page}`;
    }
    const result =
      await makeBackendGetCallWithJsonResponse<PaginatedEntityPhotoDataPayload>(
        GET_ENTITY_PHOTOS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateEntityPhotosParam {
  photo_id?: number;
  studio_id?: number;
  studio_room_id?: number;
  data?: string;
  replace_photo?: boolean;
}

export const updateEntityPhotos = createAsyncThunk(
  UPDATE_ENTITY_PHOTO,
  async (args: updateEntityPhotosParam, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<EntityPhoto>(
      UPDATE_ENTITY_PHOTO,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface PhotosByPage {
  [page: number]: EntityPhoto[];
}

export interface PaginatedEntityPhotoData {
  page: number;
  total_pages: number;
  total_photos: number;
  photos: PhotosByPage;
}

export interface EntityPhotoResults {
  [key: number]: PaginatedEntityPhotoData;
}

interface EntityPhotoState {
  studioPhotos: EntityPhotoResults;
  studioRoomPhotos: EntityPhotoResults;
}

const initialState: EntityPhotoState = {
  studioPhotos: {},
  studioRoomPhotos: {},
};

const entityPhotoSlice = createSlice({
  name: "entityPhotos",
  initialState,
  reducers: {
    removeEntityPhoto(
      state,
      action: PayloadAction<{
        photoId: number;
        entityId: number;
        entityType: SupportedEntityTypes;
      }>,
    ) {
      const { photoId: photo_id } = action.payload;
      const { entityId, entityType } = action.payload;
      const entityPhotos =
        entityType === SupportedEntityTypes.Studio
          ? state.studioPhotos
          : state.studioRoomPhotos;
      const entityPhotosData = entityPhotos[entityId];
      if (entityPhotosData) {
        const indices = Object.keys(entityPhotosData.photos)
          .reduce((acc, curr) => {
            acc.push(+curr);
            return acc;
          }, [] as number[])
          .sort();
        indices.forEach((index) => {
          const pagePhotos = entityPhotosData.photos[index];
          if (pagePhotos.some((photo) => photo.id === photo_id)) {
            entityPhotosData.total_photos -= 1;
            entityPhotosData.photos[index] = entityPhotosData.photos[
              index
            ].filter((photo) => photo.id !== photo_id);
          }
        });
      }
      if (entityType === SupportedEntityTypes.StudioRoom) {
        const studioState = state.studioPhotos;
        const studioIndices = Object.keys(studioState).reduce((acc, curr) => {
          acc.push(+curr);
          return acc;
        }, [] as number[]);
        studioIndices.forEach((studio_id_index) => {
          if (studioState[studio_id_index]) {
            const studioData = studioState[studio_id_index];
            const pages = Object.keys(studioData.photos).reduce(
              (acc, page_index) => {
                acc.push(+page_index);
                return acc;
              },
              [] as number[],
            );
            pages.forEach((pageIndex) => {
              if (studioData.photos[pageIndex]) {
                studioData.photos[pageIndex] = studioData.photos[
                  pageIndex
                ].filter((pagePhoto) => pagePhoto.id !== photo_id);
              }
            });
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEntityPhotos.fulfilled, (state, action) => {
      const { data, studio_id, studio_room_id, photo_id, replace_photo } =
        action.meta.arg;
      const photo = action.payload;

      if (!data) return;

      const studioPhotoData = studio_id ? state.studioPhotos[studio_id] : null;
      const studioRoomPhotoData = studio_room_id
        ? state.studioRoomPhotos[studio_room_id]
        : null;

      const updatePhoto = (photoData: PaginatedEntityPhotoData) => {
        if (replace_photo) {
          const photos = photoData.photos;
          for (const page in photos) {
            const index = photos[page].findIndex((p) => p.id === photo_id);
            if (index !== -1) {
              photos[page][index] = photo;
              break;
            }
          }
        } else {
          photoData.total_photos += 1;
          const indices = Object.keys(photoData.photos)
            .reduce((acc, curr) => {
              acc.push(+curr);
              return acc;
            }, [] as number[])
            .sort();
          const lastIndex = indices[indices.length - 1];
          photoData.photos[lastIndex].push(photo);
        }
      };

      if (studioPhotoData) {
        updatePhoto(studioPhotoData);
      }

      if (studioRoomPhotoData) {
        updatePhoto(studioRoomPhotoData);
      }
    });
    builder.addCase(getEntityPhotos.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { studio_id, studio_room_id } = meta.arg;
      const entityPhotosKey = studio_id || studio_room_id;
      if (entityPhotosKey) {
        const page = payload.page;
        const entityPhotosState = studio_id
          ? state.studioPhotos
          : state.studioRoomPhotos;
        const existingPhotos = entityPhotosState[entityPhotosKey]?.photos || [];
        entityPhotosState[entityPhotosKey] = {
          photos: existingPhotos ?? {},
          page: page,
          total_pages: payload.total_pages,
          total_photos: payload.total_photos,
        };
        const photosByPage = entityPhotosState[entityPhotosKey].photos;
        if (page === 1) {
          entityPhotosState[entityPhotosKey].photos = {};
          entityPhotosState[entityPhotosKey].photos[page] = payload.photos;
        } else {
          photosByPage[page] = payload.photos;
        }
      }
    });
  },
});
export const { removeEntityPhoto } = entityPhotoSlice.actions;
export default entityPhotoSlice.reducer;
