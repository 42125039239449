import { useFlags } from "flagsmith/react";
import YoutubeLivestreamScreen from "./YoutubeLivestreamScreen";
import LivestreamScreen from "./LivestreamScreen";

const VIDEO_ID = "";

export const LivestreamSelectorScreen = () => {
  // Prod
  // https://app.flagsmith.com/project/20137/environment/Fd9Af43CN7KAGNKxsL8vPC/features?feature=105562&tab=value
  // Dev
  // https://app.flagsmith.com/project/20137/environment/G2iPKBp48wJJueeWmQyUpg/features?feature=105562&tab=value
  const flags = useFlags(["youtube_livestream_id"]);
  const videoIdFlagData: string =
    flags.youtube_livestream_id.enabled && flags.youtube_livestream_id.value
      ? (flags.youtube_livestream_id.value as string)
      : VIDEO_ID;

  if (videoIdFlagData) {
    return <YoutubeLivestreamScreen videoId={videoIdFlagData} />;
  }

  return <LivestreamScreen />;
};

export default LivestreamSelectorScreen;
