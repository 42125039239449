import { ReactNode, useEffect, useRef } from "react";
import { useHideNavElementsCallback } from "../../../hooks/useHideNavElementsCallback";
import { useSetAtom } from "jotai";
import { navElementRefAtom } from "../../../atoms/navAtoms";

interface ThemeDivProps {
  darkMode?: string | null;
  children?: ReactNode;
}

export const ThemeDiv = ({ children, darkMode }: ThemeDivProps) => {
  const { handleScroll } = useHideNavElementsCallback();
  const setElementRef = useSetAtom(navElementRefAtom);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setElementRef(elementRef);
  }, [elementRef, setElementRef]);

  return (
    <div
      ref={elementRef}
      onScrollCapture={handleScroll}
      data-theme={darkMode ? "dark" : ""}
    >
      {children}
    </div>
  );
};
