export enum AuthSteps {
  LOGIN,
  START_SIGNUP,
  EMAIL_SIGNUP,
  PROFILE_TYPE,
  USERNAME,
  VERIFY_EMAIL,
  ADD_PHONE,
  VERIFY_PHONE,
  COMPLETE,
  ADD_STUDIO_LOCATION,
  ADD_STUDIO_CONTACT,
  AANDR_LABEL_SELECTION,
  AANDR_BUILD_TEAM,
  AANDR_USERNAME,
  AANDR_ADD_PHONE,
  AANDR_VERIFY_PHONE,
}

export enum AuthFlows {
  LOGIN,
  START_SIGNUP,
  STANDARD_SIGNUP,
  STUDIO_OWNER,
  AANDR,
  ADD_STUDIO,
}

export const FLOW_STEPS = {
  [AuthFlows.LOGIN]: {
    totalSteps: 1,
    steps: [[AuthSteps.LOGIN]],
  },
  [AuthFlows.START_SIGNUP]: {
    totalSteps: 2,
    steps: [[AuthSteps.START_SIGNUP], [AuthSteps.EMAIL_SIGNUP]],
  },
  [AuthFlows.STANDARD_SIGNUP]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.USERNAME, AuthSteps.VERIFY_EMAIL],
      [AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.STUDIO_OWNER]: {
    totalSteps: 3,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      //[AuthSteps.ADD_STUDIO_LOCATION, AuthSteps.ADD_STUDIO_CONTACT],
      [AuthSteps.ADD_STUDIO_LOCATION],
      [AuthSteps.VERIFY_EMAIL, AuthSteps.ADD_PHONE, AuthSteps.VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.AANDR]: {
    totalSteps: 4,
    steps: [
      [AuthSteps.PROFILE_TYPE],
      [AuthSteps.AANDR_LABEL_SELECTION, AuthSteps.AANDR_BUILD_TEAM],
      [AuthSteps.AANDR_USERNAME],
      [AuthSteps.AANDR_ADD_PHONE, AuthSteps.AANDR_VERIFY_PHONE],
      [AuthSteps.COMPLETE],
    ],
  },
  [AuthFlows.ADD_STUDIO]: {
    totalSteps: 1,
    steps: [
      //[AuthSteps.ADD_STUDIO_LOCATION], [AuthSteps.ADD_STUDIO_CONTACT]
      [AuthSteps.ADD_STUDIO_LOCATION],
    ],
  },
};
