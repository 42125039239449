import User from "./user";

export enum WEEKDAYS {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export enum DateObjectWeekdays {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const mapDateObjectWeekdaysToWeekdays = (day: number) => {
  switch (day) {
    case DateObjectWeekdays.SUNDAY:
      return WEEKDAYS.SUNDAY;
    case DateObjectWeekdays.MONDAY:
      return WEEKDAYS.MONDAY;
    case DateObjectWeekdays.TUESDAY:
      return WEEKDAYS.TUESDAY;
    case DateObjectWeekdays.WEDNESDAY:
      return WEEKDAYS.WEDNESDAY;
    case DateObjectWeekdays.THURSDAY:
      return WEEKDAYS.THURSDAY;
    case DateObjectWeekdays.FRIDAY:
      return WEEKDAYS.FRIDAY;
    case DateObjectWeekdays.SATURDAY:
      return WEEKDAYS.SATURDAY;
    default:
      return WEEKDAYS.SUNDAY;
  }
};

export interface WorkingHours {
  availability: string;
  day_of_week: WEEKDAYS;
  user: null | User;
  studio_room: null | number;
}

export const NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY = 96;
export const NUMBER_OF_FIFTEEN_MINUTE_NOTCHES_IN_A_DAY = 97;
export const FIFTEEN_MINUTE_INCREMENT_AVAILABLE = 1;
export const FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE = 0;
export const AM = 0;
export const PM = 1;
export const INVALID_AM_PM = -1;

export const convertTimeToHour = (time: number, ampm: number) => {
  if (ampm === AM) {
    return time;
  } else if (ampm === PM) {
    return time + 12;
  }
  return INVALID_AM_PM;
};

const convertHourToIndex = (hour: number) => {
  return hour * 4;
};

export const fillInUnavailability = (startTime: number, endTime: number) => {
  const binaryArray = new Array(
    NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  ).fill(FIFTEEN_MINUTE_INCREMENT_AVAILABLE);
  const startIndex = convertHourToIndex(startTime);
  const endIndex = convertHourToIndex(endTime);

  for (let i = 0; i < startIndex; i++) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  for (
    let i = endIndex;
    i < NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY;
    i++
  ) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  return binaryArray as number[];
};

export const fillInAvailability = (startTime: number, endTime: number) => {
  const binaryArray = new Array(
    NUMBER_OF_FIFTEEN_MINUTE_INCREMENTS_IN_A_DAY,
  ).fill(FIFTEEN_MINUTE_INCREMENT_AVAILABLE);
  const startIndex = convertHourToIndex(startTime);
  const endIndex = convertHourToIndex(endTime);

  for (let i = startIndex; i < endIndex; i++) {
    binaryArray[i] = FIFTEEN_MINUTE_INCREMENT_UNAVAILABLE;
  }
  return binaryArray as number[];
};

export const convertBinaryArrayToBinaryString = (binaryArray: number[]) => {
  let binaryString = "";
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < binaryArray.length; i++) {
    binaryString += binaryArray[i];
  }
  return binaryString;
};

export const combineAvailabilities = (
  availability1: string,
  availability2: string,
): string => {
  let result = "";
  if (availability1.length !== availability2.length) {
    return result;
  }
  for (let i = 0; i < availability1.length; i++) {
    if (availability1[i] === "1" || availability2[i] === "1") {
      result += "1";
    } else {
      result += "0";
    }
  }
  return result;
};
