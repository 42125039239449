import { FC, useCallback, useState } from "react";
import "./EntityPhotoGrid.css";
import {
  useEntityPhotos,
  SupportedEntityTypes,
} from "../../../hooks/useEntityPhotos";
import EntityPhoto from "../../../store/models/entityPhoto";
import {
  entityPhotoErrorHandler,
  useImageURLFromPath,
} from "../../../hooks/useImageURLFromPath";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getResizeURL } from "../../../store/utils";

export interface EntityPhotoGridProps {
  studioId?: number;
  studioRoomId?: number;
  handlePhotoClick?: (photo?: EntityPhoto) => void;
}

export const EntityPhotoGrid: FC<EntityPhotoGridProps> = ({
  studioId,
  studioRoomId,
  handlePhotoClick,
}) => {
  const supportedEntityType = studioId
    ? SupportedEntityTypes.Studio
    : SupportedEntityTypes.StudioRoom;
  const entityId = studioId ? studioId : studioRoomId;
  const { entityPhotoData, fetching } = useEntityPhotos(
    supportedEntityType,
    entityId,
  );

  return (
    <div className="entity-photo-grid-container">
      <div className="entity-photo-grid-container-section">
        <EntityPhotoItem
          loading={fetching}
          handlePhotoClick={handlePhotoClick}
          entityPhoto={entityPhotoData ? entityPhotoData.photos[0] : undefined}
        />
      </div>
      <div className="entity-photo-grid-container-section">
        <div className="entity-photo-grid-container-section-row">
          <div className="entity-photo-grid-container-section">
            <EntityPhotoItem
              handlePhotoClick={handlePhotoClick}
              loading={fetching}
              entityPhoto={
                entityPhotoData ? entityPhotoData.photos[1] : undefined
              }
            />
          </div>
          <div className="entity-photo-grid-container-section">
            <EntityPhotoItem
              handlePhotoClick={handlePhotoClick}
              loading={fetching}
              entityPhoto={
                entityPhotoData ? entityPhotoData.photos[2] : undefined
              }
            />
          </div>
        </div>
        <div className="entity-photo-grid-container-section-row">
          <div className="entity-photo-grid-container-section">
            <EntityPhotoItem
              loading={fetching}
              handlePhotoClick={handlePhotoClick}
              entityPhoto={
                entityPhotoData ? entityPhotoData.photos[3] : undefined
              }
            />
          </div>
          <div className="entity-photo-grid-container-section">
            <EntityPhotoItem
              loading={fetching}
              handlePhotoClick={handlePhotoClick}
              entityPhoto={
                entityPhotoData ? entityPhotoData.photos[4] : undefined
              }
            />
          </div>
        </div>
      </div>
      <div
        className="view-all-photos-button"
        onClick={() => {
          handlePhotoClick && handlePhotoClick();
        }}
      >
        <p className="view-all-photos-text b1-semi-bold">
          View all {entityPhotoData ? entityPhotoData.total_photos : 0} photos
        </p>
      </div>
    </div>
  );
};

export interface EntityPhotoItemProps {
  entityPhoto?: EntityPhoto;
  handlePhotoClick?: (photo?: EntityPhoto) => void;
  loading: boolean;
}

export const EntityPhotoItem: FC<EntityPhotoItemProps> = ({
  loading,
  entityPhoto,
  handlePhotoClick,
}: EntityPhotoItemProps) => {
  const url = useImageURLFromPath(true, entityPhoto?.path);
  const [fadeInImage, setFadeInImage] = useState(false);

  const handleOnPhotoClick = useCallback(() => {
    handlePhotoClick && handlePhotoClick(entityPhoto);
  }, [entityPhoto, handlePhotoClick]);

  if (!url) {
    return (
      <div
        onClick={handleOnPhotoClick}
        className={"entity-photo-item no-entity-photo-image ".concat(
          loading ? "entity-photo-item-loading" : "",
        )}
      >
        {loading ? null : (
          <FontAwesomeIcon
            className="fade-in-no-image-icon"
            icon={faImage}
            size="3x"
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={"entity-photo-item "
        .concat(!entityPhoto?.path ? "no-entity-photo-image " : " ")
        .concat(fadeInImage ? " fade-in-entity-image " : "")}
    >
      <img
        onLoad={() => setFadeInImage(true)}
        onClick={handleOnPhotoClick}
        src={getResizeURL(true, entityPhoto?.path)}
        onError={(e) => entityPhotoErrorHandler(e, entityPhoto?.path)}
      />
    </div>
  );
};
