import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { navElementRefAtom } from "../../../atoms/navAtoms";

const ScrollToTop = () => {
  const history = useHistory();
  const elementRef = useAtomValue(navElementRefAtom);

  useEffect(() => {
    const scrollToTop = () => elementRef?.current?.scrollTo(0, 0);
    const listener = history.listen(() => {
      scrollToTop();
    });
    return () => {
      listener();
    };
  }, []);
  return null;
};

export default ScrollToTop;
