import { useCallback, useState } from "react";
import {
  optInToStudioSeparateStripe,
  setupStripeAccount,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button } from "../../elements/Button/button";
// @ts-ignore
import iso3311a2 from "iso-3166-1-alpha-2";
import { toast } from "react-toastify";
import { useIsPrimaryStudioManager } from "../../../hooks/studioHooks";
import { isCurrentUserEngineerOrStudioManagerSelector } from "../../../store/selectors/userInfoSelectors";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import {
  getCountriesAsDropdownOptions,
  getCountryIndex,
} from "../../../utils/countryUtils";
import {
  TEXT_COLOR,
  TEXT_SIZE,
  Text,
} from "../../core-ui/components/Text/Text";
import { DropdownSelector } from "../../elements/DropDownSelector/DropdownSelector";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import "./ManagePaymentsSettings.css";

export interface ManagePaymentsSettingsProps {
  showHeader?: boolean;
}

export const ManagePaymentsSettings = ({
  showHeader = true,
}: ManagePaymentsSettingsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const userCountryCode = useAppSelector(
    (state) => state.accountInfo.user?.country_code,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    userCountryCode
      ? iso3311a2.getCountry(userCountryCode) ?? "United States"
      : "United States",
  );
  const userMeetsStripeRequirements = useAppSelector(
    (state) => state.accountInfo.meetsStripeRequirements,
  );
  const studioMeetsStripeRequirements = useAppSelector(
    (state) => state.selectedProfileSlice?.studio?.meets_stripe_requirements,
  );
  const meetsStripeRequirements = selectedProfile?.studio
    ? studioMeetsStripeRequirements
    : userMeetsStripeRequirements;
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const isPrimaryStudioManager = useIsPrimaryStudioManager(
    selectedProfile?.studio?.id,
  );
  const isStudioSeparateStripeToggled =
    selectedProfile?.studio?.separate_stripe_account_opt_in ?? false;
  const dispatch = useAppDispatch();

  const redirectToStripe = () => {
    setLoading(true);
    dispatch(
      setupStripeAccount({
        country_code: iso3311a2.getCode(selectedCountry) ?? "US",
        ...(isStudioSeparateStripeToggled && {
          studio_id: selectedProfile?.studio?.id,
        }),
      }),
    )
      .unwrap()
      .then((data) => {
        window.analytics.track(getDebugEventPrefix + "setup_stripe_connect", {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
          country_code: `${iso3311a2.getCode(selectedCountry)}`,
        });
        window.open(data.url);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOptIn = useCallback(
    async (updateObj: { stripe_opt_in?: boolean; studio_id?: number }) => {
      if (!selectedProfile?.studio) {
        return;
      }

      try {
        await dispatch(
          optInToStudioSeparateStripe({
            ...updateObj,
            studio_id: selectedProfile?.studio?.id,
          }),
        ).unwrap();
      } catch (error) {
        toast.error("Error opting in to separate stripe account.");
      }
    },
    [dispatch, selectedProfile],
  );

  if (!isEngineerOrStudioManager) {
    return null;
  }

  if (
    selectedProfile?.studio &&
    isPrimaryStudioManager &&
    !isStudioSeparateStripeToggled
  ) {
    return (
      <div className="manage-payments-settings-container">
        <div className="manage-payments-settings-header">
          <h6 className="h6-semi-bold settings-screen">Manage Payments</h6>
        </div>
        <div className="manage-payments-settings-content">
          <ToggleSwitch
            id="enable-studio-stripe-toggle"
            label={
              <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.MEDIUM}>
                Enable Stripe payout account for Studio
              </Text>
            }
            checked={isStudioSeparateStripeToggled}
            onChange={(value) => {
              void handleOptIn({
                stripe_opt_in: value,
                studio_id: selectedProfile?.studio?.id,
              });
            }}
            size={TOGGLE_SWITCH_SIZE.MEDIUM}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="manage-payments-settings-container">
      {showHeader && (
        <div className="manage-payments-settings-header">
          <h6 className="h6-semi-bold settings-screen">Manage Payments</h6>
        </div>
      )}
      <div className="manage-payments-settings-content">
        {selectedProfile?.studio && isPrimaryStudioManager && (
          <>
            <ToggleSwitch
              id="enable-studio-stripe-toggle"
              label={
                <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.MEDIUM}>
                  Enable Stripe payout account for Studio
                </Text>
              }
              checked={isStudioSeparateStripeToggled}
              onChange={(value) => {
                void handleOptIn({
                  stripe_opt_in: value,
                  studio_id: selectedProfile?.studio?.id,
                });
              }}
              size={TOGGLE_SWITCH_SIZE.MEDIUM}
            />
            <br />
          </>
        )}
        {meetsStripeRequirements &&
          (isStudioSeparateStripeToggled ? (
            <p>
              Your studio&apos;s separate Stripe account is set up and ready.
              Click to manage your Stripe settings.
            </p>
          ) : (
            <p>
              You&apos;re all set to receive payment! Click to redirect to
              manage your Stripe account.
            </p>
          ))}
        {!meetsStripeRequirements && (
          <p>
            Click to redirect to the Stripe account setup portal. Provide{" "}
            <strong>all</strong> verification information on the portal before
            returning to EngineEars.
          </p>
        )}
        <Button
          primary
          gradient
          loading={loading}
          label={
            !meetsStripeRequirements ? "Set Up Stripe" : "Manage Stripe Setup"
          }
          onClick={redirectToStripe}
          customClassName="mt-4"
        />
        <p className="mt-4 b3-semi-bold">
          Not in the United States? Select your country below
        </p>
        <div className="mt-2">
          <DropdownSelector
            value={{
              value: getCountryIndex(selectedCountry),
              label: selectedCountry,
            }}
            options={getCountriesAsDropdownOptions()}
            placeholder={"Select One"}
            onChange={(option) => {
              setSelectedCountry(option.label);
            }}
          />
        </div>
      </div>
    </div>
  );
};
