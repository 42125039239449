import classNames from "classnames";
import {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  ReactText,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import "./Button.css";

/**
 * We can add more variants as needed (i.e. secondary, error, text, gradient, etc)
 */
export enum ButtonVariant {
  PRIMARY = "primary",
  OUTLINED = "outlined",
  GRADIENT = "gradient",
  UNSTYLED = "unstyled",
  GHOST = "ghost",
  TERTIARY = "tertiary",
  ACTIVE = "active",
  SUCCESS = "success",
  ERROR = "error",
  TEXT = "text",
  DISABLED = "disabled",
}

type CommonButtonProps = {
  children?: ReactNode;
  disableText?: string;
  fullWidth?: boolean;
  labelIcon?: ReactNode;
  loading?: boolean;
  variant?: ButtonVariant;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "className" | "disabled" | "style" | "type"
>;

export interface AnchorAsButtonProps extends CommonButtonProps {
  href: string;
  search?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export interface RegularButtonProps extends CommonButtonProps {
  // A hacky way to specify that this type doesn't have `href` prop.
  href?: undefined;
  search?: undefined;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export type ButtonProps = AnchorAsButtonProps | RegularButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      disabled,
      disableText,
      fullWidth,
      href,
      search,
      labelIcon,
      loading,
      style,
      type = "button",
      variant = ButtonVariant.PRIMARY,
      ...restProps
    } = props;
    const toastId = useRef<ReactText>("");
    const showDisableToast = () => {
      if (!disabled || !disableText) return;
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(disableText);
      }
    };

    const buttonStyle: Record<ButtonVariant, string> = {
      [ButtonVariant.PRIMARY]: "button-primary",
      [ButtonVariant.OUTLINED]: "button-outlined",
      [ButtonVariant.GRADIENT]: "button-gradient",
      [ButtonVariant.UNSTYLED]: "button-unstyled",
      [ButtonVariant.GHOST]: "button-ghost",
      [ButtonVariant.TERTIARY]: "button-tertiary",
      [ButtonVariant.ACTIVE]: "button-active",
      [ButtonVariant.SUCCESS]: "button-success",
      [ButtonVariant.ERROR]: "button-error",
      [ButtonVariant.TEXT]: "button-text",
      [ButtonVariant.DISABLED]: "button-primary--disabled",
    };

    const buttonStyleDisabled: Record<ButtonVariant, string> = {
      [ButtonVariant.PRIMARY]: "button-primary--disabled",
      [ButtonVariant.OUTLINED]: "button-outlined--disabled",
      [ButtonVariant.GRADIENT]: "button-gradient--disabled",
      [ButtonVariant.UNSTYLED]: "button-unstyled--disabled",
      [ButtonVariant.GHOST]: "button-ghost--disabled",
      [ButtonVariant.TERTIARY]: "button-tertiary--disabled",
      [ButtonVariant.ACTIVE]: "button-active--disabled",
      [ButtonVariant.SUCCESS]: "button-success--disabled",
      [ButtonVariant.ERROR]: "button-error--disabled",
      [ButtonVariant.TEXT]: "button-text",
      [ButtonVariant.DISABLED]: "button-primary--disabled",
    };

    const finalButtonStyle = disabled
      ? buttonStyleDisabled[variant]
      : buttonStyle[variant];

    const fullWidthStyle = fullWidth ? "button-full-width" : "";

    const buttonClass = classNames(
      "button-default",
      finalButtonStyle,
      fullWidthStyle,
      className,
    );

    const labelElement = labelIcon ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "8px",
        }}
      >
        {children}
        <span>{labelIcon}</span>
      </div>
    ) : (
      children
    );

    const buttonContent = loading ? (
      <SoundWaveLoader
        whiteLoader={variant === ButtonVariant.PRIMARY}
        width={50}
        height={12}
      />
    ) : (
      labelElement
    );

    if (href === undefined) {
      return (
        <button
          className={buttonClass}
          disabled={disabled}
          onMouseOver={() => showDisableToast()}
          style={style}
          type={type}
          ref={ref}
          {...restProps}
          // Must be placed after the spread, otherwise it will get overridden
          // We couldn't de-structure onClick either because TS 4.5.5 wasn't able to infer the type correctly
          onClick={props.onClick}
        >
          {buttonContent}
        </button>
      );
    }

    return (
      <Link
        className={buttonClass}
        to={!disabled ? { pathname: href, search } : "#"}
        onClick={props.onClick}
        onMouseOver={() => showDisableToast()}
        target={href.match(/^http/) ? "_blank" : undefined}
        rel={href.match(/^http/) ? "noreferrer" : undefined}
        style={style}
      >
        {buttonContent}
      </Link>
    );
  },
);

Button.displayName = "Button";
