import { MuiTelInputProps, MuiTelInput } from "mui-tel-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export interface ValidPhoneInputProps {
  isValidPhoneNumber: boolean;
}

export const PhoneInput = ({
  isValidPhoneNumber,
  ...props
}: MuiTelInputProps & ValidPhoneInputProps) => {
  return (
    <MuiTelInput
      {...props}
      InputProps={{
        endAdornment: isValidPhoneNumber ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null,
      }}
    />
  );
};
