import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { EngineEarsFooter2024 } from "../../../devlink";
import { DevLinkProvider } from "../../../devlink/";
import { useIsPrimaryStudioManager } from "../../../hooks/studioHooks";
import { useFetchTrophies } from "../../../hooks/useFetchTrophies";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { useGetTeams } from "../../../hooks/useTeam";
import { applyPromoCode } from "../../../store/actions/marketing";
import { clearRecordingServices } from "../../../store/actions/services";
import { SetUpCartPayload } from "../../../store/actions/shoppingCart";
import { fetchStudioRooms } from "../../../store/actions/studio";
import { getSubscriptionStatus } from "../../../store/actions/subscriptions";
import { createTransaction } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM } from "../../../store/models/studio";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import { GOOGLE_API } from "../../../store/utils";
import { getDisplayableNameForStudioRoom } from "../../../store/utils/entityUtils";
import { getTransactionBookingScreenRoute } from "../../../store/utils/routeGetters";
import { BookingParameters } from "../../../store/utils/transactions";
import { host } from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { AmenityList } from "../../components/AmenityList/AmenityList";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useBottomTabBarOverlayView } from "../../components/Navigation/BottomNav/useBottomTabBarOverlayView";
import { ReviewsList } from "../../components/ReviewsList/ReviewsList";
import { StudioCalendarWidget } from "../../components/StudioCalendarWidget/StudioCalendarWidget";
import { EditStudioRoomModal } from "../../components/StudioModals/EditStudioRoomModal";
import { RecordingRoomServiceModal } from "../../components/StudioModals/RecordingRoomServiceModal";
import StudioRoomHostedBy from "../../components/StudioRoom/StudioRoomHostedBy";
import { StudioTeammates } from "../../components/StudioTeammates/StudioTeammates";
import { TrophyListItem } from "../../components/TrophyRoomList/TrophyRoomList";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Card } from "../../elements/Card/Card";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";
import { MapDisplay } from "../../elements/MapDisplay/MapDisplay";
import { EntityPhotosScreen } from "../EntityPhotosScreen/EntityPhotosScreen";
import StudioBenefitsAndConnection from "./StudioBenefitsAndConnection";
import StudioRoomHeader from "./StudioRoomHeader";
import "./StudioScreen.css";

const LIBRARIES = ["places"];
const apiLoader = {
  googleMapsApiKey: GOOGLE_API,
  preventGoogleFontsLoading: true,
  libraries: LIBRARIES,
};
const StudioRoomScreen = () => {
  const { username, room_id } = useParams<{
    username: string | undefined;
    room_id: string | undefined;
  }>();
  const history = useHistory();
  const { isDesktop } = useMediaQueryBreakpoint();
  // @ts-expect-error we are passing in a string[] instead of a constant array
  const { isLoaded } = useJsApiLoader(apiLoader);
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [showEditStudioRoomModal, setShowEditStudioRoomModal] =
    useState<boolean>(false);
  const [openBookingFlow, setOpenBookingFlow] = useState<boolean>(false);
  const studio = useAppSelector((state) => state.studiosSlice[username ?? ""]);
  useGetTeams();
  const canEditStudio = useManagerCanEditStudio(studio?.id);
  const [isBookNowLoading, setIsBookNowLoading] = useState(false);
  const studioRoom = useAppSelector((state) =>
    state.studiosSlice[username ?? ""]?.studio_rooms?.find(
      (room) => room.id === (room_id ? +room_id : -1),
    ),
  );
  const userStudioServicesEnabled = useAppSelector(
    selectUserCanEnableStudioServices,
  );
  const { studioRoomTrophies } = useFetchTrophies({
    studioRoomId: studioRoom?.id,
  });
  const promoCode = useAppSelector(
    (state) => state.marketingDataStore.appliedPromoCode,
  );
  const shareUrl = `${host}/studio/${username}/${room_id}`;
  const [showStudioPhotos, setShowStudioPhotos] = useState(false);
  const location = useLocation();

  useSetPageTitle(getDisplayableNameForStudioRoom(studioRoom));

  const handleClickBookStudio = async () => {
    if (!studioRoom?.recording_service) {
      return;
    }
    if (!studio) return;
    dispatch(clearRecordingServices());

    try {
      setIsBookNowLoading(true);
      const transaction = await dispatch(createTransaction()).unwrap();
      const bookingParameters: BookingParameters = {
        transactionId: +transaction.id,
        activeStudioId: studio.id,
        activeServiceType: ProjectType.RECORDING,
        activeStudioRoomId: studioRoom.id,
        activeServiceTypeProjectIds: [],
      };
      const shoppingCart: SetUpCartPayload = {
        pendingSessionData: [],
      };

      emitAnalyticsTrackingEvent(
        "clicked_book_studio_room_screen",
        {
          transaction_id: bookingParameters.transactionId,
          active_studio_id: bookingParameters.activeStudioId,
          active_studio_room_id: bookingParameters.activeStudioRoomId,
          service_type: bookingParameters.activeServiceType,
        },
        loggedInUser?.id,
      );

      history.push(
        getTransactionBookingScreenRoute(
          transaction.code,
          dispatch,
          bookingParameters,
          shoppingCart,
        ),
      );
    } catch {
      setIsBookNowLoading(false);
    }
  };

  const bookStudioStickyButton = useBottomTabBarOverlayView(
    !isDesktop && !canEditStudio && !showStudioPhotos,
    <Button
      className="studio-screen-fixed-footer-button"
      fullWidth={true}
      variant={ButtonVariant.GRADIENT}
      onClick={handleClickBookStudio}
      loading={isBookNowLoading}
    >
      Book Studio
    </Button>,
  );

  useEffect(() => {
    return () => {
      if (openBookingFlow) {
        setOpenBookingFlow(false);
      }
    };
  }, [openBookingFlow]);

  useEffect(() => {
    if (!studio) return;
    const searchParams = new URLSearchParams(location.search);
    const promoCode = searchParams.get("promocode");
    if (promoCode) {
      dispatch(
        applyPromoCode({
          promocode: promoCode,
          studio_id: studio?.id,
        }),
      )
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [location, dispatch, studio?.id]);

  const isRecordingServiceSetup: boolean = useMemo(() => {
    if (!studioRoom) {
      return false;
    }
    return Boolean(studioRoom.recording_service);
  }, [studioRoom]);
  const canEditStudioService = useManagerCanEditStudio(studio?.id);
  const [showRecordingServiceModal, setShowRecordingServiceModal] =
    useState(false);
  const isPrimaryStudioManager = useIsPrimaryStudioManager(studio?.id);

  useEffect(() => {
    if (!isPrimaryStudioManager) return;
    if (isPrimaryStudioManager) {
      dispatch(getSubscriptionStatus({ refetch_subs_status: true }))
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [isPrimaryStudioManager]);

  // Combines studio and studio room amenities for displaying purposes on the Studio Room Screen.
  // If the studio and the studio room have the same amenity, only one copy of that amenity is kept for
  // display on the frontend.
  const amenities = useMemo(() => {
    if (!studio?.studio_amenities) {
      return studioRoom?.amenities ?? [];
    }
    const roomAmenities = studioRoom?.amenities ?? [];
    return (roomAmenities.concat(studio?.studio_amenities ?? []) ?? []).filter(
      (amenity, index, self) =>
        index ===
        self.findIndex((a) => a.amenity_type === amenity.amenity_type),
    );
  }, [studioRoom?.amenities, studio?.studio_amenities]);

  useEffect(() => {
    if (!username || !room_id || studioRoom) {
      return;
    }
    dispatch(
      // fetch all studio rooms so studio are already fetched if navigating back to studio
      fetchStudioRooms({
        studio_username: username,
      }),
    )
      .unwrap()
      .then(() => {})
      .catch(() => {});
  }, [username, dispatch, room_id, studioRoom]);

  useEffect(() => {
    if (!canEditStudio || !studioRoom) {
      return;
    }
    if (
      studioRoom?.max_number_of_guests <
      MINIMUM_NUMBER_OF_GUESTS_FOR_STUDIO_ROOM
    ) {
      setShowEditStudioRoomModal(true);
      setShowRecordingServiceModal(false);
    }
  }, [canEditStudio, studioRoom]);

  if (studio && !studioRoom) {
    return <Redirect to={`/studio/${username}`} />;
  }

  if (!studio || !studioRoom) {
    return <LoadingScreen />;
  }

  return (
    <>
      <StudioRoomHeader
        studio={studio}
        studioRoom={studioRoom}
        canEditStudio={canEditStudio}
        showEditStudioRoomModal={showEditStudioRoomModal}
        setShowEditStudioRoomModal={setShowEditStudioRoomModal}
        setShowStudioPhotos={setShowStudioPhotos}
        trophies={studioRoomTrophies}
      />
      <div className="studio-screen studio-room-screen">
        <div className={"container"}>
          <div className={"studio-split-row"}>
            <div className={"studio-split-row-column"}>
              <div className={"studio-split-row-column-row"}>
                <div className="studio-facilities-section">
                  <StudioRoomHostedBy studio={studio} studioRoom={studioRoom} />
                </div>
                {studioRoom.about_this_room && (
                  <div className="studio-facilities-section">
                    <p className="b2 studio-bio">
                      <ExpandableTextArea
                        header={"ABOUT THIS STUDIO ROOM"}
                        body={studioRoom.about_this_room}
                      />
                    </p>
                  </div>
                )}
                {studio.studio_profile?.long_bio && (
                  <div className="studio-facilities-section">
                    <p className="b2">
                      <ExpandableTextArea
                        header={"ABOUT THE STUDIO"}
                        body={studio.studio_profile.long_bio}
                      />
                    </p>
                  </div>
                )}
                <StudioBenefitsAndConnection
                  studio={studio}
                  userCanEditStudio={canEditStudio}
                  showMessageButton
                />
                {amenities.length > 0 && (
                  <div className="studio-facilities-section">
                    <p className={"label-semi-bold studio-section-header"}>
                      AMENITIES
                    </p>
                    <AmenityList
                      amenities={amenities}
                      editMode={false}
                      useCardForm={false}
                    />
                  </div>
                )}
              </div>
              <div
                className={"studio-facilities-section studio-screen-divider"}
              />

              {studioRoomTrophies && studioRoomTrophies.length > 0 && (
                <>
                  <div className={"studio-split-row-column-row"}>
                    <div>
                      <p
                        className={"label-semi-bold studio-section-header mb-3"}
                      >
                        OUR BADGES
                      </p>
                      {studioRoomTrophies.map((trophy, index) => {
                        return (
                          <TrophyListItem
                            key={index}
                            idx={index}
                            trophy={trophy}
                            showInfo
                            showStatus={false}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={
                      "studio-facilities-section studio-screen-divider"
                    }
                  />
                </>
              )}
              {studioRoom.equipment_highlights && (
                <>
                  <div className={"studio-split-row-column-row"}>
                    <div className={"studio-facilities-section"}>
                      <div style={{ flex: 1 }}>
                        <ExpandableTextArea
                          header={"EQUIPMENT INCLUDED"}
                          body={studioRoom.equipment_highlights}
                        />
                      </div>
                      <div style={{ flex: 1 }}></div>
                    </div>
                  </div>
                  <div
                    className={
                      "studio-facilities-section studio-screen-divider"
                    }
                  />
                </>
              )}
              <div className={"studio-split-row-column-row"}>
                <StudioTeammates
                  managerUsers={studio?.studio_team?.managers ?? []}
                  engineerUsers={studio?.studio_team?.members ?? []}
                />
              </div>
            </div>
            <div className={"studio-split-row-column-row"}>
              <div className="studio-facilities-section studio-room-calendar-container">
                <StudioCalendarWidget
                  promoCode={promoCode}
                  studioRoom={studioRoom}
                  studio={studio}
                  canManageStudio={canEditStudioService}
                  showRecordingServiceModal={() => {
                    const hasMaxGuests = Boolean(
                      studioRoom.max_number_of_guests,
                    );
                    if (!showRecordingServiceModal && hasMaxGuests) {
                      setShowRecordingServiceModal(true);
                    }
                    if (!hasMaxGuests) {
                      toast.success(
                        "Your studio room should be able to host at least one guest to enable recording services. Please update your studio room settings to enable recording services.",
                      );
                      setShowEditStudioRoomModal(true);
                    }
                  }}
                  openBookingFlow={openBookingFlow}
                />
              </div>
            </div>
          </div>
          <Card hideOverflow customClassName="studio-room-reviews-card">
            <ReviewsList studioRoom={studioRoom} hideIfNoReviews />
          </Card>
          <div className={!isDesktop ? " studio-split-row-column-row" : ""}>
            <div className="studio-screen-location">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                width={18}
                height={14}
                style={{ marginRight: "4px" }}
              />
              <p className="h7-semi-bold studio-location-text">
                Studio Location
              </p>
              <p className="b1">
                {studio.location
                  ? studio.location.city_location ?? "Location"
                  : "Location"}
              </p>
            </div>
            <div
              className={
                "studio-hero-section studio-map-section map-display-container"
              }
            >
              {isLoaded &&
                studio.location?.rough_latitude &&
                studio.location?.rough_longitude && (
                  <MapDisplay
                    mapContainerStyle={{
                      width: "100%",
                      height: "500px",
                    }}
                    latitude={studio.location.rough_latitude}
                    longitude={studio.location.rough_longitude}
                  />
                )}
            </div>
          </div>
        </div>
        {studioRoom && (
          <EditStudioRoomModal
            studio={studio}
            onHide={() => {
              setShowEditStudioRoomModal(false);
              if (!isRecordingServiceSetup && userStudioServicesEnabled) {
                setShowRecordingServiceModal(true);
              }
            }}
            show={showEditStudioRoomModal}
            showXButton={true}
            studioRoom={studioRoom}
            username={studio.username}
          />
        )}

        {showRecordingServiceModal && (
          <RecordingRoomServiceModal
            canManageStudio={canEditStudioService}
            studioRoom={studioRoom}
            onHide={() => {
              if (showRecordingServiceModal)
                setShowRecordingServiceModal(false);
            }}
            show={showRecordingServiceModal}
            showXButton={true}
            studioId={studio.id}
          />
        )}
        <EntityPhotosScreen
          shareUrl={shareUrl}
          onClose={() => setShowStudioPhotos(false)}
          canManageStudio={canEditStudio}
          username={username}
          studioRoom={studioRoom}
          slideUp={showStudioPhotos}
        />
        {bookStudioStickyButton}
      </div>
      <div className="studio-screen-footer">
        <DevLinkProvider>
          <EngineEarsFooter2024 />
        </DevLinkProvider>
      </div>
    </>
  );
};

export default StudioRoomScreen;
