import { ProjectById } from "../../../../store/models/project";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { CompletePaymentButton } from "../../InProgressTransitionView/components/CompletePaymentButton";
import { InProgressButtonRow } from "../../InProgressTransitionView/InProgressTransitionView.styles";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { CompleteTransitionViewIndex } from "../CompleteTransitionView";
import { ApproveAssetsButton } from "./ApproveAssetsButton";
import { RequestReuploadButton } from "./RequestReuploadButton";

export interface CompleteArtistButtonRowProps {
  transitionToDownloadFiles: () => void;
  transitionToMainView: () => void;
  project: ProjectById;
  isDeliverablesUploaded: boolean;
  isPendingBudgetApproval: boolean;
  isPendingBillingInfo: boolean;
  code?: string;
  isPartiallyPaid?: boolean;
  allowFileDownload?: boolean;
  isCompletePaymentPopoverOpen: boolean;
  setIsCompletePaymentPopoverOpen: (status: boolean) => void;
  closeCompletePaymentPopover: () => void;
  outstandingBalance?: number;
  viewIndex: CompleteTransitionViewIndex;
}

export const CompleteArtistButtonRow = ({
  transitionToDownloadFiles,
  project,
  isDeliverablesUploaded,
  transitionToMainView,
  isPendingBudgetApproval,
  isPendingBillingInfo,
  code,
  isCompletePaymentPopoverOpen,
  setIsCompletePaymentPopoverOpen,
  closeCompletePaymentPopover,
  isPartiallyPaid = false,
  allowFileDownload = true,
  outstandingBalance,
  viewIndex,
}: CompleteArtistButtonRowProps) => {
  const assetsApproved = Boolean(project.assets_approved);
  const isDownloadFilesView =
    viewIndex === CompleteTransitionViewIndex.DOWNLOAD_FILES;

  const renderMainButton = () => {
    if (isDownloadFilesView && !assetsApproved) {
      return (
        <ApproveAssetsButton
          isDeliverablesUploaded={isDeliverablesUploaded}
          projectId={project.id}
        />
      );
    }

    return allowFileDownload ? (
      <Button
        disabled={isPendingBudgetApproval}
        onClick={transitionToDownloadFiles}
        fullWidth
        variant={ButtonVariant.PRIMARY}
      >
        Download project files
      </Button>
    ) : (
      <CompletePaymentButton
        project={project}
        code={code}
        isPartiallyPaid={isPartiallyPaid}
        isCompletePaymentPopoverOpen={isCompletePaymentPopoverOpen}
        setIsCompletePaymentPopoverOpen={setIsCompletePaymentPopoverOpen}
        closeCompletePaymentPopover={closeCompletePaymentPopover}
        outstandingBalance={outstandingBalance}
      />
    );
  };

  return (
    <MainProjectWorkflowButtonRow>
      <InProgressButtonRow>
        <RequestReuploadButton
          disabled={
            isPendingBillingInfo ||
            isPendingBudgetApproval ||
            !allowFileDownload
          }
          transitionView={transitionToMainView}
          isDeliverablesUploaded={isDeliverablesUploaded}
          project={project}
        />
        {renderMainButton()}
      </InProgressButtonRow>
    </MainProjectWorkflowButtonRow>
  );
};
