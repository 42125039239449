import "./ActionItems.css";
import { ActionItemRow } from "../../elements/ActionItemRow/ActionItemRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { DashboardCardHeader } from "../DashboardCardHeader/DashboardCardHeader";
import Pagination from "rc-pagination";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { useGetPaginatedAdminActions } from "../../../hooks/useAdminActions";
import { useState } from "react";

export interface ActionItemsProps {
  onSidePanel?: boolean;
  onClick?: () => void;
  pageSize?: number;
}

const PAGE_SIZE = 4;

export const ActionItems = ({
  onSidePanel = false,
  onClick,
  pageSize = PAGE_SIZE,
}: ActionItemsProps) => {
  const [isPendingTab, setIsPendingTab] = useState<boolean>(true);
  const { paginatedAdminActions, isLoading, page, setPage } =
    useGetPaginatedAdminActions(!isPendingTab, pageSize);

  return (
    <div className="action-items-container">
      {onSidePanel && (
        <div className="action-items-header-container ">
          <p
            onClick={() => setIsPendingTab(true)}
            className={"dashboard-card-header__title ".concat(
              isPendingTab ? "active" : "",
            )}
            style={{ cursor: "pointer" }}
          >
            Your Action Items
          </p>
          <p
            onClick={() => setIsPendingTab(false)}
            style={{ cursor: "pointer" }}
            className={"dashboard-card-header__title ".concat(
              !isPendingTab ? "active" : "",
            )}
          >
            Completed Action Items
          </p>
        </div>
      )}
      {!onSidePanel && (
        <DashboardCardHeader
          title={isPendingTab ? "Your Action Items" : "Completed Action Items"}
          actionButton={
            onSidePanel ? null : (
              <FontAwesomeIcon
                onClick={onClick}
                icon={faChevronRight}
                color={"var(--medium-grey)"}
                style={{ cursor: "pointer" }}
              />
            )
          }
        />
      )}
      <div className="action-items-column-container">
        {isLoading && <SoundWaveLoader height={100} width={100} />}
        {!isLoading && !paginatedAdminActions.data.length && (
          <div className="action-items-empty-list-container">
            <p
              style={{
                color: "var(--text-secondary-color)",
                width: "fit-content",
                whiteSpace: "normal",
              }}
              className="b1 empty-text"
            >
              You don&apos;t have any Action Items currently. When someone on
              your team needs your help, you will see your Action Items here.{" "}
            </p>
          </div>
        )}
        {!isLoading &&
          paginatedAdminActions.data.length !== 0 &&
          paginatedAdminActions.data.map((actionItem) => (
            <ActionItemRow
              key={actionItem.id}
              actionItem={actionItem}
              completedOverride={!isPendingTab}
            />
          ))}
      </div>
      <Pagination
        current={page}
        total={paginatedAdminActions.count}
        pageSize={pageSize}
        onChange={(nextPage) => {
          if (paginatedAdminActions.num_pages < nextPage) {
            return;
          }
          setPage(nextPage);
        }}
      />
    </div>
  );
};
