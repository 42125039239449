import Service from "../store/models/service";
import User from "../store/models/user";
import { ProjectType } from "../store/models/project";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../store/hooks";
import { toast } from "react-toastify";
import { emitAnalyticsTrackingEvent } from "../utils/analyticsUtils";
import {
  ServiceOperations,
  engineerServiceCrud,
} from "../store/actions/services";
import { OptionType } from "../stories/elements/DropDownSelector/DropdownSelector";

export const MINIMUM_DEPOSIT = 25;
export const MAXIMUM_DEPOSIT = 75;
export const DEFAULT_DEPOSIT = 100;

const useManageEngineeringService = (
  initialService: Service,
  engineerUser: User,
  selectedServiceType: ProjectType,
  onSuccessfulSubmit?: (service: Service) => void,
  onDeleteService?: () => void,
) => {
  const [allowPartialPayments, setAllowPartialPayments] = useState<boolean>(
    initialService.minimum_deposit_percentage < 1,
  );
  const [addMasteringService, setAddMasteringService] = useState<boolean>(
    Boolean(initialService?.add_master_discount_percentage),
  );
  const [addAtmosMixingService, setAddAtmosMixingService] = useState<boolean>(
    Boolean(initialService?.add_atmos_mix_discount_percentage),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingService, setPendingService] = useState<Service>(initialService);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!allowPartialPayments) {
      handleDepositPercentage(100);
    }
  }, [allowPartialPayments]);

  useEffect(() => {
    if (initialService.service_type !== pendingService.service_type) {
      setPendingService(initialService);
    }
  }, [initialService]);

  const initialDepositPercentage = useMemo(() => {
    const initialDepositPercentage = pendingService.minimum_deposit_percentage
      ? +pendingService.minimum_deposit_percentage * DEFAULT_DEPOSIT
      : DEFAULT_DEPOSIT;
    if (initialDepositPercentage === DEFAULT_DEPOSIT) return MAXIMUM_DEPOSIT;
    if (initialDepositPercentage < MINIMUM_DEPOSIT) return MINIMUM_DEPOSIT;
    if (initialDepositPercentage > MAXIMUM_DEPOSIT) return MAXIMUM_DEPOSIT;
    return initialDepositPercentage;
  }, [allowPartialPayments, pendingService]);

  const updateMP3DownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_mp3_file_download: value,
    });
  };

  const updateWAVDownloadPreference = (value: boolean) => {
    setPendingService({
      ...pendingService,
      enable_artist_review_wav_file_download: value,
    });
  };

  const updateCostPerExtraAlt = useCallback(
    (percentageCost: number) => {
      const rate = pendingService.service_rate?.price;
      if (!rate) return;
      const altPrice = (rate * percentageCost) / 100;
      const extra_alt_price = Math.round(altPrice);
      setPendingService({
        ...pendingService,
        extra_alt_price,
      });
    },
    [pendingService],
  );

  const updateExtraRevisionPrice = useCallback(
    (percentageCost: number) => {
      const rate = pendingService.service_rate?.price;
      if (!rate) return;
      const calculatedPrice = (rate * percentageCost) / 100;
      const extra_revision_price = Math.round(calculatedPrice);
      setPendingService((prev) => ({
        ...prev,
        extra_revision_price,
      }));
    },
    [pendingService],
  );

  const manageEngineeringService = useCallback(
    async (operation: ServiceOperations) => {
      if (
        allowPartialPayments &&
        pendingService.minimum_deposit_percentage < 0.25
      ) {
        toast.error("Minimum deposit percentage must be at least 25%");
        return;
      }
      if (
        allowPartialPayments &&
        !pendingService.partial_payment_paywall_option
      ) {
        toast.error("Partial payment paywall option must be set");
        return;
      }

      emitAnalyticsTrackingEvent(
        operation === ServiceOperations.UPDATE
          ? "update_engineering_service"
          : operation === ServiceOperations.CREATE
            ? "add_engineering_service"
            : "delete_engineering_service",
        {
          service_type: `${selectedServiceType}`,
        },
        engineerUser?.id,
      );

      setLoading(true);

      try {
        await dispatch(
          engineerServiceCrud({
            ...pendingService,
            service_rate: {
              ...pendingService.service_rate,
              user_id: engineerUser.id,
            },
            operation: operation,
          }),
        ).unwrap();
        setLoading(false);
        if (operation === ServiceOperations.DELETE) {
          toast.success("Service deleted");
          onDeleteService?.();
          return;
        }
        onSuccessfulSubmit?.(pendingService);
      } catch (error) {
        setLoading(false);
        toast.error(
          operation === ServiceOperations.DELETE
            ? "Error deleting service, please reach out to customer support"
            : "Error creating service, please reach out to customer support",
        );
      }
    },
    [
      allowPartialPayments,
      selectedServiceType,
      engineerUser,
      pendingService,
      addMasteringService,
    ],
  );

  const updateNumberOfFreeRevisions = useCallback(
    (updatedNumber: OptionType) => {
      setPendingService((prevState) => ({
        ...prevState,
        free_revisions: updatedNumber.value,
      }));
    },
    [],
  );

  const changeServiceRatePrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          price: updatedPrice,
        },
      } as Service;
    });
  }, []);

  const changeServiceRateForLabelPrice = useCallback((updatedPrice: number) => {
    setPendingService((prevState: Service) => {
      return {
        ...prevState,
        service_rate: {
          ...prevState.service_rate,
          label_price: updatedPrice,
        },
      } as Service;
    });
  }, []);

  const handleDepositPercentage = useCallback((depositPercentage: number) => {
    if (depositPercentage < MINIMUM_DEPOSIT) {
      toast.error("Minimum deposit percentage must be at least 25%");
      return;
    }
    if (
      depositPercentage > MAXIMUM_DEPOSIT &&
      depositPercentage < DEFAULT_DEPOSIT
    ) {
      toast.error("Maximum deposit percentage must be 100%");
      return;
    }
    setPendingService((prevState: Service) => ({
      ...prevState,
      minimum_deposit_percentage: depositPercentage / DEFAULT_DEPOSIT,
    }));
  }, []);

  const handlePartialPaymentPaywallOption = useCallback(
    (option: OptionType) => {
      setPendingService((prevState: Service) => {
        return {
          ...prevState,
          partial_payment_paywall_option: option.value,
        } as Service;
      });
    },
    [],
  );

  return {
    loading,
    allowPartialPayments,
    setAllowPartialPayments,
    addMasteringService,
    setAddMasteringService,
    addAtmosMixingService,
    setAddAtmosMixingService,
    pendingService,
    setPendingService,
    manageEngineeringService,
    handleDepositPercentage,
    updateCostPerExtraAlt,
    updateExtraRevisionPrice,
    initialDepositPercentage,
    changeServiceRateForLabelPrice,
    updateNumberOfFreeRevisions,
    changeServiceRatePrice,
    updateWAVDownloadPreference,
    updateMP3DownloadPreference,
    handlePartialPaymentPaywallOption,
  };
};

export default useManageEngineeringService;
