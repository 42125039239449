import React, { useEffect, useMemo } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { useImageURLFromPath } from "../../../../../../hooks/useImageURLFromPath";
import { SCREENS } from "../../../../../../routes";
import {
  setStudio,
  setUser,
} from "../../../../../../store/actions/selectedProfile";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { Studio } from "../../../../../../store/models/studio";
import User from "../../../../../../store/models/user";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../../../store/utils/routeGetters";
import defaultUser from "../../../../../../stories/assets/defaultuser.jpg";
import { CheckBox } from "../../../../../core-ui/components/CheckBox/CheckBox";
import {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../../../../core-ui/components/Text/Text";
import "./ProfileRow.css";

export interface ProfileRowProps {
  studio?: Studio;
  user?: User;
  onClick: () => void;
}

export const ProfileRow = ({ studio, user, onClick }: ProfileRowProps) => {
  const loggedInUsername = useAppSelector(
    (state) => state.accountInfo?.user?.username,
  );
  const history = useHistory();
  const userImage = useImageURLFromPath(false, user?.photo?.path);
  const studioImage = useImageURLFromPath(true, studio?.photo?.path);
  const dispatch = useAppDispatch();
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const [loadImageFailed, setLoadImageFailed] = React.useState(false);
  const matchProfileScreen = matchPath(history.location.pathname, {
    path: SCREENS.PROFILE_SCREEN,
    exact: true,
    strict: false,
  });
  const matchStudioScreen = matchPath(history.location.pathname, {
    path: SCREENS.STUDIO_SCREEN,
    exact: true,
    strict: false,
  });

  const onCurrentProfile =
    matchProfileScreen &&
    // @ts-ignore
    matchProfileScreen.params.username === loggedInUsername;
  const isOnProfileScreen = Boolean(onCurrentProfile || matchStudioScreen);

  const selected = useMemo(() => {
    if (studio) {
      return selectedProfile.studio?.id === studio.id;
    }
    return selectedProfile.user?.id === user?.id;
  }, [selectedProfile, studio, user]);

  useEffect(() => {
    if (
      studio &&
      selectedProfile.studio &&
      selectedProfile.studio.id === studio.id
    ) {
      const cachedStudio = JSON.stringify(selectedProfile.studio);
      const fetchedStudio = JSON.stringify(studio);
      if (cachedStudio !== fetchedStudio) {
        dispatch(setStudio(studio));
      }
    }
    if (user && selectedProfile.user && selectedProfile.user.id === user.id) {
      const cachedUser = JSON.stringify(selectedProfile.user);
      const fetchedUser = JSON.stringify(user);
      if (cachedUser !== fetchedUser) {
        dispatch(setUser(user));
      }
    }
  }, [dispatch, selectedProfile, studio, user]);

  const { username, imagePath } = useMemo(() => {
    if (studio) {
      return {
        username: studio?.username,
        imagePath: studioImage,
      };
    }
    return {
      username: user?.username,
      imagePath: userImage,
    };
  }, [studio, user, userImage, studioImage]);

  const url = useMemo(() => {
    if (studio) {
      return getStudioScreenRoute(studio.username);
    }
    if (!user) return "";
    return getProfileScreenRoute(user.username);
  }, [studio, user]);

  const currentPath = window.location.pathname;

  const handleSettingSelectedProfile = () => {
    if (selected) {
      if (currentPath === url) {
        onClick();
        return;
      }
      history.push(url);
      onClick();
    }
    if (studio) {
      dispatch(setStudio(studio));
      if (currentPath === url) {
        onClick();
        return;
      }
      if (isOnProfileScreen)
        history.push(getStudioScreenRoute(studio.username));
      onClick();
    }
    if (user) {
      dispatch(setUser(user));
      if (currentPath === url) {
        onClick();
        return;
      }
      if (isOnProfileScreen) history.push(getProfileScreenRoute(user.username));
      onClick();
    }
  };

  return (
    <div className="profile-row" onClick={handleSettingSelectedProfile}>
      <div className="profile-row-right">
        <div className="profile-row__image">
          <img
            src={loadImageFailed ? defaultUser : imagePath}
            onError={() => setLoadImageFailed(true)}
            alt="profile"
          />
        </div>
        <div className="profile-row__info__name">
          <Text
            color={selected ? TEXT_COLOR.PRIMARY : TEXT_COLOR.SECONDARY}
            size={TEXT_SIZE.XS}
            weight={selected ? TEXT_WEIGHT.BOLD : TEXT_WEIGHT.MEDIUM}
          >
            @{username}
          </Text>
        </div>
      </div>
      <CheckBox className="profile-row__info__selected" checked={selected} />
    </div>
  );
};
