import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { DetailedPurchaseOrderWithTransaction } from "../store/models/project";

export const useBudgetManagerForLink = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(() => purchaseOrder?.budget_manager_for_link, [purchaseOrder]);
};

export const usePurchaseOrderBudgetManagers = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(() => purchaseOrder?.budget_managers ?? [], [purchaseOrder]);
};

export const useIsBudgetApproved = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(
    () => Boolean(purchaseOrder?.budget_approved),
    [purchaseOrder],
  );
};

export const useShowBudgetApprovalCTA = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  const budgetManager = useBudgetManagerForLink(purchaseOrder);
  const isBudgetApproved = useIsBudgetApproved(purchaseOrder);
  return useMemo(() => {
    if (isBudgetApproved) return false;
    if (!budgetManager) return false;
    const submittedApproval =
      Boolean(budgetManager.budget_approved) ||
      Boolean(budgetManager.budget_rejected);
    return budgetManager.can_approve_budget && !submittedApproval;
  }, [purchaseOrder, budgetManager, isBudgetApproved]);
};

export const useIsLoggedInUserArtistOnPurchaseOrderTransaction = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  return useMemo(() => {
    if (!loggedInUser) return false;
    return (
      purchaseOrder?.purchase_order_transaction[0].user?.id === loggedInUser.id
    );
  }, [purchaseOrder, loggedInUser]);
};

export const useShowBillingInfoCTA = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  const isLoggedInUserArtist =
    useIsLoggedInUserArtistOnPurchaseOrderTransaction(purchaseOrder);
  const budgetManager = useBudgetManagerForLink(purchaseOrder);
  const showBudgetApprovalCTA = useShowBudgetApprovalCTA(purchaseOrder);
  return useMemo(() => {
    return (
      isLoggedInUserArtist ||
      (budgetManager?.can_submit_billing_info && !showBudgetApprovalCTA)
    );
  }, [isLoggedInUserArtist, budgetManager]);
};

export const useIsUMGAandR = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(() => {
    return Boolean(
      purchaseOrder?.purchase_order_transaction[0].user?.is_umg_aandr,
    );
  }, [purchaseOrder]);
};

export const useIsPurchaseOrderAandR = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  const isUmgAandR = useIsUMGAandR(purchaseOrder);
  return useMemo(() => {
    return (
      Boolean(
        purchaseOrder?.purchase_order_transaction[0].user?.is_umg_po_aandr,
      ) || !isUmgAandR
    );
  }, [purchaseOrder]);
};

export const useBillingInfoOrPurchaseOrderSubmitted = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(() => {
    const purchaseOrderSubmitted = Boolean(purchaseOrder?.order_number);
    const billingInfoSubmitted = Boolean(
      purchaseOrder?.cost_center &&
        purchaseOrder?.general_ledger &&
        purchaseOrder?.work_breakdown_structure,
    );
    return {
      purchaseOrderSubmitted,
      billingInfoSubmitted,
    };
  }, [purchaseOrder]);
};

export const useBillingInfoSubmitterEmails = (
  purchaseOrder?: DetailedPurchaseOrderWithTransaction,
) => {
  return useMemo(() => {
    return (
      purchaseOrder?.budget_managers
        .filter((bm) => bm.can_submit_billing_info)
        .reduce((acc, bm) => {
          return acc.concat(bm.email);
        }, [] as string[]) ?? []
    );
  }, [purchaseOrder]);
};
