import { useMemo, useCallback, useState } from "react";
import {
  deleteFavoritedAccount,
  createFavoritedAccount,
} from "../actions/dashboard";
import { useAppSelector, useAppDispatch } from "../hooks";
import { Studio, StudioRoom } from "./studio";
import User from "./user";
import {
  getDisplayableNameForUser,
  getDisplayableNameForStudioRoom,
  getDisplayableNameForStudio,
} from "../utils/entityUtils";
import { getResizeURL } from "../utils";
import { toggleLoginModal } from "../actions/userInterface";
import {
  getStudioScreenRoute,
  getStudioRoomScreenRoute,
  getProfileScreenRoute,
} from "../utils/routeGetters";

export interface FavoritedAccount {
  id: number;
  user: number;
  liked_user: number | null;
  liked_studio: number | null;
  liked_studio_room: number | null;
  created: string;
  deleted: string | null;
}

export interface FavoritedAccountEntity {
  id: number;
  user: User;
  liked_user: User | null;
  liked_studio: Studio | null;
  liked_studio_room: StudioRoom | null;
  created: string;
  deleted: string | null;
}

export const convertFavoritedAccountEntity = (
  account: FavoritedAccountEntity,
): FavoritedAccount => {
  return {
    id: account.id,
    user: account.user.id,
    liked_user: account.liked_user?.id || null,
    liked_studio: account.liked_studio?.id || null,
    liked_studio_room: account.liked_studio_room?.id || null,
    created: account.created,
    deleted: account.deleted,
  };
};

export interface FavoriteHeartProps {
  liked_user?: number;
  liked_studio?: number;
  liked_studio_room?: number;
}

export const useFavoriteHeartLogic = ({
  liked_user,
  liked_studio,
  liked_studio_room,
}: FavoriteHeartProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const { userFavorites } = useAppSelector((state) => state.dashboard);
  const [updating, setUpdating] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isFilled = useMemo(() => {
    if (liked_user) {
      return userFavorites?.liked_users.includes(liked_user);
    }
    if (liked_studio) {
      return userFavorites?.liked_studios.includes(liked_studio);
    }
    if (liked_studio_room) {
      return userFavorites?.liked_studio_rooms.includes(liked_studio_room);
    }
  }, [liked_user, liked_studio, liked_studio_room, userFavorites]);

  const handleDelete = () => {
    setUpdating(true);
    void dispatch(
      deleteFavoritedAccount({
        liked_user,
        liked_studio,
        liked_studio_room,
      }),
    ).finally(() => {
      setUpdating(false);
    });
  };

  const handleCreate = () => {
    setUpdating(true);
    void dispatch(
      createFavoritedAccount({
        liked_user,
        liked_studio,
        liked_studio_room,
      }),
    ).finally(() => {
      setUpdating(false);
    });
  };

  const handleOnClick: React.MouseEventHandler<
    HTMLButtonElement | SVGSVGElement
  > = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isAuthenticated) {
        return dispatch(toggleLoginModal());
      }
      if (updating) {
        return;
      }
      return isFilled ? handleDelete() : handleCreate();
    },
    [isFilled, updating, isAuthenticated, dispatch],
  );

  if (
    (liked_user === undefined &&
      liked_studio === undefined &&
      liked_studio_room === undefined) ||
    (liked_user !== undefined && liked_studio !== undefined) ||
    (liked_user !== undefined && liked_studio_room !== undefined) ||
    (liked_studio !== undefined && liked_studio_room !== undefined)
  ) {
    return {
      isFilled,
      handleOnClick: undefined,
      updating,
    };
  }

  return {
    isFilled,
    handleOnClick,
    updating,
  };
};

export const useFavoritedAccount = (account: FavoritedAccountEntity) => {
  const path = useMemo(() => {
    if (account.liked_studio) {
      return account.liked_studio.photo?.path;
    }
    if (account.liked_studio_room) {
      return account.liked_studio_room.photo?.path;
    }
    if (account.liked_user) {
      return account.liked_user.photo?.path;
    }
  }, [account]);

  const url = getResizeURL(
    Boolean(account.liked_studio || account.liked_studio_room),
    path,
  );

  const displayName = useMemo(() => {
    if (account.liked_user) {
      return getDisplayableNameForUser(account.liked_user);
    }
    if (account.liked_studio_room) {
      return getDisplayableNameForStudioRoom(account.liked_studio_room);
    }
    if (account.liked_studio) {
      return getDisplayableNameForStudio(account.liked_studio);
    }
    return "";
  }, [account]);

  const username = useMemo(() => {
    if (account.liked_user) {
      return account.liked_user.username;
    }
    if (account.liked_studio_room) {
      return account.liked_studio_room?.studio?.username;
    }
    if (account.liked_studio) {
      return account.liked_studio.username;
    }
    return "";
  }, [account]);

  const accountTypes: string = useMemo(() => {
    if (account.liked_user) {
      const types = [];
      if (
        Boolean(account.liked_user.engineer) &&
        !account.liked_user.engineer?.deleted
      ) {
        types.push("Engineer");
      }
      if (
        Boolean(account.liked_user.artist) &&
        !account.liked_user.artist?.deleted
      ) {
        types.push("Artist");
      }
      if (
        account.liked_user.studio_manager &&
        !account.liked_user.studio_manager?.deleted
      ) {
        types.push("Studio Manager");
      }
      return types.join(", ");
    }
    if (account.liked_studio_room) {
      return "Studio Room";
    }
    if (account.liked_studio) {
      return "Studio";
    }
    return "";
  }, [account]);

  const navigationPath = useMemo(() => {
    if (account.liked_user) {
      return getProfileScreenRoute(account.liked_user.username);
    }
    if (
      account.liked_studio_room &&
      Boolean(account.liked_studio_room?.studio?.username)
    ) {
      const studioUsername = account.liked_studio_room.studio?.username;
      if (!studioUsername) return "";
      return getStudioRoomScreenRoute(
        studioUsername,
        account.liked_studio_room.id,
      );
    }
    if (account.liked_studio) {
      return getStudioScreenRoute(account.liked_studio.username);
    }
    return "";
  }, [account]);

  return {
    url,
    displayName,
    username,
    accountTypes: accountTypes,
    navigationPath,
  };
};
