import { PortfolioFeatureData } from "../store/models/portfolio";
import {
  AtmosProjectSteps,
  MasteringProjectSteps,
  MasteringWorkflowTranslation,
  MixingProjectSteps,
  MixingWorkflowTranslation,
  Project,
  ProjectById,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../store/models/project";
import { SessionSteps } from "../store/models/recordingSession";
import { ScheduledProject } from "../store/models/scheduledproject";
import User, { UserLite } from "../store/models/user";
import { TaskMessageType } from "../stories/components/ProjectWorkflowPanel/components/ProjectWorkflowTasksRow/ProjectWorkflowTaskMessage";

export const determineIfProjectType = (
  project?: Project | ProjectById | PortfolioFeatureData,
): project is Project => {
  return (project as Project).artist_file_link !== undefined;
};

export const projectIsInReviewStepOrBeyond = (project?: Project) => {
  if (!project) return false;
  const { step, service_type } = project;
  if (service_type === ProjectType.MASTERING) {
    return (
      step >= MasteringProjectSteps.MASTER_REVIEW &&
      step < MasteringProjectSteps.MASTER_FINISHED &&
      step !== MasteringProjectSteps.MASTER_REVISION
    );
  }
  if (service_type === ProjectType.ATMOS_MIXING) {
    return (
      (step >= AtmosProjectSteps.MIX_REVIEW &&
        step < AtmosProjectSteps.MIX_FINISHED &&
        step !== AtmosProjectSteps.MIX_REVISION) ||
      (step >= AtmosProjectSteps.ATMOS_MIX_REVIEW &&
        step !== AtmosProjectSteps.ATMOS_MIX_REVISION)
    );
  }
  return (
    step >= MixingProjectSteps.MIX_REVIEW &&
    step < MixingProjectSteps.MIX_FINISHED &&
    step !== AtmosProjectSteps.MIX_REVISION
  );
};

export const getMixingMasteringProject = (
  project: ProjectById | undefined | null,
) => {
  if (!project) return null;
  return project.service_type !== ProjectType.MASTERING
    ? project.mixing_project
    : project.mastering_project;
};

export const getArtistFromProject = (
  project: ProjectById | undefined | null,
) => {
  if (!project) return null;
  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return null;

  const artist = project?.users.find(
    (user) => user.artist?.id === mixingMasteringProject?.artist_id,
  );

  return artist;
};

/**
 * @description A project is considered approve when it is past the review step and not in a revision
 */
export const isProjectApproved = (
  projectType?: ProjectType,
  step?:
    | MixingProjectSteps
    | MasteringProjectSteps
    | AtmosProjectSteps
    | SessionSteps,
) => {
  if (!projectType || !step) return false;
  if (projectType === ProjectType.MASTERING) {
    return (
      step > MasteringProjectSteps.MASTER_REVISION &&
      step <= MasteringProjectSteps.MASTER_PUBLISHED
    );
  }
  if (projectType === ProjectType.ATMOS_MIXING) {
    return (
      step > AtmosProjectSteps.MIX_REVISION &&
      step !== AtmosProjectSteps.ATMOS_MIX_REVISION
    );
  }
  if (
    projectType === ProjectType.MIXING ||
    projectType === ProjectType.TWO_TRACK_MIXING
  ) {
    return (
      step > MixingProjectSteps.MIX_REVISION &&
      step <= MixingProjectSteps.PUBLISHED
    );
  }
  return false;
};

/**
 * translate the project/session step to the corresponding workflow step based on the type of project
 */
export const getProjectWorkflowStep = (
  projectType: ProjectType,
  projectStep: MasteringProjectSteps | MixingProjectSteps | SessionSteps,
) => {
  switch (projectType) {
    case ProjectType.MASTERING:
      return MasteringWorkflowTranslation[projectStep as MasteringProjectSteps];
    case ProjectType.MIXING:
      return MixingWorkflowTranslation[projectStep as MixingProjectSteps];
    default:
      return MixingWorkflowTranslation[projectStep as MixingProjectSteps];
  }
};

export const canArtistDownloadDeliverables = (project: ProjectById) => {
  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return false;

  const engineerEnabledDownloads =
    mixingMasteringProject.enable_artist_review_wav_file_download;
  const projectWorkflowStep = getProjectWorkflowStep(
    project.service_type,
    mixingMasteringProject.step,
  );
  const isReviewStepOrComplete =
    projectWorkflowStep !== ProjectWorkflowSteps.FILE_TRANSFER &&
    projectWorkflowStep !== ProjectWorkflowSteps.IN_MIX;

  return engineerEnabledDownloads && isReviewStepOrComplete;
};

export const getUserTypeFromProject = (
  user: User | undefined,
  project: ProjectById | undefined | null,
) => {
  if (!user || !project) return null;
  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return null;

  if (user.artist?.id === mixingMasteringProject.artist_id) {
    return ProjectUserType.ARTIST;
  }

  if (user.engineer?.id === mixingMasteringProject.engineer_id) {
    return ProjectUserType.ENGINEER;
  }

  return null;
};

export const isUserCollaboratorOnProject = (
  currentUser: User | undefined,
  projectUsers: UserLite[] | User[] | undefined,
) => {
  return Boolean(projectUsers?.some((user) => user.id === currentUser?.id));
};

export const getPrereqProjectTitle = (
  prereqProjectId: number | undefined,
  projects: Project[] | undefined,
) => {
  if (!prereqProjectId) {
    return "";
  }

  return (
    projects?.find((project) => project.id === prereqProjectId)?.title ?? ""
  );
};

export const getUserIsEngineerOnScheduledProject = (
  user: User | undefined,
  scheduledProject: ScheduledProject,
) => {
  return user && scheduledProject.projects[0].engineer?.id === user.id;
};

export const getOutstandingScheduledProjectBalance = (
  isPartiallyPaid: boolean,
  scheduledProject: ScheduledProject,
) => {
  return isPartiallyPaid
    ? scheduledProject.outstanding_balance
    : scheduledProject.total_price + scheduledProject.fees_collected;
};

export const getEngineerFromProject = (project?: Project | ProjectById) => {
  if (!project) return undefined;
  if (determineIfProjectType(project)) {
    return project.engineer;
  }
  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return null;

  return project?.users.find(
    (user) => user.engineer?.id === mixingMasteringProject?.engineer_id,
  );
};
export const isEngineerForProject = (
  project?: Project | ProjectById,
  user?: User,
) => {
  if (!project || !user) return false;

  const engineerForProject = getEngineerFromProject(project);
  return engineerForProject?.id === user.id;
};

export const getProjectsFromScheduledProject = (
  scheduledProject: ScheduledProject,
) => {
  const { mixing_projects, mastering_projects } = scheduledProject;
  const projects: Project[] = [];
  mixing_projects.forEach((mixingProject) => {
    if (mixingProject.project !== undefined)
      projects.push(mixingProject.project);
  });
  mastering_projects.forEach((masteringProject) => {
    if (masteringProject.project !== undefined)
      projects.push(masteringProject.project);
  });
  return projects;
};

export const isProjectInReviewStepOrBeyond = (
  projectType?: ProjectType,
  step?:
    | MixingProjectSteps
    | MasteringProjectSteps
    | AtmosProjectSteps
    | SessionSteps,
) => {
  if (!projectType || !step) return false;
  if (projectType === ProjectType.MASTERING) {
    return (
      step >= MasteringProjectSteps.MASTER_REVIEW &&
      step <= MasteringProjectSteps.MASTER_PUBLISHED &&
      step !== MasteringProjectSteps.MASTER_REVISION
    );
  }
  if (projectType === ProjectType.ATMOS_MIXING) {
    return (
      step >= AtmosProjectSteps.MIX_REVIEW &&
      step <= AtmosProjectSteps.PUBLISHED &&
      step !== AtmosProjectSteps.MIX_REVISION
    );
  }
  if (
    projectType === ProjectType.MIXING ||
    projectType === ProjectType.TWO_TRACK_MIXING
  ) {
    return (
      step >= MixingProjectSteps.MIX_REVIEW &&
      step <= MixingProjectSteps.PUBLISHED &&
      step !== MixingProjectSteps.MIX_REVISION
    );
  }
  return false;
};

export const isUserArtistForProject = (
  project?: Project | ProjectById,
  user?: User,
) => {
  if (!project || !user) return false;
  if (determineIfProjectType(project)) {
    return user.id === project.artist?.id;
  }
  const mixingMasteringProject = getMixingMasteringProject(project);
  if (!mixingMasteringProject) return false;
  return user.artist?.id === mixingMasteringProject.artist_id;
};

/**
 * The final step (Mix Approved) actuallly has two steps - the second of which is unaccounted for
 * This checks if the project is in the second final step
 */
export const areDeliverablesUploaded = (
  projectType: ProjectType,
  step: MixingProjectSteps | MasteringProjectSteps | AtmosProjectSteps,
) => {
  return projectType === ProjectType.MASTERING
    ? step === MasteringProjectSteps.MASTER_FINISHED
    : step >= MixingProjectSteps.STEMS_UPLOADED;
};

/**
 * Since the final step has two steps, we need to create the task message for the second final step
 */
export const getFinishedTaskMessage = (
  userType: ProjectUserType | null,
  projectType: ProjectType,
  step: MixingProjectSteps | MasteringProjectSteps | AtmosProjectSteps,
): TaskMessageType | undefined => {
  const isFinished = areDeliverablesUploaded(projectType, step);
  if (!isFinished || !userType) return;

  return userType === ProjectUserType.ENGINEER
    ? { message: "Leave a review for your collaborator", tooltipText: "" }
    : { message: "Download final deliverables", tooltipText: "" };
};

// Groups a list of projects into a list of primary projects
// and associated linked projects (add masters/atmos).
export const groupPrereqProjects = (projects: Project[]) => {
  const projectList: Project[] = [];
  const attachedProjectMap: Record<number, Project[]> = {};

  projects.forEach((project) => {
    const prereqId = project.prereq_project_id;
    if (!prereqId) {
      projectList.push(project);
    } else {
      if (attachedProjectMap[prereqId] === undefined) {
        attachedProjectMap[prereqId] = [];
      }

      attachedProjectMap[prereqId].push(project);
    }
  });

  return [projectList, attachedProjectMap] as [
    Project[],
    Record<number, Project[]>,
  ];
};
