import { styled, css } from "styled-components";

export const MusoRosterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 32px auto 0 auto;
  max-width: var(--max-screen-width);
`;

export const MusoRosterDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 32px;
`;

export const MusoRosterProfileData = styled.div<{ $showHoverStyles?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  background-color: inherit;

  ${({ $showHoverStyles }) => {
    if ($showHoverStyles) {
      return css`
        &:hover {
          transform: scale(1.1);
          background-color: ${({ theme }) => theme.midgroundColor};
          cursor: pointer;
        }
      `;
    }
  }};
`;

export const MusoRosterProfileImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;
