import { addDays, addMilliseconds, format, isBefore, subDays } from "date-fns";
import { DISCIPLINE_TYPE } from "../../hooks/user";
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { AltItem } from "./alts";
import { FileVersion } from "./fileVersion";
import { RecordingSession } from "./recordingSession";
import { ProjectAcceptance } from "./scheduledproject";
import { InvoiceStatus, Transaction, TransactionStatus } from "./transaction";
import { MajorLabelEnum, UMGSubLabelEnum } from "./trophy";
import User, { MockUser, MockUser1, MockUser2, UserLite } from "./user";

const NO_TIME_LIMIT = -1;
const TWENTY_FOUR_HOURS_MS = 24 * 60 * 60 * 1000;
const TWO_DAYS_MS = 2 * TWENTY_FOUR_HOURS_MS;
const THREE_DAYS_MS = 3 * TWENTY_FOUR_HOURS_MS;
const FOUR_DAYS_MS = 4 * TWENTY_FOUR_HOURS_MS;
const FIVE_DAYS_MS = 5 * TWENTY_FOUR_HOURS_MS;

export enum ProjectQueryParam {
  PROJECT_ID = "project",
  SESSION_ID = "session",
}

export enum ProjectType {
  NO_TYPE,
  RECORDING,
  MIXING,
  MASTERING,
  TWO_TRACK_MIXING,
  ATMOS_MIXING,
}

export const PROJECT_TYPE_OPTIONS = [
  { label: "All Services", value: ProjectType.NO_TYPE },
  { label: "Mixing", value: ProjectType.MIXING },
  { label: "Mastering", value: ProjectType.MASTERING },
  { label: "Dolby Atmos", value: ProjectType.ATMOS_MIXING },
];

export enum ProjectUserType {
  ARTIST = "artist",
  ENGINEER = "engineer",
}

export enum FileStorageLocation {
  ENGINEEARS,
  UMG,
}

// Decrement by 2 because Recording service is not aggregated in to the service array and NO_TYPE is not a service
export const NumberOfProjectTypes =
  Math.floor(Object.keys(ProjectType).length / 2) - 2;

export const HandoffProjectTypes = [
  ProjectType.RECORDING,
  ProjectType.ATMOS_MIXING,
  ProjectType.TWO_TRACK_MIXING,
  ProjectType.MIXING,
];

export enum MasteringProjectSteps {
  MASTERING_NO_STEP,
  MASTERING_BOOKING_REQUESTED,
  MASTERING_FILE_UPLOADED,
  MASTERING_ENG_FILE_REVIEW,
  MASTERING_FILE_NEEDS_REUPLOAD,
  IN_MASTER,
  MASTER_REVIEW,
  MASTER_REVISION,
  MASTER_FINISHED,
  MASTER_PUBLISHED,
  MASTER_CANCELLED,
  MASTER_REFUNDED,
  MASTERING_UPLOAD_ALTS,
}

export enum MixingProjectSteps {
  NO_STEP,
  BOOKING_REQUESTED,
  REFERENCE_UPLOADED,
  PROJECT_ACCEPTED,
  ENG_FILE_REVIEW,
  FILES_NEED_REUPLOAD,
  IN_MIX,
  MIX_REVIEW,
  MIX_REVISION,
  MIX_FINISHED,
  STEMS_UPLOADED,
  PUBLISHED,
  CANCELLED,
  REFUNDED,
}

export enum TrackStage {
  ALL = "ALL",
  FILE_TRANSFER = "FILE_TRANSFER",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  REVISION_IN_PROGRESS = "REVISION_IN_PROGRESS",
  FINAL_FILE_TRANSFER = "FINAL_FILE_TRANSFER",
  REFUNDED = "REFUNDED",
  COMPLETED = "COMPLETED",
}

export const TRACK_STAGE_OPTIONS = [
  { label: "All Statuses", value: TrackStage.ALL },
  { label: "File Transfer", value: TrackStage.FILE_TRANSFER },
  { label: "In-progress", value: TrackStage.IN_PROGRESS },
  { label: "In-review", value: TrackStage.IN_REVIEW },
  { label: "Revision in progress", value: TrackStage.REVISION_IN_PROGRESS },
  { label: "Final File Transfer", value: TrackStage.FINAL_FILE_TRANSFER },
  { label: "Completed", value: TrackStage.COMPLETED },
  { label: "Refunded", value: TrackStage.REFUNDED },
];

export const SERVICE_OPTIONS = [
  { label: "All Services", value: ProjectType.NO_TYPE },
  { label: "Mixing", value: ProjectType.MIXING },
  { label: "Mastering", value: ProjectType.MASTERING },
  { label: "Dolby Atmos", value: ProjectType.ATMOS_MIXING },
];

export enum AtmosProjectSteps {
  NO_STEP,
  BOOKING_REQUESTED,
  REFERENCE_UPLOADED,
  PROJECT_ACCEPTED,
  ENG_FILE_REVIEW,
  FILES_NEED_REUPLOAD,
  IN_MIX,
  MIX_REVIEW,
  MIX_REVISION,
  MIX_FINISHED,
  STEMS_UPLOADED,
  PUBLISHED,
  CANCELLED,
  REFUNDED,
  IN_ATMOS_MIX = 14,
  PROCESSING_ATMOS_FILES = 15,
  ATMOS_MIX_REVIEW = 16,
  ATMOS_MIX_REVISION = 17,
}

/**
 * Enum for the steps in the project workflow drawer/panel
 */
export enum ProjectWorkflowSteps {
  FILE_TRANSFER,
  IN_MIX,
  MIX_REVIEW,
  MIX_FINISHED,
}

/**
 * Enum for the text that is displayed on the project workflow drawer/panel
 */
export enum ProjectWorkflowStepText {
  BOOKING_REQUESTED = "Booking Requested",
  CANCELLED = "Cancelled",
  FILE_TRANSFER = "File Transfer",
  IN_MIX = "Mix in Progress",
  MIX_REVIEW = "Mix in Review",
  MIX_FINISHED = "Mix Approved",
  IN_MASTER = "Mastering in Progress",
  MASTER_REVIEW = "Master in Review",
  MASTER_FINISHED = "Master Approved",
}

/**
 * Enum for the text that is displayed on the Booking Requested workflow drawer/panel
 */
export enum BookingRequestedStepText {
  FILE_TRANSFER = "File Transfer",
  IN_MIX = "In Progress",
  MIX_REVIEW = "In Review",
  MIX_FINISHED = "Approved",
}

export type ProjectWorkflowStepTextType = Record<
  ProjectWorkflowSteps,
  ProjectWorkflowStepText
>;

export type BookingRequestedStepTextType = Record<
  ProjectWorkflowSteps,
  BookingRequestedStepText
>;

/**
 * Object that maps the project workflow steps to the text that is displayed for a Mixing Project
 */
export const MixingProjectWorkflowStepText: ProjectWorkflowStepTextType = {
  [ProjectWorkflowSteps.FILE_TRANSFER]: ProjectWorkflowStepText.FILE_TRANSFER,
  [ProjectWorkflowSteps.IN_MIX]: ProjectWorkflowStepText.IN_MIX,
  [ProjectWorkflowSteps.MIX_REVIEW]: ProjectWorkflowStepText.MIX_REVIEW,
  [ProjectWorkflowSteps.MIX_FINISHED]: ProjectWorkflowStepText.MIX_FINISHED,
};

/**
 * Mixing Project Task Messages based on the user type and the project workflow step
 */
export const MixingTaskMessage = {
  [ProjectUserType.ARTIST]: {
    [ProjectWorkflowSteps.FILE_TRANSFER]: {
      message: "Share your project files",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.IN_MIX]: {
      message: "Wait for your mix",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.MIX_REVIEW]: {
      message: "Approve mix or request a revision",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.MIX_FINISHED]: {
      message: "Wait for final project files",
      tooltipText: "",
    },
  },
  [ProjectUserType.ENGINEER]: {
    [ProjectWorkflowSteps.FILE_TRANSFER]: {
      message: "Wait for project files from client",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.IN_MIX]: {
      message: "Upload and share your mix",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.MIX_REVIEW]: {
      message: "Wait for client approval",
      tooltipText: "",
    },
    [ProjectWorkflowSteps.MIX_FINISHED]: {
      message: "Create stems and upload song files",
      tooltipText: "",
    },
  },
};

/**
 * Mastering Project Task Messages based on the user type and the project workflow step
 */
export const MasteringTaskMessage = {
  [ProjectUserType.ARTIST]: {
    [ProjectWorkflowSteps.FILE_TRANSFER]: {
      message: "Share your project files",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.IN_MIX]: {
      message: "Wait for your master",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.MIX_REVIEW]: {
      message: "Approve master or request a revision",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.MIX_FINISHED]: {
      message: "Wait for final project files",
      toolTipText: "",
    },
  },
  [ProjectUserType.ENGINEER]: {
    [ProjectWorkflowSteps.FILE_TRANSFER]: {
      message: "Wait for project files from client",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.IN_MIX]: {
      message: "Upload and share your master",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.MIX_REVIEW]: {
      message: "Wait for client approval",
      toolTipText: "",
    },
    [ProjectWorkflowSteps.MIX_FINISHED]: {
      message: "Create stems and upload song files",
      toolTipText: "",
    },
  },
};

/**
 * Object that maps the project workflow steps to the text that is displayed for a Mastering Project
 */
export const MasteringProjectWorkflowStepText: ProjectWorkflowStepTextType = {
  [ProjectWorkflowSteps.FILE_TRANSFER]: ProjectWorkflowStepText.FILE_TRANSFER,
  [ProjectWorkflowSteps.IN_MIX]: ProjectWorkflowStepText.IN_MASTER,
  [ProjectWorkflowSteps.MIX_REVIEW]: ProjectWorkflowStepText.MASTER_REVIEW,
  [ProjectWorkflowSteps.MIX_FINISHED]: ProjectWorkflowStepText.MASTER_FINISHED,
};

/**
 * Object that maps the project workflow steps to the text that is displayed for a Booking Request
 */
export const BookingRequestStepText: BookingRequestedStepTextType = {
  [ProjectWorkflowSteps.FILE_TRANSFER]: BookingRequestedStepText.FILE_TRANSFER,
  [ProjectWorkflowSteps.IN_MIX]: BookingRequestedStepText.IN_MIX,
  [ProjectWorkflowSteps.MIX_REVIEW]: BookingRequestedStepText.MIX_REVIEW,
  [ProjectWorkflowSteps.MIX_FINISHED]: BookingRequestedStepText.MIX_FINISHED,
};

/**
 * Object that maps the Mastering Project Steps to the ProjectWorkflowSteps
 */
export const MasteringWorkflowTranslation: Record<
  MasteringProjectSteps,
  ProjectWorkflowSteps
> = {
  [MasteringProjectSteps.MASTERING_NO_STEP]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MasteringProjectSteps.MASTERING_BOOKING_REQUESTED]:
    ProjectWorkflowSteps.FILE_TRANSFER,
  [MasteringProjectSteps.MASTERING_FILE_UPLOADED]:
    ProjectWorkflowSteps.FILE_TRANSFER,
  [MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW]:
    ProjectWorkflowSteps.IN_MIX,
  [MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD]:
    ProjectWorkflowSteps.FILE_TRANSFER,
  [MasteringProjectSteps.IN_MASTER]: ProjectWorkflowSteps.IN_MIX,
  [MasteringProjectSteps.MASTER_REVIEW]: ProjectWorkflowSteps.MIX_REVIEW,
  [MasteringProjectSteps.MASTER_REVISION]: ProjectWorkflowSteps.IN_MIX,
  [MasteringProjectSteps.MASTER_FINISHED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MasteringProjectSteps.MASTER_PUBLISHED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MasteringProjectSteps.MASTER_CANCELLED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MasteringProjectSteps.MASTER_REFUNDED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MasteringProjectSteps.MASTERING_UPLOAD_ALTS]:
    ProjectWorkflowSteps.MIX_FINISHED,
};

/**
 * Object that maps the Mixing Project Steps to the ProjectWorkflowSteps
 */
export const MixingWorkflowTranslation: Record<
  MixingProjectSteps,
  ProjectWorkflowSteps
> = {
  [MixingProjectSteps.NO_STEP]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MixingProjectSteps.BOOKING_REQUESTED]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MixingProjectSteps.REFERENCE_UPLOADED]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MixingProjectSteps.PROJECT_ACCEPTED]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MixingProjectSteps.ENG_FILE_REVIEW]: ProjectWorkflowSteps.IN_MIX,
  [MixingProjectSteps.FILES_NEED_REUPLOAD]: ProjectWorkflowSteps.FILE_TRANSFER,
  [MixingProjectSteps.IN_MIX]: ProjectWorkflowSteps.IN_MIX,
  [MixingProjectSteps.MIX_REVIEW]: ProjectWorkflowSteps.MIX_REVIEW,
  [MixingProjectSteps.MIX_REVISION]: ProjectWorkflowSteps.IN_MIX,
  [MixingProjectSteps.MIX_FINISHED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MixingProjectSteps.STEMS_UPLOADED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MixingProjectSteps.PUBLISHED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MixingProjectSteps.CANCELLED]: ProjectWorkflowSteps.MIX_FINISHED,
  [MixingProjectSteps.REFUNDED]: ProjectWorkflowSteps.MIX_FINISHED,
};

export enum ScheduledProjectPaywallTypes {
  FULL_PAYMENT_UPFRONT_REQUIRED,
  TRACK_PREVIEW_NOT_ALLOWED,
  TRACK_PREVIEW_ALLOWED,
  REVIEWS_AND_REVISIONS_ALLOWED,
}

export const ScheduledProjectPaywallOptions: OptionType[] = [
  {
    value: ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
    label: "Upfront payment required",
  },
  {
    value: ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED,
    label: "Mix/master playback not allowed without payment",
  },
  {
    value: ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED,
    label: "Mix/master playback allowed before payment",
  },
  {
    value: ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED,
    label: "Reviews and revisions allowed before payment",
  },
];

export const paywallTooltipDescription = new Map<
  ScheduledProjectPaywallTypes,
  string
>([
  [
    ScheduledProjectPaywallTypes.FULL_PAYMENT_UPFRONT_REQUIRED,
    "beginning the project",
  ],
  [
    ScheduledProjectPaywallTypes.TRACK_PREVIEW_NOT_ALLOWED,
    "previewing completed tracks",
  ],
  [
    ScheduledProjectPaywallTypes.TRACK_PREVIEW_ALLOWED,
    "reviewing and requesting revisions on tracks",
  ],
  [
    ScheduledProjectPaywallTypes.REVIEWS_AND_REVISIONS_ALLOWED,
    "downloading final file versions",
  ],
]);

export const ProjectTypeOptions: OptionType[] = [
  {
    value: ProjectType.RECORDING,
    label: "Recording",
  },
  {
    value: ProjectType.MIXING,
    label: "Mixing (Full Stems)",
  },
  {
    value: ProjectType.TWO_TRACK_MIXING,
    label: "2 Track Mixing",
  },
  { value: ProjectType.MASTERING, label: "Mastering" },
  { value: ProjectType.ATMOS_MIXING, label: "Dolby ATMOS" },
];

export function getProjectTypeOptionFromServiceType(serviceType: ProjectType) {
  return ProjectTypeOptions.find((value) => {
    return value.value === serviceType;
  });
}

export const projectTypeReadableName = new Map<ProjectType, string>([
  [ProjectType.RECORDING, "Recording"],
  [ProjectType.MIXING, "Full Stems Mixing"],
  [ProjectType.MASTERING, "Mastering"],
  [ProjectType.TWO_TRACK_MIXING, "Two Track Mixing"],
  [ProjectType.ATMOS_MIXING, "Dolby ATMOS"],
]);

export const tableServiceTypeReadableName = new Map<ProjectType, string>([
  [ProjectType.RECORDING, "Recording"],
  [ProjectType.MIXING, "Mixing"],
  [ProjectType.MASTERING, "Mastering"],
  [ProjectType.TWO_TRACK_MIXING, "Two Track Mixing"],
  [ProjectType.ATMOS_MIXING, "Dolby ATMOS"],
]);

export const projectTypeReadableFileName = new Map<ProjectType, string>([
  [ProjectType.RECORDING, "session"],
  [ProjectType.MIXING, "mix"],
  [ProjectType.MASTERING, "master"],
  [ProjectType.TWO_TRACK_MIXING, "mix"],
  [ProjectType.ATMOS_MIXING, "Dolby ATMOS mix"],
]);

export const projectTypeToEventStringMap = new Map<ProjectType, string>([
  [ProjectType.RECORDING, "recording"],
  [ProjectType.MIXING, "full_stems_mixing"],
  [ProjectType.MASTERING, "mastering"],
  [ProjectType.TWO_TRACK_MIXING, "two_track_mixing"],
  [ProjectType.ATMOS_MIXING, "dolby_atmos_mixing"],
]);

export const NUM_MASTERING_STEPS = 6.0;
export const masteringMap = new Map<MasteringProjectSteps, number>([
  [MasteringProjectSteps.MASTERING_BOOKING_REQUESTED, 1],
  [MasteringProjectSteps.MASTERING_FILE_UPLOADED, 2],
  [MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD, 1],
  [MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW, 2],
  [MasteringProjectSteps.IN_MASTER, 3],
  [MasteringProjectSteps.MASTER_REVIEW, 4],
  [MasteringProjectSteps.MASTER_REVISION, 3],
  [MasteringProjectSteps.MASTER_FINISHED, 6],
  [MasteringProjectSteps.MASTERING_UPLOAD_ALTS, 5],
]);
export const NUM_MIXING_STEPS = 8.0;
export const mixingMap = new Map<MixingProjectSteps, number>([
  [MixingProjectSteps.BOOKING_REQUESTED, 1],
  [MixingProjectSteps.REFERENCE_UPLOADED, 2],
  [MixingProjectSteps.PROJECT_ACCEPTED, 3],
  [MixingProjectSteps.ENG_FILE_REVIEW, 4],
  [MixingProjectSteps.FILES_NEED_REUPLOAD, 3],
  [MixingProjectSteps.IN_MIX, 5],
  [MixingProjectSteps.MIX_REVIEW, 6],
  [MixingProjectSteps.MIX_REVISION, 5],
  [MixingProjectSteps.MIX_FINISHED, 7],
  [MixingProjectSteps.STEMS_UPLOADED, 8],
]);

export const NUM_ATMOS_STEPS = 9.0;
export const atmosMap = new Map<AtmosProjectSteps, number>([
  [AtmosProjectSteps.BOOKING_REQUESTED, 1],
  [AtmosProjectSteps.REFERENCE_UPLOADED, 2],
  [AtmosProjectSteps.PROJECT_ACCEPTED, 3],
  [AtmosProjectSteps.ENG_FILE_REVIEW, 4],
  [AtmosProjectSteps.FILES_NEED_REUPLOAD, 3],
  [AtmosProjectSteps.IN_MIX, 5],
  [AtmosProjectSteps.MIX_REVIEW, 7],
  [AtmosProjectSteps.MIX_REVISION, 5],
  [AtmosProjectSteps.IN_ATMOS_MIX, 5],
  [AtmosProjectSteps.ATMOS_MIX_REVISION, 5],
  [AtmosProjectSteps.PROCESSING_ATMOS_FILES, 6],
  [AtmosProjectSteps.ATMOS_MIX_REVIEW, 7],
  [AtmosProjectSteps.MIX_FINISHED, 8],
  [AtmosProjectSteps.STEMS_UPLOADED, 9],
]);

const mixingStepTimeInMs = new Map<MixingProjectSteps, number>([
  [MixingProjectSteps.NO_STEP, NO_TIME_LIMIT],
  [MixingProjectSteps.BOOKING_REQUESTED, -FIVE_DAYS_MS],
  [MixingProjectSteps.REFERENCE_UPLOADED, -FOUR_DAYS_MS],
  [MixingProjectSteps.PROJECT_ACCEPTED, -THREE_DAYS_MS],
  [MixingProjectSteps.ENG_FILE_REVIEW, -TWO_DAYS_MS],
  [MixingProjectSteps.FILES_NEED_REUPLOAD, -TWO_DAYS_MS],
  [MixingProjectSteps.IN_MIX, TWO_DAYS_MS],
  [MixingProjectSteps.MIX_REVIEW, THREE_DAYS_MS],
  [MixingProjectSteps.MIX_REVISION, TWO_DAYS_MS],
  [MixingProjectSteps.MIX_FINISHED, TWENTY_FOUR_HOURS_MS],
  [MixingProjectSteps.STEMS_UPLOADED, NO_TIME_LIMIT],
]);

const dolbyMixingStepTimeInMS = new Map<AtmosProjectSteps, number>([
  [AtmosProjectSteps.NO_STEP, NO_TIME_LIMIT],
  [AtmosProjectSteps.BOOKING_REQUESTED, -FIVE_DAYS_MS],
  [AtmosProjectSteps.REFERENCE_UPLOADED, -FOUR_DAYS_MS],
  [AtmosProjectSteps.PROJECT_ACCEPTED, -THREE_DAYS_MS],
  [AtmosProjectSteps.ENG_FILE_REVIEW, -TWO_DAYS_MS],
  [AtmosProjectSteps.FILES_NEED_REUPLOAD, -TWO_DAYS_MS],
  [AtmosProjectSteps.IN_MIX, TWO_DAYS_MS],
  [AtmosProjectSteps.MIX_REVIEW, THREE_DAYS_MS],
  [AtmosProjectSteps.MIX_REVISION, TWO_DAYS_MS],
  [AtmosProjectSteps.IN_ATMOS_MIX, TWO_DAYS_MS],
  [AtmosProjectSteps.ATMOS_MIX_REVISION, TWO_DAYS_MS],
  [AtmosProjectSteps.PROCESSING_ATMOS_FILES, NO_TIME_LIMIT],
  [AtmosProjectSteps.ATMOS_MIX_REVIEW, THREE_DAYS_MS],
  [AtmosProjectSteps.MIX_FINISHED, TWENTY_FOUR_HOURS_MS],
  [AtmosProjectSteps.STEMS_UPLOADED, NO_TIME_LIMIT],
]);

const masteringStepTimeInMs = new Map<MasteringProjectSteps, number>([
  [MasteringProjectSteps.MASTERING_NO_STEP, NO_TIME_LIMIT],
  [MasteringProjectSteps.MASTERING_BOOKING_REQUESTED, -THREE_DAYS_MS],
  [MasteringProjectSteps.MASTERING_FILE_UPLOADED, -THREE_DAYS_MS],
  [MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW, -TWO_DAYS_MS],
  [MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD, -TWO_DAYS_MS],
  [MasteringProjectSteps.IN_MASTER, TWENTY_FOUR_HOURS_MS],
  [MasteringProjectSteps.MASTER_REVIEW, THREE_DAYS_MS],
  [MasteringProjectSteps.MASTER_REVISION, TWO_DAYS_MS],
  [MasteringProjectSteps.MASTER_FINISHED, NO_TIME_LIMIT],
  [MasteringProjectSteps.MASTERING_UPLOAD_ALTS, TWENTY_FOUR_HOURS_MS],
]);

export interface BaseProject {
  alts?: null | AltItem[];
  artist_name?: string;
  assets_approved: null | string;
  checkout_price: number;
  completed: null | string;
  created: string;
  deleted: null | string;
  extra_revision_price: number;
  final_asset_file_notes: null | string;
  first_pass_date: null | string;
  has_pending_purchase_order: boolean;
  hosted_invoice_url: null | string;
  id: number;
  invoice_status: null | InvoiceStatus;
  last_transition: string | null;
  order_index?: number;
  prereq_project_id?: number;
  prereq_project_is_completed: boolean;
  purchase_order_budget_approved: boolean;
  refunded: null | string;
  revision_counter: number;
  revisions_available: number;
  service_fee: number;
  service_type: ProjectType;
  stripe_session_id: string | null;
  title: string;
  total_price: string;
  users: User[];
}

export interface Project extends BaseProject {
  artist?: User;
  artist_file_link: null | string;
  calculatedDueDate: string | undefined;
  closed?: null | string;
  engineer?: User;
  engineer_has_files: null | string;
  engineer_reference_notes?: null | string;
  engineer_file_notes: null | string;
  file_storage_location?: number;
  id: number;
  is_project_handoff?: boolean;
  step: number;
  // Only one of the following will be filled in for any given project.
  mastering_project?: MasteringProject;
  mixing_project?: MixingProject;
  recording_session?: RecordingSession;
}

export interface ProjectHistory {
  id: number;
  action: string;
  created: string;
  file_version: FileVersion | null;
  mastering_step: number;
  mixing_step: number;
  project_id: number;
  recording_step: number;
  service_type: ProjectType;
  user: UserLite;
}

export interface PaginatedProjectHistory {
  data: ProjectHistory[];
  page: number;
  num_pages: number;
  count: number;
}

/**
 * This interface is based on the response from the API for GET_PROJECT (api/projects/projectById)
 */
export interface ProjectById {
  alts?: null | AltItem[];
  artist_name?: string;
  assets_approved: null | string;
  completed: null | string;
  created: string;
  deleted: null | string;
  extra_revision_price: number;
  file_storage_location: number | null;
  final_asset_file_notes: null | string;
  first_pass_date: null | string;
  id: number;
  last_transition: string | null;
  order_index?: number;
  prereq_project_id?: number;
  prereq_project_is_completed: boolean;
  refunded: null | string;
  revision_counter: number;
  revisions_available: number;
  service_fee: number;
  service_type: ProjectType;
  title: string;
  total_price: string;
  users: UserLite[];
  // Only one of the following will be filled in for any given project.
  mastering_project?: APIMasteringProjectBase;
  mixing_project?: APIMixingProjectBase;
  engineer?: User;
  scheduled_project_id?: number;
}

export interface APIMixingProjectBase {
  id: number;
  artist_mix_notes: null | string;
  artist_mix_revision_notes: null | string;
  engineer_mix_notes: null | string;
  engineer_has_reference: null | string;
  enable_artist_review_mp3_file_download: boolean;
  enable_artist_review_wav_file_download: boolean;
  closed?: string | null;
  step: number;
  engineer_reference_notes?: string | null;
  engineer_file_notes: string | null;
  engineer_has_files: string | null;
  artist_file_link: string | null;
  engineer_id: number;
  artist_id: number;
}

export interface APIMixingProject extends APIMixingProjectBase {
  project: Project;
}

export interface MixingProject {
  id: number;
  artist_mix_notes: null | string;
  artist_mix_revision_notes: null | string;
  engineer_mix_notes: null | string;
  engineer_has_reference: null | string;
  project?: Project;
  enable_artist_review_mp3_file_download?: boolean;
  enable_artist_review_wav_file_download?: boolean;
}

export interface APIMasteringProjectBase {
  id: number;
  artist_master_notes: null | string;
  artist_master_revision_notes: null | string;
  engineer_master_notes: string;
  enable_artist_review_mp3_file_download: boolean;
  enable_artist_review_wav_file_download: boolean;
  closed?: string | null;
  step: number;
  engineer_reference_notes?: string | null;
  engineer_file_notes: string | null;
  engineer_has_files: string | null;
  artist_file_link: string | null;
  engineer_id: number;
  artist_id: number;
}

export interface APIMasteringProject extends APIMasteringProjectBase {
  project: Project;
}

export interface MasteringProject {
  id: number;
  artist_master_notes: null | string;
  artist_master_revision_notes: null | string;
  engineer_master_notes: string;
  project?: Project;
  enable_artist_review_mp3_file_download?: boolean;
  enable_artist_review_wav_file_download?: boolean;
}

export interface APIProjectUploadLink {
  id: number;
  created: string;
  deleted?: string | null;
  used?: string | null;
  code: string;
  mixing_project?: APIMixingProject;
  mastering_project?: APIMasteringProject;
}

export interface APIProjectListenLink {
  id: number;
  created: string;
  deleted?: string | null;
  used?: string | null;
  code: string;
  mixing_project?: APIMixingProject;
  mastering_project?: APIMasteringProject;
}

export interface APIRecordingSessionShareLink {
  id: number;
  created: string;
  deleted?: string | null;
  used?: string | null;
  code: string;
  recording_session?: RecordingSession;
}

export interface LabelAssociation {
  major_label: MajorLabelEnum;
  umg_sub_label: UMGSubLabelEnum;
}

export interface PurchaseOrder {
  id: number;
  required_approvals: ProjectAcceptance[];
  order_number: string;
  cost_center: string;
  work_breakdown_structure: string;
  general_ledger: string;
  admin_email: string | null;
  secondary_admin_email: string | null;
  purchase_order_label: LabelAssociation[];
  final_approval_submitted: boolean;
  received_payment: string | null;
  collaborators_list: string[];
  collaborators_users: User[];
}

export interface BudgetManager {
  id: number;
  email: string;
  can_approve_budget: boolean;
  can_submit_billing_info: boolean;
  created_at: string;
  updated_at: string;
  deleted: string | null;
  approval_required: boolean;
  budget_approved: string | null;
  budget_rejected: string | null;
  code: string;
  rejection_reason: string | null;
  marked_final_approval: null | string;
  user: User | null;
}

export interface DetailedPurchaseOrder extends PurchaseOrder {
  budget_managers: BudgetManager[];
  budget_approved: boolean;
  budget_rejected: boolean;
  purchase_order_status:
    | TransactionStatus.PURCHASE_ORDER_REQUIRED
    | TransactionStatus.PURCHASE_ORDER_SUBMITTED;
  all_projects_approved: boolean;
  final_approval_submitted: boolean;
}

export interface DetailedPurchaseOrderWithTransaction
  extends DetailedPurchaseOrder {
  purchase_order_transaction: Transaction[];
  scheduled_project_id: number;
  budget_manager_for_link: BudgetManager | null;
}

export const MockRecordingProject: Project = {
  prereq_project_is_completed: true,
  checkout_price: 400000,
  has_pending_purchase_order: false,
  purchase_order_budget_approved: false,
  extra_revision_price: 0,
  refunded: null,
  revision_counter: 0,
  revisions_available: 0,
  deleted: null,
  last_transition: null,
  engineer_reference_notes: null,
  service_type: ProjectType.RECORDING,
  stripe_session_id: null,
  title: "Mock Session",
  total_price: "400.00",
  first_pass_date: addDays(new Date(), 2).toISOString(),
  closed: null,
  created: subDays(new Date(), 2).toISOString(),
  engineer_file_notes: null,
  engineer_has_files: null,
  artist_file_link: null,
  id: 1,
  step: 2,
  engineer: MockUser,
  artist: MockUser,
  completed: null,
  assets_approved: null,
  final_asset_file_notes: null,
  alts: [],
  service_fee: 0.15,
  calculatedDueDate: format(new Date(), "MM/dd/yy"),
  users: [MockUser, { ...MockUser, id: 2 }],
  hosted_invoice_url: null,
  invoice_status: null,
};

export const transformRawData = (
  data: APIMixingProject | APIMasteringProject,
): Project => {
  const dueDate = calculateDueDate(data.step, data.project);
  return {
    order_index: data.project.order_index ?? data.project.id,
    prereq_project_is_completed: data.project.prereq_project_is_completed,
    checkout_price: data.project.checkout_price,
    completed: data.project.completed,
    assets_approved: data.project.assets_approved,
    final_asset_file_notes: data.project.final_asset_file_notes,
    closed: data.closed,
    engineer_reference_notes: data.engineer_reference_notes,
    title: data.project.title,
    total_price: data.project.total_price,
    deleted: data.project.deleted,
    service_type: data.project.service_type,
    stripe_session_id: data.project.stripe_session_id,
    created: data.project.created,
    engineer_file_notes: data.engineer_file_notes,
    engineer_has_files: data.engineer_has_files,
    first_pass_date: data.project.first_pass_date,
    file_storage_location: data.project.file_storage_location,
    last_transition: data.project.last_transition,
    prereq_project_id: data.project.prereq_project_id,
    artist_file_link: data.artist_file_link,
    id: data.project.id,
    step: data.step,
    engineer: extractUserTypeFromList(
      data.engineer_id,
      DISCIPLINE_TYPE.ENGINEER,
      data.project.users,
    ),
    service_fee: 0.15,
    users: data.project.users,
    artist: extractUserTypeFromList(
      data.artist_id,
      DISCIPLINE_TYPE.ARTIST,
      data.project.users,
    ),
    calculatedDueDate: dueDate ? dueDate.toISOString() : undefined,
    alts: data.project.alts ?? [],
    mixing_project: getMixingProjectIfExists(data),
    mastering_project: getMasteringProjectIfExists(data),
    refunded: data.project.refunded,
    revisions_available: data.project.revisions_available,
    revision_counter: +data.project.revision_counter,
    extra_revision_price: +data.project.extra_revision_price,
    has_pending_purchase_order: data.project.has_pending_purchase_order,
    purchase_order_budget_approved: data.project.purchase_order_budget_approved,
    hosted_invoice_url: data.project.hosted_invoice_url,
    invoice_status: data.project.invoice_status,
  };
};

export const getMixingProjectIfExists = (
  data: APIMixingProject | APIMasteringProject,
): MixingProject | undefined => {
  if (data.project.service_type !== ProjectType.MASTERING) {
    const mixingData = data as APIMixingProject;
    return {
      id: mixingData.id,
      engineer_mix_notes: mixingData.engineer_mix_notes,
      artist_mix_revision_notes: mixingData.artist_mix_revision_notes,
      engineer_has_reference: mixingData.engineer_has_reference,
      enable_artist_review_mp3_file_download:
        data.enable_artist_review_mp3_file_download,
      enable_artist_review_wav_file_download:
        data.enable_artist_review_wav_file_download,
      artist_mix_notes: mixingData.artist_mix_notes,
    };
  }
  return undefined;
};

export const getMasteringProjectIfExists = (
  data: APIMixingProject | APIMasteringProject,
): MasteringProject | undefined => {
  if (data.project.service_type === ProjectType.MASTERING) {
    const masteringData = data as APIMasteringProject;
    return {
      id: masteringData.id,
      artist_master_notes: masteringData.artist_master_notes,
      artist_master_revision_notes: masteringData.artist_master_revision_notes,
      engineer_master_notes: masteringData.engineer_master_notes,
      enable_artist_review_mp3_file_download:
        masteringData.enable_artist_review_mp3_file_download,
      enable_artist_review_wav_file_download:
        masteringData.enable_artist_review_wav_file_download,
    };
  }
  return undefined;
};

export const getStep = (
  step: number,
  projectType: ProjectType,
  isLabelProject?: boolean,
): number | undefined => {
  if (projectType === ProjectType.MASTERING) {
    return masteringMap.get(step as MasteringProjectSteps);
  }
  if (projectType === ProjectType.ATMOS_MIXING) {
    const atmosMixStep = atmosMap.get(step as AtmosProjectSteps);
    if (!atmosMixStep) return 0;
    if (isLabelProject) {
      return atmosMixStep - 2;
    }
    return atmosMixStep;
  }
  const mixStep = mixingMap.get(step as MixingProjectSteps);
  if (isLabelProject && mixStep) {
    return mixStep - 2;
  }
  return mixStep;
};

export const mixingStepDescriptions = [
  "",
  "File Transfer",
  "Reference Acceptance",
  "File Upload",
  "File Review",
  "In Mix",
  "Mix Review",
  "Final Upload",
  "Complete",
];

export const atmosAandRMixingDescriptions = [
  "",
  "File Transfer",
  "Reference Acceptance",
  "File Upload",
  "File Review",
  "In Mix",
  "Processing Files",
  "Mix Review",
  "Final Upload",
  "Complete",
];

export const mixingStepActions = [
  "",
  "Upload Files",
  "Review Song",
  "Upload Files",
  "Review Files",
  "Upload Mix",
  "Review Mix",
  "Upload Files",
  "",
];

export const masteringStepDescriptions = [
  "",
  "File Upload",
  "File Review",
  "In Master",
  "In Review",
  "Final Upload",
  "Complete",
];

export const masteringStepActions = [
  "",
  "Upload Files",
  "Review Files",
  "Upload Master",
  "Review Master",
  "Upload Files",
  "View Project",
];

export const engineerSetupSteps = [
  "",
  "Set up Stripe Connect",
  "Choose a Membership",
  "Add Services to your Profile",
];

export const aandRSetupSteps = [
  "Add your Admin Email",
  "Add your Number",
  "Walkthrough Paying with a Purchase Order on our Platform",
];

export const recordingSteps = ["Session Booked", "Session Complete"];

export const getStepDescription = (
  step: number,
  projectType: ProjectType,
): string | undefined => {
  if (projectType === ProjectType.MASTERING) {
    return masteringStepDescriptions[
      masteringMap.get(step as MasteringProjectSteps)!
    ];
  } else if (
    projectType === ProjectType.TWO_TRACK_MIXING ||
    projectType === ProjectType.MIXING ||
    projectType === ProjectType.ATMOS_MIXING
  ) {
    return mixingStepDescriptions[mixingMap.get(step as MixingProjectSteps)!];
  }
  return "";
};

export const getStepActionLabel = (
  step: number,
  projectType: ProjectType,
): string | undefined => {
  if (projectType === ProjectType.MASTERING) {
    return masteringStepActions[
      masteringMap.get(step as MasteringProjectSteps)!
    ];
  } else if (
    projectType === ProjectType.TWO_TRACK_MIXING ||
    projectType === ProjectType.MIXING ||
    projectType === ProjectType.ATMOS_MIXING
  ) {
    return mixingStepActions[mixingMap.get(step as MixingProjectSteps)!];
  }
  return "view recording session";
};

const calculateDueDate = (
  step: number,
  project?: Project,
): Date | undefined => {
  let timeInMs: number | undefined = NO_TIME_LIMIT;
  // First determine if we're past the initial first_pass_date or before it.
  if (project?.service_type === ProjectType.MASTERING) {
    timeInMs = masteringStepTimeInMs.get(step);
  } else if (
    project?.service_type === ProjectType.MIXING ||
    project?.service_type === ProjectType.TWO_TRACK_MIXING
  ) {
    timeInMs = mixingStepTimeInMs.get(step);
  } else if (project?.service_type === ProjectType.ATMOS_MIXING) {
    timeInMs = dolbyMixingStepTimeInMS.get(step);
  }
  if (timeInMs === undefined || timeInMs === NO_TIME_LIMIT) {
    return undefined;
  } else if (timeInMs < 0) {
    // We haven't yet reached the step for the scheduled date has yet to occur.
    const firstPassDate = project?.first_pass_date;
    let scheduledDate = new Date();
    if (firstPassDate) {
      scheduledDate = new Date(firstPassDate);
    }
    return addMilliseconds(scheduledDate, timeInMs);
  }

  // We've past the scheduled date step.
  const lastTransition = project?.last_transition;
  let lastTransitionDate = new Date();
  if (lastTransition) {
    lastTransitionDate = new Date(lastTransition);
  }

  const firstPassDate = project?.first_pass_date;
  let scheduledDate = new Date();
  if (firstPassDate) {
    scheduledDate = new Date(firstPassDate);
  }

  let dateToUse = scheduledDate;
  // If the last transition is after the scheduled date, then we use the last transition to calculate due date.
  if (isBefore(scheduledDate, lastTransitionDate)) {
    dateToUse = lastTransitionDate;
  }

  return addMilliseconds(dateToUse, timeInMs);
};

export const extractUserTypeFromList = (
  id: number,
  type: DISCIPLINE_TYPE,
  userArray?: User[],
): User | undefined => {
  if (!userArray) {
    return undefined;
  }
  switch (type) {
    case DISCIPLINE_TYPE.ARTIST:
      return userArray.find((user) => user.artist && user.artist.id === id);
    case DISCIPLINE_TYPE.ENGINEER:
      return userArray.find((user) => user.engineer && user.engineer.id === id);
    default:
      return undefined;
  }
};

export const ENGINEER_MASTER_ACTION_STEPS = [
  MasteringProjectSteps.MASTERING_FILE_UPLOADED,
  MasteringProjectSteps.MASTERING_ENG_FILE_REVIEW,
  MasteringProjectSteps.IN_MASTER,
  MasteringProjectSteps.MASTER_REVISION,
  MasteringProjectSteps.MASTERING_UPLOAD_ALTS,
];

export const ARTIST_MASTER_ACTION_STEPS = [
  MasteringProjectSteps.MASTERING_BOOKING_REQUESTED,
  MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD,
  MasteringProjectSteps.MASTER_REVIEW,
  MasteringProjectSteps.MASTER_FINISHED,
];

export const ENGINEER_MIXING_ACTION_STEPS = [
  MixingProjectSteps.REFERENCE_UPLOADED,
  MixingProjectSteps.ENG_FILE_REVIEW,
  MixingProjectSteps.MIX_REVISION,
  MixingProjectSteps.IN_MIX,
  MixingProjectSteps.MIX_FINISHED,
];

export const ARTIST_MIXING_ACTION_STEPS = [
  MixingProjectSteps.BOOKING_REQUESTED,
  MixingProjectSteps.PROJECT_ACCEPTED,
  MixingProjectSteps.FILES_NEED_REUPLOAD,
  MixingProjectSteps.MIX_REVIEW,
];
export const UPLOAD_MIXING_STEPS = [
  MixingProjectSteps.BOOKING_REQUESTED,
  MixingProjectSteps.PROJECT_ACCEPTED,
  MixingProjectSteps.FILES_NEED_REUPLOAD,
  MixingProjectSteps.IN_MIX,
  MixingProjectSteps.MIX_REVISION,
  MixingProjectSteps.MIX_FINISHED,
];

export const ATMOS_UPLOAD_STEP = [
  AtmosProjectSteps.BOOKING_REQUESTED,
  AtmosProjectSteps.PROJECT_ACCEPTED,
  AtmosProjectSteps.FILES_NEED_REUPLOAD,
  AtmosProjectSteps.IN_MIX,
  AtmosProjectSteps.MIX_REVISION,
  AtmosProjectSteps.ATMOS_MIX_REVISION,
  AtmosProjectSteps.IN_ATMOS_MIX,
  AtmosProjectSteps.ATMOS_MIX_REVISION,
  AtmosProjectSteps.MIX_FINISHED,
];
export const UPLOAD_MASTER_STEPS = [
  MasteringProjectSteps.MASTERING_BOOKING_REQUESTED,
  MasteringProjectSteps.MASTER_REVISION,
  MasteringProjectSteps.MASTERING_FILE_NEEDS_REUPLOAD,
  MasteringProjectSteps.IN_MASTER,
  MasteringProjectSteps.MASTERING_UPLOAD_ALTS,
];

export const STANDARD_MIXING_STEPS = [
  MixingProjectSteps.BOOKING_REQUESTED,
  MixingProjectSteps.REFERENCE_UPLOADED,
  MixingProjectSteps.PROJECT_ACCEPTED,
  MixingProjectSteps.FILES_NEED_REUPLOAD,
  MixingProjectSteps.ENG_FILE_REVIEW,
  MixingProjectSteps.IN_MIX,
  MixingProjectSteps.MIX_REVIEW,
  MixingProjectSteps.MIX_FINISHED,
  MixingProjectSteps.STEMS_UPLOADED,
];

export enum MasteringTransitions {
  ARTIST_UPLOAD_FILE = "artist_upload_file",
  ARTIST_SKIP_UPLOAD_FILES = "artist_skip_upload_files",
  ARTIST_ACCEPTING_MASTER = "accept_master",
  ARTIST_REJECTING_MASTER = "reject_master",
  ENGINEER_REFUND_PROJECT = "refund_eng",
  ENGINEER_REJECT_FILES = "reject_files",
  ENGINEER_ACCEPT_FILES = "accept_files",
  ENGINEER_ACCEPT_PROJECT = "eng_accept_project",
  ENGINEER_ACCEPT_AND_UPLOAD_FILES = "eng_upload_files_and_approve_project",
  ENGINEER_UPLOAD_INITIAL_MAIN = "upload_initial_main", // Transitions from File Transfer to Master in Progress
  ENGINEER_UPLOAD_MAIN = "finish_master",
  ENGINEER_UPLOAD_PROJECT_FILES = "engineer_upload_project_files", // Transitions from File Transfer to In Progress
  TOGGLE_PROJECT_FILES = "toggle_project_files", // Transitions from In Progress/In Review to File Transfer
  ENG_UPLOADING_ALTS_TRANSITION = "upload_alts",
  ENGINEER_SKIP_UPLOAD_FILES = "engineer_skip_upload_files",
  ADD_ARTIST_MASTER_NOTES = "add_master_notes",
  REQUEST_ASSET_REUPLOAD = "request_asset_reupload",
}

export enum MixingTransitions {
  ARTIST_UPLOAD_REFERENCE = "artist_upload_reference",
  ARTIST_SKIP_REFERENCE_UPLOAD = "artist_skip_upload_reference",
  ENGINEER_REJECT_REFERENCE = "engineer_reject_reference",
  ENGINEER_UPLOAD_REFERENCE = "engineer_upload_reference",
  GO_BACK_TO_REF_UPLOAD = "go_back_to_reference_upload",
  ENGINEER_ACCEPT_PROJECT = "eng_accept_project",
  ENGINEER_REJECT_FILES = "reject_files",
  ENGINEER_APPROVE_FILES = "accept_files",
  ARTIST_UPLOAD_FILES = "artist_upload_files",
  ARTIST_SKIP_FILE_UPLOADS = "artist_skip_upload_files",
  ARTIST_ACCEPT_MIX = "accept_mix",
  ARTIST_REJECT_MIX = "reject_mix",
  ENGINEER_UPLOAD_INITIAL_MAIN = "upload_initial_main", // Transitions from File Transfer to Mix in Progress
  ENGINEER_UPLOAD_MIX = "finish_mix",
  ENGINEER_UPLOAD_PROJECT_FILES = "engineer_upload_project_files", // Transitions from File Transfer to In Progress
  TOGGLE_PROJECT_FILES = "toggle_project_files", // Transitions from In Progress/In Review to File Transfer
  ENGINEER_UPLOAD_STEMS = "upload_stems",
  ADD_MIX_NOTES = "add_mix_notes",
  ENGINEER_SKIP_UPLOAD_REFERENCE = "engineer_skip_upload_reference",
  ENGINEER_SKIP_UPLOAD_FILES = "engineer_skip_upload_files",
  REQUEST_ASSET_REUPLOAD = "request_asset_reupload",
}

export const contextualTransitionMessageMixing = new Map<
  MixingTransitions,
  string
>([
  [MixingTransitions.ARTIST_UPLOAD_REFERENCE, "Sending your song for review"],
  [
    MixingTransitions.ARTIST_SKIP_REFERENCE_UPLOAD,
    "Requesting reference (demo) upload from engineer",
  ],
  [
    MixingTransitions.ENGINEER_REJECT_REFERENCE,
    "Requesting reference (demo) re-upload from your artist",
  ],
  [MixingTransitions.ENGINEER_UPLOAD_REFERENCE, "Uploading reference"],
  [
    MixingTransitions.ENGINEER_ACCEPT_PROJECT,
    "Accepting song reference (demo), requesting project upload",
  ],
  [MixingTransitions.ENGINEER_REJECT_FILES, "Requesting file re-upload"],
  [
    MixingTransitions.ENGINEER_APPROVE_FILES,
    "Approving submitted files, mix is ready to begin",
  ],
  [MixingTransitions.ARTIST_UPLOAD_FILES, "Sending files to engineer"],
  [
    MixingTransitions.ARTIST_SKIP_FILE_UPLOADS,
    "Requesting file upload from engineer",
  ],
  [
    MixingTransitions.ARTIST_ACCEPT_MIX,
    "Accepting mix, requesting final stems from engineer",
  ],
  [MixingTransitions.ARTIST_REJECT_MIX, "Requesting mix revision"],
  [
    MixingTransitions.ENGINEER_UPLOAD_MIX,
    "Sending finished mix to artist for review",
  ],
  [
    MixingTransitions.ENGINEER_UPLOAD_STEMS,
    "Sending final project files to artist",
  ],
  [MixingTransitions.REQUEST_ASSET_REUPLOAD, "Requesting file re-upload"],
]);

export const contextualTransitionMessageMastering = new Map<
  MasteringTransitions,
  string
>([
  [
    MasteringTransitions.ARTIST_UPLOAD_FILE,
    "Sending files to engineer for review",
  ],
  [
    MasteringTransitions.ARTIST_SKIP_UPLOAD_FILES,
    "Requesting file uploads from your engineer",
  ],
  [MasteringTransitions.ARTIST_ACCEPTING_MASTER, "Accepting mastered track"],
  [
    MasteringTransitions.ARTIST_REJECTING_MASTER,
    "Requesting revision from engineer",
  ],
  [MasteringTransitions.ENGINEER_REJECT_FILES, "Requesting file re-upload"],
  [
    MasteringTransitions.ENGINEER_ACCEPT_PROJECT,
    "Accepting project files, master ready to begin",
  ],
  [
    MasteringTransitions.ENGINEER_ACCEPT_AND_UPLOAD_FILES,
    "Uploading project files, master ready to begin.",
  ],
  [
    MasteringTransitions.ENGINEER_UPLOAD_MAIN,
    "Sending master to artist for review",
  ],
  [
    MasteringTransitions.ENG_UPLOADING_ALTS_TRANSITION,
    "Sending alts to artist",
  ],
  [MasteringTransitions.REQUEST_ASSET_REUPLOAD, "Requesting file re-upload"],
]);

export interface PaidRevision {
  created: string;
  id: number;
  project: Project;
  service_fee: number;
}

export const MockBudgetManager1: BudgetManager = {
  id: 1,
  email: "test+1@engineears.org",
  can_approve_budget: true,
  can_submit_billing_info: true,
  created_at: new Date().toISOString(),
  updated_at: "",
  deleted: "",
  approval_required: true,
  budget_approved: "",
  budget_rejected: "",
  code: "",
  rejection_reason: "",
  marked_final_approval: "",
  user: null,
};

export const MockBudgetManager2: BudgetManager = {
  id: 1,
  email: "test+2@engineears.org",
  can_approve_budget: true,
  can_submit_billing_info: true,
  created_at: new Date().toISOString(),
  updated_at: "",
  deleted: "",
  approval_required: true,
  budget_approved: new Date().toISOString(),
  budget_rejected: "",
  code: "",
  rejection_reason: "",
  marked_final_approval: "",
  user: null,
};

export const MockBudgetManager3: BudgetManager = {
  id: 1,
  email: "test+3@engineears.org",
  can_approve_budget: true,
  can_submit_billing_info: true,
  created_at: new Date().toISOString(),
  updated_at: "",
  deleted: "",
  approval_required: true,
  budget_approved: "",
  budget_rejected: new Date().toISOString(),
  code: "",
  rejection_reason: "",
  marked_final_approval: "",
  user: MockUser1,
};

export const MockBudgetManager4: BudgetManager = {
  id: 1,
  email: "test+4@engineears.org",
  can_approve_budget: false,
  can_submit_billing_info: true,
  created_at: new Date().toISOString(),
  updated_at: "",
  deleted: "",
  approval_required: true,
  budget_approved: "",
  budget_rejected: "",
  code: "",
  rejection_reason: "",
  marked_final_approval: "",
  user: MockUser2,
};

export const MockPurchaseOrder1: PurchaseOrder = {
  id: 1,
  required_approvals: [],
  order_number: "123456",
  cost_center: "123456",
  work_breakdown_structure: "123456",
  general_ledger: "123456",
  admin_email: "",
  secondary_admin_email: null,
  purchase_order_label: [],
  final_approval_submitted: false,
  received_payment: null,
  collaborators_list: [],
  collaborators_users: [],
};
