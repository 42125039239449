import { Box } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { useAppSelector } from "../../../store/hooks";
import { Button } from "../../core-ui/components/Button/Button";
import {
  getDashboardRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { useAtomValue } from "jotai";
import { authModalOpenAtom, currentFlowAtom } from "./atoms";
import { AuthFlows } from "../../../constants/authSteps";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { Redirect } from "react-router";

export const AccountCreateComplete = () => {
  const flow = useAtomValue(currentFlowAtom);
  const [, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const { user, userStudios } = useAppSelector((state) => state.accountInfo);
  const username = getDisplayableNameForUser(user);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const redirectPath = useMemo(() => {
    if (!user) return getDashboardRoute();

    if (flow === AuthFlows.STUDIO_OWNER) {
      return getStudioScreenRoute(userStudios[0]?.username);
    } else if (flow === AuthFlows.AANDR) {
      return getDashboardRoute();
    } else {
      return {
        pathname: getProfileScreenRoute(user.username),
        state: { showTutorial: true, showPrimaryAccountTypeModal: true },
      };
    }
  }, [flow, user, userStudios]);

  if (shouldRedirect) {
    return <Redirect to={redirectPath} />;
  }

  if (!user)
    return (
      <Box>
        <Text variant={TextStyleVariant.H5}>
          Your profile has been created!
        </Text>
      </Box>
    );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        gap: "32px",
        padding: "40px",
      }}
    >
      <Text variant={TextStyleVariant.H5}>
        Congratulations {username}, your profile has been created!
      </Text>
      <Button
        onClick={() => {
          setAuthModalOpen(false);
          setShouldRedirect(true);
        }}
      >
        Go to profile
      </Button>
    </Box>
  );
};
