import {
  ProfileTypeCardCheck,
  ProfileTypeCardContainer,
  ProfileTypeCardInfo,
  ProfileTypeCardTitlePill,
  ProfileTypeCardTitle,
} from "./AuthModal.styles";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import * as Popover from "@radix-ui/react-popover";
import useModal from "../../../hooks/useModal";
import { TextField } from "@mui/material";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import React from "react";
import { faCircleCheck, faCircle } from "@fortawesome/pro-duotone-svg-icons";

export interface ProfileTypeCardProps {
  profileType: string;
  description: string;
  isPrimary: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  onClick: () => void;
  isOtherType: boolean;
  setOtherTypeDescription: React.Dispatch<React.SetStateAction<string>>;
  otherTypeDescription: string;
  alreadySelected?: boolean;
}

export const ProfileTypeCard = ({
  profileType,
  description,
  isPrimary = false,
  isSelected = false,
  isDisabled = false,
  onClick,
  isOtherType = false,
  setOtherTypeDescription,
  otherTypeDescription,
  alreadySelected = false,
}: ProfileTypeCardProps) => {
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  const popoverContent = (
    <TextField
      fullWidth={true}
      size={"small"}
      onChange={(e) => setOtherTypeDescription?.(e.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (otherTypeDescription?.length > 0) {
            onClick();
            closePopover();
          }
        }
      }}
    />
  );

  const profileTypeCard = (
    <ProfileTypeCardContainer
      onClick={
        isDisabled || (isOtherType && !alreadySelected) ? undefined : onClick
      }
      $isSelected={isSelected}
      $isDisabled={isDisabled}
    >
      <ProfileTypeCardInfo>
        <ProfileTypeCardTitle>
          <ProfileTypeCardTitlePill $isSelected={isSelected}>
            <Text
              variant={TextStyleVariant.H6}
              color={isDisabled ? TextColor.TERTIARY : TextColor.PRIMARY}
            >
              {profileType}
            </Text>
          </ProfileTypeCardTitlePill>
          {isPrimary && isSelected && (
            <Text variant={TextStyleVariant.P2} color={TextColor.SUCCESS}>
              Primary
            </Text>
          )}
        </ProfileTypeCardTitle>
        <Text
          variant={TextStyleVariant.P1}
          color={isDisabled ? TextColor.TERTIARY : TextColor.SECONDARY}
        >
          {description}
        </Text>
      </ProfileTypeCardInfo>
      <ProfileTypeCardCheck
        icon={isSelected ? faCircleCheck : faCircle}
        $isSelected={isSelected}
      />
    </ProfileTypeCardContainer>
  );

  return isOtherType && !alreadySelected && !isDisabled ? (
    <BasePopover
      side="top"
      isOpen={isPopoverOpen}
      setIsPopoverOpen={(isOpen) => {
        setIsPopoverOpen(isOpen);
      }}
      closePopover={closePopover}
      title={"What are you looking to do on EngineEars?"}
      additionalContent={popoverContent}
      onConfirm={onClick}
      okButtonProps={{
        disabled: !otherTypeDescription,
      }}
    >
      <Popover.Trigger asChild>{profileTypeCard}</Popover.Trigger>
    </BasePopover>
  ) : (
    profileTypeCard
  );
};
