import styled from "styled-components";

export const BudgetManagerModalH5 = styled.h5`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-family: Roobert;
  font-size: ${({ theme }) => theme.textSizeLg};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.36px;
  width: 100%;
  text-align: center;
`;

export const BudgetManagerModalP = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-family: Roobert;
  font-size: ${({ theme }) => theme.textSizeMd};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  text-align: left;
`;

export const BudgetManagerModalBoldP = styled(BudgetManagerModalP)`
  font-weight: 600;
`;

export const BillingInfoVerificationView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 24px;
  gap: 8px;
`;
