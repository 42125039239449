import { ADD_MUSO_CREDITS, MUSO_CREDITS } from "../utils/routes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { MUSO_SEARCH_TYPE, MusoCredit } from "../models/muso";
import { receiveErrors } from "./errorStore";

export interface searchMusoParams {
  offset: number;
  keyword: string;
  type: MUSO_SEARCH_TYPE;
}

export interface createMusoCreditParams {
  muso_entity_id: number;
  muso_id: string;
  credits: MusoCredit[];
}

interface CreditsResponse {
  data: MusoCredit[];
}

export const createMusoCredit = createAsyncThunk(
  ADD_MUSO_CREDITS + "/post",
  async (args: createMusoCreditParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<CreditsResponse>(
      ADD_MUSO_CREDITS,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getMusoCreditParams {
  user_id: number;
}

export const getMusoCredit = createAsyncThunk(
  MUSO_CREDITS,
  async (args: getMusoCreditParams, thunkAPI) => {
    const param = `?user_id=${args.user_id}`;
    const result = await makeBackendGetCallWithJsonResponse<CreditsResponse>(
      MUSO_CREDITS,
      param,
    );

    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface MusoProfileSearchState {
  fetchingMusoCredits: boolean;
  credits: MusoCredit[];
}

const initialState: MusoProfileSearchState = {
  fetchingMusoCredits: false,
  credits: [],
};

const MusoProfessionalSearchSlice = createSlice({
  name: "musoProfessionalSearchSlice",
  initialState,
  reducers: {
    clearMusoData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMusoCredit.pending, (state) => {
      state.fetchingMusoCredits = true;
    });
    builder.addCase(getMusoCredit.fulfilled, (state, action) => {
      state.credits = action.payload.data;
      state.fetchingMusoCredits = false;
    });
    builder.addCase(getMusoCredit.rejected, () => {
      return initialState;
    });
  },
});

export const { clearMusoData } = MusoProfessionalSearchSlice.actions;
export default MusoProfessionalSearchSlice.reducer;
