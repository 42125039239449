import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createMusoCredit, getMusoCredit } from "../../../store/actions/muso";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { MusoCredit } from "../../../store/models/muso";
import { Button } from "../../elements/Button/button";
import { MusoSelectedCreditsTable } from "../MusoSelectedCreditsTable/MusoSelectedCreditsTable";
import "./SelectMusoCredit.css";
import { useHistory } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { CreditTablerRowSkeletonLoader } from "../../elements/CreditTablerRowSkeletonLoader/CreditTablerRowSkeletonLoader";
import { MusoCreditTable } from "../MusoCreditTable/MusoCreditTable";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import {
  fetchAllMusoCreditsById,
  setKeyword,
} from "../../../store/actions/searchMusoCredits";
import { useMusoSearchStateOffsets } from "../../../hooks/useMusoSearchStateOffsets";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";

const MAX_SELECTABLE_CREDITS = 10;
const MAX_NUM_CREDITS_DISPLAY = 10;

export interface SelectMusoCreditProps {
  musoProfileId: string;
  musoEntityId: number;
}

// TODO: Update with new SSO API
export const SelectMusoCredit = ({
  musoProfileId,
  musoEntityId,
}: SelectMusoCreditProps) => {
  const [localKeyWord, setLocalKeyWord] = useState<string>("");
  const dispatch = useAppDispatch();

  const [disableCheckmarks, setDisableCheckmarks] = useState<boolean>(false);
  const [selectedCredits, setSelectedCredits] = useState(
    new Map<string, MusoCredit>(),
  );
  const [credits, setCredits] = useState<MusoCredit[]>([]);
  const [fetchingCredits, setFetchingCredits] = useState<boolean>(true);
  const { currentOffset, nextOffset, previousOffset, hasMore } =
    useMusoSearchStateOffsets();
  const { data, total, keyword } = useAppSelector(
    (state) => state.searchMusoCredits,
  );
  const creditList = data[currentOffset] ?? [];

  const [savingCredits, setSavingCredits] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string[]>();
  const { isDesktop } = useMediaQueryBreakpoint();
  const { user } = useAppSelector((state) => state.accountInfo);
  const topCredit = useAppSelector((state) => state?.musoSearchStore.credits);

  useEffect(() => {
    if (!musoProfileId) return;
    fetchCredits(0, keyword);
  }, [musoProfileId]);

  useEffect(() => {
    fetchCredits(0, localKeyWord);
  }, [keyword, musoProfileId]);

  // Fetch user's currently selected credits from the backend.
  useEffect(() => {
    if (!topCredit && user) {
      dispatch(
        getMusoCredit({
          user_id: user.id,
        }),
      )
        .unwrap()
        .then((res) => {
          setCredits(res.data);
        })
        .catch((error) => console.log("error, ", error));
    } else {
      topCredit.map((credit) => onChecked(credit));
    }
  }, [dispatch, user]);

  const history = useHistory();
  const navigateToDashboard = useCallback(() => {
    history.push({
      pathname: getProfileScreenRoute(user?.username ?? ""),
    });
  }, [history]);

  const onChecked = (data: MusoCredit) => {
    const key = data.muso_credit_id + data.track_title;
    if (selectedCredits.has(key)) {
      selectedCredits.delete(key);
    } else {
      selectedCredits.set(key, data);
    }

    if (selectedCredits.size === MAX_SELECTABLE_CREDITS) {
      setDisableCheckmarks(true);
    } else {
      setDisableCheckmarks(false);
    }
    setSelectedCredits(selectedCredits);
    setCredits(Array.from(selectedCredits.values()));
  };

  useEffect(() => {
    setSelectedId(
      credits.map((credit) => credit.muso_credit_id + credit.track_title),
    );
  }, [credits]);

  const saveMusoCredits = () => {
    setSavingCredits(true);
    if (!musoEntityId || !credits) return;
    dispatch(
      createMusoCredit({
        muso_entity_id: musoEntityId,
        muso_id: musoProfileId,
        credits: credits,
      }),
    )
      .unwrap()
      .then(() => {
        setSavingCredits(false);
        navigateToDashboard();
      })
      .catch((error) => console.log("error, ", error));
  };

  const fetchCredits = (offset: number, searchKeyword?: string) => {
    if (!musoProfileId) return;
    setFetchingCredits(true);
    dispatch(
      fetchAllMusoCreditsById({
        musoProfileId,
        offset: offset,
        keyword: searchKeyword,
      }),
    )
      .unwrap()
      .then(() => {
        setFetchingCredits(false);
      })
      .catch(() => {
        setFetchingCredits(false);
        toast.error("Error fetching credits. Please reach out to support.");
      });
  };

  const loadNext = () => {
    if (!hasMore) return;
    fetchCredits(nextOffset, localKeyWord);
  };

  const loadPrev = () => {
    fetchCredits(previousOffset, localKeyWord);
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    setLocalKeyWord(value);
    const searchStateUpdate = debounce(() => {
      dispatch(setKeyword(value));
    }, 500);
    searchStateUpdate();
  };

  const skeletonLoader = useMemo(() => {
    const loadersList = [];
    for (let i = 0; i < MAX_NUM_CREDITS_DISPLAY; i++) {
      loadersList.push(<CreditTablerRowSkeletonLoader isMobile={!isDesktop} />);
    }
    return loadersList;
  }, [isDesktop]);

  return (
    <div className="container muso-credits-container">
      <h3 className="h3 my-3">Select Your Credits</h3>
      <p className="b1">
        Choose up to 10 credits that you want displayed on your profile.
      </p>
      <p className="b1">You can always edit your list later.</p>
      <div className="selected-credits">
        Selected Credits: {selectedCredits.size + "/" + MAX_SELECTABLE_CREDITS}
      </div>
      <MusoSelectedCreditsTable
        selectedCredits={credits}
        disabled={false}
        visibleCheckMarks={true}
        onChecked={onChecked}
        checkedList={selectedId}
      />
      &nbsp;
      <div className="credit-count">Credits: {total}</div>
      <input
        value={localKeyWord}
        onChange={handleKeywordChange}
        style={{ width: "95%" }}
        placeholder="Search Credit"
        type={"text"}
        className="textfield mb-4"
      />
      {!fetchingCredits ? (
        <MusoCreditTable
          selectedCredits={creditList}
          disabled={disableCheckmarks}
          onChecked={onChecked}
          checkedList={selectedId}
        />
      ) : (
        <div className="muso-credit-table">{skeletonLoader}</div>
      )}
      <div className="submit-selected-muso-credits-button">
        <div>
          <button
            className="paginate-button"
            value={"<"}
            onClick={() => loadPrev()}
          >
            {"<"}
          </button>
          <button
            className="paginate-button"
            value={">"}
            disabled={!hasMore}
            onClick={() => loadNext()}
          >
            {">"}
          </button>
        </div>
        <Button
          loading={savingCredits}
          primary
          label="SAVE CREDITS"
          type="submit"
          onClick={saveMusoCredits}
        />
      </div>
    </div>
  );
};
