import { atom, Setter } from "jotai";
import { atomWithLocation } from "jotai-location";
import { AuthFlows, AuthSteps, FLOW_STEPS } from "../../../constants/authSteps";
import { reduxAtom } from "../../../store";

const _searchQueryAtom = atomWithLocation();

interface ExpectedSearchParams {
  step?: string;
  flow?: string;
  [x: string]: unknown;
}

interface SetSearchParams<T> {
  key: keyof ExpectedSearchParams;
  value: T;
  clear?: boolean;
}

const setSearchParamsHelper = (
  set: Setter,
  params: SetSearchParams<string> | SetSearchParams<string>[],
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(prev.searchParams);

    const setParam = (param: SetSearchParams<string>) => {
      if (param.clear && !param.value) {
        newParams.delete(String(param.key));
      } else {
        newParams.set(String(param.key), param.value);
      }
    };

    if (Array.isArray(params)) {
      params.forEach(setParam);
    } else {
      setParam(params);
    }

    return { ...prev, searchParams: newParams };
  });
};

// Read the current step from the URL
export const signUpStepAtom = atom((get) => {
  const stepValue: AuthSteps = parseInt(
    get(_searchQueryAtom).searchParams?.get("step") || "",
  ) as AuthSteps;

  const user = get(reduxAtom).accountInfo.user;
  const currentFlow = get(currentFlowAtom);
  const flowSteps = FLOW_STEPS[currentFlow]?.steps.flat() || [];

  if (
    stepValue !== AuthSteps.START_SIGNUP &&
    stepValue !== AuthSteps.LOGIN &&
    !user
  ) {
    return AuthSteps.START_SIGNUP;
  }
  if (Number.isNaN(stepValue)) {
    return flowSteps[0];
  }
  return stepValue;
});

// Write the current step to the URL
export const currentSignUpStepAtom = atom(
  AuthSteps.START_SIGNUP,
  (_, set, value: AuthSteps) => {
    const key: keyof ExpectedSearchParams = "step";
    setSearchParamsHelper(set, { key, value: String(value), clear: true });
    set(currentSignUpStepAtom, value);
  },
);

// Read current flow from the URL
export const readCurrentFlow = atom((get) => {
  const flowValue: AuthFlows = parseInt(
    get(_searchQueryAtom).searchParams?.get("flow") || "",
  ) as AuthFlows;
  return flowValue;
});

// Write the current flow to the URL. Used when changing flows
export const currentFlowAtom = atom(
  AuthFlows.LOGIN,
  (_, set, value: AuthFlows) => {
    const key: keyof ExpectedSearchParams = "flow";
    setSearchParamsHelper(set, { key, value: String(value), clear: true });

    set(currentFlowAtom, value);
    const flowSteps = FLOW_STEPS[value]?.steps.flat() || [];
    set(currentSignUpStepAtom, flowSteps[0]);
  },
);

// Controls modal
export const authModalOpenAtom = atom(false);

// Use this instead of useModal stuff in AuthModal
export const authModalAtom = atom(
  (get) => get(authModalOpenAtom),
  (_, set, value: boolean | undefined) =>
    set(authModalOpenAtom, (oldVal) => {
      if (typeof value === "undefined") return !oldVal;
      return value;
    }),
);
