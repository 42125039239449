import { SyntheticEvent, useMemo } from "react";
import defaultUser from "../stories/assets/defaultuser.jpg";
import noImage from "../stories/assets/noimage.jpg";
import { imagePathPrefix, entityPhotoPrefix } from "../store/utils";

export const useImageURLFromPath = (
  isEntityProfilePath: boolean,
  path?: string,
): string | undefined => {
  return useMemo(() => {
    if (!path) return isEntityProfilePath ? noImage : defaultUser;
    if (isEntityProfilePath) {
      return entityPhotoPrefix + path;
    }
    return imagePathPrefix + path;
  }, [isEntityProfilePath, path]);
};

export const getImageURLFromPath = (
  isEntityProfilePath: boolean,
  path?: string,
) => {
  if (!path) return isEntityProfilePath ? undefined : defaultUser;
  if (isEntityProfilePath) {
    return entityPhotoPrefix + path;
  }
  return imagePathPrefix + path;
};

export const entityPhotoErrorHandler = (
  e: SyntheticEvent<HTMLImageElement, Event>,
  path?: string,
) => {
  e.currentTarget.onerror = null;
  e.currentTarget.src = getImageURLFromPath(true, path) || "";
};
