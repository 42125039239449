import { toggleInProgressProject } from "../../../store/actions/mixMasterCartsStore";
import { setPaywallOption } from "../../../store/actions/generateBookingStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ScheduledProjectPaywallOptions } from "../../../store/models/project";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import {
  DropdownSelectorContainer,
  ToggleSwitchText,
} from "./InProgressProjectSelector.styles";

export const InProgressProjectSelector = () => {
  const dispatch = useAppDispatch();
  const { inProgressProject } = useAppSelector(
    (state) => state.mixMasterCartsStore.cart,
  );
  const { paywallOption } = useAppSelector(
    (state) => state.generateBookingStore,
  );

  const selectPaywallOption = (option: OptionType) => {
    dispatch(setPaywallOption(option.value));
  };

  return (
    <>
      <ToggleSwitch
        id="in-progress-project-toggle"
        label={
          <div style={{ display: "flex" }}>
            <ToggleSwitchText>Project is already in progress </ToggleSwitchText>
            <ToolTipTextArea
              text="Enables you to upload files and share out a project for review by a client before payment has been collected"
              tooltipTextStyle={{
                textAlign: "left",
                top: 0,
                fontWeight: 400,
              }}
            />
          </div>
        }
        checked={inProgressProject}
        onChange={() => dispatch(toggleInProgressProject())}
        size={TOGGLE_SWITCH_SIZE.SMALL}
        updateCheckedLocally={false}
      />
      {inProgressProject && (
        <DropdownSelectorContainer>
          <DropdownSelector
            value={ScheduledProjectPaywallOptions.find(
              (option) => option.value === paywallOption,
            )}
            options={ScheduledProjectPaywallOptions.slice(1)}
            onChange={selectPaywallOption}
            placeholder="Select paywall option"
          />
        </DropdownSelectorContainer>
      )}
    </>
  );
};
