import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetStudioRooms } from "../../../hooks/UseGetStudioRooms";
import { SetUpCartPayload } from "../../../store/actions/shoppingCart";
import { createTransaction } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Studio } from "../../../store/models/studio";
import { getTransactionBookingScreenRoute } from "../../../store/utils/routeGetters";
import { BookingParameters } from "../../../store/utils/transactions";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  Button,
  ButtonProps,
  ButtonVariant,
} from "../../core-ui/components/Button/Button";

type GenerateBookingProps = {
  isLoadingEngineerServices: boolean;
  isMobile: boolean;
  activeStudio?: Studio;
} & Pick<ButtonProps, "className" | "disabled">;

export const GenerateBookingButton: FC<GenerateBookingProps> = ({
  className,
  disabled,
  isLoadingEngineerServices,
  isMobile,
  activeStudio,
}: GenerateBookingProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const { loadingStudioRooms, studioRooms } = useGetStudioRooms();

  const isDisabled = disabled ?? (activeStudio && studioRooms.length === 0);

  const handleGenerateBookingLink = async () => {
    emitAnalyticsTrackingEvent(
      "dashboard_on_click_generate_booking_link",
      {},
      loggedInUser?.id,
    );

    try {
      setIsTransactionLoading(true);
      const transaction = await dispatch(createTransaction()).unwrap();
      const bookingParameters: BookingParameters = {
        transactionId: +transaction.id,
        engineerId: activeStudio ? undefined : loggedInUser?.engineer?.id,
        engineerUserId: activeStudio
          ? undefined
          : loggedInUser?.engineer?.user_id,
        activeStudioId: activeStudio?.id,
        activeServiceType: activeStudio ? ProjectType.RECORDING : undefined,
        activeServiceTypeProjectIds: [],
      };

      let studioRoom;
      if (studioRooms && studioRooms.length > 0) {
        studioRoom = studioRooms[0];
        bookingParameters.activeStudioRoomId = studioRoom.id;
      }

      emitAnalyticsTrackingEvent(
        "clicked_generate_booking_button",
        {
          transaction_id: bookingParameters.transactionId,
          active_studio_id: bookingParameters.activeStudioId,
          engineer_user_id: bookingParameters.engineerUserId,
          service_type: bookingParameters.activeServiceType,
        },
        loggedInUser?.id,
      );

      history.push(
        getTransactionBookingScreenRoute(
          transaction.code,
          dispatch,
          bookingParameters,
          studioRoom?.recording_service
            ? ({
                pendingSessionData: [],
              } as SetUpCartPayload)
            : undefined,
        ),
      );
    } catch {
      setIsTransactionLoading(false);
    }
  };
  return (
    <Button
      className={className}
      disabled={isDisabled}
      fullWidth={isMobile}
      variant={ButtonVariant.PRIMARY}
      onClick={handleGenerateBookingLink}
      loading={
        isLoadingEngineerServices || loadingStudioRooms || isTransactionLoading
      }
    >
      Generate Booking
    </Button>
  );
};
