import { createRoot } from "react-dom/client";
// @ts-ignore
import HttpsRedirect from "react-https-redirect";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/umgAuthConfig";
import * as Sentry from "@sentry/react";
import { isProd } from "./store/utils";
import { VERSION, INSTANCE_URL } from "./constants/generated";

// Used for UMG SSO authentication.
const msalInstance = new PublicClientApplication(msalConfig);

const getSentryEnv = () => {
  if (isProd) {
    return "production";
  }

  return process.env.NODE_ENV === "development" ? "dev" : "staging";
};

const sentryEnv = getSentryEnv();
const isDevelopment = sentryEnv === "dev";

Sentry.init({
  dsn: "https://8d3d70958ee130ecdbbe33cb166bd591@o4507077185110016.ingest.us.sentry.io/4507077209030656",
  integrations: isDevelopment
    ? [Sentry.browserTracingIntegration()]
    : [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: sentryEnv,
  normalizeDepth: 5,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^\//,
    /^engineears.*\//,
    /https:\/\/.*\..*-?engineears-215922\.appspot\.com\/api\//,
  ],
  dist: INSTANCE_URL ? "firebase" : "django",
  release: VERSION,
  // Session Replay
  replaysSessionSampleRate: isDevelopment ? 0.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: isDevelopment ? 0.0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </Provider>
  </HttpsRedirect>,
);
