import { useMemo } from "react";
import { PurchaseOrderBillingInfoSteps } from "./utils";

interface BillingInfoHookArgs {
  step: PurchaseOrderBillingInfoSteps;
  submittingPurchaseOrder: boolean;
}

export const usePurchaseOrderHeader = ({
  step,
  submittingPurchaseOrder,
}: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Submit Purchase Order";
        }
        return "Submit Billing Info";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Confirm Purchase Order";
        }
        return "Confirm Billing Info";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        if (submittingPurchaseOrder) {
          return "Purchase Order Submitted";
        }
        return "Billing Info Submitted";
    }
  }, [step, submittingPurchaseOrder]);
};

export const useConfirmTextForPurchaseOrderModal = ({
  step,
  submittingPurchaseOrder,
}: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Submit Purchase Order";
        }
        return "Submit Billing Info";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Confirm Purchase Order";
        }
        return "Confirm Billing Info";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        return "Close";
    }
  }, [step, submittingPurchaseOrder]);
};

export const useCancelTextForPurchaseOrderModal = ({
  step,
  submittingPurchaseOrder,
}: BillingInfoHookArgs) => {
  return useMemo(() => {
    switch (step) {
      case PurchaseOrderBillingInfoSteps.SUBMIT_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Cancel";
        }
        return "Cancel";
      case PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO:
        if (submittingPurchaseOrder) {
          return "Edit Purchase Order";
        }
        return "Edit Billing Info";
      case PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED:
        return undefined;
    }
  }, [step, submittingPurchaseOrder]);
};
