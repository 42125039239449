import { useFlags } from "flagsmith/react";
import { useMemo } from "react";

// https://app.flagsmith.com/project/18710/environment/QykAErhii4UHVRs2Bt9pET/features?feature=100674&tab=value
export const useMaintenanceMode = () => {
  const flags = useFlags(["maintenance"]);
  const isMaintenanceMode = useMemo(() => {
    if (flags.maintenance.enabled) {
      return Boolean(flags.maintenance.value);
    }
    return false;
  }, [flags.maintenance]);
  return isMaintenanceMode;
};
