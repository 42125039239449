import { useGoogleLogin } from "@react-oauth/google";
import { useSetAtom } from "jotai";
import { toast } from "react-toastify";
import { AuthFlows } from "../../constants/authSteps";
import { startGoogleLogin } from "../../store/actions/accountInfo";
import { useAppDispatch } from "../../store/hooks";
import {
  authModalOpenAtom,
  currentFlowAtom,
} from "../../stories/components/Auth/atoms";
import {
  emitAnalyticsTrackingEvent,
  getDebugEventPrefix,
} from "../../utils/analyticsUtils";
import { useAuthNavigationSteps } from "./useAuthNavigationSteps";

const useGoogleAuth = () => {
  const dispatch = useAppDispatch();
  const { nextStep } = useAuthNavigationSteps();
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const setFlow = useSetAtom(currentFlowAtom);

  const handleGoogleLogin = useGoogleLogin({
    onError: () => {
      toast.error(
        "Something went wrong. Please try again or reach out to customer support.",
      );
    },
    onSuccess: (codeResponse) => {
      const { code } = codeResponse;
      dispatch(startGoogleLogin({ authorization_code: code }))
        .unwrap()
        .then((result) => {
          emitAnalyticsTrackingEvent(
            getDebugEventPrefix + "login_via_google",
            {},
          );
          if (result.created) {
            setFlow(AuthFlows.STANDARD_SIGNUP);
            nextStep();
          } else {
            setAuthModalOpen(false);
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    flow: "auth-code",
  });

  const handleGoogleClick = () => {
    handleGoogleLogin();
  };
  return { handleGoogleClick };
};

export default useGoogleAuth;
