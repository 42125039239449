import "./CardSectionStyles.css";
import { PaymentElement } from "@stripe/react-stripe-js";
import { FinancialMethod } from "../../../store/models/transaction";

interface CardSectionProps {
  handlePaymentTypeChange: (paymentType: FinancialMethod) => void;
}

function CardSection({ handlePaymentTypeChange }: CardSectionProps) {
  return (
    <PaymentElement
      options={{
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      }}
      onChange={(e) => {
        if (e.elementType === "payment") {
          const value = e.value;
          switch (value.type) {
            case "card":
              return handlePaymentTypeChange(FinancialMethod.STRIPE);
            case "cashapp":
              return handlePaymentTypeChange(FinancialMethod.CASH_APP);
            case "klarna":
              return handlePaymentTypeChange(FinancialMethod.KLARNA);
            case "apple_pay":
              return handlePaymentTypeChange(FinancialMethod.APPLE_PAY);
            case "google_pay":
              return handlePaymentTypeChange(FinancialMethod.GOOGLE_PAY);
          }
        }
      }}
    />
  );
}

export default CardSection;
