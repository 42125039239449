import { atomWithLocation } from "jotai-location";
import { atom, Setter } from "jotai";

const _searchQueryAtom = atomWithLocation();
_searchQueryAtom.debugPrivate = false;

// The purpose of this interface is to define the expected shape of the search parameters that can be passed to a search function.
interface ExpectedSearchParams {
  term?: string;
  [x: string]: string | undefined;
}

// This represents a key in the url, the value in the url, and a boolean on whether or not the key should be cleared from the URL when being set
interface SetSearchParams<T> {
  key: keyof ExpectedSearchParams;
  value: T;
  clear?: boolean;
}
/**
 * Helper function to set search parameters.
 *
 * @param {Setter} set - The setter function from Jotai.
 * @param {SetSearchParams<string>} params - The parameters to set.
 * @return {void}
 */
const setSearchParamsHelper = (
  set: Setter,
  params: SetSearchParams<string>,
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(prev.searchParams);
    if (params.clear && !params.value) {
      newParams.delete(String(params.key));
    } else {
      newParams.set(String(params.key), params.value);
    }
    return { ...prev, searchParams: newParams };
  });
};

// parses the URL parameters into an object that can only be read from.
const searchParamsAtom = atom((get) => {
  const currentParams = get(_searchQueryAtom).searchParams;
  const validatedParams: ExpectedSearchParams = {};
  currentParams?.forEach((value, key) => {
    validatedParams[key] = value;
  });
  return validatedParams;
});

// getter/setter for the search term
const searchTermAtom = atom(
  (get) => {
    return get(searchParamsAtom).term || "";
  },
  (_, set, value: string) => {
    const key: keyof ExpectedSearchParams = "term";
    setSearchParamsHelper(set, { key, value, clear: true });
  },
);

searchTermAtom.debugLabel = "Search Term Atom";

// a read-only atom that returns the URL-safe query string
const queryStringAtom = atom((get) => {
  const params = get(searchParamsAtom) || {};
  return Object.keys(params)
    .map((key) => {
      if (typeof params[key] !== "undefined") {
        return `${key}=${encodeURIComponent(params[key] || "")}`;
      }
      return "";
    })
    .join("&");
});

queryStringAtom.debugLabel = "URL-Safe Query String";

export { searchTermAtom, queryStringAtom };
