import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useState } from "react";
import { verifyAccountPhoneNumber } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import VerificationCodeInput from "./VerificationCodeInput";
import { AuthNavigation } from "./AuthNavigation";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useTheme } from "@mui/material";
import { DISCIPLINE_TYPE, getPrimaryDiscipline } from "../../../hooks/user";

export const VerifyPhone = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const { nextStep, prevStep } = useAuthNavigationSteps();
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const primaryDiscipline = getPrimaryDiscipline(user);

  const handleVerified = () => {
    setVerificationError(false);
    setPhoneVerified(true);
  };

  const handleVerificationError = () => {
    setPhoneVerified(false);
    setVerificationError(true);
  };

  const onComplete = (verificationCode: string) => {
    if (!user?.id) return;
    setIsLoading(true);
    dispatch(
      verifyAccountPhoneNumber({
        verification: verificationCode,
      }),
    )
      .unwrap()
      .then(() => {
        handleVerified();
        nextStep();
      })
      .catch(() => {
        handleVerificationError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (phoneVerified) {
      nextStep();
    }
  };

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>Confirm your phone</Text>
        {user?.phone_number && (
          <Text variant={TextStyleVariant.P1}>
            Enter the verification code we sent to:
            <br />
            {user.phone_number.phone_number}
          </Text>
        )}
        <Button
          onClick={() => nextStep({ skipVerification: true })}
          variant={ButtonVariant.UNSTYLED}
        >
          <Button
            style={{
              textDecoration: "underline",
              color: theme.palette.text.primary,
            }}
            variant={ButtonVariant.UNSTYLED}
            onClick={() => prevStep()}
          >
            (Use a different phone)
          </Button>
        </Button>
        <VerificationCodeInput codeLength={6} onComplete={onComplete} />
        {verificationError && (
          <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
            Invalid verification code
          </Text>
        )}
        {phoneVerified && (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verified!
          </Text>
        )}
        <Button
          loading={isLoading}
          fullWidth
          onClick={handleSubmit}
          disabled={!phoneVerified}
        >
          Submit
        </Button>
        {primaryDiscipline === DISCIPLINE_TYPE.ARTIST && (
          <Button
            style={{
              alignSelf: "flex-end",
              fontWeight: "600",
              color: theme.palette.text.primary,
            }}
            variant={ButtonVariant.TEXT}
            onClick={() => nextStep({ skipVerification: true })}
          >
            Remind me later
          </Button>
        )}
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
