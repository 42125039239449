import { createTheme } from "@mui/material";

/**
 * breakpoints are based off our current breakpoints,
 * except for xl which based on MUI's default value
 */
const muiBreakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480, // mobile
      md: 768, // tablet
      lg: 1200, // desktop
      xl: 1536,
    },
  },
});

export default muiBreakpointsTheme;
