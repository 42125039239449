import { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "./ActionItemRow.css";
import { PurchaseOrderWithTransactions } from "../../../store/models/PurchaseOrderWithTransactions";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  Project,
  ProjectType,
  projectTypeReadableName,
} from "../../../store/models/project";
import { createScheduledProjectShareLink } from "../../../store/actions/scheduledprojects";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { Transaction } from "../../../store/models/transaction";
import { useProjectTypeToCountMap } from "../../../hooks/useAdminActions";
import { imagePathPrefix } from "../../../store/utils";
import defaultUser from "../../../stories/assets/defaultuser.jpg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { SCREENS } from "../../../routes";
import { getProjectOverviewRoute } from "../../../store/utils/routeGetters";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";

export interface ActionItemRowProps {
  actionItem: PurchaseOrderWithTransactions;
  completedOverride?: boolean;
}
export interface ProjectTypeNumbers {
  count: number;
  subTotal: number;
  firstProject: Project;
}

export const ActionItemRow = ({
  actionItem,
  completedOverride,
}: ActionItemRowProps) => {
  const [isProjectLinkLoading, setIsProjectLinkLoading] = useState(false);
  const dispatch = useAppDispatch();

  // Expected to get only one transaction per purchase order.
  const transaction: Transaction = useMemo(() => {
    return actionItem.purchase_order_transaction[0];
  }, [actionItem]);
  const { isDesktop } = useMediaQueryBreakpoint();
  const totalPrice = useMemo(() => {
    if (transaction?.total_price && transaction.fees_collected) {
      const total = PennyDollarFormatter().format(
        parseFloat(transaction?.total_price ?? "") +
          parseFloat(transaction?.fees_collected ?? ""),
      );
      return (
        <p style={{ textAlign: "center" }} className="b2-semi-bold">
          {total}
        </p>
      );
    }
    return null;
  }, [transaction]);

  const user = useAppSelector((state) => state.accountInfo.user);
  const history = useHistory();

  const projectTypeToCountMap: Map<ProjectType, ProjectTypeNumbers> =
    useProjectTypeToCountMap(transaction);

  const projectCountAndService = useMemo(() => {
    return Array.from(
      projectTypeToCountMap,
      ([projectType, projectTypeNumbers]) => ({
        projectType,
        projectTypeNumbers,
      }),
    );
  }, [projectTypeToCountMap]);

  const { budgetManager, completed } = useMemo(() => {
    const budgetManager = actionItem.budget_managers.find(
      (budgetManager) => budgetManager.email === user?.email,
    );
    let completed =
      Boolean(budgetManager?.budget_approved) ||
      Boolean(budgetManager?.budget_rejected);

    if (completedOverride !== undefined) {
      completed = completedOverride;
    }

    return { budgetManager, completed };
  }, [actionItem.budget_managers, completedOverride]);

  const actionToPerform = useMemo(() => {
    if (!budgetManager) {
      return "";
    }
    if (budgetManager.can_submit_billing_info) {
      return "Submit Billing Info";
    } else if (budgetManager.can_approve_budget) {
      return "Approve Budget";
    }
    return "";
  }, [budgetManager]);

  const handleClick = () => {
    if (!budgetManager) return;
    const param = transaction.id + "/?code=" + budgetManager.code;
    history.push("/transaction-overview/" + param);
  };

  const onClickProjectDetails = async () => {
    if (!actionItem.scheduled_project_id) {
      history.push(SCREENS.PROJECTS_NO_TAB);
      return;
    }

    setIsProjectLinkLoading(true);
    try {
      const response = await dispatch(
        createScheduledProjectShareLink({
          scheduled_project_id: actionItem.scheduled_project_id.toString(),
        }),
      ).unwrap();
      history.push(
        getProjectOverviewRoute(
          actionItem.scheduled_project_id,
          response[0].code,
        ),
      );
    } catch {
      // redirect to /projects on a failed API call
      history.push(SCREENS.PROJECTS_NO_TAB);
    } finally {
      setIsProjectLinkLoading(false);
    }
  };

  const imageContainer = () => {
    return actionItem.users?.map((user, key) => {
      const imgUrl = !user.photo
        ? defaultUser
        : imagePathPrefix + user.photo?.path;
      return (
        <Link
          to={SCREENS.PROFILE_SCREEN.replace(":username", user.username)}
          key={key}
          className="action-item-image-container"
        >
          <img
            className="action-item-image"
            key={key}
            height={21}
            width={21}
            src={imgUrl}
            alt="profile"
          />
          <p>{getDisplayableNameForUser(user)}</p>
        </Link>
      );
    });
  };

  const budgetStatus = () => {
    if (budgetManager?.budget_approved) {
      return <p className="b1-semi-bold">Budget Approved</p>;
    }
    if (budgetManager?.budget_rejected) {
      return <p className="b1-semi-bold">Budget Rejected</p>;
    }
    return null;
  };

  return (
    <div className="action-item-table-element-container">
      {isDesktop && (
        <div className="action-item-row-container">
          {budgetManager && (
            <input
              className="action-item-checkbox"
              type="checkbox"
              name={"Main"}
              disabled={true}
              checked={Boolean(completed)}
            />
          )}
          <div className="action-item-column-container">
            <p style={{ textAlign: "center" }} className="b2-semi-bold">
              {actionToPerform}
            </p>
            <p className="b2">{actionItem.scheduled_project_title}</p>
          </div>
        </div>
      )}
      {isDesktop && (
        <div className="action-item-column-container">{imageContainer()}</div>
      )}
      <div className="action-item-column-container">
        {totalPrice}
        {projectCountAndService.map((projectCountAndService, key) => (
          <p style={{ textAlign: "center" }} key={key} className="b3">{`${
            projectCountAndService.projectTypeNumbers.count
          } Songs • ${projectTypeReadableName.get(
            projectCountAndService.projectType,
          )}`}</p>
        ))}
      </div>
      <div className="action-item-column-container">
        {isProjectLinkLoading ? (
          <SoundWaveLoader width={100} height={100} />
        ) : (
          <p
            style={{
              textDecoration: "underline",
              textTransform: "uppercase",
              textAlign: "center",
              cursor: "pointer",
            }}
            className="b3"
            onClick={onClickProjectDetails}
          >
            Project Details
          </p>
        )}
      </div>
      <div className="action-item-column-container">
        {completed ? (
          budgetStatus()
        ) : (
          <Button
            variant={ButtonVariant.GRADIENT}
            onClick={handleClick}
            className="action-item-element-button"
            fullWidth={isDesktop}
          >
            {actionToPerform}
          </Button>
        )}
      </div>
    </div>
  );
};
