import { addHours, subDays } from "date-fns";
import Artist, { MockArtist } from "./artist";
import Engineer, { MockEngineer, MockEngineerForRecording } from "./engineer";
import { BaseProject, MockRecordingProject, ProjectUserType } from "./project";
import { CancellationPolicy } from "./recording";
import { ProjectAcceptance } from "./scheduledproject";
import {
  BasicStudioRoom,
  MockStudioRoom,
  MockStudioRoomForRecordingBreakdown,
  StudioRoom,
} from "./studio";
import User, {
  MockUser,
  MockUser1,
  MockUserForRecordingEngineer,
  UserLite,
} from "./user";

export enum SessionSteps {
  NO_STEP,
  SESSION_BOOKED,
  SESSION_FILES_UPLOADED,
}

export enum SessionWorkflowSteps {
  SESSION_BOOKED,
  SESSION_UPCOMING,
  SESSION_IN_PROGRESS,
  SESSION_COMPLETE,
}

export enum SessionModificationWorkflowSteps {
  SESSION_BOOKED,
  SESSION_MODIFICATION,
  SESSION_IN_PROGRESS,
  SESSION_COMPLETE,
}

export enum SessionCancellationWorkflowSteps {
  SESSION_BOOKED,
  SESSION_UPCOMING,
  SESSION_IN_PROGRESS,
  SESSION_CANCELLED,
}

export enum SessionProjectWorkflowStepText {
  SESSION_BOOKED = "Session Booked",
  SESSION_UPCOMING = "Session Upcoming",
  SESSION_IN_PROGRESS = "Session in progress",
  SESSION_COMPLETE = "Session Complete",
  SESSION_MODIFICATION = "Modification pending",
  SESSION_CANCELLED = "Session Cancelled",
}

/**
 * @TODO Update the copy
 */
export const RecordingTaskMessage = {
  [ProjectUserType.ARTIST]: {
    [SessionWorkflowSteps.SESSION_UPCOMING]: {
      message: "Show up on time for your session",
      toolTipText: "",
    },
    [SessionWorkflowSteps.SESSION_IN_PROGRESS]: {
      message: "Enjoy your session",
      toolTipText: "",
    },
    [SessionWorkflowSteps.SESSION_COMPLETE]: {
      message: "Leave a review on your experience",
      toolTipText: "",
    },
  },
  [ProjectUserType.ENGINEER]: {
    [SessionWorkflowSteps.SESSION_UPCOMING]: {
      message: "Show up on time for your session",
      toolTipText: "",
    },
    [SessionWorkflowSteps.SESSION_IN_PROGRESS]: {
      message: "Enjoy your session",
      toolTipText: "",
    },
    [SessionWorkflowSteps.SESSION_COMPLETE]: {
      message: "Upload session files and leave a review",
      toolTipText: "",
    },
  },
};

/**
 * Object that maps the session workflow steps to the text that is displayed for a Session Project
 */
export const SessionWorkflowStepText: Record<
  SessionWorkflowSteps,
  SessionProjectWorkflowStepText
> = {
  [SessionWorkflowSteps.SESSION_BOOKED]:
    SessionProjectWorkflowStepText.SESSION_BOOKED,
  [SessionWorkflowSteps.SESSION_UPCOMING]:
    SessionProjectWorkflowStepText.SESSION_UPCOMING,
  [SessionWorkflowSteps.SESSION_IN_PROGRESS]:
    SessionProjectWorkflowStepText.SESSION_IN_PROGRESS,
  [SessionWorkflowSteps.SESSION_COMPLETE]:
    SessionProjectWorkflowStepText.SESSION_COMPLETE,
};

export const SessionModificationWorkflowStepText: Record<
  SessionModificationWorkflowSteps,
  SessionProjectWorkflowStepText
> = {
  [SessionModificationWorkflowSteps.SESSION_BOOKED]:
    SessionProjectWorkflowStepText.SESSION_BOOKED,
  [SessionModificationWorkflowSteps.SESSION_MODIFICATION]:
    SessionProjectWorkflowStepText.SESSION_MODIFICATION,
  [SessionModificationWorkflowSteps.SESSION_IN_PROGRESS]:
    SessionProjectWorkflowStepText.SESSION_IN_PROGRESS,
  [SessionModificationWorkflowSteps.SESSION_COMPLETE]:
    SessionProjectWorkflowStepText.SESSION_COMPLETE,
};

export const SessionCancellationWorkflowStepText: Record<
  SessionCancellationWorkflowSteps,
  SessionProjectWorkflowStepText
> = {
  [SessionCancellationWorkflowSteps.SESSION_BOOKED]:
    SessionProjectWorkflowStepText.SESSION_BOOKED,
  [SessionCancellationWorkflowSteps.SESSION_UPCOMING]:
    SessionProjectWorkflowStepText.SESSION_UPCOMING,
  [SessionCancellationWorkflowSteps.SESSION_IN_PROGRESS]:
    SessionProjectWorkflowStepText.SESSION_IN_PROGRESS,
  [SessionCancellationWorkflowSteps.SESSION_CANCELLED]:
    SessionProjectWorkflowStepText.SESSION_CANCELLED,
};

export const MINUTES_PER_HOUR = 60;

export enum PaginatedRecordingSessionsPriority {
  REFUNDED = 0,
  COMPLETED = 1,
  ACTIVE = 2,
  PENDING = 3,
}

export enum RecordingSessionStages {
  ALL = -1,
  REFUNDED = 0,
  COMPLETED = 1,
  UPCOMING = 2,
  ACTIVE = 3,
  PENDING = 4,
  SESSION_MODIFICATION = 5,
}

export const SESSION_STAGE_OPTIONS = [
  { label: "All Statuses", value: RecordingSessionStages.ALL },
  { label: "Pending", value: RecordingSessionStages.PENDING },
  { label: "In Progress", value: RecordingSessionStages.ACTIVE },
  { label: "Upcoming", value: RecordingSessionStages.UPCOMING },
  { label: "Completed", value: RecordingSessionStages.COMPLETED },
  { label: "Refunded", value: RecordingSessionStages.REFUNDED },
  {
    label: "Modification pending",
    value: RecordingSessionStages.SESSION_MODIFICATION,
  },
];

export const SESSION_STAGE_REVERSE_OPTIONS = SESSION_STAGE_OPTIONS.reduce<
  Record<RecordingSessionStages, string>
>(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as Record<RecordingSessionStages, string>,
);

export interface PaginatedRecordingSessions {
  priority: PaginatedRecordingSessionsPriority;
  id: number;
  first_choice_datetime: string;
  completed: string | null;
  session_duration_minutes: number;
  artist_id: number;
  engineer_id: number | null;
  studio_id: number | null;
  studio_room_id: number | null;
  refunded: string | null;
  project: number;
  total_price: string;
  room_name: string;
  studio_name: string;
  engineer_name: string | null;
  engineer_username: string | null;
  artist_name: string | null;
  artist_username: string | null;
  recording_session_request_id: number | null;
  pending_booking_acceptance: boolean;
  pending_my_acceptance_or_rejection: boolean | null;
  has_pending_modification_acceptance: boolean | null;
}

export enum RecordingSessionStep {
  NO_STEP = 0,
  SESSION_BOOKED = 1,
  SESSION_FILES_UPLOADED = 2,
}

export const recordingSessionMap = new Map<RecordingSessionStep, number>([
  [RecordingSessionStep.NO_STEP, 0],
  [RecordingSessionStep.SESSION_BOOKED, 1],
  [RecordingSessionStep.SESSION_FILES_UPLOADED, 2],
]);

export enum ModificationType {
  NO_TYPE = 0,
  RESCHEDULE = 1,
  EXTENSION = 2,
}

export interface SessionModification {
  id: number;
  previous_datetime: string;
  new_datetime: string;
  required_acceptances: ProjectAcceptance[];
  modification_type: ModificationType;
  new_session_duration_minutes: number;
  previous_session_duration_minutes: number;
}

export interface RecordingLocation {
  city_location: string;
  formatted?: string;
  latitude?: number;
  longitude?: number;
}

export interface BasicRecordingSession {
  id?: number;
  completed: string | null;
  refunded: string | null;
  project: BaseProject;
  step: number;
  engineer_id: number;
  first_choice_datetime: string;
  second_choice_datetime: string;
  engineer_goes_to_artist: string | null;
  session_duration_minutes: number;
  unit_number: string | null;
  arrival_information: string | null;
  // Represents the RecordingSessionBooking id.
  recording_session_request_id: number | null;
  studio_manager_payment_amount_split: number;
  engineer_payment_amount_split: number;
  pending_booking_acceptance: boolean;
  studio_room: BasicStudioRoom;
  session_modification?: SessionModification;
  outstanding_balance: number;
}

export interface RecordingSession {
  id?: number;
  created: string | null;
  deleted: string | null;
  completed: string | null;
  refunded: string | null;
  project: BaseProject;
  step: number;
  engineer?: Engineer;
  artist: Artist;
  title: string | null;
  artist_name?: string;
  first_choice_datetime: string;
  second_choice_datetime: string;
  end_datetime: string | null;
  recording_location: RecordingLocation;
  engineer_goes_to_artist: string | null;
  session_duration_minutes: number;
  unit_number: string | null;
  arrival_information: string | null;
  cancellation_policy?: CancellationPolicy;
  studio_room?: StudioRoom;
  // Represents the RecordingSessionBooking id.
  recording_session_request_id: number | null;
  users: User[];
  studio_manager_payment_amount_split: number;
  engineer_payment_amount_split: number;
  engineer_id: number;
  session_modification?: SessionModification;
  pending_booking_acceptance: boolean;
  outstanding_balance: number;
  artist_contact_info: {
    artist_name: string;
    artist_phone_number: string;
  } | null;
  pending_my_acceptance_or_rejection: boolean | null;
  is_engineer_accepting_bookings: boolean | null;
}

export interface SummarizedRecordingSession {
  id: number;
  first_choice_datetime: string;
  completed: string | null;
  second_choice_datetime: string | null;
  step: number;
  recording_location: RecordingLocation;
  session_duration_minutes: number;
  cancellation_policy?: CancellationPolicy;
  studio_room?: BasicStudioRoom;
  refunded: string | null;
  engineer_payment_amount_split: number;
  studio_manager_payment_amount_split: number;
  artist_contact_info: UserLite | null;
  engineer_username: string | null;
  engineer?: Engineer;
  project_id: number;
}

export interface SessionBookingDetails {
  id: number;
  recording_sessions: SummarizedRecordingSession[];
  users: UserLite[];
  pending_my_acceptance: boolean | null;
  cancelled: boolean | null;
}

export interface RecordingSessionBooking {
  id: number;
  created: string;
  deleted: string | null;
  recording_sessions: RecordingSession[];
  users: User[];
  projectAcceptance: ProjectAcceptance[];
}

export const MockCancellationPolicy: CancellationPolicy = {
  artist_cancellation_fee_percentage: 0.5,
  full_refund_cancellation_window_minutes: 120,
  full_refund_prior_to_cancellation_deadline_days: 3,
  partial_refund_prior_to_cancellation_deadline_hours: 24,
};
export const MockBasicRecordingSession: BasicRecordingSession = {
  id: 1,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 1,
  engineer_id: MockEngineer.id,
  first_choice_datetime: new Date().toUTCString(),
  second_choice_datetime: subDays(new Date(), 3).toUTCString(),
  engineer_goes_to_artist: null,
  session_duration_minutes: 60,
  unit_number: null,
  arrival_information: null,
  // Represents the RecordingSessionBooking id.
  recording_session_request_id: null,
  studio_manager_payment_amount_split: 0,
  engineer_payment_amount_split: 100,
  pending_booking_acceptance: false,
  studio_room: MockStudioRoom,
  outstanding_balance: 0,
};

export const MockRecordingSession1: RecordingSession = {
  artist: MockUser1,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2023-04-21 08:00:00+00",
  second_choice_datetime: "2023-04-21 08:00:00+00",
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 180,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Late in the day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: "2023-04-21 08:00:00+00",
  users: [],
  recording_session_request_id: 2,
  studio_manager_payment_amount_split: 200,
  engineer_payment_amount_split: 100,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

const firstChoiceDateLeadingIntoNextDay = "2022-01-09T06:00:00.406114";
const endDateTimeLeadingIntoNextDay = addHours(
  new Date(firstChoiceDateLeadingIntoNextDay),
  3,
).toISOString();
export const MockRecordingSession2: RecordingSession = {
  engineer: MockEngineer,
  artist: MockUser1,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 180,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 200,
  engineer_payment_amount_split: 100,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionBooking: RecordingSessionBooking = {
  id: 1,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  recording_sessions: [MockRecordingSession1, MockRecordingSession2],
  users: [MockUser, MockUser1],
  projectAcceptance: [
    {
      user_id: MockUser.id,
      accepted: null,
      deleted: null,
    },
    {
      user_id: MockUser1.id,
      accepted: null,
      deleted: null,
    },
  ],
};

export const MockRecordingSessionWithoutStudio: RecordingSession = {
  engineer: MockEngineerForRecording,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 0,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionWithoutStudio1: RecordingSession = {
  engineer: MockEngineerForRecording,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2022-01-13T23:59:06.406114",
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 0,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionWithStudio: RecordingSession = {
  engineer: MockEngineerForRecording,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2022-01-11T23:59:06.406114",
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 300,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  studio_room: MockStudioRoomForRecordingBreakdown,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionWithStudio1: RecordingSession = {
  engineer: MockEngineerForRecording,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2022-01-12T06:00:00.406114",
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 300,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  studio_room: MockStudioRoomForRecordingBreakdown,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionWithStudio2: RecordingSession = {
  engineer: MockEngineerForRecording,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2022-01-13T06:00:00.406114",
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 300,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  studio_room: MockStudioRoomForRecordingBreakdown,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};

export const MockRecordingSessionWithStudioNoEng: RecordingSession = {
  engineer: undefined,
  artist: MockArtist,
  created: "2022-01-08T23:59:06.406114",
  deleted: null,
  completed: null,
  refunded: null,
  project: MockRecordingProject,
  step: 0,
  first_choice_datetime: "2022-01-14T06:00:00.406114",
  second_choice_datetime: firstChoiceDateLeadingIntoNextDay,
  recording_location: {
    city_location: "123 Fake Street, Los Angeles, CA 90001",
  },
  session_duration_minutes: 210,
  engineer_goes_to_artist: "2022-01-08T23:59:06.406114",
  title: "Next day",
  unit_number: "123",
  arrival_information: "This is a test",
  cancellation_policy: MockCancellationPolicy,
  end_datetime: endDateTimeLeadingIntoNextDay,
  users: [MockUser1, MockUserForRecordingEngineer],
  recording_session_request_id: 3,
  studio_manager_payment_amount_split: 300,
  engineer_payment_amount_split: 350,
  engineer_id: MockEngineer.id,
  pending_booking_acceptance: false,
  studio_room: MockStudioRoomForRecordingBreakdown,
  outstanding_balance: 0,
  artist_contact_info: null,
  pending_my_acceptance_or_rejection: null,
  is_engineer_accepting_bookings: null,
};
