import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { Button } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { LocationInput } from "../LocationInput/LocationInput";
import { FieldInput } from "../SignInForm/SignInForm.styles";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { AuthNavigation } from "./AuthNavigation";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createOrDeleteStudioProfile,
  updateStudio,
  updateStudioProfileParams,
} from "../../../store/actions/studio";
import { toast } from "react-toastify";

const StudioLocation = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const [studioUsername, setStudioUsername] = useState("");
  const [showUsernameError, setShowUsernameError] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<updateStudioProfileParams>({
    studio_id: undefined,
    location: undefined,
  });
  const [creatingStudioProfile, setCreatingStudioProfile] =
    useState<boolean>(false);
  const { nextStep } = useAuthNavigationSteps();

  const handleCreateNewStudioProfile = useCallback(() => {
    if (creatingStudioProfile) return;
    if (!studioUsername || /\s/.test(studioUsername)) {
      return setShowUsernameError(true);
    }
    setCreatingStudioProfile(true);
    dispatch(
      createOrDeleteStudioProfile({
        studio_username: studioUsername,
        deleted: false,
      }),
    )
      .unwrap()
      .then((response) => {
        const updatedProfileData = {
          ...profileData,
          studio_id: response.id,
        };
        setProfileData(updatedProfileData);
        dispatch(updateStudio(updatedProfileData))
          .unwrap()
          .then(() => {
            nextStep();
          })
          .catch(() => {
            toast.error("Failed to set location.");
          });
      })
      .catch(() => {
        toast.error("Failed to set username.");
      })
      .finally(() => {
        setCreatingStudioProfile(false);
      });
  }, [studioUsername, user, dispatch, profileData, creatingStudioProfile]);

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>
          Get your studio profile started
        </Text>
        <Box sx={{ width: "100%" }}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Studio Username</Text>
          <Box marginTop="10px">
            <FieldInput
              sx={{ width: "100%" }}
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, "");
                setStudioUsername(value);
              }}
              size="small"
              disabled={creatingStudioProfile}
              error={showUsernameError}
              helperText={
                showUsernameError
                  ? "May contain only letters, numbers, and @/./+/-/_"
                  : ""
              }
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Studio address</Text>
          <Box marginTop="10px">
            <LocationInput
              defaultValue=""
              onPlaceSelected={(placeResult) => {
                setProfileData((currentData) => ({
                  ...currentData,
                  location: placeResult,
                }));
              }}
              showBorder
            />
          </Box>
        </Box>
        <Button
          style={{ width: "100%" }}
          onClick={handleCreateNewStudioProfile}
          loading={creatingStudioProfile}
          disabled={!studioUsername.length || !profileData.location}
        >
          Create my studio
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
export default StudioLocation;
