import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  ShareLinkParams,
  getUploadLinkProject,
} from "../../../store/actions/projectsMap";
import { useAppDispatch, useProjectFromProjectMap } from "../../../store/hooks";
import {
  MixingProjectSteps,
  ProjectType,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import {
  getMixingMasteringProject,
  getProjectWorkflowStep,
} from "../../../utils/projectUtils";
import AppScreenContent from "../../components/AppScreenContent/AppScreenContent";
import { MainProjectWorkflowPanel } from "../../components/MainProjectWorkflowPanel/MainProjectWorkflowPanel";
import { ProjectActionUploadProjectFiles } from "../../components/ProjectWorkflowPanel/components/ProjectWorkflowTasksRow/ProjectWorkflowActions/ProjectActionUploadProjectFiles";
import {
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../core-ui/components/Text/Text";

export const LoggedOutUploadScreen = () => {
  const { project_id: projectId } = useParams<{ project_id: string }>();
  const project = useProjectFromProjectMap(isNaN(+projectId) ? -1 : +projectId);
  const mixingMasteringProject = getMixingMasteringProject(project);
  const projectStep =
    mixingMasteringProject?.step ?? MixingProjectSteps.PROJECT_ACCEPTED;
  const projectType = project?.service_type ?? ProjectType.MIXING;
  const projectWorkflowStep = getProjectWorkflowStep(projectType, projectStep);

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code: null | string = query.get("code");

  useSetPageTitle("Project View");

  useEffect(() => {
    if (!code) return;
    if (isNaN(+projectId)) {
      return;
    }
    const args: ShareLinkParams = { code };
    args.projectId = +projectId;
    void dispatch(getUploadLinkProject(args));
  }, [code, dispatch, projectId]);

  if (!code) return null;

  if (!project) return null;

  if (projectWorkflowStep !== ProjectWorkflowSteps.FILE_TRANSFER) {
    return (
      <AppScreenContent className={"container-fluid centered-screen"}>
        <Text
          size={TEXT_SIZE.LARGE}
          weight={TEXT_WEIGHT.BOLD}
          style={{ marginBottom: "16px", textAlign: "center" }}
        >
          No pending uploads required
        </Text>
        <ProjectActionUploadProjectFiles
          isButton
          projectId={project.id}
          projectType={projectType}
          workflowStep={projectWorkflowStep}
        />
      </AppScreenContent>
    );
  }

  return (
    <AppScreenContent
      style={{ overflowX: "hidden" }}
      className="container-fluid"
    >
      <div className="container">
        <MainProjectWorkflowPanel
          albumTitleOverride={project.title}
          isOpen={true}
          projectId={project.id}
          noSidePanel={true}
          onClose={() => {}}
          hideTrackButtons={true}
          code={code}
        />
      </div>
      <DevLinkProvider>
        <EngineEarsFooter2024 />
      </DevLinkProvider>
    </AppScreenContent>
  );
};

export default LoggedOutUploadScreen;
