import { useMemo } from "react";
import { useAppSelector } from "../../../store/hooks";
import { LivestreamOverlay, LivestreamWrapper } from "./Livestream.styles";
import { UnauthenticatedModal } from "../../components/UnauthenticatedModal/UnauthenticatedModal";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";

export interface YoutubeLivestreamScreenParams {
  videoId?: string;
}

export const YoutubeLivestreamScreen = ({
  videoId = "",
}: YoutubeLivestreamScreenParams) => {
  const videoIdFlagData: string = videoId;
  const { isDesktop } = useMediaQueryBreakpoint();
  const { user, isAuthenticated } = useAppSelector(
    (state) => state.accountInfo,
  );

  const hasViewPermissions = useMemo(() => {
    if (user?.engineer?.verified || user?.engineer?.started_onboarding)
      return true;
    return false;
  }, [user]);

  return (
    <LivestreamWrapper>
      {!hasViewPermissions && (
        <>
          <LivestreamOverlay $blur="50px" />
          <UnauthenticatedModal
            showModal={!isAuthenticated}
            closeModal={() => {}}
            showCloseButton={false}
            message={"Please sign in to join the stream"}
          />
          <BaseModal
            open={!videoIdFlagData || (isAuthenticated && !hasViewPermissions)}
            setOpen={() => {}}
            header={"Livestream Unavailable"}
            showCloseButton={false}
          >
            <div style={{ width: "100%" }}>
              <p className="h7-semi-bold unauthenticated-modal-header mb-3">
                This livestream is{" "}
                {videoIdFlagData
                  ? `available to only Gold and Platinum members`
                  : `currently unavailable`}
                .
              </p>
            </div>
          </BaseModal>
        </>
      )}
      <iframe
        src={`https://www.youtube-nocookie.com/embed/${videoIdFlagData}?autoplay=1`}
        title="Engineears Livestream"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        width={isDesktop ? "75%" : "100%"}
        height="100%"
      ></iframe>
      {isDesktop && (
        <iframe
          width="25%"
          frameBorder="0"
          height="100%"
          src={`https://www.youtube.com/live_chat?v=${videoIdFlagData}&embed_domain=${window.location.hostname}`}
        ></iframe>
      )}
    </LivestreamWrapper>
  );
};

export default YoutubeLivestreamScreen;
