import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import "./ExpandableTextArea.css";

export interface ExpandableTextAreaProps {
  header?: string;
  body: string;
  maxHeight?: number;
}

export const ExpandableTextArea = ({
  header,
  body,
  maxHeight,
}: ExpandableTextAreaProps) => {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const textRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setClamped(!clamped);
  };

  // If the window resizes, re-compute whether we need to show the
  // "Show More" button or not. Inspiration taken from:
  // https://plainenglish.io/blog/creating-a-read-more-collapsible-component-in-reactjs-6d55fbc4ff0f
  useEffect(() => {
    const hasClamping = (textElement: HTMLElement) => {
      const { clientHeight, scrollHeight } = textElement;
      return clientHeight < scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (textRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass =
          textRef.current.classList.contains("body-text-clamped");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) {
          textRef.current.classList.add("body-text-clamped");
        }
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(textRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) {
          textRef.current.classList.remove("body-text-clamped");
        }
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [textRef.current]);

  const textAreaClassNames = classNames({
    "body-text": true,
    "body-text-clamped": clamped,
    "body-text-fade": clamped && showButton,
  });

  return (
    <div className="expandable-text-area">
      {header && <p className="collapsable-header">{header.toUpperCase()}</p>}
      <div
        className={textAreaClassNames}
        ref={textRef}
        style={{ maxHeight: clamped ? maxHeight ?? "none" : "unset" }}
      >
        {body}
      </div>
      {showButton && (
        <div className="extend-text-container" onClick={handleClick}>
          <span className={classNames("extend-text", "h8-semi-bold")}>
            {clamped ? "Read More" : "Hide"}
          </span>
          <FontAwesomeIcon icon={clamped ? faChevronDown : faChevronUp} />
        </div>
      )}
    </div>
  );
};
