import { useState, useEffect, ChangeEvent } from "react";
import classNames from "classnames";
import "./ToggleSwitch.css";

export interface ToggleSwitchProps {
  id?: string;
  label?: string | JSX.Element;
  checked?: boolean;
  onChange?: (checkedState: boolean) => void;
  updateCheckedLocally?: boolean;
  disabled?: boolean;
  size?: TOGGLE_SWITCH_SIZE;
  labelClassName?: string;
  disableBackgroundColor?: boolean;
}

export enum TOGGLE_SWITCH_SIZE {
  LARGE = "large",
  SMALL = "small",
  MEDIUM = "medium",
}

export const ToggleSwitch = ({
  id,
  checked,
  onChange,
  label,
  updateCheckedLocally = true,
  disabled = false,
  size = TOGGLE_SWITCH_SIZE.LARGE,
  labelClassName,
  disableBackgroundColor = false,
}: ToggleSwitchProps) => {
  const [checkedState, setCheckedState] = useState(checked || false);

  useEffect(() => {
    if (checked === undefined) return;
    if (updateCheckedLocally) return;
    setCheckedState(checked);
  }, [checked, updateCheckedLocally]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked);
    if (updateCheckedLocally) setCheckedState(e.target.checked);
  };

  return (
    <form className="d-flex justify-content-start align-items-center">
      <label
        className={classNames("switch", {
          "toggle-switch-small": size === TOGGLE_SWITCH_SIZE.SMALL,
          "toggle-switch-medium": size === TOGGLE_SWITCH_SIZE.MEDIUM,
          "toggle-switch-background-color-disabled": disableBackgroundColor,
        })}
      >
        <input
          disabled={disabled}
          id={id}
          type="checkbox"
          checked={checkedState}
          onChange={onChangeHandler}
        />
        <span
          className={classNames("slider", "round", {
            "toggle-slider-small": size === TOGGLE_SWITCH_SIZE.SMALL,
            "toggle-slider-medium": size === TOGGLE_SWITCH_SIZE.MEDIUM,
            disabled,
          })}
        ></span>
      </label>
      {label && (
        <label className={classNames("mx-2", labelClassName)} htmlFor={id}>
          {label}
        </label>
      )}
    </form>
  );
};
