import { useMediaQuery, useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { FC } from "react";
import { AuthFlows } from "../../../../constants/authSteps";
import { SCREENS } from "../../../../routes";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { authModalOpenAtom, currentFlowAtom } from "../../Auth/atoms";
import "./NavLoginContainer.css";

interface NavLoginContainerProps {
  column?: boolean;
  onClick?: () => void;
  showSignUpButton?: boolean;
}

export const NavLoginContainer: FC<NavLoginContainerProps> = ({
  column = false,
  onClick,
  showSignUpButton = true,
}: NavLoginContainerProps) => {
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <div
      className={`nav-login-container ${column ? "nav-login-container--column" : ""}`}
    >
      {showSignUpButton && (
        <Button
          className={`nav-login-button ${column ? "nav-link-secondary--column" : "nav-link-secondary"}`}
          fullWidth
          href={isMobile ? SCREENS.AUTH_SCREEN : ""}
          onClick={() => {
            emitAnalyticsTrackingEvent("clicked_sign_up", {}, undefined);
            setFlow(AuthFlows.START_SIGNUP);
            setAuthModalOpen(true);
            if (onClick) {
              onClick();
            }
          }}
          variant={ButtonVariant.OUTLINED}
        >
          Sign up
        </Button>
      )}
      <Button
        className="nav-login-button"
        fullWidth
        href={isMobile ? SCREENS.AUTH_SCREEN : ""}
        onClick={() => {
          emitAnalyticsTrackingEvent("clicked_login", {}, undefined);
          setFlow(AuthFlows.LOGIN);
          setAuthModalOpen(true);
          if (onClick) {
            onClick();
          }
        }}
      >
        Login
      </Button>
    </div>
  );
};
