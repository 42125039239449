import { useMemo } from "react";
import User from "../store/models/user";

export const useIsAdmin = (user: User | undefined): boolean => {
  return useMemo(() => {
    if (!user) {
      return false;
    }
    return Boolean(user.admin && !user.admin?.deleted);
  }, [user]);
};
