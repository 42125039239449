import User from "../models/user";
import headerLogo from "../../stories/assets/header-logo.svg";
import musoLogo from "../../stories/assets/powered-by-muso.svg";
import { MockArtist } from "./artist";
import { MusoCredit } from "./muso";
import { Project } from "./project";

export const PORTFOLIO_FEATURE_LIMIT = 4;

export enum FeaturedTrackRequestStatus {
  FEATURED_TRACK_STATUS_UNKNOWN,
  FEATURED_TRACK_PENDING,
  FEATURED_TRACK_ACCEPTED,
  FEATURED_TRACK_REJECTED,
}

export enum FEATURE_UPLOADER {
  NONE,
  ENGINEEARS,
  MUSO,
  OTHER,
}

export interface FeatureUploaderDetails {
  logo: string;
  type: string;
}

export const featureUploaderMap = new Map<
  FEATURE_UPLOADER,
  FeatureUploaderDetails
>([
  [
    FEATURE_UPLOADER.ENGINEEARS,
    {
      logo: headerLogo,
      type: "Completed Projects",
    },
  ],
  [
    FEATURE_UPLOADER.MUSO,
    {
      logo: musoLogo,
      type: "Credited Songs",
    },
  ],
  [
    FEATURE_UPLOADER.OTHER,
    {
      logo: "",
      type: "Files",
    },
  ],
]);

export interface PortfolioFeatureDetails {
  id?: number;
  artist_name?: string;
  description?: string;
  song_name?: string;
  album_name?: string;
  project?: Project;
}

export interface FeaturedTrackArtwork {
  featured_track_id: number;
  created: string;
  deleted: null | string;
  path: string;
}

export interface PortfolioFeatureData {
  id?: number;
  user?: User;
  user_id?: number;
  title: string;
  artist: string;
  album?: string;
  sort_index: number;
  created?: string;
  deleted?: null | string;
  approved?: boolean;
  muso_credit?: MusoCredit;
  track_details?: PortfolioFeatureDetails;
  spotify_track_id?: string;
  spotify_preview_url?: string;
  featured_track_artwork?: FeaturedTrackArtwork;
  description?: string;
}

export interface PortfolioFeatureRequestData {
  id: number;
  approver_user_id: number;
  featured_track: PortfolioFeatureData;
  status: FeaturedTrackRequestStatus;
}

export const transformRawPortfolioData = (
  data: PortfolioFeatureData,
): PortfolioFeatureData => {
  const musoCredit = data.muso_credit;
  const trackDetails = data.track_details;
  return {
    id: data.id,
    user: data.user,
    user_id: data.user?.id,
    title: trackDetails
      ? trackDetails.song_name
        ? trackDetails.song_name
        : ""
      : musoCredit
        ? musoCredit.track_title
        : "",
    artist: trackDetails
      ? trackDetails.artist_name
        ? trackDetails.artist_name
        : ""
      : musoCredit
        ? musoCredit.artist.split(",").reduce((previousValue, value) => {
            return value ? previousValue + ", " + value : "";
          })
        : "",
    album: trackDetails
      ? trackDetails.album_name
      : musoCredit
        ? musoCredit.album_title
        : "",
    sort_index: data.id!,
    created: data.created,
    deleted: data.deleted,
    approved: musoCredit ? true : data.approved,
    description: trackDetails?.description,
    muso_credit: musoCredit,
    track_details: trackDetails,
    featured_track_artwork: data.featured_track_artwork,
    // Prioritize the Spotify track and preview url values on the MusoCredit model,
    // as the fields on the FeaturedTrack model are deprecated.
    spotify_track_id: musoCredit?.spotify_track_id ?? data.spotify_track_id,
    spotify_preview_url:
      musoCredit?.spotify_preview_url ?? data.spotify_preview_url,
  };
};

export const MockFeaturesList: PortfolioFeatureData[] = [
  "The Heart Part 5",
  "United In Grief",
  "Worldwide Steppers",
].map((song_name, sort_index) => {
  return {
    sort_index: sort_index,
    title: song_name,
    artist: "Kedrick Lamar",
    album: "Mr.Morale & The Big Steppers",
    created: "05/10/22",
    deleted: null,
    description: "Pglang4",
    track_details: {
      song_name,
      artist_name: "Kedrick Lamar",
    },
    id: sort_index,
  };
});

export const MockPortfolioFeatureData: PortfolioFeatureData =
  MockFeaturesList[1];

export const MockfeatureProjectsSelection: PortfolioFeatureData[] = [
  0, 1, 2, 3, 4, 5,
].map(() => MockPortfolioFeatureData);

export const MockPortfolioFeatureRequestData: PortfolioFeatureRequestData = {
  id: 0,
  approver_user_id: MockArtist.id,
  status: FeaturedTrackRequestStatus.FEATURED_TRACK_PENDING,
  featured_track: MockFeaturesList[0],
};
