import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  useRef,
} from "react";
import "./CheckBox.css";
import { Text } from "../Text/Text";

export enum CheckBoxVariant {
  PRIMARY = "primary",
  OUTLINED = "outlined",
}

export type CheckBoxProps = {
  label?: string | React.ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  variant?: CheckBoxVariant;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  "id" | "checked" | "className" | "disabled" | "onClick" | "style"
>;

/**
 * @TODO - Refactor this to be a Radix styled-component
 * As of Feb 2024, this is the newest Checkbox component.
 * It has minimal styling and can be easily customized.
 * Feel free to extend the props as needed.
 */
export const CheckBox: FC<CheckBoxProps> = ({
  id,
  className,
  checked,
  disabled,
  label,
  onClick,
  style,
  variant = CheckBoxVariant.PRIMARY,
  onChange,
}: CheckBoxProps) => {
  const checkColor =
    variant === CheckBoxVariant.PRIMARY ? "var(--white-0)" : "var(--white-0)";

  const checkColorDisabled =
    variant === CheckBoxVariant.PRIMARY ? "var(--white-0)" : "var(--white-0)";

  const checkboxRef = useRef<HTMLInputElement | null>(null);
  // The onClick is necessary to allow the label to toggle the checkbox
  // Without the onChange, then the checkbox input is considered uncontrolled and gives errors in the console
  // The longterm solution is to build this component properly with a library or advanced styling
  const defaultOnChange = (e: ChangeEvent) => e.preventDefault();

  return (
    <label className="checkbox-label">
      <div
        className={classNames(
          `checkbox-container-${variant}`,
          { checked, disabled },
          className,
        )}
        onClick={(e) => {
          e.preventDefault();
          checkboxRef?.current?.click();
        }}
        style={style}
      >
        <input
          id={id}
          ref={checkboxRef}
          className="checkbox-input"
          checked={checked}
          onChange={onChange ?? defaultOnChange}
          onClick={onClick}
          type="checkbox"
          disabled={disabled}
        />
        {checked && (
          <FontAwesomeIcon
            icon={faCheck}
            color={disabled ? checkColorDisabled : checkColor}
            size="xs"
          />
        )}
      </div>
      <Text>{label}</Text>
    </label>
  );
};
