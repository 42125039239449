import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { MixMasterOrderSummary } from "../../../store/actions/mixMasterCartsStore";
import { RecordingOrderSummary } from "../../../store/actions/recordingCartsStore";
import { SubmitBillingInfoParams } from "../../../store/actions/transactions";
import { ColorPalette } from "../../theme";
import {
  BillingInfoVerificationView,
  BudgetManagerModalBoldP,
  BudgetManagerModalP,
} from "../BudgetManagerModals/BudgetManagerModals.styles";
import { OrderBreakdown } from "../OrderBreakdown/OrderBreakdown";
import {
  RequestBudgetApprovalModalBodyContainer,
  RequestBudgetApprovalModalText,
} from "../RequestBudgetApprovalModal/RequestBudgetApprovalModal.styles";
import {
  BillingInfoInput,
  BillingInfoInputContainer,
  SuccessTextContainer,
} from "./BillingInfoModals.styles";
import { PurchaseOrderBillingInfoSteps } from "./utils";

interface BillingInfoModalBodyProps {
  step: PurchaseOrderBillingInfoSteps;
  submittingPurchaseOrder: boolean;
  projectAdminEmails: string[];
  billingInfoParams: SubmitBillingInfoParams;
  recordingOrderSummaryIsLoading: boolean;
  mixMasterOrderSummaryIsLoading: boolean;
  recordingOrderSummary: RecordingOrderSummary | null;
  mixMasterOrderSummary: MixMasterOrderSummary | null;
  setBillingInfoParams: Dispatch<SetStateAction<SubmitBillingInfoParams>>;
}

const BillingInfoModalBody = ({
  step,
  submittingPurchaseOrder,
  projectAdminEmails,
  billingInfoParams,
  recordingOrderSummaryIsLoading,
  mixMasterOrderSummaryIsLoading,
  recordingOrderSummary,
  mixMasterOrderSummary,
  setBillingInfoParams,
}: BillingInfoModalBodyProps) => {
  if (step === PurchaseOrderBillingInfoSteps.BILLING_INFO_SUBMITTED) {
    return (
      <>
        <SuccessTextContainer>
          <FontAwesomeIcon icon={faCheck} color={ColorPalette.Green600} />
          <RequestBudgetApprovalModalText isBold>
            {submittingPurchaseOrder
              ? `Purchase Order submitted.`
              : `Billing information submitted.`}
          </RequestBudgetApprovalModalText>
        </SuccessTextContainer>
        <RequestBudgetApprovalModalBodyContainer>
          <RequestBudgetApprovalModalText isBold>
            Project Adminstrators:
          </RequestBudgetApprovalModalText>
          {projectAdminEmails.map((email, index) => (
            <RequestBudgetApprovalModalText key={index}>
              {email}
            </RequestBudgetApprovalModalText>
          ))}
        </RequestBudgetApprovalModalBodyContainer>
        <BudgetManagerModalP>No further actions required.</BudgetManagerModalP>
      </>
    );
  }
  if (step === PurchaseOrderBillingInfoSteps.CONFIRM_BILLING_INFO) {
    return (
      <>
        <BudgetManagerModalP>
          {submittingPurchaseOrder
            ? "Is the following Purchase Order correct?"
            : "Is the following billing information correct?"}
        </BudgetManagerModalP>
        {submittingPurchaseOrder ? (
          <BillingInfoVerificationView>
            <BudgetManagerModalBoldP>Purchase Order:</BudgetManagerModalBoldP>
            <BudgetManagerModalP>
              {billingInfoParams.order_number}
            </BudgetManagerModalP>
          </BillingInfoVerificationView>
        ) : (
          <BillingInfoVerificationView>
            <BudgetManagerModalBoldP>Cost Center:</BudgetManagerModalBoldP>
            <BudgetManagerModalP>
              {billingInfoParams.cost_center}
            </BudgetManagerModalP>
            <BudgetManagerModalBoldP>WBS:</BudgetManagerModalBoldP>
            <BudgetManagerModalP>
              {billingInfoParams.work_breakdown_structure}
            </BudgetManagerModalP>
            <BudgetManagerModalBoldP>GL:</BudgetManagerModalBoldP>
            <BudgetManagerModalP>
              {billingInfoParams.general_ledger}
            </BudgetManagerModalP>
          </BillingInfoVerificationView>
        )}
      </>
    );
  }

  return (
    <>
      <BudgetManagerModalBoldP>
        {submittingPurchaseOrder
          ? "Please review the project price below and submit purchase order."
          : "Please review the project price below and input billing information below."}
      </BudgetManagerModalBoldP>
      <OrderBreakdown
        showLabelToggle={false}
        isLoading={
          recordingOrderSummaryIsLoading || mixMasterOrderSummaryIsLoading
        }
        showHeader={false}
        orderSummary={recordingOrderSummary ?? mixMasterOrderSummary}
      />
      <BudgetManagerModalP>
        {submittingPurchaseOrder
          ? "Input purchase order below:"
          : "Input direct billing information below:"}
      </BudgetManagerModalP>
      {submittingPurchaseOrder ? (
        <BillingInfoInputContainer>
          <BillingInfoInput
            placeholder={"Purchase order"}
            value={billingInfoParams.order_number}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setBillingInfoParams((currBillingInfo) => ({
                ...currBillingInfo,
                order_number: e.target.value,
              }));
            }}
          />
        </BillingInfoInputContainer>
      ) : (
        <BillingInfoInputContainer>
          <BillingInfoInput
            placeholder={"Cost center"}
            value={billingInfoParams.cost_center}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setBillingInfoParams((currBillingInfo) => ({
                ...currBillingInfo,
                cost_center: e.target.value,
              }));
            }}
          />
          <BillingInfoInput
            placeholder={"WBS"}
            value={billingInfoParams.work_breakdown_structure}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setBillingInfoParams((currBillingInfo) => ({
                ...currBillingInfo,
                work_breakdown_structure: e.target.value,
              }));
            }}
          />
          <BillingInfoInput
            placeholder={"GL"}
            value={billingInfoParams.general_ledger}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setBillingInfoParams((currBillingInfo) => ({
                ...currBillingInfo,
                general_ledger: e.target.value,
              }));
            }}
          />
        </BillingInfoInputContainer>
      )}
    </>
  );
};

export default BillingInfoModalBody;
