import { Tab, Tabs as MuiTabs } from "@mui/material";
import { styled } from "@mui/material";
import { ColorPalette } from "../../theme";

export const StyledTabs = styled(MuiTabs)(() => ({
  backgroundColor: "var(--smooth-ivory)",
  "& .MuiTabs-indicator": {
    backgroundColor: ColorPalette.BoomyOrange400,
    height: "4px",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: "30px",
  padding: "8px",
  textTransform: "unset",
  "& p": {
    fontWeight: "600",
    fontSize: "1rem",
    color: "var(--gray-500)",
  },
  "&.Mui-selected": {
    "& p": {
      color: "var(--black)",
    },
  },
  [theme.breakpoints.only("xs")]: {
    "& p": {
      fontSize: "0.75rem",
    },
  },
  [theme.breakpoints.up("lg")]: {
    padding: "16px",
    "& p": {
      fontSize: "1.5rem",
    },
  },
}));
