import React, { useEffect, useState } from "react";
import "./FixedRangeNumberField.css";

export interface FixedRangeNumberFieldProps {
  className?: string;
  initialValue?: number;
  placeholder?: string;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  parseAsFloat?: boolean;
  isCurrentValueAsync?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const FixedRangeNumberField = ({
  className,
  initialValue,
  placeholder,
  min = 0,
  max = 100,
  onChange,
  parseAsFloat,
  isCurrentValueAsync = false,
  style = { width: 80 },
  disabled,
  ...props
}: FixedRangeNumberFieldProps) => {
  const [value, setValue] = useState<number>(initialValue ? initialValue : 0);

  useEffect(() => {
    if (!isCurrentValueAsync) return;
    setValue(initialValue ?? min);
  }, [initialValue, isCurrentValueAsync]);

  return (
    <div>
      <input
        style={style}
        disabled={disabled}
        className={className ?? "textfield"}
        name="text"
        type="number"
        value={parseAsFloat ? value : Math.round(value)}
        {...props}
        placeholder={placeholder}
        onBlur={() => {
          if (Number.isNaN(value) || value < min) {
            setValue(min);
            if (onChange) onChange(min);
          }
        }}
        onChange={(e) => {
          let valueAsNumber = parseAsFloat
            ? parseFloat(e.target.value)
            : parseInt(e.target.value);
          if (valueAsNumber > max) {
            valueAsNumber = max;
          }
          setValue(valueAsNumber);
          if (onChange) {
            onChange(valueAsNumber);
          }
        }}
      />
    </div>
  );
};
