import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import queryString from "query-string";
import { REHYDRATE, RehydrateAction } from "redux-persist";
import { QueryParamsType } from "..";
import {
  determineIfProjectType,
  isProjectInReviewStepOrBeyond,
} from "../../utils/projectUtils";

import { FileVersion } from "../models/fileVersion";
import { PortfolioFeatureData } from "../models/portfolio";
import { Project, ProjectById } from "../models/project";
import { determineIfPortfolioFeatureData } from "../selectors/abPlayerSelectors";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { PLAYLIST } from "../utils/routes";
import { downloadGeneratedMP3Track, downloadTrack } from "./audioService";
import { receiveErrors } from "./errorStore";
import { updateScheduledProjectsTracks } from "./scheduledprojects";

export enum FooterFileTrackType {
  NONE,
  AB_INSTANCE,
  AB_SNIPPET,
  SPOTIFY_SNIPPET,
  COMPLETED_PROJECT,
  SCHEDULED_PROJECT,
}

export const DEFAULT_REGION_CREATION_DURATION = 10;

interface DownloadedTrack {
  [project_id: number]: {
    isTrackBlobUrlLoading?: boolean;
    trackBlobUrl?: string | null;
    isTrackGeneratedMP3BlobUrlLoading?: boolean;
    trackGeneratedMP3BlobUrl?: string | null;
  };
}
interface AbPlayerState {
  selectedTrack?: FileVersion;
  currentPosition: number;
  seekToValue?: number;
  isLooping: boolean;
  showFooterPlayer: boolean;
  // represents the track in the A/B player that the user has manually selected to listen to.
  // selectedTrack above can be changed by the lock screen controls.
  focusedTrack?: FileVersion;
  url?: string;
  trackedPlayerId?: number;
  isFooterPlaying: boolean;
  isFooterReady: boolean;
  projectId?: number;
  refUrl?: string;
  mainPlayerId?: number;
  refPlayerId?: number;
  mainUrl?: string;
  abState?: boolean;
  footerFileTrackType: FooterFileTrackType;
  isRehydrating: boolean;
  playlist: (Project | ProjectById | PortfolioFeatureData)[];
  currentTrackIndex: number;
  playlistId?: number;
  downloadedPlaylistTrack: DownloadedTrack;
  playOnLoad: boolean;
  loopStartTime?: number;
  loopEndTime?: number;
  loopRegionId?: string;
  previousFile: FileVersion | null;
  nextFile: FileVersion | null;
}

const initialState: AbPlayerState = {
  selectedTrack: undefined,
  currentPosition: 0,
  seekToValue: undefined,
  focusedTrack: undefined,
  isLooping: false,
  showFooterPlayer: false,
  isFooterPlaying: false,
  isFooterReady: false,
  abState: true,
  footerFileTrackType: FooterFileTrackType.NONE,
  isRehydrating: false,
  playlist: [],
  playOnLoad: false,
  currentTrackIndex: -1,
  downloadedPlaylistTrack: {},
  previousFile: null,
  nextFile: null,
};

interface SelectFileActionType {
  track: FileVersion | undefined;
  isFocused: boolean;
  playOnLoad?: boolean;
  abState?: boolean;
}

interface GetPlaylistParams {
  scheduled_project_id?: number;
  project_id?: number;
}

export const getPlaylist = createAsyncThunk(
  PLAYLIST,
  async ({ scheduled_project_id, project_id }: GetPlaylistParams, thunkAPI) => {
    const getPlaylistArgs: QueryParamsType = {};
    if (scheduled_project_id) {
      getPlaylistArgs.scheduled_project_id = scheduled_project_id;
    }
    if (project_id) {
      getPlaylistArgs.project_id = project_id;
    }
    const params = `?${queryString.stringify(getPlaylistArgs)}`;

    const response = await makeBackendGetCallWithJsonResponse<
      (Project | ProjectById | PortfolioFeatureData)[]
    >(PLAYLIST, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const abPlayerStore = createSlice({
  name: "abPlayerStore",
  initialState,
  reducers: {
    setSeekTo: (state, action: PayloadAction<number | undefined>) => {
      state.seekToValue = action.payload;
    },
    setCurrentPosition: (state, action: PayloadAction<number>) => {
      state.currentPosition = action.payload;
    },
    selectFile: (state, action: PayloadAction<SelectFileActionType>) => {
      const { track, isFocused, playOnLoad } = action.payload;
      state.selectedTrack = track;
      if (isFocused) {
        state.focusedTrack = track;
      }
      state.playOnLoad = playOnLoad || false;
      state.abState = action.payload.abState;
    },
    setIsLooping: (
      state,
      action: PayloadAction<{
        isLooping: boolean;
        loopStartTime?: number;
        loopEndTime?: number;
        loopRegionId?: string;
      }>,
    ) => {
      const { isLooping, loopStartTime, loopEndTime, loopRegionId } =
        action.payload;
      if (isLooping && loopStartTime !== undefined) {
        state.isLooping = isLooping;
        state.loopStartTime = loopStartTime;
        state.loopEndTime = loopEndTime
          ? loopEndTime
          : loopStartTime + DEFAULT_REGION_CREATION_DURATION;
        state.loopRegionId = loopRegionId;
      } else {
        state.isLooping = isLooping;
        state.loopStartTime = undefined;
        state.loopEndTime = undefined;
        state.loopRegionId = undefined;
      }
    },
    resetAbPlayStore: () => {
      return initialState;
    },
    setShowFooterPlayer: (state, action: PayloadAction<boolean>) => {
      state.showFooterPlayer = action.payload;
    },
    setIsFooterPlaying: (state, action: PayloadAction<boolean>) => {
      state.isFooterPlaying = action.payload;
    },
    setIsFooterReady: (state, action: PayloadAction<boolean>) => {
      state.isFooterReady = action.payload;
      if (!action.payload) state.isFooterPlaying = action.payload;
    },
    setFooterIsActive: (state, action: PayloadAction<boolean>) => {
      state.isFooterReady = action.payload;
      state.showFooterPlayer = action.payload;
    },
    // setLocalPlayer should only be used for single tracks.
    setLocalPlayer: (
      state,
      action: PayloadAction<{
        url?: string | undefined;
        footerFileTrackType?: FooterFileTrackType;
        trackedPlayerId?: number;
        keepPosition?: boolean;
        file?: FileVersion | null;
        abState?: boolean;
        loadedTrack?: Project | ProjectById | PortfolioFeatureData;
        playOnLoad?: boolean;
      }>,
    ) => {
      state.url = action.payload.url;
      state.trackedPlayerId = action.payload.trackedPlayerId;
      if (!action.payload.keepPosition) {
        state.currentPosition = 0;
        state.refUrl = undefined;
        state.mainPlayerId = undefined;
        state.refPlayerId = undefined;
        state.mainUrl = undefined;
      }
      if (action.payload.playOnLoad) {
        state.playOnLoad = action.payload.playOnLoad;
      }
      if (action.payload.footerFileTrackType) {
        state.footerFileTrackType = action.payload.footerFileTrackType;
      }
      // Selected Track is used for the track table to handle file swapping.
      if (action.payload.file) state.selectedTrack = action.payload.file;

      state.abState = action.payload.abState;
      if (!action.payload.loadedTrack) return;
      // The loaded track would be stored on the playlist.
      // We need to set the project id to the project id of the loaded track.
      if ("completed" in action.payload.loadedTrack) {
        if (state.projectId !== action.payload.loadedTrack.id) {
          state.currentPosition = 0;
        }
        state.projectId = action.payload.loadedTrack.id;
      }
      if (
        "track_details" in action.payload.loadedTrack &&
        action.payload.loadedTrack.track_details
      ) {
        state.projectId = action.payload.loadedTrack.track_details?.project?.id;
      }
      state.playlist = [action.payload.loadedTrack];
      state.currentTrackIndex = 0;
    },
    setPlaylist: (
      state,
      action: PayloadAction<{
        tracks: (Project | ProjectById | PortfolioFeatureData)[];
        playlistId: number; // This is a generic field which could be scheduledProjectId or playlistId
        index?: number; // Optional field to determine where to start playing from.
        playOnLoad?: boolean;
      }>,
    ) => {
      state.previousFile = null;
      state.nextFile = null;
      const { tracks, playlistId, index, playOnLoad } = action.payload;
      const tracksToLoad = tracks.filter((track) => {
        if (determineIfProjectType(track)) {
          return isProjectInReviewStepOrBeyond(track.service_type, track.step);
        }
        return true;
      });
      if (state.playlistId !== playlistId && state.playlist.length > 0) {
        state.playlist.map((track) => {
          if (track?.id) {
            delete state.downloadedPlaylistTrack[track.id];
          }
        });
      }
      const updatedState = {
        ...initialState,
        playOnLoad: playOnLoad || false,
        currentPosition: 0,
        footerFileTrackType: FooterFileTrackType.SCHEDULED_PROJECT,
        playlist: tracksToLoad,
        currentTrackIndex: index || 0,
        playlistId,
        showFooterPlayer: state.showFooterPlayer,
        downloadedPlaylistTrack: state.downloadedPlaylistTrack,
      };
      Object.assign(state, updatedState);
    },
    setMainPlayer: (
      state,
      action: PayloadAction<{ id: number; url: string }>,
    ) => {
      state.mainPlayerId = action.payload.id;
      state.mainUrl = action.payload.url;
    },
    setRefPlayer: (
      state,
      action: PayloadAction<{ id: number; url: string }>,
    ) => {
      state.refUrl = action.payload.url;
      state.refPlayerId = action.payload.id;
    },
    setUrl: (state, action: PayloadAction<{ url: string | undefined }>) => {
      state.url = action.payload.url;
    },
    setIsRehydrating: (state, action: PayloadAction<boolean>) => {
      state.isRehydrating = action.payload;
    },
    setIndex: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (state.playlist.length === 0) return;
      if (index > state.playlist.length - 1 || index < 0) return;
      const previousTrackIndex = state.currentTrackIndex;
      state.playlist.forEach((track, i) => {
        if (index === i || previousTrackIndex === i) {
          return;
        }
        if (!determineIfPortfolioFeatureData(track)) {
          delete state.downloadedPlaylistTrack[track.id];
        }
      });
      state.playOnLoad = true;
      state.currentPosition = 0;
      state.currentTrackIndex = index;
    },
    setProjectInfo: (
      state,
      action: PayloadAction<{
        project: Project | PortfolioFeatureData | ProjectById;
      }>,
    ) => {
      state.playlist = [action.payload.project];
      state.currentTrackIndex = 0;
    },
    setPreviousFile: (state, action: PayloadAction<FileVersion | null>) => {
      state.previousFile = action.payload;
    },
    setNextFile: (state, action: PayloadAction<FileVersion | null>) => {
      state.nextFile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      if (!action.payload) return;
      const payload = (action.payload as { abPlayerStore: AbPlayerState })
        .abPlayerStore;
      Object.assign(state, payload);
      state.playlist = [];
      state.isRehydrating = true;
    });
    builder.addCase(downloadTrack.fulfilled, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      const blobUrl = action.payload;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        trackBlobUrl: blobUrl,
        isTrackBlobUrlLoading: false,
      };
    });
    builder.addCase(downloadTrack.pending, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        isTrackBlobUrlLoading: true,
      };
    });
    builder.addCase(downloadTrack.rejected, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        trackBlobUrl: null,
        isTrackBlobUrlLoading: false,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.fulfilled, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      const blobUrl = action.payload;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        trackGeneratedMP3BlobUrl: blobUrl,
        isTrackGeneratedMP3BlobUrlLoading: false,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.pending, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        isTrackGeneratedMP3BlobUrlLoading: true,
      };
    });
    builder.addCase(downloadGeneratedMP3Track.rejected, (state, action) => {
      const { projectId } = action.meta.arg;
      if (!projectId) return;
      state.downloadedPlaylistTrack[projectId] = {
        ...state.downloadedPlaylistTrack[projectId],
        trackGeneratedMP3BlobUrl: null,
        isTrackGeneratedMP3BlobUrlLoading: false,
      };
    });
    builder.addCase(updateScheduledProjectsTracks, (state, action) => {
      const { currentTrackIndex, playlist } = state;
      if (playlist.length === 0) {
        return;
      }
      const currentProject = playlist[currentTrackIndex];
      const index = action.payload.findIndex(
        (project) => project.id === currentProject.id,
      );
      if (index < 0) return;
      state.playlist = action.payload;
      state.currentTrackIndex = action.payload.findIndex(
        (project) => project.id === currentProject.id,
      );
      state.currentTrackIndex = index;
    });
  },
});

export const {
  selectFile,
  resetAbPlayStore,
  setCurrentPosition,
  setIsLooping,
  setShowFooterPlayer,
  setSeekTo,
  setLocalPlayer,
  setIsFooterReady,
  setIsFooterPlaying,
  setFooterIsActive,
  setRefPlayer,
  setMainPlayer,
  setIsRehydrating,
  setIndex,
  setUrl,
  setPlaylist,
  setProjectInfo,
  setPreviousFile,
  setNextFile,
} = abPlayerStore.actions;

export default abPlayerStore.reducer;
