import React, { FC } from "react";
import { UserProfileImage } from "../../elements/UserProfileImage/UserProfileImage";
import { TimeLineProgressBar } from "../../elements/TimeLineProgressBar/TimeLineProgressBar";
import { Button } from "../../elements/Button/button";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export interface TableRowSkeletonProps {
  isMobile: boolean;
}

export const TableRowSkeleton: FC<TableRowSkeletonProps> = ({ isMobile }) => {
  return (
    <tr className="project-row loading-table-view">
      <td>
        <Skeleton count={2} height={14} />
      </td>
      {!isMobile && (
        <td>
          <Skeleton height={14} />
        </td>
      )}
      <td>
        <UserProfileImage
          width={35}
          height={35}
          hideBorder={false}
          isCircle={true}
          borderWidth={"thin"}
        />
      </td>
      <td>
        <Skeleton height={14} />
      </td>
      {!isMobile && (
        <td>
          <div className={"individual-section time-line-container"}>
            <TimeLineProgressBar currentStep={0} totalSteps={8} />
          </div>
        </td>
      )}
      {!isMobile && (
        <td>
          <Skeleton height={14} />
        </td>
      )}
      {!isMobile && (
        <td>
          <Button
            label={"loading"}
            loading={true}
            disabled={true}
            primary={true}
          />
        </td>
      )}
    </tr>
  );
};
