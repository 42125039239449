import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DevLinkProvider, EngineEarsFooter2024 } from "../../../devlink";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

import { useAtomValue } from "jotai";
import { topNavSpacerHeightAtom } from "../../../atoms/navAtoms";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { ReviewShareLinkParams } from "../../../store/actions/projectsMap";
import { getRecordingSessionDetailsFromShareLink } from "../../../store/actions/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import AppScreenContent from "../../components/AppScreenContent/AppScreenContent";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { SessionDetailsPanel } from "../../components/SessionDetailsPanel/SessionDetailsPanel";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { EnterPasswordViewWrapper } from "../LoggedOutReviewScreen/LoggedOutReviewScreen.styles";

export const LoggedOutRecordingSessionDetailsScreen = () => {
  const navHeight = useAtomValue(topNavSpacerHeightAtom);
  const { project_id: projectId } = useParams<{ project_id: string }>();
  const [password, setPassword] = useState("");
  const { recordingSession, recordingSessionLoading } = useAppSelector(
    (state) => state.recordingSessionReducer,
  );
  const dispatch = useAppDispatch();
  const codeQuery = useQueryParam("code");
  const code = codeQuery.get();

  useSetPageTitle("Recording Session Details");

  useEffect(() => {
    if (!code) return;
    if (isNaN(+projectId)) {
      return;
    }
    const args: ReviewShareLinkParams = { code };
    args.projectId = +projectId;
    void dispatch(getRecordingSessionDetailsFromShareLink(args));
  }, [code, dispatch, projectId]);

  const fetchPasswordProtectedProject = useCallback(() => {
    if (!code) return;
    if (!projectId) return;
    void dispatch(
      getRecordingSessionDetailsFromShareLink({
        code,
        projectId: +projectId,
        ...(password && { password }),
      }),
    );
  }, [code, password, dispatch, projectId]);

  if (!code) return null;

  if (!recordingSession && !recordingSessionLoading) {
    return (
      <div className="container-fluid">
        <div className="container d-flex flex-column">
          <EnterPasswordViewWrapper
            $navHeight={navHeight}
            className="enter-password-view"
          >
            <p>This page is password protected</p>
            <EditableTextField
              className={"mb-2"}
              isSecureText={true}
              onKeyPressed={(event) => {
                if (event.key === "Enter") fetchPasswordProtectedProject();
              }}
              initialValue={password}
              label={"enter password"}
              onChange={(value) => setPassword(value)}
              editMode={true}
            />
            <Button
              onClick={fetchPasswordProtectedProject}
              variant={ButtonVariant.PRIMARY}
              fullWidth
            >
              Submit
            </Button>
          </EnterPasswordViewWrapper>
        </div>
      </div>
    );
  }

  if (recordingSessionLoading && !recordingSession) {
    return <LoadingScreen />;
  }

  return (
    <AppScreenContent
      style={{ overflowX: "hidden" }}
      className="container-fluid"
    >
      <div className="container">
        <SessionDetailsPanel
          noSidePanel={true}
          customProjectId={recordingSession?.project.id.toString()}
        />
      </div>
      <DevLinkProvider>
        <EngineEarsFooter2024 />
      </DevLinkProvider>
    </AppScreenContent>
  );
};

export default LoggedOutRecordingSessionDetailsScreen;
