import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNetworkState } from "@uidotdev/usehooks";
import { getWsRootUrl } from "../../../utils/utils";
import ErrorBoundary from "./ErrorBoundary";

export interface OnlineHeartbeatProps {
  children: ReactNode;
}

const triggerWebsocket = (websocket: WebSocket) => {
  const token = localStorage.getItem("token");
  websocket.send(
    JSON.stringify({
      token,
    }),
  );
};

const pollWebsocket = (websocket: WebSocket) => {
  if (websocket.readyState !== 1) {
    return;
  }
  triggerWebsocket(websocket);
  setTimeout(() => {
    pollWebsocket(websocket);
  }, 60000);
};

const makeWebsocket = (
  onopen: () => void,
  onerror: () => void,
  onclose: (event: CloseEvent) => void,
) => {
  const websocket = new WebSocket(getWsRootUrl() + "/ws/online/");
  websocket.onopen = () => {
    onopen();
    pollWebsocket(websocket);
  };
  websocket.onerror = () => {
    onerror();
  };

  websocket.onclose = (ev: CloseEvent) => {
    onclose(ev);
  };
  return websocket;
};

const OnlineHeartbeat = ({ children }: OnlineHeartbeatProps) => {
  const { online } = useNetworkState();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [isValidVersion, setIsValidVersion] = useState(true);

  const handleOpen = useCallback(() => {
    setIsValidVersion(true);
  }, [setIsValidVersion]);

  const handleError = useCallback(() => {
    setIsValidVersion(false);
  }, [setIsValidVersion]);
  const handleClose = useCallback(
    (event: CloseEvent) => {
      if (!event.wasClean) {
        setIsValidVersion(false);
      }
    },
    [setIsValidVersion],
  );

  useEffect(() => {
    if (online) {
      if (!websocket) {
        setWebsocket(makeWebsocket(handleOpen, handleError, handleClose));
      } else if (websocket.readyState === 3) {
        setWebsocket(makeWebsocket(handleOpen, handleError, handleClose));
      }
    } else {
      if (websocket) {
        websocket.close();
        setWebsocket(null);
      }
    }
  }, [online, websocket, handleOpen, handleError, handleClose, setWebsocket]);

  const activateErrorBoundary = useMemo(() => {
    if (!online) {
      return false;
    }
    return !isValidVersion;
  }, [online, isValidVersion]);
  return (
    <ErrorBoundary activated={activateErrorBoundary}>{children}</ErrorBoundary>
  );
};

export default OnlineHeartbeat;
