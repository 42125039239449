import { useEffect, useMemo } from "react";
import { getMusoAuthToken } from "../store/actions/musoProfile";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { MusoAssociation, MusoAssociationStatus } from "../store/models/muso";
import {
  SUBSCRIPTION_STATUS,
  Subscription,
} from "../store/models/subscription";

/**
 * Fetches Muso Auth information for the given user from the backend.
 * Retrieves the data from the redux store if it already exists.
 */
export const useGetMusoAuth = () => {
  const dispatch = useAppDispatch();
  const { activeMusoUserId, musoAssociation, loading } = useAppSelector(
    (state) => state.musoAssociationStore,
  );
  const isStudioProfileActive = Boolean(
    useAppSelector((state) => state.selectedProfileSlice.studio),
  );

  useEffect(() => {
    if (!activeMusoUserId && !isStudioProfileActive) {
      void dispatch(getMusoAuthToken()).unwrap();
    }
  }, [activeMusoUserId, dispatch, isStudioProfileActive]);

  return isStudioProfileActive
    ? { activeMusoUserId: null, loading: false, musoAssociation: undefined }
    : { activeMusoUserId, loading, musoAssociation };
};

export const useMusoCardText = (
  engineerIsOnboarded: boolean,
  subscription: Subscription,
  musoAssociation: MusoAssociation | undefined | null,
  musoUserId: string | null,
) => {
  return useMemo(() => {
    if (!engineerIsOnboarded) return "Onboarding Required";
    if (
      subscription.stripe_subscription_status !== SUBSCRIPTION_STATUS.ACTIVE
    ) {
      return "Subscription Required";
    }

    if (!musoUserId) {
      return "Link Profile";
    }

    let text = "";
    switch (musoAssociation?.status) {
      case MusoAssociationStatus.MUSO_ASSOC_STATUS_ACCEPTED:
      case MusoAssociationStatus.MUSO_ASSOC_STATUS_CONFIRMED_WITH_SSO:
        text = "Update Credits";
        break;
      default:
        text = "Add Credits";
        break;
    }
    return text;
  }, [
    subscription.stripe_subscription_status,
    musoAssociation?.status,
    engineerIsOnboarded,
    musoUserId,
  ]);
};
