import { Box } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { AuthSteps } from "../../../constants/authSteps";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { AccountCreateComplete } from "./AccountCreateComplete";
import { AddPhone } from "./AddPhone";
import {
  authModalOpenAtom,
  currentSignUpStepAtom,
  signUpStepAtom,
} from "./atoms";
import AuthBannerInfoWrapper from "./AuthBannerInfoWrapper";
import { AuthModalContainer } from "./AuthModal.styles";
import { BuildTeam } from "./BuildTeam";
import { EmailAuth } from "./EmailAuth";
import { LabelSelection } from "./LabelSelection";
import { ProfileType } from "./ProfileType";
import { StartSignUp } from "./StartSignUp";
import StudioContact from "./StudioContact";
import StudioLocation from "./StudioLocation";
import { UsernameSelection } from "./UsernameSelection";
import VerifyEmail from "./VerifyEmail";
import { VerifyPhone } from "./VerifyPhone";

const stepToForm: Record<number, React.FC> = {
  [AuthSteps.LOGIN]: EmailAuth,
  [AuthSteps.EMAIL_SIGNUP]: EmailAuth,
  [AuthSteps.PROFILE_TYPE]: ProfileType,
  [AuthSteps.USERNAME]: UsernameSelection,
  [AuthSteps.VERIFY_EMAIL]: VerifyEmail,
  [AuthSteps.ADD_PHONE]: AddPhone,
  [AuthSteps.VERIFY_PHONE]: VerifyPhone,
  [AuthSteps.ADD_STUDIO_LOCATION]: StudioLocation,
  [AuthSteps.ADD_STUDIO_CONTACT]: StudioContact,
  [AuthSteps.COMPLETE]: AccountCreateComplete,
  [AuthSteps.AANDR_LABEL_SELECTION]: LabelSelection,
  [AuthSteps.AANDR_USERNAME]: UsernameSelection,
  [AuthSteps.AANDR_ADD_PHONE]: AddPhone,
  [AuthSteps.AANDR_VERIFY_PHONE]: VerifyPhone,
  [AuthSteps.AANDR_BUILD_TEAM]: BuildTeam,
};

export const AuthModal = () => {
  const { isOpen, openModal, toggleModal, closeModal } = useModal();
  const initialStep = useAtomValue(signUpStepAtom);
  const [step, setStep] = useAtom(currentSignUpStepAtom);
  const [authModalOpen, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const StepForm = stepToForm[step];
  const nonAuthBannerSteps = [
    AuthSteps.PROFILE_TYPE,
    AuthSteps.AANDR_LABEL_SELECTION,
    AuthSteps.COMPLETE,
  ];
  const isNonAuthBannerStep = nonAuthBannerSteps.includes(step);
  const { isMobile } = useMediaQueryBreakpoint();

  useEffect(() => {
    setStep(initialStep);
  }, []);

  useEffect(() => {
    if (authModalOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [authModalOpen, openModal, closeModal]);

  const onModalClose = () => {
    setAuthModalOpen(false);
    toggleModal();
  };

  return isMobile && authModalOpen ? (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {step === AuthSteps.START_SIGNUP ? (
        <StartSignUp onClose={onModalClose} />
      ) : StepForm ? (
        <StepForm />
      ) : null}
    </Box>
  ) : (
    <BaseModal
      header=""
      open={isOpen}
      setOpen={toggleModal}
      showModalHeader={false}
      style={{
        maxWidth:
          isNonAuthBannerStep && step !== AuthSteps.COMPLETE
            ? "1224px"
            : "840px",
        boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.15)",
      }}
      modalBodyStyle={{ padding: 0, width: "100%" }}
    >
      <AuthModalContainer>
        <AuthBannerInfoWrapper />
        <Box
          style={{
            width: isNonAuthBannerStep ? "100%" : "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {step === AuthSteps.START_SIGNUP ? (
            <StartSignUp onClose={onModalClose} />
          ) : StepForm ? (
            <StepForm />
          ) : null}
        </Box>
      </AuthModalContainer>
    </BaseModal>
  );
};
