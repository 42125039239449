import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { getItemizedTransaction } from "../../store/actions/transactions";
import { useAppDispatch } from "../../store/hooks";

interface UseGetItemizedTransactionParams {
  scheduledProjectId: number | undefined;
  transactionCode: string;
  enabled?: boolean;
}

/**
 * Used to fetch the OrderSummary data used in the OrderBreakdown component
 * This could be useful in several places where we need to display the OrderSummary
 */
export const useGetItemizedTransaction = (
  params: UseGetItemizedTransactionParams,
) => {
  const dispatch = useAppDispatch();
  // Since either transactionCode or scheduledProjectId can be used to uniquely identify the transaction query
  // we'll create a unique query param string to identify the query (for caching purposes)
  const paramString = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ITEMIZED_TRANSACTION, paramString],
    queryFn: async () => {
      return dispatch(getItemizedTransaction(params)).unwrap();
    },
    enabled:
      params.enabled ??
      Boolean(params.transactionCode || params.scheduledProjectId),
  });
};
