import { Redirect, useParams } from "react-router-dom";
import {
  getHomepageRoute,
  getSessionDetailsRoute,
} from "../../../store/utils/routeGetters";

/**
 * Deprecated component. Redirects to the new Session Details page route.
 */
const RecordingSessionScreen = () => {
  const { project_id: projectId } = useParams<{
    project_id: string | undefined;
  }>();

  if (!projectId) {
    return <Redirect to={getHomepageRoute()} />;
  }

  return <Redirect to={getSessionDetailsRoute(+projectId)} />;
};

export default RecordingSessionScreen;
