import { useCallback, useEffect, useState } from "react";
import {
  FooterFileTrackType,
  getPlaylist,
  resetAbPlayStore,
  setIsRehydrating,
  setLocalPlayer,
  setMainPlayer,
  setPlaylist,
  setRefPlayer,
} from "../../store/actions/abPlayerStore";
import {
  downloadGeneratedMP3Track,
  downloadTrack,
} from "../../store/actions/audioService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Alt } from "../../store/models/alts";
import { AUDIO_UPLOAD_TYPE } from "../../store/models/fileVersion";
import {
  useLatestNonReferenceAudioFileVersion,
  useLatestReference,
} from "../useFilesFromFileVersionStore";
import { useQueryParam } from "../useQueryParam";

export const useFetchFiles = () => {
  const {
    projectId,
    url,
    footerFileTrackType,
    isRehydrating,
    playlistId,
    currentTrackIndex,
  } = useAppSelector((state) => state.abPlayerStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);

  const dispatch = useAppDispatch();

  const file = useLatestNonReferenceAudioFileVersion(
    projectId || -1,
    Alt.MAIN,
    AUDIO_UPLOAD_TYPE,
  );

  useEffect(() => {
    if (!isRehydrating) return;
    if (!loggedInUser) {
      dispatch(resetAbPlayStore());
      return;
    }

    const getFilesAfterRehydrate = async () => {
      switch (footerFileTrackType) {
        case FooterFileTrackType.SCHEDULED_PROJECT:
          if (!playlistId) break;
          const playlist = await dispatch(
            getPlaylist({
              scheduled_project_id: playlistId,
            }),
          ).unwrap();
          dispatch(
            setPlaylist({
              tracks: playlist,
              playlistId: playlistId,
              index: currentTrackIndex,
            }),
          );
          break;
        default:
          dispatch(resetAbPlayStore());
          break;
      }
    };

    void getFilesAfterRehydrate();
  }, [
    dispatch,
    file,
    footerFileTrackType,
    isRehydrating,
    loggedInUser,
    projectId,
    url,
  ]);
};

export const useDownloadTrack = () => {
  const {
    projectId,
    url,
    footerFileTrackType,
    mainUrl,
    refUrl,
    trackedPlayerId,
    mainPlayerId,
    refPlayerId,
    isRehydrating,
  } = useAppSelector((state) => state.abPlayerStore);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const file = useLatestNonReferenceAudioFileVersion(projectId || -1);
  const referenceFileVersion = useLatestReference(projectId || -1);
  const mixOrMasterFileVersion = useLatestNonReferenceAudioFileVersion(
    projectId || -1,
  );
  const code = useQueryParam("code").get();

  const downloadCompletedTrack = useCallback(async () => {
    if (!file) return;
    setIsLoading(true);
    const downloadedTrack = await dispatch(
      downloadTrack({
        fileVersionId: file.id,
        code: code || undefined,
      }),
    ).unwrap();
    setIsLoading(false);
    dispatch(setLocalPlayer({ url: downloadedTrack, keepPosition: true }));
  }, [dispatch, file, projectId, code]);

  const downloadABTrackMP3 = useCallback(
    async (isRef: boolean, id?: number) => {
      if (!id) return;
      void dispatch(
        downloadGeneratedMP3Track({
          fileVersionId: id,
          code: code || undefined,
        }),
      )
        .unwrap()
        .then((data) => {
          if (isRef) {
            dispatch(setRefPlayer({ id: id, url: data }));
          } else {
            dispatch(setMainPlayer({ id: id, url: data }));
          }
          if (trackedPlayerId === id) {
            dispatch(
              setLocalPlayer({
                url: data,
                trackedPlayerId:
                  trackedPlayerId === mainPlayerId ? mainPlayerId : refPlayerId,
                keepPosition: true,
                abState: trackedPlayerId === mainPlayerId ? false : true,
              }),
            );
          }
        });
    },
    [dispatch, trackedPlayerId, mainPlayerId, refPlayerId, code],
  );

  const downloadABTrackWav = useCallback(
    async (isRef: boolean, id?: number) => {
      if (!id) return;
      void dispatch(
        downloadTrack({
          fileVersionId: id,
          code: code || undefined,
        }),
      )
        .unwrap()
        .then((data) => {
          if (isRef) {
            dispatch(setRefPlayer({ id: id, url: data }));
          } else {
            dispatch(setMainPlayer({ id: id, url: data }));
          }
          if (trackedPlayerId === id) {
            dispatch(
              setLocalPlayer({
                url: data,
                trackedPlayerId:
                  trackedPlayerId === mainPlayerId ? mainPlayerId : refPlayerId,
                keepPosition: true,
                abState: trackedPlayerId === mainPlayerId ? false : true,
              }),
            );
          }
        });
    },
    [dispatch, mainPlayerId, refPlayerId, trackedPlayerId, code],
  );

  useEffect(() => {
    if (!isRehydrating) return;
    if (!loggedInUser) {
      dispatch(resetAbPlayStore());
      return;
    }

    const downloadTrackForFooter = async () => {
      if (!loggedInUser || isLoading) return;
      switch (footerFileTrackType) {
        case FooterFileTrackType.COMPLETED_PROJECT:
          if (!file || url) break;
          await downloadCompletedTrack();
          break;
        default:
          break;
      }
      dispatch(setIsRehydrating(false));
    };

    void downloadTrackForFooter();
  }, [
    dispatch,
    downloadABTrackMP3,
    downloadABTrackWav,
    downloadCompletedTrack,
    file,
    footerFileTrackType,
    isLoading,
    isRehydrating,
    loggedInUser,
    mainUrl,
    mixOrMasterFileVersion,
    projectId,
    refPlayerId,
    refUrl,
    referenceFileVersion,
    trackedPlayerId,
    url,
  ]);
};
