import { useSetAtom } from "jotai";
import { useCallback } from "react";
import useOnScrollCallback from "./useOnScrollCallback";
import { showBottomAndTopNavAtom } from "../atoms/navAtoms";

export const useHideNavElementsCallback = () => {
  const setShowNav = useSetAtom(showBottomAndTopNavAtom);
  const handleToggle = useCallback(
    (show: boolean) => {
      setShowNav(show);
    },
    [setShowNav],
  );

  return useOnScrollCallback({ onToggle: handleToggle });
};
