import {
  faChevronLeft,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { useMemo } from "react";
import { Calendar, CalendarTileProperties } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "styled-components";
import "./EstimatedDeliveryDatePicker.css";

import useModal from "../../../hooks/useModal";
import { setEstimatedDeliveryDate } from "../../../store/actions/mixMasterCartsStore";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertLocalDateToUTCDate,
  getCurrentDate,
  getFormattedDateString,
} from "../../../store/utils/dateTimeUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { ButtonVariant } from "../../core-ui/components/Button/Button";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import {
  Container,
  DisplayDate,
  DisplayDateSpan,
  Heading,
} from "./EstimatedDeliveryDatePicker.styles";

interface EstimatedDeliveryDatePickerProps {
  isLoggedInUserGeneratingBooking: boolean;
}

const EstimatedDeliveryDatePicker = ({
  isLoggedInUserGeneratingBooking,
}: EstimatedDeliveryDatePickerProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { estimatedDeliveryDateData } = useAppSelector(
    (state) => state.mixMasterCartsStore.cart,
  );
  const estimatedDeliveryDate = estimatedDeliveryDateData?.date ?? null;
  const { isOpen, openModal, closeModal } = useModal();

  const utcEstimatedDeliveryDate = useMemo(() => {
    if (!estimatedDeliveryDate) {
      return null;
    }

    return convertLocalDateToUTCDate(new Date(estimatedDeliveryDate));
  }, [estimatedDeliveryDate]);

  const estimatedDeliveryDateString = useMemo(() => {
    if (!utcEstimatedDeliveryDate) {
      return "Select";
    }

    return format(utcEstimatedDeliveryDate, "MMM dd, yyyy");
  }, [utcEstimatedDeliveryDate]);

  const onClickDate = (date: Date) => {
    dispatch(setEstimatedDeliveryDate(getFormattedDateString(date)));
    closeModal();
  };

  const getDisabledTiles = ({ date }: CalendarTileProperties) => {
    return date.getTime() <= getCurrentDate().getTime();
  };

  const getTileClass = ({ date }: CalendarTileProperties) => {
    if (!utcEstimatedDeliveryDate) {
      return "";
    }

    if (
      date.getFullYear() === utcEstimatedDeliveryDate.getFullYear() &&
      date.getMonth() === utcEstimatedDeliveryDate.getMonth() &&
      date.getDate() === utcEstimatedDeliveryDate.getDate()
    ) {
      return "estimated-delivery-date-picker-selected";
    }
    return "";
  };

  return (
    <>
      <Container>
        <Heading>{`${isLoggedInUserGeneratingBooking ? "Estimated" : "Requested"} delivery date:`}</Heading>
        <ToolTipTextArea
          text="Rough estimate of the completion date for the project based on the number of services"
          tooltipTextStyle={{
            textAlign: "left",
            top: 0,
          }}
        />
        <DisplayDate variant={ButtonVariant.UNSTYLED} onClick={openModal}>
          <DisplayDateSpan>{estimatedDeliveryDateString}</DisplayDateSpan>
          &nbsp;
          <FontAwesomeIcon icon={faEdit} className={"edit-studio-button"} />
        </DisplayDate>
      </Container>
      <BaseModal
        open={isOpen}
        setOpen={closeModal}
        header="Choose Estimated Delivery Date"
      >
        <Calendar
          className="estimated-delivery-date-calendar"
          prevLabel={
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="1x"
              color={theme.textPrimaryColor}
            />
          }
          nextLabel={
            <FontAwesomeIcon
              icon={faChevronRight}
              size="1x"
              color={theme.textPrimaryColor}
            />
          }
          tileClassName={getTileClass}
          onClickDay={onClickDate}
          tileDisabled={getDisabledTiles}
          view="month"
          value={utcEstimatedDeliveryDate}
        />
      </BaseModal>
    </>
  );
};

export default EstimatedDeliveryDatePicker;
