import queryString from "query-string";

import { SCREENS } from "../../routes";
import { QueryParamsType } from "../index";
import { MUSO_CLIENT_ID } from "../utils";

export enum MUSO_SEARCH_TYPE {
  PROFILE = "profile",
  ALBUM = "album",
  TRACK = "track",
  ARTIST = "artist",
}

export const MUSO_WEBSITE_BASE_URI = "https://credits.muso.ai/";
export const MUSO_AUTH_DOMAIN = "https://auth.muso.ai/authorize";
export const MUSO_AUDIENCE_URL = "https://api.developer.muso.ai/";

export const getMusoAuthorizationURL = (redirectPath: string) => {
  const queryParamObj: QueryParamsType = {};
  queryParamObj.response_type = "code";
  queryParamObj.client_id = MUSO_CLIENT_ID;
  queryParamObj.redirect_uri = queryString.stringifyUrl({
    url: `${window.location.origin}${SCREENS.MUSO_AUTH_REDIRECT_SCREEN}`,
    query: { redirect_path: redirectPath },
  });
  queryParamObj.audience = MUSO_AUDIENCE_URL;
  queryParamObj.prompt = "login";
  queryParamObj.scope = "offline_access read:user read:roster_profiles";
  const params = `?${queryString.stringify(queryParamObj, {
    arrayFormat: "comma",
  })}`;
  return `${MUSO_AUTH_DOMAIN}${params}`;
};

export interface MusoCredit {
  id?: number; // ID generated on the backend.
  muso_credit_id: string;
  track_title: string;
  album_art: string;
  artist: string;
  album_title: string;
  role: string;
  release_date: string;
  link_to_credit: string;
  spotify_preview_url: string | null;
  spotify_track_id: string | null;
}

export const MockMusoCredit: MusoCredit = {
  muso_credit_id: "1",
  track_title: "Track Title",
  album_art: "https://via.placeholder.com/150",
  artist: "Artist Name",
  album_title: "Album Title",
  role: "Role",
  release_date: "2021-01-01",
  link_to_credit: "https://credits.muso.ai/",
  spotify_preview_url: "spotify.com",
  spotify_track_id: "spotifyTrackId",
};

export interface MusoArtist {
  id: string;
  fullName: string;
  avatarUrl: string;
}

export interface MusoAlbum {
  id: string;
  albumTitle: string;
  type: string;
  albumArt: string;
  releaseDate: string;
  trackCount: number;
  collaboratorCount: number;
  genres: string;
}

interface InnerCredit {
  parentCredit: string;
  childCredit: string;
}

export interface MusoTrack {
  id: string;
  title: string;
  trackNumber: number;
  credits: InnerCredit[];
  artists: MusoArtist[];
}

export interface Credit {
  parent: string;
  child: string;
}

export interface Track {
  id: string;
  title: string;
  popularity: number;
  spotifyPreviewUrl: string;
  spotifyId: string;
}

export interface Album {
  id: string;
  title: string;
  albumArt: string;
}

export interface Artist {
  id: string;
  name: string;
  avatarUrl: string;
}

export interface CreditData {
  album: Album;
  track: Track;
  credits: Credit[];
  artists: Artist[];
  releaseDate: string;
}

export interface MusoProfessionalEntity {
  id: string;
  name: string | null;
  score: number;
  creditCount: number;
  avatarUrl: string | null;
  type: MUSO_SEARCH_TYPE;
}

export interface MusoProfileEntity {
  id: string;
  name: string;
  avatar_url: string | null;
  credit_count: number;
}

export const MockProfessionalEntity: MusoProfessionalEntity = {
  id: "917170c0-230c-4419-9b25-1e82ef904b3b",
  name: "Derek “MixedbyAli” Ali",
  score: 12.672994,
  creditCount: 415,
  avatarUrl:
    "https://assets-production-177422a0211e5806.s3.amazonaws.com/917170c0-230c-4419-9b25-1e82ef904b3bavatar1643999777886.jpg",
  type: MUSO_SEARCH_TYPE.PROFILE,
};

export const MockMusoProfileList: MusoProfessionalEntity[] = [
  {
    id: "77c63565-0f83-466b-a203-1052e50aae5a",
    name: "Ali Direk",
    score: 13.572805,
    creditCount: 2,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "b5b449d1-6c93-4b45-86f3-f3a319b16b72",
    name: "Derek 'MixedBy' Ali'",
    score: 12.743235,
    creditCount: 1,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "917170c0-230c-4419-9b25-1e82ef904b3b",
    name: "Derek “MixedbyAli” Ali",
    score: 12.672994,
    creditCount: 415,
    avatarUrl:
      "https://assets-production-177422a0211e5806.s3.amazonaws.com/917170c0-230c-4419-9b25-1e82ef904b3bavatar1643999777886.jpg",
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "278b1a5b-12f5-47fe-861d-74e54c3bcefe",
    name: "Derek Beres",
    score: 12.405588,
    creditCount: 12,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "226154f6-4aa8-4bd6-834a-19f7625e6e23",
    name: "Derek Beres",
    score: 12.284621,
    creditCount: 27,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "02aa04e9-ee84-4401-977a-cdaa25766ae0",
    name: "4li ali",
    score: 12.267902,
    creditCount: 5,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "67a36dde-ae44-425f-a11e-6d7536dc3a1c",
    name: "Mixed by Ali",
    score: 11.81415,
    creditCount: 2,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "b471cff0-181c-4750-820b-55229252e46f",
    name: "Mix by LMM",
    score: 11.639689,
    creditCount: 2,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "bca56927-d4b5-4f33-9a3f-e4d34d48b4dc",
    name: "Mix by Edward",
    score: 11.639689,
    creditCount: 2,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
  {
    id: "70af96cd-a2cd-43e2-9272-78683a60a15f",
    name: "Mix by Frankindeed",
    score: 11.639689,
    creditCount: 1,
    avatarUrl: null,
    type: MUSO_SEARCH_TYPE.PROFILE,
  },
];

export enum MusoAssociationStatus {
  // PENDING, UNDEFINED, and REJECTED are deprecated after the switch to the Muso SSO API.
  // They are kept here for backwards compatibility.
  MUSO_ASSOC_STATUS_UNDEFINED,
  MUSO_ASSOC_STATUS_PENDING,
  MUSO_ASSOC_STATUS_ACCEPTED,
  MUSO_ASSOC_STATUS_REJECTED,
  MUSO_ASSOC_STATUS_CONFIRMED_WITH_SSO,
}

export const MUSO_ASSOCIATION_ACCEPTED_STATUSES = [
  MusoAssociationStatus.MUSO_ASSOC_STATUS_ACCEPTED,
  MusoAssociationStatus.MUSO_ASSOC_STATUS_CONFIRMED_WITH_SSO,
];

export interface MusoAssociation {
  id: number;
  status: MusoAssociationStatus;
  muso_id: string;
}

export enum MusoSelectionTab {
  ROSTER = "roster",
  CREDITS = "credits",
}
