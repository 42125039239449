import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";
import { Container, ButtonContainer } from "./StripePaymentRedirectPage.styles";
import {
  FinancialMethod,
  PaymentRedirectType,
} from "../../../store/models/transaction";
import {
  clearTransactionsState,
  markTransactionPaid,
} from "../../../store/actions/transactions";
import { clearBookingState } from "../../../store/actions/booking";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  getMyBookingsRoute,
  getProjectOverviewRoute,
  getTransactionBookingScreenRoute,
} from "../../../store/utils/routeGetters";
import useModal from "../../../hooks/useModal";
import AppScreenContent from "../../components/AppScreenContent/AppScreenContent";

interface StripePaymentRedirectPageProps {
  paymentRedirectType: PaymentRedirectType;
}

export const StripePaymentRedirectPage = ({
  paymentRedirectType,
}: StripePaymentRedirectPageProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isOpen, openModal, closeModal } = useModal();
  const [
    transactionProcessedSuccessfully,
    setTransactionProcessedSuccessfully,
  ] = useState<boolean | undefined>(undefined);
  const params = new URLSearchParams(window.location.search);
  const projectTitle = params.get("project_title") ?? "Untitled";
  const transactionId = params.get("transaction_id");
  const transactionCode = params.get("transaction_code");
  const scheduledProjectIdForHandoff = params.get("scheduled_project_id");
  const redirectStatus = params.get("redirect_status");

  const financialMethod = useMemo(() => {
    if (paymentRedirectType === PaymentRedirectType.CASH_APP) {
      return FinancialMethod.CASH_APP;
    }

    if (paymentRedirectType === PaymentRedirectType.KLARNA) {
      return FinancialMethod.KLARNA;
    }

    return undefined;
  }, [paymentRedirectType]);

  const paymentTypeString = useMemo(() => {
    if (paymentRedirectType === PaymentRedirectType.CASH_APP) {
      return "Cash App";
    }

    if (paymentRedirectType === PaymentRedirectType.KLARNA) {
      return "Klarna";
    }

    return "";
  }, [paymentRedirectType]);

  useEffect(() => {
    if (!transactionId) {
      toast.error("No transaction ID found, Please contact support.");
      return;
    }

    // should never happen, but guard against it
    if (!financialMethod) {
      return;
    }

    if (redirectStatus !== "succeeded") {
      setTransactionProcessedSuccessfully(false);
      return;
    }

    dispatch(
      markTransactionPaid({
        title: projectTitle,
        transaction_id: +transactionId,
        booked_with_purchase_order: false,
        financial_method: financialMethod,
      }),
    )
      .unwrap()
      .then((result) => {
        emitAnalyticsTrackingEvent(
          "mark_paid_scheduled_project",
          {
            transaction_id: `${transactionId}`,
            value: result?.total_price ?? 0,
          },
          user?.id,
        );
        setTransactionProcessedSuccessfully(true);
        dispatch(clearBookingState());
        dispatch(clearTransactionsState());

        // Redirect to project page after successful payment processing.
        setTimeout(() => {
          if (scheduledProjectIdForHandoff) {
            history.push(
              getProjectOverviewRoute(+scheduledProjectIdForHandoff),
            );
          } else {
            history.push(getMyBookingsRoute("projects"));
          }
        }, 2000);
      })
      .catch(() => {
        setTransactionProcessedSuccessfully(false);
      });
  }, []);

  if (transactionProcessedSuccessfully === undefined) {
    return (
      <AppScreenContent>
        <Container>
          <DefaultSoundWaveLoader />
          <p className="b2-semi-bold text-center">
            Processing payment... Please do not exit or refresh the page.
          </p>
        </Container>
      </AppScreenContent>
    );
  }

  if (!transactionProcessedSuccessfully) {
    return (
      <AppScreenContent>
        <Container>
          <h3>Payment unsuccessful</h3>
          <p className="b2-semi-bold mt-2">
            {`Payment via ${paymentTypeString} failed, please try again or contact support.`}
          </p>
          <ButtonContainer>
            <Button onClick={openModal} variant={ButtonVariant.OUTLINED}>
              Contact Support
            </Button>
            {transactionCode && (
              <Button
                onClick={() =>
                  history.replace(
                    getTransactionBookingScreenRoute(transactionCode),
                  )
                }
                variant={ButtonVariant.PRIMARY}
              >
                Try Again
              </Button>
            )}
          </ButtonContainer>
          {isOpen && <FeedbackModal onClose={closeModal} />}
        </Container>
      </AppScreenContent>
    );
  }
  return (
    <AppScreenContent className="centered-screen">
      <Container>
        <p className="b2-semi-bold">Payment processed successfully!</p>
      </Container>
    </AppScreenContent>
  );
};
