import Engineer, { MockEngineer } from "./engineer";
import { ProjectType } from "./project";
import Service, { MockServiceRate, ServiceRate } from "./service";
import { StudioRoom } from "./studio";

export enum RecordingSessionQueryParams {
  PROJECT_ID = "project",
  BOOKING_ID = "session_booking_request",
}

export enum RecordingBookingSteps {
  BACK_TO_SERVICES,
  ENTER_DATES,
  SETUP_RECORDING_PARAMETERS,
  SELECT_HOST,
  ENTER_LOCATION,
  BOOK_RECORDING,
  PREDEFINED_PROJECT,
  SELECT_RECORDING_SERVICE,
  SELECT_ENGINEER,
}

export interface CancellationPolicy {
  artist_cancellation_fee_percentage: number;
  full_refund_cancellation_window_minutes: number;
  full_refund_prior_to_cancellation_deadline_days: number;
  partial_refund_prior_to_cancellation_deadline_hours: number;
}

export interface RecordingLocation {
  city_location: string;
  rough_latitude?: number;
  rough_longitude?: number;
  formatted?: string;
  latitude?: number;
  longitude?: number;
}
export interface Discounts {
  minimum_time_to_enable_rate: number;
  price: number;
}
export interface DiscountRate {
  id: number;
  created: string;
  deleted: string | null;
  service_rate: ServiceRate;
  minimum_time_to_enable_rate: number;
  recording_service_id: number;
}

export interface RecordingService {
  id: number;
  engineer?: Engineer;
  studio_room: null | StudioRoom;
  minimum_session_time_minutes: number;
  maximum_session_time_minutes: number;
  will_come_to_you: string | null;
  max_travel_distance_kilometers: number | null;
  equipment_highlights: string | null;
  service_rate: ServiceRate;
  service_type: ProjectType;
  arrival_information: string | null;
  recording_location?: RecordingLocation | null;
  unit_number: string | null;
  cancellation_policy?: CancellationPolicy;
  number_of_hours_notice: number;
  minimum_gap_between_sessions_in_minutes: number;
  deleted: string | null;
  not_accepting_bookings: string | null;
  recording_service_discount_rate?: DiscountRate[];
  minimum_deposit_percentage: number;
}

export interface recordingSessionParams {
  RecordingSessionData: recordingSessionData[];
  financial_method?: 1;
  emailsList?: string[];
  adminEmail?: string;
  transaction_type?: 1;
}

export interface recordingSessionData {
  service_id: number;
  service_type: 1;
  service_provider_user_id: number;
  title?: string;
  artist_name?: string;
  code?: string;
  first_choice_datetime: string;
  second_choice_datetime?: string | null;
  session_duration_minutes: number;
  engineer_goes_to_artist: boolean;
  recording_location: google.maps.places.PlaceResult;
  promocode?: string | null;
}

export const isRecordingService = (
  service: Service | RecordingService,
): service is RecordingService => {
  return service.service_type === ProjectType.RECORDING;
};

export const MockRecordingService: RecordingService = {
  studio_room: null,
  deleted: null,
  service_rate: MockServiceRate,
  service_type: ProjectType.RECORDING,
  minimum_session_time_minutes: 15,
  maximum_session_time_minutes: 480,
  will_come_to_you: new Date("2023-01-16").toDateString(),
  max_travel_distance_kilometers: 25,
  equipment_highlights:
    "Shure SM7B \nCardioid \nDynamic Vocal Microphone.  \nMacBook Pro M2 Pro. ",
  arrival_information:
    "Parking on side street is fine but there are parking fees. Private Parking is across the street",
  id: 0,
  engineer: MockEngineer,
  recording_location: {
    city_location: "Los Angeles, CA",
  },
  unit_number: null,
  cancellation_policy: {
    artist_cancellation_fee_percentage: 50,
    full_refund_cancellation_window_minutes: 60,
    full_refund_prior_to_cancellation_deadline_days: 7,
    partial_refund_prior_to_cancellation_deadline_hours: 24,
  },
  number_of_hours_notice: 24,
  minimum_gap_between_sessions_in_minutes: 60,
  not_accepting_bookings: null,
  recording_service_discount_rate: [],
  minimum_deposit_percentage: 1.0,
};
