import { useEffect, useState } from "react";
import { MusoCredit } from "../../../store/models/muso";
import "./CreditTableRow.css";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
export interface CreditTableRowProps {
  creditData: MusoCredit;
  disabledCheckMarks: boolean;
  visibleCheckMarks: boolean;
  checked?: boolean;
  onChecked?: (data: MusoCredit) => void;
  selectable?: boolean;
  checkedList?: string[];
}

export const CreditTableRow = ({
  creditData,
  disabledCheckMarks,
  visibleCheckMarks,
  checked,
  onChecked,
  selectable,
  checkedList,
}: CreditTableRowProps) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(checked);
  const { isDesktop } = useMediaQueryBreakpoint();

  useEffect(() => {
    const key = creditData.muso_credit_id + creditData.track_title;
    if (checkedList && checkedList.includes(key)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checkedList]);

  const handleChecked = () => {
    setIsChecked(!isChecked);
    if (onChecked) onChecked(creditData);
  };

  const desktopTableContents = (
    <>
      <td>{creditData.album_title}</td>
      <td>{creditData.role}</td>
      <td>{creditData.release_date}</td>
    </>
  );

  return (
    <>
      {visibleCheckMarks && (
        <td className="small-padding">
          <input
            checked={isChecked}
            onChange={handleChecked}
            type="checkbox"
            id="credit-checkbox"
            disabled={!selectable ? true : !isChecked && disabledCheckMarks}
          />
          <label htmlFor="credit-checkbox"></label>
        </td>
      )}
      <td className="small-padding">
        <img
          src={creditData.album_art}
          alt="Album art"
          width="40"
          height="40"
          style={{
            borderRadius: "8px",
            backgroundColor: "unset",
          }}
        />
      </td>
      <td>{creditData.track_title}</td>
      {
        <td>
          <>
            <p>{creditData.artist}</p>
          </>
        </td>
      }
      {isDesktop && desktopTableContents}
    </>
  );
};
