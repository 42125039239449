import { useSetPageTitle } from "../../../hooks/useSetPageTitle";

const LoadPredefinedProjectScreen = () => {
  useSetPageTitle(undefined);

  return (
    <div className="container-fluid centered-screen">This link is expired.</div>
  );
};

export default LoadPredefinedProjectScreen;
