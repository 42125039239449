import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ACCEPT_TEAM_INVITE,
  DASHBOARD_TEAM_API,
  DECLINE_TEAM_INVITE,
  GET_TEAM_INVITE,
  REMOVE_TEAM_MEMBER,
  REVOKE_TEAM_INVITE,
} from "../utils/routes";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  AffiliationInvite,
  Team,
  TeamRole,
  AffiliationInviteForTeam,
} from "../models/admins";
import { receiveErrors } from "./errorStore";

export const getTeams = createAsyncThunk(
  DASHBOARD_TEAM_API,
  async (_, thunkAPI) => {
    const params = "";
    const response = await makeBackendGetCallWithJsonResponse<Team[]>(
      DASHBOARD_TEAM_API,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface TeamInviteArgs {
  invite_id: number;
}

export const getTeamInvite = createAsyncThunk(
  GET_TEAM_INVITE,
  async ({ invite_id }: TeamInviteArgs, thunkAPI) => {
    const params = `?invite_id=${invite_id}`;
    const response =
      await makeBackendGetCallWithJsonResponse<AffiliationInviteForTeam>(
        GET_TEAM_INVITE,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
export const acceptTeamInvite = createAsyncThunk(
  ACCEPT_TEAM_INVITE,
  async ({ invite_id }: TeamInviteArgs, thunkAPI) => {
    const body = { invite_id };
    const response =
      await makeBackendPostCallWithJsonResponse<AffiliationInvite>(
        ACCEPT_TEAM_INVITE,
        body,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);
export const declineTeamInvite = createAsyncThunk(
  DECLINE_TEAM_INVITE,
  async ({ invite_id }: TeamInviteArgs, thunkAPI) => {
    const body = { invite_id };
    const response =
      await makeBackendPostCallWithJsonResponse<AffiliationInvite>(
        DECLINE_TEAM_INVITE,
        body,
      );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface RevokeInviteParams {
  invite_id: number;
  email: string;
  role: TeamRole;
  team_id: number;
}

export const revokeTeamInvite = createAsyncThunk(
  REVOKE_TEAM_INVITE,
  async ({ invite_id, email, role, team_id }: RevokeInviteParams, thunkAPI) => {
    const body = {
      invite_id: invite_id,
      email: email,
      role: TeamRole[role],
      team_id: team_id,
    };
    const response = await makeBackendPostCallWithJsonResponse<Team>(
      REVOKE_TEAM_INVITE,
      body,
    );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface RemoveTeamMemberInviteParams {
  user_id: number;
  team_id: number;
  role: TeamRole;
}

export const removeTeamInvite = createAsyncThunk(
  REMOVE_TEAM_MEMBER,
  async (
    { user_id, team_id, role }: RemoveTeamMemberInviteParams,
    thunkAPI,
  ) => {
    const body = { user_id: user_id, team_id: team_id, role: TeamRole[role] };
    const response = await makeBackendPostCallWithJsonResponse<Team>(
      REMOVE_TEAM_MEMBER,
      body,
    );
    if (response.success) {
      return response.resultJson;
    }

    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface TeamState {
  associatedTeams: Team[];
  selectedTeamId?: number;
  loading: boolean;
  updating: boolean;
}

const initialState: TeamState = {
  associatedTeams: [],
  selectedTeamId: undefined,
  loading: false,
  updating: false,
};

const teamSlice = createSlice({
  name: "teamStateSlice",
  initialState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<number>) => {
      if (action.payload < state.associatedTeams.length) {
        state.selectedTeamId = action.payload;
      }
    },
    updateTeam: (state, action: PayloadAction<Team>) => {
      const updatedTeam = action.payload;
      state.associatedTeams = state.associatedTeams.map((currentTeam) => {
        if (currentTeam.id === updatedTeam.id) {
          return updatedTeam;
        }
        return currentTeam;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeams.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.length > 0) {
        state.selectedTeamId = action.payload[0].id;
      }
      state.associatedTeams = action.payload;
    });
    builder.addMatcher(
      isAnyOf(revokeTeamInvite.pending, removeTeamInvite.pending),
      (state) => {
        state.updating = true;
      },
    );
    builder.addMatcher(
      isAnyOf(revokeTeamInvite.rejected, removeTeamInvite.rejected),
      (state) => {
        state.updating = false;
      },
    );
    builder.addMatcher(
      isAnyOf(revokeTeamInvite.fulfilled, removeTeamInvite.fulfilled),
      (state, action) => {
        state.updating = false;
        const updatedTeam = action.payload;
        state.associatedTeams = state.associatedTeams.map((currentTeam) => {
          if (currentTeam.id === updatedTeam.id) {
            return updatedTeam;
          }
          return currentTeam;
        });
      },
    );
  },
});

export const { updateTeam } = teamSlice.actions;
export default teamSlice.reducer;
