import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "stream-chat-react/dist/css/index.css";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import "./App.css";
import { darkModeAtom } from "./atoms/user/darkModeAtom";
import { FLAGSMITH_ENV_ID } from "./constants/featureFlags";
import { useQuery } from "./hooks/useQuery";
import {
  getDisciplineString,
  getPrimaryDiscipline,
  useUserDisciplinesString,
} from "./hooks/user";
import store from "./store";
import {
  loadUser,
  storeLocalUTMParams,
  updateProfile,
  updateUserLocation,
} from "./store/actions/accountInfo";
import { fetchAllUserFavorites } from "./store/actions/dashboard";
import { clearErrors } from "./store/actions/errorStore";
import { setUserLatLngForStudioRoomSearch } from "./store/actions/studioRoomSearch";
import {
  setUserLatLngForUserSearch,
  toggleRequestingLatLong,
} from "./store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  exceptionCodeErrorStringMap,
  STUDIO_PROFILE_FOUND,
  TRANSITION_NOT_ALLOWED,
  UNKNOWN_ERROR,
} from "./store/models/exceptions";
import { ProjectType } from "./store/models/project";
import { LocalUTMParams } from "./store/models/user";
import {
  engineerOnboardedDateSelector,
  isEngineerOnboardedSelector,
  isEngineerVerifiedSelector,
  selectUserCanEnableStudioServices,
} from "./store/selectors/userInfoSelectors";
import {
  DEVELOPMENT_STRIPE,
  getPaypalClientId,
  GOOGLE_OAUTH_CLIENT_ID,
  isProd,
  PRODUCTION_STRIPE,
} from "./store/utils";
import { AppBarHeader } from "./stories/components/AppBarHeader/AppBarHeader";
import ChatSuspense from "./stories/components/ChatInjector/ChatSuspense";
import LoadingScreen from "./stories/components/LoadingScreen/LoadingScreen";
import { PopoverContainerContextProvider } from "./stories/core-ui/components/BasePopover/PopoverContainerContext";
import { ThemeDiv } from "./stories/screens/ThemeDiv/ThemeDiv";
import { darkTheme, GlobalStyles, lightTheme } from "./stories/theme";
import { MuiThemeProvider } from "./styles/muiTheme/MuiTheme";
import "./styles/stream-chat-react.css";
import UiContent from "./UiContent";
import {
  getDebugEventUserIdPrefix,
  identifyAuthenticatedUser,
  identifyUnauthenticatedUser,
} from "./utils/analyticsUtils";
import OnlineHeartbeat from "./stories/components/ErrorBoundary/OnlineHeartbeat";

const paypalScriptOptions: PayPalScriptOptions = {
  "client-id": getPaypalClientId,
};

const queryClient = new QueryClient();
const persistor = persistStore(store);

const App = () => {
  const flagsmithEnvironmentId = FLAGSMITH_ENV_ID;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const accountInfo = useAppSelector((state) => state.accountInfo);
  const verifiedEngineer = useAppSelector(isEngineerVerifiedSelector);
  const subscriptionPlanChoice = useAppSelector(
    (state) => state.subscriptionStore.subscription_plan_choice,
  );
  const onboardedEngineer = useAppSelector(isEngineerOnboardedSelector);
  const canManageStudios = useAppSelector(selectUserCanEnableStudioServices);
  const engineerOnboardedDate = useAppSelector(engineerOnboardedDateSelector);
  const { isAuthenticated, user, localUTMParams } = accountInfo;
  const darkMode = useAtomValue(darkModeAtom);
  const [initialAppLoad, setInitLoad] = useState(true);
  const [userDisciplinesString] = useUserDisciplinesString(user);
  const errors = useAppSelector((state) => state.errorStore.errors);
  const location = useLocation();
  const {
    latitude,
    longitude,
    serviceTypes: selectedServices,
    requestingLatLong: isRequestingLatLong,
  } = useAppSelector((state) => state.userSearch);
  const { userFavorites } = useAppSelector((state) => state.dashboard);

  useEffect(() => {
    if (latitude > 0 && longitude > 0) {
      return;
    }
    if (
      selectedServices.includes(ProjectType.RECORDING) &&
      isRequestingLatLong
    ) {
      const onSuccess = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLatLngForUserSearch({ latitude, longitude }));
        dispatch(setUserLatLngForStudioRoomSearch({ latitude, longitude }));
        dispatch(toggleRequestingLatLong());
      };
      const onFail = () => {
        dispatch(toggleRequestingLatLong());
      };
      window.navigator.geolocation.getCurrentPosition(onSuccess, onFail);
    }
  }, [selectedServices, dispatch, isRequestingLatLong, latitude, longitude]);

  // useEffect for storing UTM parameters.
  useEffect(() => {
    const localUTMParams: LocalUTMParams = {
      utm_source: query.get("utm_source"),
      utm_medium: query.get("utm_medium"),
      utm_campaign: query.get("utm_campaign"),
      utm_content: query.get("utm_content"),
      utm_term: query.get("utm_term"),
    };
    if (!localUTMParams.utm_source) return;
    dispatch(storeLocalUTMParams(localUTMParams));
    // "Soft-register" the user so that an anonymous ID is generated by segment.
    if (!isAuthenticated) {
      identifyUnauthenticatedUser({
        utm_source: `${localUTMParams.utm_source}`,
        utm_medium: `${localUTMParams.utm_medium}`,
        utm_campaign: `${localUTMParams.utm_campaign}`,
        utm_content: `${localUTMParams.utm_content}`,
        utm_term: `${localUTMParams.utm_term}`,
      });
    }
  }, [dispatch, query, isAuthenticated]);

  const handleUserLocationUpdate = useCallback(async () => {
    if (navigator.permissions) {
      await navigator.permissions
        .query({ name: "geolocation" })
        .then(async (result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(async (position) => {
              await dispatch(
                updateUserLocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }),
              );
            });
          } else {
            await dispatch(updateUserLocation({}));
          }
        });
    } else {
      await dispatch(updateUserLocation({}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      updateProfile({
        timezone_shift_minutes: timezoneOffset,
        timezone,
      }),
    )
      .unwrap()
      .then(() => {})
      .catch(() => {});
    handleUserLocationUpdate()
      .then(() => {})
      .catch(() => {});
  }, [dispatch, isAuthenticated, handleUserLocationUpdate]);

  // Sends local UTM params to the backend on sign in.
  useEffect(() => {
    if (!isAuthenticated) return;
    if (!localUTMParams.utm_source) return;
    // We've already posted this data.
    if (user?.utm_source === localUTMParams.utm_source) return;
    // Post updates to the backend if user is signed in.
    if (!location.pathname.includes("verify-email")) {
      dispatch(
        updateProfile({
          utm_source: localUTMParams.utm_source,
          utm_medium: localUTMParams.utm_medium,
          utm_campaign: localUTMParams.utm_campaign,
          utm_content: localUTMParams.utm_content,
          utm_term: localUTMParams.utm_term,
        }),
      )
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [
    dispatch,
    isAuthenticated,
    localUTMParams,
    location.pathname,
    user?.utm_source,
  ]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (userFavorites === null) {
      void dispatch(fetchAllUserFavorites());
    }
  }, [dispatch, isAuthenticated, userFavorites]);

  // Load the logged-in user information on initial app load.
  useEffect(() => {
    setInitLoad(true);
    dispatch(loadUser())
      .unwrap()
      .then(() => {
        setInitLoad(false);
      })
      .catch(() => {
        setInitLoad(false);
      });
  }, [dispatch]);

  // Update the active sentry user whenever it changes.
  useEffect(() => {
    if (initialAppLoad) {
      return;
    }

    if (user?.id) {
      Sentry.setUser({ id: user.id });
    } else {
      Sentry.setUser(null);
    }
  }, [user?.id, initialAppLoad]);

  useEffect(() => {
    errors.forEach((error) => {
      if (error === undefined) return;
      if ("code" in error && error.code === STUDIO_PROFILE_FOUND) {
        if (window.location.pathname.includes("/studio/")) return;
        history.push("/studio" + window.location.pathname);
      }
      if ("code" in error && error.code === UNKNOWN_ERROR) {
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          toast.error(error.non_field_errors[0]);
        }
      } else if (
        "code" in error &&
        error.code === TRANSITION_NOT_ALLOWED &&
        "detail" in error &&
        error.detail &&
        error.detail.length > 0
      ) {
        toast.error(error.detail);
      } else {
        if (exceptionCodeErrorStringMap.get(error.code)?.length !== 0) {
          toast.error(exceptionCodeErrorStringMap.get(error.code));
        }
      }
    });
    dispatch(clearErrors());
  }, [errors, dispatch, history]);

  useEffect(() => {
    loadStripe(isProd ? PRODUCTION_STRIPE : DEVELOPMENT_STRIPE, {
      betas: ["klarna_pm_beta_1"],
    })
      .then(() => {})
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      identifyAuthenticatedUser(user.id, {
        user_id: `${getDebugEventUserIdPrefix}${user.id}`,
        username: `${user.username}`,
        display_name: `${user.profile?.display_name}`,
        email: `${user.email}`,
        firstname: `${user.first_name}`,
        lastname: `${user.last_name}`,
        date_joined: `${user.date_joined}`,
        utm_source: `${user.utm_source}`,
        utm_medium: `${user.utm_medium}`,
        utm_campaign: `${user.utm_campaign}`,
        utm_content: `${user.utm_content}`,
        utm_term: `${user.utm_term}`,
        account_type: `${getDisciplineString(getPrimaryDiscipline(user))}`,
        verified_engineer: `${verifiedEngineer}`,
        engineer_subscription: `${subscriptionPlanChoice}`,
        started_onboarding: `${onboardedEngineer}`,
        started_onboarding_date: `${engineerOnboardedDate}`,
        all_account_types: `${userDisciplinesString}`,
        can_manage_studios: `${canManageStudios}`,
      });
    }
  }, [
    user,
    isAuthenticated,
    verifiedEngineer,
    subscriptionPlanChoice,
    onboardedEngineer,
    userDisciplinesString,
    dispatch,
    canManageStudios,
    engineerOnboardedDate,
  ]);

  useEffect(() => {
    if (!user) {
      document.body.classList.remove("body-dark-mode");
      return;
    }
    if (darkMode) {
      document.body.classList.add("body-dark-mode");
    }
    if (!darkMode) {
      document.body.classList.remove("body-dark-mode");
    }
  }, [darkMode, user]);

  const hideMainContent = initialAppLoad;

  return (
    <OnlineHeartbeat>
      <FlagsmithProvider
        options={{
          environmentID: flagsmithEnvironmentId,
        }}
        flagsmith={flagsmith}
      >
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider darkMode={Boolean(darkMode)}>
            <StyledComponentThemeProvider
              theme={darkMode ? darkTheme : lightTheme}
            >
              <GlobalStyles theme={darkMode ? darkTheme : lightTheme} />
              <ThemeDiv darkMode={darkMode ? "dark" : ""}>
                <PopoverContainerContextProvider>
                  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                    <PayPalScriptProvider options={paypalScriptOptions}>
                      <PersistGate
                        loading={<LoadingScreen />}
                        persistor={persistor}
                      >
                        <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          closeOnClick
                          hideProgressBar={false}
                          pauseOnHover
                        />
                        <ChatSuspense>
                          <AppBarHeader isEmptyHeader={hideMainContent} />
                          {!hideMainContent && <UiContent />}
                        </ChatSuspense>
                      </PersistGate>
                    </PayPalScriptProvider>
                  </GoogleOAuthProvider>
                </PopoverContainerContextProvider>
              </ThemeDiv>
            </StyledComponentThemeProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </FlagsmithProvider>
    </OnlineHeartbeat>
  );
};

export default App;
