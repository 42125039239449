import React, { useState } from "react";
import "./PercentageTextfield.css";

export interface PercentageTextfieldProps {
  label?: string;
  className?: string;
  initialValue?: number;
  min?: number;
  max?: number;
  onChangePercentage?: (value: number) => void;
}

export const PercentageTextfield = ({
  label = "",
  className,
  initialValue,
  min = 0,
  max = 100,
  onChangePercentage,
  ...props
}: PercentageTextfieldProps) => {
  const [value, setValue] = useState<number>(initialValue ? initialValue : 0);

  return (
    <div>
      <input
        className={className ?? "textfield"}
        style={{
          width: 80,
        }}
        name="text"
        type="number"
        value={Math.round(value! * 100)}
        {...props}
        placeholder={label}
        onChange={(e) => {
          let valueAsInt = parseInt(e.target.value);
          if (valueAsInt < min) {
            valueAsInt = min;
          }
          if (valueAsInt > max) {
            valueAsInt = max;
          }
          if (onChangePercentage) {
            const newValue = valueAsInt / 100;
            setValue(newValue);
            onChangePercentage(newValue);
          }
        }}
      />
      <span className="b1" style={{ marginLeft: -40 }}>
        %
      </span>
    </div>
  );
};
