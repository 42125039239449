import { Team, TeamRole } from "../../../store/models/admins";
import { makeBackendPostCallWithJsonResponse } from "../../../store/utils/fetch";
import { CREATE_TEAM_INVITE } from "../../../store/utils/routes";

export interface CreateInviteParams {
  team_id: number;
  role: TeamRole;
  emails: string | string[];
  full_name?: string;
  phone_number?: string | undefined;
}

export const createTeamInvite = async ({
  team_id,
  role,
  emails,
  full_name,
  phone_number,
}: CreateInviteParams) => {
  const body = {
    team_id: team_id,
    role: TeamRole[role],
    emails: emails,
    full_name: full_name,
    phone_number: phone_number,
  };
  const response = await makeBackendPostCallWithJsonResponse<Team>(
    CREATE_TEAM_INVITE,
    body,
  );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default createTeamInvite;
