import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../index";
import { MockMixingProject } from "../models/mockDataForMixMaster/mockDataForMixMaster";
import {
  APIMasteringProject,
  APIMixingProject,
  APIProjectListenLink,
  APIProjectUploadLink,
  APIRecordingSessionShareLink,
  contextualTransitionMessageMastering,
  contextualTransitionMessageMixing,
  MasteringTransitions,
  MixingTransitions,
  Project,
  ProjectById,
  ProjectType,
  ScheduledProjectPaywallTypes,
  transformRawData,
} from "../models/project";
import { Transaction } from "../models/transaction";
import {
  downloadFilesFromUrls,
  downloadFilesFromUrlsWithDelay,
} from "../utils/downloadFilesFromUrls";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { getProjectFilesRoute } from "../utils/routeGetters";
import {
  ARTIST_MASTER_PROJECT,
  ARTIST_MIXING_PROJECT,
  CREATE_LISTEN_LINK,
  CREATE_RECORDING_SESSION_SHARE_LINK,
  CREATE_UPLOAD_LINK,
  DOWNLOAD_ALL_FILES,
  DOWNLOAD_FINAL_FILES,
  DOWNLOAD_PROJECT_FILES,
  DOWNLOAD_REVIEW_TRACKS,
  DOWNLOAD_SINGLE_TRACK,
  ENGINEER_MASTER_PROJECT,
  ENGINEER_MIXING_PROJECT,
  GET_PROJECT_FILES,
  LOAD_IN_PROGRESS_PROJECT,
  LOAD_PROJECT,
  LOAD_PROJECTS,
  MARK_FINAL_ASSETS_APPROVED,
  RENAME_PROJECT_OR_SCHEDULED_PROJECT,
  START_IN_PROGRESS_PROJECT,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";

interface ProjectsState {
  isLoading: boolean;
  mixingProjects: Project[];
  masteringProjects: Project[];
  mastering_pages: number;
  mixing_pages: number;
  total_mastering?: number;
  total_mixing?: number;
  singleProjectIsUpdating: boolean;
  contextualPendingMessage: string;
  downloadingAllTracks: boolean;
}

const initialState: ProjectsState = {
  isLoading: false,
  singleProjectIsUpdating: false,
  contextualPendingMessage: "",
  mixingProjects: [],
  masteringProjects: [],
  mastering_pages: 0,
  mixing_pages: 0,
  downloadingAllTracks: false,
};

export interface loadProjectsParams {
  mastering_page: number;
  mixing_page: number;
}

interface LoadProjectsResponse {
  mixing_projects: APIMixingProject[];
  mixing_pages: number;
  mastering_projects: APIMasteringProject[];
  mastering_pages: number;
}

export const loadProjects = createAsyncThunk(
  LOAD_PROJECTS,
  async (args: loadProjectsParams, thunkAPI) => {
    let { mastering_page, mixing_page } = args;
    const {
      projectsStore: { total_mastering, total_mixing },
    } = thunkAPI.getState() as RootState;

    if (total_mastering !== undefined && mastering_page > total_mastering) {
      mastering_page = total_mastering;
    }
    if (total_mixing !== undefined && mixing_page > total_mixing) {
      mixing_page = total_mixing;
    }
    const params = `?mixing_page=${mixing_page}&mastering_page=${mastering_page}`;
    const response =
      await makeBackendGetCallWithJsonResponse<LoadProjectsResponse>(
        LOAD_PROJECTS,
        params,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface ProjectParams {
  project_id: string;
  transition: MixingTransitions;
  skipLoadingIndicator?: boolean;
}

export interface engineerMixTransitionParams extends ProjectParams {
  engineer_mix_notes?: string;
  engineer_file_notes?: string;
  engineer_reference_notes?: string;
  enable_artist_review_mp3_file_download?: boolean;
  enable_artist_review_wav_file_download?: boolean;
}

interface artistMixTransitionParams extends ProjectParams {
  artist_mix_notes?: string;
  artist_mix_revision_notes?: string;
  code?: string | null;
  artist_file_link?: string;
  final_asset_file_notes?: string;
  instagram_username?: string;
  social_media_sharing_approval?: boolean;
  // TODO: Remove this param once ArtistMixingProjectAPI has been updated to handle in progress projects.
  is_in_progress_project?: boolean;
}

export const artistMixingTransitions = createAsyncThunk(
  ARTIST_MIXING_PROJECT,
  async (args: artistMixTransitionParams, thunkAPI) => {
    if (!args.code) {
      delete args.code;
    }
    const response =
      await makeBackendPostCallWithJsonResponse<APIMixingProject>(
        ARTIST_MIXING_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const engMixTransition = createAsyncThunk(
  ENGINEER_MIXING_PROJECT,
  async (args: engineerMixTransitionParams, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<APIMixingProject>(
        ENGINEER_MIXING_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface renameProjectOrScheduledProjectParams {
  project_id?: number;
  scheduled_project_id?: number;
  transaction_code?: string;
  title: string;
}

export const renameProjectOrScheduledProject = createAsyncThunk(
  RENAME_PROJECT_OR_SCHEDULED_PROJECT,
  async (args: renameProjectOrScheduledProjectParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      Transaction | object
    >(RENAME_PROJECT_OR_SCHEDULED_PROJECT, args);

    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface masteringTransitionParams {
  project_id: string;
  transition: MasteringTransitions;
  skipLoadingIndicator?: boolean;
}

interface artistMasteringTransition extends masteringTransitionParams {
  artist_master_notes?: string;
  artist_master_revision_notes?: string;
  code?: string | null;
  artist_file_link?: string;
  final_asset_file_notes?: string;
  instagram_username?: string;
  social_media_sharing_approval?: boolean;
}

export const artistMasteringTransitions = createAsyncThunk(
  ARTIST_MASTER_PROJECT,
  async (args: artistMasteringTransition, thunkAPI) => {
    if (!args.code) {
      delete args.code;
    }
    const response =
      await makeBackendPostCallWithJsonResponse<APIMasteringProject>(
        ARTIST_MASTER_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface engineerMasteringTransition extends masteringTransitionParams {
  engineer_file_notes?: string;
  engineer_master_notes?: string;
  enable_artist_review_mp3_file_download?: boolean;
  enable_artist_review_wav_file_download?: boolean;
}

export const engMasteringTransition = createAsyncThunk(
  ENGINEER_MASTER_PROJECT,
  async (args: engineerMasteringTransition, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<APIMasteringProject>(
        ENGINEER_MASTER_PROJECT,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface loadProjectParams {
  project_id: string;
}

export interface downloadFilesByIdParams {
  project_id: number;
  file_version_ids: number[];
  code?: string;
}

export const loadProject = createAsyncThunk(
  LOAD_PROJECT,
  async ({ project_id }: loadProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}`;
    const response = await makeBackendGetCallWithJsonResponse<
      APIMixingProject | APIMasteringProject
    >(LOAD_PROJECT, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface loadInProgressProjectParams {
  project_id: string;
  code: string;
}

export const loadInProgressProject = createAsyncThunk(
  LOAD_IN_PROGRESS_PROJECT,
  async ({ project_id, code }: loadInProgressProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}&code=${code}`;
    const response = await makeBackendGetCallWithJsonResponse<
      APIMixingProject | APIMasteringProject
    >(LOAD_IN_PROGRESS_PROJECT, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface startInProgressProjectParams {
  scheduled_project_id: number;
  default_paywall_option: ScheduledProjectPaywallTypes;
}

export const startInProgressProject = createAsyncThunk(
  START_IN_PROGRESS_PROJECT,
  async (params: startInProgressProjectParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<object>(
      START_IN_PROGRESS_PROJECT,
      params,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const currentProjectMatches =
  (project_id: number) =>
  (mixOrMaster: Project): boolean => {
    return mixOrMaster.id === project_id;
  };

interface DownloadFilesResponse {
  urls: string[];
}

/**
 * Allows Engineers and Artists to download all reference/working files for a single project
 */
export const downloadFiles = createAsyncThunk(
  DOWNLOAD_PROJECT_FILES,
  async ({ project_id }: loadProjectParams, thunkAPI) => {
    const params = `?project_id=${project_id}`;
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_PROJECT_FILES,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

/**
 * Allows collaborator to download files for a single project based on the file IDs
 */
export const downloadFilesById = createAsyncThunk(
  GET_PROJECT_FILES,
  async (
    { project_id, file_version_ids, code }: downloadFilesByIdParams,
    thunkAPI,
  ) => {
    const projectFilesRoute = getProjectFilesRoute(
      project_id,
      file_version_ids,
      code,
    );

    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        projectFilesRoute,
        "",
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const createUploadLink = createAsyncThunk(
  CREATE_UPLOAD_LINK,
  async (args: loadProjectParams, thunkAPI) => {
    const response =
      await makeBackendPostCallWithJsonResponse<APIProjectUploadLink>(
        CREATE_UPLOAD_LINK,
        args,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface createListingLinkParams extends loadProjectParams {
  password?: string;
  emails?: string[];
  single_use?: boolean;
}

export const createListenLink = createAsyncThunk(
  CREATE_LISTEN_LINK,
  async (args: createListingLinkParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      APIProjectListenLink[]
    >(CREATE_LISTEN_LINK, args);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface createRecordingSessionShareLinkParams
  extends loadProjectParams {
  password?: string;
  emails?: string[];
}

export const createRecordingSessionShareLink = createAsyncThunk(
  CREATE_RECORDING_SESSION_SHARE_LINK,
  async (args: createListingLinkParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<
      APIRecordingSessionShareLink[]
    >(CREATE_RECORDING_SESSION_SHARE_LINK, args);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface downloadFinalFilesParams {
  project_id: string;
  get_zip_only?: boolean;
  code?: string;
}

export const downloadFinalFiles = createAsyncThunk(
  DOWNLOAD_FINAL_FILES,
  async (args: downloadFinalFilesParams, thunkAPI) => {
    let params = `?project_id=${args.project_id}`;
    params +=
      "get_zip_only" in args && args.get_zip_only
        ? `&get_zip_only=${args.get_zip_only}`
        : ``;
    params += "code" in args && args.code ? `&code=${args.code}` : ``;
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_FINAL_FILES,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface downloadSingleTrackParams {
  project_id: string;
  get_mp4?: boolean;
}

export const downloadSingleTrack = createAsyncThunk(
  DOWNLOAD_SINGLE_TRACK,
  async (args: downloadSingleTrackParams, thunkAPI) => {
    let params = `?project_id=${args.project_id}`;
    params +=
      "get_mp4" in args && args.get_mp4 ? `&get_mp4=${args.get_mp4}` : ``;
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_SINGLE_TRACK,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrls(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface downloadReviewTracksParams {
  project_id: number;
}

export const downloadReviewTracks = createAsyncThunk(
  DOWNLOAD_REVIEW_TRACKS,
  async (args: downloadReviewTracksParams, thunkAPI) => {
    const params = `?project_id=${args.project_id}`;
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_REVIEW_TRACKS,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface downloadAllFilesParams {
  scheduledProjectId: string;
  shareLinkCode?: string;
}

export const downloadAllFilesForScheduledProject = createAsyncThunk(
  DOWNLOAD_ALL_FILES,
  async (
    { scheduledProjectId, shareLinkCode }: downloadAllFilesParams,
    thunkAPI,
  ) => {
    let params = `?scheduled_project_id=${scheduledProjectId}`;
    if (shareLinkCode) {
      params += `&code=${shareLinkCode}`;
    }
    const response =
      await makeBackendGetCallWithJsonResponse<DownloadFilesResponse>(
        DOWNLOAD_ALL_FILES,
        params,
      );
    if (response.success) {
      const { urls } = response.resultJson;
      downloadFilesFromUrlsWithDelay(urls);
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface markFinalAssetsApprovedParams {
  project_id?: number;
  code?: string;
  qc_approver_email?: string;
}

export const markFinalAssetsApproved = createAsyncThunk(
  MARK_FINAL_ASSETS_APPROVED,
  async (args: markFinalAssetsApprovedParams, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<ProjectById>(
      MARK_FINAL_ASSETS_APPROVED,
      args,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateProjectCounts: (state, action: PayloadAction<Project>) => {
      if (
        state.mixingProjects.some((project) => project.id === action.payload.id)
      ) {
        state.mixingProjects = state.mixingProjects.map((project) => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              revisions_available: action.payload.revisions_available,
              revision_counter: action.payload.revision_counter,
            };
          }
          return project;
        });
      } else if (
        state.masteringProjects.some(
          (project) => project.id === action.payload.id,
        )
      ) {
        state.masteringProjects = state.masteringProjects.map((project) => {
          if (project.id === action.payload.id) {
            return {
              ...project,
              revisions_available: action.payload.revisions_available,
              revision_counter: action.payload.revision_counter,
            };
          }
          return project;
        });
      }
    },
    clearProjectsState: (state) => {
      state = { ...initialState };
      return state;
    },
    addMockProject: (state) => {
      state.mixingProjects = [MockMixingProject];
    },
    updateProjectInProjectStore: (state, action: PayloadAction<Project>) => {
      if (
        state.mixingProjects.some((project) => project.id === action.payload.id)
      ) {
        state.mixingProjects = state.mixingProjects.map((project) => {
          if (project.id === action.payload.id) {
            return action.payload;
          }
          return project;
        });
      } else if (
        state.masteringProjects.some(
          (project) => project.id === action.payload.id,
        )
      ) {
        state.masteringProjects = state.masteringProjects.map((project) => {
          if (project.id === action.payload.id) {
            return action.payload;
          }
          return project;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      downloadAllFilesForScheduledProject.pending,
      (state: ProjectsState) => {
        state.downloadingAllTracks = true;
      },
    );
    builder.addCase(
      downloadAllFilesForScheduledProject.fulfilled,
      (state: ProjectsState) => {
        state.downloadingAllTracks = false;
      },
    );
    builder.addCase(downloadAllFilesForScheduledProject.rejected, (state) => {
      state.downloadingAllTracks = false;
    });
    builder.addCase(loadProjects.rejected, (state: ProjectsState) => {
      state.isLoading = false;
    });
    builder.addCase(loadProjects.pending, (state: ProjectsState) => {
      state.isLoading = true;
    });
    builder.addCase(loadProjects.fulfilled, (state: ProjectsState, action) => {
      const { payload } = action;
      state.mixing_pages = payload.mixing_pages;
      state.mastering_pages = payload.mastering_pages;
      state.mixingProjects = payload.mixing_projects.map(transformRawData);
      state.masteringProjects =
        payload.mastering_projects.map(transformRawData);
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(loadProject.pending, loadInProgressProject.pending),
      (state: ProjectsState) => {
        state.singleProjectIsUpdating = true;
        state.contextualPendingMessage = "fetching project details";
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.pending,
        artistMasteringTransitions.pending,
      ),
      (state: ProjectsState, action) => {
        const message = contextualTransitionMessageMastering.get(
          action.meta.arg.transition,
        );
        if (!message) {
          state.contextualPendingMessage = "transitioning project to next step";
        } else {
          state.contextualPendingMessage = message;
        }
        if (!action.meta.arg?.skipLoadingIndicator) {
          state.singleProjectIsUpdating = true;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(artistMixingTransitions.pending, engMixTransition.pending),
      (state: ProjectsState, action) => {
        const message = contextualTransitionMessageMixing.get(
          action.meta.arg.transition,
        );
        if (!message) {
          state.contextualPendingMessage = "transitioning project to next step";
        } else {
          state.contextualPendingMessage = message;
        }
        if (!action.meta.arg?.skipLoadingIndicator)
          state.singleProjectIsUpdating = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.rejected,
        artistMixingTransitions.rejected,
        loadProject.rejected,
        engMixTransition.rejected,
        artistMasteringTransitions.rejected,
        loadInProgressProject.rejected,
      ),
      (state) => {
        state.singleProjectIsUpdating = false;
        state.contextualPendingMessage = "";
      },
    );
    builder.addMatcher(
      isAnyOf(
        engMasteringTransition.fulfilled,
        loadProject.fulfilled,
        artistMixingTransitions.fulfilled,
        engMixTransition.fulfilled,
        artistMasteringTransitions.fulfilled,
        loadInProgressProject.fulfilled,
      ),
      (state: ProjectsState, action) => {
        const { payload } = action;
        if (payload.project?.service_type === ProjectType.MASTERING) {
          const index = state.masteringProjects.findIndex(
            currentProjectMatches(payload.project.id),
          );
          const updatedProject = transformRawData(payload);

          if (index < 0) {
            state.masteringProjects.push(updatedProject);
          } else {
            state.masteringProjects[index] = updatedProject;
          }
        }
        if (
          payload.project?.service_type === ProjectType.MIXING ||
          payload.project?.service_type === ProjectType.TWO_TRACK_MIXING ||
          payload.project?.service_type === ProjectType.ATMOS_MIXING
        ) {
          const index = state.mixingProjects.findIndex(
            currentProjectMatches(payload.project.id),
          );
          const updatedProject = transformRawData(payload);

          if (index < 0) {
            state.mixingProjects.push(updatedProject);
          } else {
            state.mixingProjects[index] = updatedProject;
          }
        }
        state.contextualPendingMessage = "";
        state.singleProjectIsUpdating = false;
      },
    );
  },
});

export const {
  clearProjectsState,
  addMockProject,
  updateProjectCounts,
  updateProjectInProjectStore,
} = projectSlice.actions;

export default projectSlice.reducer;
