import {
  faCheck,
  faGreaterThan,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import humanizeDuration from "humanize-duration";
import { useCallback, useMemo } from "react";
import {
  useIsInProgressProject,
  usePaymentPlanStatus,
} from "../../../hooks/partialPaymentHooks";
import { useAppSelector } from "../../../store/hooks";
import {
  DetailedPurchaseOrderWithTransaction,
  projectTypeReadableName,
} from "../../../store/models/project";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import {
  convertLocalDateToUTCDate,
  convertUTCDateToLocalDate,
} from "../../../store/utils/dateTimeUtils";
import {
  getMyBookingsRoute,
  getProjectOverviewRoute,
} from "../../../store/utils/routeGetters";
import { convertMinutesToMilliseconds } from "../../../store/utils/serviceUtils";
import { formatDateToShort } from "../../../utils/formatDateToShort";
import {
  getUserIsEngineerOnScheduledProject,
  isUserCollaboratorOnProject,
} from "../../../utils/projectUtils";
import {
  InfoBadge,
  InfoBadgeType,
} from "../../core-ui/components/InfoBadge/InfoBadge";
import { ProjectListRow } from "../../elements/ProjectListRow/ProjectListRow";
import { ProjectChatPanel } from "../ProjectChatPanel/ProjectChatPanel";
import { ScheduledProjectActionsDropdown } from "../SchduledProjectActionsDropdown/ScheduledProjectActionsDropdown";
import { ShareToClientButton } from "../ShareToClientButton/ShareToClientButton";
import {
  BreadCrumbContainer,
  BreadCrumbIcon,
  BreadCrumbLink,
  BudgetBlock,
  BudgetRow,
  ContentRow,
  DetailAndStatusRow,
  DetailsAndBudgetRow,
  DetailsBlock,
  InnerContainer,
  OuterContainer,
  OverviewHeaderDropDownSection,
  ProgressBlock,
  ProgressBlockKeyText,
  ProgressBlockValueText,
  ProjectIdContainer,
  ProjectOverviewText,
  SecondaryDetailedBlockKeyText,
  SecondaryDetailedBlockValueText,
  StatusBlock,
  VerticalSeparator,
} from "./ProjectHeader.styles";

export interface ProjectHeaderProps {
  scheduledProject: ScheduledProject;
  purchaseOrder?: DetailedPurchaseOrderWithTransaction;
  code?: string;
}

export const ProjectHeader = ({
  scheduledProject,
  purchaseOrder,
  code,
}: ProjectHeaderProps) => {
  const isTablet = useMediaQuery("(min-width: 481px) and (max-width: 1024px)");
  const serviceTypeSet = useMemo(() => {
    const returnValue = new Set<string>();
    scheduledProject.projects.forEach((project) => {
      const readableServiceType = projectTypeReadableName.get(
        project.service_type,
      );
      if (readableServiceType) {
        returnValue.add(readableServiceType);
      }
    });
    return returnValue;
  }, [scheduledProject.projects]);
  const isPartiallyPaid = useMemo(
    () => Boolean(scheduledProject.outstanding_balance > 0),
    [scheduledProject.outstanding_balance],
  );
  const isProjectCancelled = Boolean(scheduledProject.refunded);
  const { redirectToPurchaseProject } = usePaymentPlanStatus(
    code,
    scheduledProject.id,
    undefined,
    isPartiallyPaid,
  );
  const { isInProgressProject } = useIsInProgressProject(scheduledProject);
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const userIsEngineerOnScheduledProject = getUserIsEngineerOnScheduledProject(
    currentUser,
    scheduledProject,
  );
  const userIsCollaborator = isUserCollaboratorOnProject(
    currentUser,
    scheduledProject?.users,
  );

  const disableDownloadAndSharingActions = useMemo(() => {
    return (
      !userIsEngineerOnScheduledProject &&
      (isPartiallyPaid || isInProgressProject)
    );
  }, [isPartiallyPaid, isInProgressProject, userIsEngineerOnScheduledProject]);

  const numberOfCompletedTracks = useMemo(
    () =>
      scheduledProject.projects.filter((project) => project.completed).length,
    [scheduledProject.projects],
  );

  const completedTracksPercentage = useMemo(() => {
    return (numberOfCompletedTracks / scheduledProject.projects.length) * 100;
  }, [numberOfCompletedTracks, scheduledProject.projects.length]);

  const estimatedCompletionDate = useMemo(
    () =>
      scheduledProject.estimated_delivery_date
        ? formatDateToShort(new Date(scheduledProject.estimated_delivery_date))
        : "TBD",
    [scheduledProject.estimated_delivery_date],
  );

  const calculateProjectDuration = useCallback(() => {
    const now = convertLocalDateToUTCDate(new Date());
    const createdDate = new Date(scheduledProject.created);
    const completedDate = scheduledProject.completed
      ? new Date(scheduledProject.completed)
      : now;

    const durationInMinutes = differenceInMinutes(completedDate, createdDate);

    return humanizeDuration(convertMinutesToMilliseconds(durationInMinutes), {
      largest: 2,
      round: true,
      delimiter: " ",
    });
  }, [scheduledProject.created, scheduledProject.completed]);

  const DetailsBlockComponent = useCallback(() => {
    return (
      <DetailsBlock>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Project Name
          </SecondaryDetailedBlockKeyText>
          <div style={{ marginBottom: "12px" }}></div>
          <ProjectListRow
            allowEdit={!isProjectCancelled && userIsCollaborator}
            paginatedScheduledProject={scheduledProject}
          />
        </ProjectIdContainer>
        <span style={{ marginBottom: "16px" }}></span>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Project ID:{" "}
          </SecondaryDetailedBlockKeyText>
          <SecondaryDetailedBlockValueText>
            {scheduledProject.id}
          </SecondaryDetailedBlockValueText>
        </ProjectIdContainer>
        <ProjectIdContainer>
          <SecondaryDetailedBlockKeyText>
            Booking date:{" "}
          </SecondaryDetailedBlockKeyText>
          <SecondaryDetailedBlockValueText>
            {formatDateToShort(
              convertUTCDateToLocalDate(new Date(scheduledProject.created)),
            )}
          </SecondaryDetailedBlockValueText>
        </ProjectIdContainer>
        {scheduledProject.refunded && (
          <ProjectIdContainer>
            <SecondaryDetailedBlockKeyText>
              Refunded:{" "}
            </SecondaryDetailedBlockKeyText>
            <SecondaryDetailedBlockValueText>
              {formatDateToShort(
                convertUTCDateToLocalDate(new Date(scheduledProject.refunded)),
              )}
            </SecondaryDetailedBlockValueText>
          </ProjectIdContainer>
        )}
      </DetailsBlock>
    );
  }, [scheduledProject]);

  const ProgressBlockComponent = useCallback(() => {
    if (scheduledProject.refunded) return null;
    return (
      <ProgressBlock>
        <BudgetRow>
          <ProgressBlockKeyText>Services:</ProgressBlockKeyText>
          <ProgressBlockValueText>
            {Array.from(serviceTypeSet).join(", ")}
          </ProgressBlockValueText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Completed tracks:</ProgressBlockKeyText>
          <ProgressBlockValueText>
            {numberOfCompletedTracks}/{scheduledProject.projects.length} (
            {Math.round(completedTracksPercentage)}%)
          </ProgressBlockValueText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Est. completion date:</ProgressBlockKeyText>
          <ProgressBlockValueText>
            {estimatedCompletionDate}
          </ProgressBlockValueText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Project Duration:</ProgressBlockKeyText>
          <ProgressBlockValueText>
            {calculateProjectDuration()}
          </ProgressBlockValueText>
        </BudgetRow>
      </ProgressBlock>
    );
  }, [
    serviceTypeSet,
    numberOfCompletedTracks,
    scheduledProject.projects.length,
    scheduledProject.refunded,
    completedTracksPercentage,
    estimatedCompletionDate,
    calculateProjectDuration,
  ]);

  const BudgetBlockComponent = useCallback(() => {
    if (!purchaseOrder) {
      return null;
    }

    return (
      <BudgetBlock>
        <BudgetRow>
          <ProgressBlockKeyText style={{ textDecoration: "underline" }}>
            Project Team:
          </ProgressBlockKeyText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Budget Approvals</ProgressBlockKeyText>
          <ProgressBlockValueText>
            &#40;
            {purchaseOrder?.required_approvals.filter((approval) =>
              Boolean(approval.accepted),
            ).length / purchaseOrder?.required_approvals.length}
            &#41;
          </ProgressBlockValueText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Project Administrators</ProgressBlockKeyText>
          <ProgressBlockValueText>
            &#40;{purchaseOrder?.budget_managers.length}&#41;
          </ProgressBlockValueText>
        </BudgetRow>
        <BudgetRow>
          <ProgressBlockKeyText>Collaborators</ProgressBlockKeyText>
          <ProgressBlockValueText>
            &#40;
            {purchaseOrder.collaborators_list.length +
              purchaseOrder.collaborators_users.length}
            &#41;
          </ProgressBlockValueText>
        </BudgetRow>
      </BudgetBlock>
    );
  }, [purchaseOrder]);

  const StatusBlockComponent = useCallback(() => {
    const isCompleted = Boolean(scheduledProject.completed);
    const isAccepted = Boolean(scheduledProject.accepted);
    let projectStatusString = isInProgressProject
      ? "Project not funded"
      : isPartiallyPaid
        ? "Pending balance"
        : isCompleted
          ? "Project completed"
          : "Project in progress";
    if (scheduledProject.refunded) {
      projectStatusString = "Refunded";
    }
    if (!isAccepted) {
      projectStatusString = "Pending Acceptance";
    }
    let projectStatusIcon =
      isInProgressProject || isPartiallyPaid
        ? faTriangleExclamation
        : isCompleted
          ? faCheck
          : faClock;

    if (isProjectCancelled) {
      projectStatusIcon = faBan;
      projectStatusString = "Project cancelled";
    }

    const showShareToClientButton = isInProgressProject && !code;
    const showProjectChatButton = !isInProgressProject && !code;

    return (
      <StatusBlock $completed={Boolean(scheduledProject.completed)}>
        <>
          <div style={{ marginBottom: "16px" }}>
            <>
              <FontAwesomeIcon icon={projectStatusIcon} />
              <span>{` ${projectStatusString}`}</span>
            </>
          </div>
          {!scheduledProject.refunded && (
            <OverviewHeaderDropDownSection>
              {showShareToClientButton && (
                <ShareToClientButton scheduledProjectId={scheduledProject.id} />
              )}
              {showProjectChatButton && (
                <ProjectChatPanel
                  scheduledProject={scheduledProject}
                  loading={false}
                />
              )}
              <ScheduledProjectActionsDropdown
                showOptionsText={
                  !showShareToClientButton && !showProjectChatButton
                }
                scheduledProjectId={scheduledProject.id}
                shareLinkCode={code}
                disablePageSharing={disableDownloadAndSharingActions}
                disableDownloadInvoice={disableDownloadAndSharingActions}
                disableDownloadAllFiles={disableDownloadAndSharingActions}
                isInProgressProject={isInProgressProject}
                isPartiallyPaid={isPartiallyPaid}
                showCompletePaymentOption={disableDownloadAndSharingActions}
              />
            </OverviewHeaderDropDownSection>
          )}
        </>
      </StatusBlock>
    );
  }, [
    scheduledProject,
    disableDownloadAndSharingActions,
    code,
    isInProgressProject,
    isPartiallyPaid,
  ]);

  return (
    <OuterContainer>
      {!code && (
        <BreadCrumbContainer>
          <BreadCrumbLink to={getMyBookingsRoute("projects")}>
            MY BOOKINGS
          </BreadCrumbLink>
          <BreadCrumbIcon>
            <FontAwesomeIcon
              color="var(--medium-grey)"
              icon={faGreaterThan}
              size="2xs"
            />
          </BreadCrumbIcon>
          <BreadCrumbLink
            to={getProjectOverviewRoute(scheduledProject.id)}
            $isProjectTitle={true}
          >
            {scheduledProject?.title.toUpperCase()}
          </BreadCrumbLink>
        </BreadCrumbContainer>
      )}
      <InnerContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          <ProjectOverviewText>Project Overview</ProjectOverviewText>
          {(isInProgressProject || isPartiallyPaid) && (
            <div>
              <InfoBadge
                onClick={
                  userIsEngineerOnScheduledProject
                    ? undefined
                    : () => {
                        // A silent catch prevents the error boundary from crashing if the URL hit fails
                        void redirectToPurchaseProject().catch(() => {});
                      }
                }
                type={
                  isInProgressProject
                    ? InfoBadgeType.NOT_FUNDED
                    : InfoBadgeType.PARTIALLY_PAID
                }
                outstandingBalance={scheduledProject.outstanding_balance}
                userIsEngineerOnProject={userIsEngineerOnScheduledProject}
                paywallOption={scheduledProject.default_paywall_option}
                showToolTip
              />
            </div>
          )}
        </div>
        <ContentRow>
          {isTablet ? (
            <>
              <DetailAndStatusRow>
                <DetailsBlockComponent />
                <StatusBlockComponent />
              </DetailAndStatusRow>
              <DetailsAndBudgetRow>
                <ProgressBlockComponent />
                {purchaseOrder && (
                  <>
                    <VerticalSeparator />
                    <BudgetBlockComponent />
                  </>
                )}
              </DetailsAndBudgetRow>
            </>
          ) : (
            <>
              <DetailsBlockComponent />
              <DetailsAndBudgetRow>
                <ProgressBlockComponent />
                {purchaseOrder && (
                  <>
                    <VerticalSeparator />
                    <BudgetBlockComponent />
                  </>
                )}
              </DetailsAndBudgetRow>
              <StatusBlockComponent />
            </>
          )}
        </ContentRow>
      </InnerContainer>
    </OuterContainer>
  );
};
