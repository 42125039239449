import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccountStatus } from "../../../hooks/accountHooks";
import { useIsPrimaryStudioManager } from "../../../hooks/studioHooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useSocialMediaMap } from "../../../hooks/useSocialMediaMap";
import {
  getCalendarIntegrationStatus,
  removeCalendarIntegration,
} from "../../../store/actions/calendarService";
import {
  updateStudio,
  updateStudioProfileParams,
} from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Studio } from "../../../store/models/studio";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import { formatSocialMediaUsernameForHyperlink } from "../../../store/utils/formatSocialMediaUsernameForHyperlink";
import { getStudioScreenRoute } from "../../../store/utils/routeGetters";
import GoogleCalendarIcon from "../../assets/google-calendar-icon.png";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { CheckBox } from "../../elements/CheckBox/CheckBox";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { EditableTextField } from "../../elements/EditableTextField/EditableTextField";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { SocialMediaListEnum } from "../../types/types";
import { AmenityList } from "../AmenityList/AmenityList";
import { BaseModal } from "../BaseModal/BaseModal";
import { CalendarSetUpView } from "../CalendarSetupView/CalendarSetUpView";
import { CollapsableComponent } from "../CollapsableComponent/CollapsableComponent";
import { ContactInfoModal } from "../ContactInfoModal/ContactInfoModal";
import { LocationInput } from "../LocationInput/LocationInput";
import { ManagePaymentsSettings } from "../ManagePaymentsSettings/ManagePaymentsSettings";
import { PrimaryEntityImage } from "../PrimaryEntityImage/PrimaryEntityImage";
import { SocialMediaButtonsList } from "../SocialMediaButtonsList/SocialMediaButtonsList";
import "./StudioModals.css";

const STUDIO_USERNAME_REGEX = /^[A-Za-z0-9@.+-_]+$/g;

export interface EditStudioModalProps {
  studio?: Studio;
  show: boolean;
  showXButton: boolean;
  onHide: () => void;
}

export const EditStudioModal = ({
  studio,
  show,
  showXButton,
  onHide,
}: EditStudioModalProps) => {
  const { phoneVerified, missingPhoneNumber } = useAccountStatus();
  const { isDesktop } = useMediaQueryBreakpoint();
  const [editMode, setEditMode] = useState<boolean>(true);
  const editQueryParam = useQueryParam("edit");
  const closeIconSize = useMemo(() => (!isDesktop ? "lg" : "2xl"), [isDesktop]);
  const dispatch = useAppDispatch();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<updateStudioProfileParams>({
    studio_id: studio?.id,
    location: undefined,
    profile: {
      display_name: studio?.studio_profile?.display_name ?? "",
      long_bio: studio?.studio_profile?.long_bio ?? "",
      instagram_username: studio?.studio_profile?.instagram_username ?? "",
      twitter_username: studio?.studio_profile?.twitter_username ?? "",
      facebook_username: studio?.studio_profile?.facebook_username ?? "",
      soundcloud_username: studio?.studio_profile?.soundcloud_username ?? "",
      twitch_username: studio?.studio_profile?.twitch_username ?? "",
    },
  });

  const buttonLabel = useMemo(() => {
    if (studio) {
      return updatingData ? "Updating..." : "Update Studio";
    }
    return "Create Studio";
  }, [studio, updatingData]);

  const [fetchingCalendarStatus, setFetchingCalendarStatus] =
    useState<boolean>(false);
  const [allowExternal, setAllowExternal] = useState<boolean>(
    studio ? Boolean(studio.allow_bookings_without_engineer) : true, // default to true if no studio is set up
  );
  const [engineerPriceIncluded, setEngineerPriceIncluded] = useState(
    Boolean(studio?.tracking_engineer_price_included),
  );
  const currentUserCanManageStudios = useAppSelector(
    selectUserCanEnableStudioServices,
  );
  const currentUserCanEditCurrentStudio = useManagerCanEditStudio(studio?.id);
  const canEditStudioService =
    currentUserCanManageStudios && currentUserCanEditCurrentStudio;
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isPrimaryStudioManager = useIsPrimaryStudioManager(
    selectedProfile?.studio?.id,
  );

  const [
    affiliatedEngineerBookingLinksEnabled,
    setAffiliatedEngineerBookingLinksEnabled,
  ] = useState<boolean>(
    Boolean(studio?.affiliated_engineer_booking_links_enabled),
  );

  const { calendarIntegrated, email } = useAppSelector(
    (state) => state.calendarService,
  );
  const history = useHistory();
  useEffect(() => {
    if (!studio?.id) return;
    setFetchingCalendarStatus(true);
    dispatch(getCalendarIntegrationStatus({ studio_id: studio?.id }))
      .unwrap()
      .then(() => {
        setFetchingCalendarStatus(false);
      })
      .catch(() => {
        setFetchingCalendarStatus(false);
      });
  }, [dispatch, studio]);

  const handleRemovingGoogleCalendarIntegration = () => {
    if (!studio?.id) return;
    setFetchingCalendarStatus(true);
    dispatch(removeCalendarIntegration({ studio_id: studio?.id }))
      .unwrap()
      .then(() => {
        toast.success("Successfully removed Google Calendar");
        setFetchingCalendarStatus(false);
      })
      .catch(() => {
        toast.error("Error removing Google Calendar");
        setFetchingCalendarStatus(false);
      });
  };

  const socialMediaUsernamesMap = useSocialMediaMap(studio?.studio_profile);

  const handleUserNameUpdate = useCallback(
    (updatedSocialMediaUsernameMap: Map<SocialMediaListEnum, string>) => {
      const usernamesFormatted = formatSocialMediaUsernameForHyperlink(
        updatedSocialMediaUsernameMap,
      );
      setProfileData((currentData) => ({
        ...currentData,
        profile: {
          ...profileData.profile,
          soundcloud_username:
            updatedSocialMediaUsernameMap.get(SocialMediaListEnum.soundcloud) ||
            "",
          instagram_username:
            usernamesFormatted.get(SocialMediaListEnum.instagram) || "",
          twitter_username:
            usernamesFormatted.get(SocialMediaListEnum.twitter) || "",
          facebook_username:
            usernamesFormatted.get(SocialMediaListEnum.facebook) || "",
          twitch_username:
            usernamesFormatted.get(SocialMediaListEnum.twitch) || "",
          tiktok_username:
            usernamesFormatted.get(SocialMediaListEnum.tiktok) || "",
          youtube_username:
            usernamesFormatted.get(SocialMediaListEnum.youtube) || "",
        },
      }));
    },
    [profileData.profile],
  );

  const onClose = () => {
    editQueryParam.remove();
    onHide();
  };

  const onClickSave = useCallback(() => {
    if (!studio) {
      if (!profileData.location) {
        return toast.error("Please enter a location");
      }
    }
    const username = profileData.username ?? studio?.username;
    if (!username) {
      toast.error("Please enter a username");
      return;
    }
    if (!STUDIO_USERNAME_REGEX.test(username)) {
      toast.error(
        "Please enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.",
      );
      return;
    }

    setUpdatingData(true);
    setEditMode(false);
    dispatch(
      updateStudio({
        ...profileData,
        allow_bookings_without_engineer: allowExternal,
        allow_unaffiliated_engineers: false,
        affiliated_engineer_booking_links_enabled:
          affiliatedEngineerBookingLinksEnabled,
        tracking_engineer_price_included: engineerPriceIncluded,
      }),
    )
      .unwrap()
      .then(() => {
        setUpdatingData(false);
        setEditMode(true);
        onClose();
        if (!studio) {
          // Redirect to the studio screen if a new studio was created.
          history.push(`${getStudioScreenRoute(profileData.username)}?edit=1`);
        }
      })
      .catch(() => {
        setUpdatingData(false);
        setEditMode(true);
      });
  }, [
    studio,
    profileData,
    dispatch,
    allowExternal,
    affiliatedEngineerBookingLinksEnabled,
    engineerPriceIncluded,
    onClose,
    history,
  ]);

  if ((missingPhoneNumber || !phoneVerified) && show) {
    return (
      <ContactInfoModal
        customHeading={"Add phone number to your account"}
        onClose={({ phoneNumberVerified }) => {
          if (!phoneNumberVerified) {
            onClose();
          }
        }}
        overwriteShowModal={show}
      />
    );
  }

  return (
    <BaseModal
      hideCloseButton={true}
      modalIsOpen={show}
      label={"edit studio modal"}
    >
      <div className={"studio-modal"}>
        <div className={"modal-header"}>
          <h2 className={"h3 mx-4"}>
            {!studio ? "Create Studio" : "Edit Studio"}
          </h2>
          {showXButton && (
            <FontAwesomeIcon
              width={30}
              height={30}
              icon={faTimes}
              color={"var(--black)"}
              size={closeIconSize}
              className={"close-modal-button mx-2"}
              onClick={onClose}
            />
          )}
        </div>
        {(calendarIntegrated || !studio) && !fetchingCalendarStatus && (
          <div className={"modal-body"}>
            <p className={"b1-semi-bold"}>Studio Username</p>
            <EditableTextField
              initialValue={studio?.username ?? ""}
              label={"username"}
              filter={(val: string) => val.replace(/([A-Z])*\s*/g, "")}
              onChange={(val) => {
                setProfileData((currentData) => ({
                  ...currentData,
                  username: val,
                }));
              }}
              editMode={true}
            />
            <p className={"b1-semi-bold"}>Display Name</p>
            <EditableTextField
              initialValue={studio?.studio_profile?.display_name ?? ""}
              label={"display name"}
              onChange={(value) => {
                if (!profileData) return;
                setProfileData({
                  ...profileData,
                  profile: {
                    ...profileData.profile,
                    display_name: value,
                  },
                });
              }}
              editMode={true}
            />
            <p className={"b1-semi-bold"}>Studio Address</p>
            <div className="location-input-auto-complete">
              <LocationInput
                defaultValue={studio?.location?.formatted ?? ""}
                onPlaceSelected={(placeResult) => {
                  setProfileData((currentData) => ({
                    ...currentData,
                    location: placeResult,
                  }));
                }}
                showBorder
              />
            </div>
            <Text
              variant={TextStyleVariant.P3}
              style={{ marginBottom: "0.75rem" }}
            >
              Note: your studio&apos;s exact address will not be revealed until
              you accept a booking.
            </Text>
            <p className={"b1-semi-bold"}>Unit Number (optional)</p>
            <EditableTextField
              initialValue={studio?.unit_number ?? ""}
              label={"unit number (optional)"}
              onChange={(value) => {
                if (!profileData) return;
                setProfileData((currentData) => ({
                  ...currentData,
                  unit_number: value,
                }));
              }}
              editMode={true}
            />
            <p className={"b1-semi-bold"}>Arrival Information</p>
            <EditableTextArea
              initialValue={studio?.arrival_information ?? ""}
              editMode={true}
              placeholder={"Add arrival information"}
              onChange={(value) => {
                if (!profileData) return;
                setProfileData((currentData) => ({
                  ...currentData,
                  arrival_information: value,
                }));
              }}
              width={"100%"}
              characterCount={2000}
              numberOfLines={3}
            />
            <p className={"b1-semi-bold"}>Bio</p>
            <EditableTextArea
              initialValue={studio?.studio_profile?.long_bio ?? ""}
              editMode={true}
              placeholder={"Add bio"}
              onChange={(value) => {
                if (!profileData) return;
                setProfileData((currentData) => ({
                  ...currentData,
                  profile: {
                    ...profileData.profile,
                    long_bio: value,
                  },
                }));
              }}
              width={"100%"}
              characterCount={2000}
              numberOfLines={3}
            />
            {studio && (
              <>
                <p className={"b1-semi-bold"}>Amenities</p>
                <AmenityList
                  username={studio?.username}
                  studioId={studio.id}
                  amenities={studio?.studio_amenities ?? []}
                  editMode={editMode}
                />
                <p className={"b1-semi-bold"}>Upload Primary Photo</p>
                <div className="edit-modal-primary-image">
                  <PrimaryEntityImage
                    studio={studio}
                    primaryPhoto={studio?.photo ?? null}
                    editMode={true}
                    imageClassName="studio-modal-image-in-modal"
                    canManageStudio={true}
                  />
                </div>
              </>
            )}
            <p className={"b1-semi-bold"}>Social Media Links</p>
            <SocialMediaButtonsList
              editMode={true}
              onChange={handleUserNameUpdate}
              usernamesMap={socialMediaUsernamesMap}
              isCurrentUserPage={true}
            />
            {studio && (
              <>
                {canEditStudioService && (
                  <>
                    <div className="edit-studio-modal-checkbox">
                      <CheckBox
                        label="Allow studio bookings without an audio engineer"
                        isSelected={allowExternal}
                        toggle={() =>
                          setAllowExternal((current) => {
                            return !current;
                          })
                        }
                      />
                      <CheckBox
                        label="Allow your affiliated engineers to generate studio booking links"
                        isSelected={affiliatedEngineerBookingLinksEnabled}
                        toggle={() =>
                          setAffiliatedEngineerBookingLinksEnabled(
                            (current) => !current,
                          )
                        }
                      />
                      <CheckBox
                        label="Recording engineers are included in the room price for all sessions"
                        isSelected={engineerPriceIncluded}
                        toggle={() =>
                          setEngineerPriceIncluded((current) => !current)
                        }
                      />
                    </div>
                  </>
                )}

                {isPrimaryStudioManager && (
                  <div className="mt-3">
                    <CollapsableComponent hintText="Manage studio payouts">
                      <div className="mb-3">
                        <ManagePaymentsSettings showHeader={false} />
                      </div>
                    </CollapsableComponent>
                  </div>
                )}

                <div className="mt-3">
                  <CollapsableComponent hintText="Manage Google calendar access">
                    <div
                      className={"remove-studio-calendar-integration-container"}
                    >
                      <p className="b1 mb-4 text-center">
                        Revoke access to your{" "}
                        <span className="b1-semi-bold google-product-span">
                          Google Calendar
                        </span>{" "}
                        . This will remove your
                        <strong>{` ${email ?? ""} `}</strong>
                        <span className="b1-semi-bold google-product-span">
                          Google Calendar
                        </span>{" "}
                        from the EngineEars platform and your calendar will no
                        longer be synced with EngineEars and your recording
                        service will be removed.
                      </p>
                      <button
                        className="calendar-setup-button"
                        onClick={handleRemovingGoogleCalendarIntegration}
                      >
                        {!fetchingCalendarStatus && (
                          <img
                            alt={"google calendar icon"}
                            src={GoogleCalendarIcon}
                            width={20}
                            height={20}
                          />
                        )}
                        {fetchingCalendarStatus ? (
                          <SoundWaveLoader
                            whiteLoader={false}
                            width={120}
                            height={20}
                          />
                        ) : (
                          <p className="ml-2">
                            Unlink{" "}
                            <span className="b1-semi-bold google-product-span">
                              Google Calendar
                            </span>
                          </p>
                        )}
                      </button>
                    </div>
                  </CollapsableComponent>
                </div>
              </>
            )}
          </div>
        )}
        {fetchingCalendarStatus && (
          <div
            className={
              "modal-body d-flex mx-4 justify-content-center align-items-center"
            }
          >
            <SoundWaveLoader whiteLoader={false} width={120} height={20} />
          </div>
        )}
        {!calendarIntegrated && studio && !fetchingCalendarStatus && (
          <div className={"modal-body"}>
            <CalendarSetUpView studioID={studio.id} />
          </div>
        )}
        <div className={"modal-header modal-footer"}>
          <Button
            loading={updatingData}
            onClick={onClickSave}
            variant={ButtonVariant.PRIMARY}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
