import { AuthFlows } from "../../../constants/authSteps";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { SignInForm } from "../SignInForm/SignInForm";
import { currentFlowAtom } from "./atoms";
import { FormContainer, SignUpFooter } from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import { Box, useTheme } from "@mui/material";
import { useAtom } from "jotai";

export const EmailAuth = () => {
  const theme = useTheme();
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const isRegistering = flow === AuthFlows.START_SIGNUP;

  return (
    <FormContainer>
      <AuthNavigation />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        <Text variant={TextStyleVariant.H6}>
          {isRegistering
            ? "Continue with your email"
            : "Sign in to your account"}
        </Text>
        <Text>
          {isRegistering ? "Already a member?" : "Don’t have an account?"}
          <Button
            style={{
              textDecoration: "underline",
              display: "inline",
              marginLeft: "4px",
              color: theme.palette.text.primary,
            }}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              if (isRegistering) {
                setFlow(AuthFlows.LOGIN);
              } else {
                setFlow(AuthFlows.START_SIGNUP);
              }
            }}
          >
            {isRegistering ? "Sign in" : "Sign up"}
          </Button>
        </Text>
      </Box>
      <SignInForm
        register={isRegistering ? true : false}
        key={isRegistering ? "register" : "login"}
      />
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
