import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { Chat } from "stream-chat-react";
import { connectionEstablishedAtom } from "../../../hooks/chatHooks/atoms";
import { useCreateChatClient } from "../../../hooks/useCreateChatClient";
import { streamApiKey } from "../../../store/utils";

export interface ChatInjectorParams {
  children?: React.ReactNode;
  token: string;
  userData?: {
    id: string;
    name: string;
    username: string;
    image: string;
  };
}
const ChatInjector = ({
  children = null,
  token,
  userData,
}: ChatInjectorParams) => {
  const connectionEstablished = useAtomValue(connectionEstablishedAtom);
  const client = useCreateChatClient({
    apiKey: streamApiKey,
    tokenOrProvider: token,
    userData: userData,
  });
  useEffect(() => {
    if (!client || !userData) return;
    if (connectionEstablished) {
      void client.upsertUser(userData);
    }
  }, [connectionEstablished, client, userData]);

  if (!client) {
    return <>{children}</>;
  }

  return (
    <>
      <Chat initialNavOpen={false} client={client}>
        {children}
      </Chat>
    </>
  );
};

export default ChatInjector;
