import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  addMusoAssociation,
  getMusoUserRoster,
} from "../../../store/actions/musoProfile";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MusoProfileEntity,
  MusoSelectionTab,
} from "../../../store/models/muso";
import { getMusoSelectionScreenRoute } from "../../../store/utils/routeGetters";
import defaultUser from "../../assets/defaultuser.jpg";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import {
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../core-ui/components/Text/Text";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  MusoRosterContainer,
  MusoRosterDataContainer,
  MusoRosterProfileData,
  MusoRosterProfileImage,
} from "./MusoRosterScreen.styles";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

interface MusoRosterProfileProps {
  entity: MusoProfileEntity;
  onProfileClick?: (_: MusoProfileEntity) => void;
}

const MusoRosterProfile = ({
  entity,
  onProfileClick,
}: MusoRosterProfileProps) => {
  return (
    <MusoRosterProfileData
      onClick={onProfileClick ? () => onProfileClick(entity) : undefined}
      $showHoverStyles={Boolean(onProfileClick)}
    >
      <MusoRosterProfileImage src={entity.avatar_url ?? defaultUser} />
      <Text style={{ marginTop: "4px" }} weight={TEXT_WEIGHT.SEMI_BOLD}>
        {entity.name}
      </Text>
      <Text>{`${entity.credit_count} Credits`}</Text>
    </MusoRosterProfileData>
  );
};

const MusoRosterScreen = () => {
  const history = useHistory();
  const [isLoadingProfileData, setIsLoadingProfileData] = useState(false);
  const [profileData, setProfileData] = useState<MusoProfileEntity[] | null>(
    null,
  );
  const [activeEntity, setActiveEntity] = useState<MusoProfileEntity | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const { loading: isMusoAssociationLoading } = useAppSelector(
    (state) => state.musoAssociationStore,
  );
  const { openModal, isOpen, closeModal } = useModal();

  useSetPageTitle("Select Muso Profile");

  useEffect(() => {
    if (!profileData) {
      setIsLoadingProfileData(true);
      void dispatch(getMusoUserRoster())
        .unwrap()
        .then((data) => {
          setProfileData(data);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoadingProfileData(false);
        });
    }
  }, [profileData, dispatch]);

  const onProfileClick = (entity: MusoProfileEntity) => {
    setActiveEntity(entity);
    openModal();
  };

  const onProfileConfirm = () => {
    if (!activeEntity) {
      return;
    }
    emitAnalyticsTrackingEvent("click_confirm_add_muso_association", {
      musoProfileId: activeEntity.id,
    });
    void dispatch(
      addMusoAssociation({
        muso_profile_id: activeEntity.id,
      }),
    )
      .unwrap()
      .then(() => {
        history.push(getMusoSelectionScreenRoute(MusoSelectionTab.CREDITS));
      });
  };

  return (
    <>
      <MusoRosterContainer>
        <Text size={TEXT_SIZE.LARGE} weight={TEXT_WEIGHT.SEMI_BOLD}>
          Select a Muso Profile
        </Text>
        {isLoadingProfileData && <SoundWaveLoader width={100} height={100} />}
        <MusoRosterDataContainer>
          {profileData?.map((entity) => {
            return (
              <MusoRosterProfile
                entity={entity}
                onProfileClick={onProfileClick}
                key={entity.id}
              />
            );
          })}
        </MusoRosterDataContainer>
      </MusoRosterContainer>
      {activeEntity && (
        <BaseModal
          open={isOpen}
          header="Confirm Muso Profile Selection"
          setOpen={closeModal}
          onConfirm={onProfileConfirm}
          onCancel={closeModal}
          showModalFooter
          loading={isMusoAssociationLoading}
        >
          <Text>
            Are you sure you want to link this Muso profile to your EngineEars
            account?
          </Text>
          <MusoRosterProfile entity={activeEntity} />
        </BaseModal>
      )}
    </>
  );
};

export default MusoRosterScreen;
