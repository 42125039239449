import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import queryString from "query-string";
import { QueryParamsType, RootState } from "../index";
import { ProjectType, TrackStage } from "../models/project";
import {
  PaginatedScheduledProject,
  ScheduledProjectStage,
} from "../models/scheduledproject";
import { makeBackendGetCallWithJsonResponse } from "../utils/fetch";
import { PAGINATED_SCHEDULED_PROJECTS } from "../utils/routes";
import { manageScheduledProject } from "./booking";
import {
  BookingsScreenSearchState,
  initialState as initialSearchState,
  setCollaboratorFilter,
  setSearchQuery,
} from "./bookingsSearch";
import { receiveErrors } from "./errorStore";

export interface PaginatedScheduledProjectsState {
  [page: number]: PaginatedScheduledProject[];
}

export type OrderByType =
  | "created"
  | "title"
  | "-created"
  | "-title"
  | "priority"
  | "-priority";

interface PaginatedScheduledProjectState {
  data: PaginatedScheduledProjectsState;
  isDataLoading: boolean;
  page: number;
  orderBy: OrderByType;
  selectedServices: ProjectType[];
  stages: ScheduledProjectStage[];
  numberOfPages: number;
  count: number;
  trackStages: TrackStage[];
  search: BookingsScreenSearchState;
}

const initialState: PaginatedScheduledProjectState = {
  data: {},
  isDataLoading: false,
  page: 1,
  orderBy: "-priority",
  selectedServices: [],
  stages: [],
  numberOfPages: 0,
  count: 0,
  trackStages: [],
  search: initialSearchState,
};

export const fetchPaginatedScheduledProjects = createAsyncThunk(
  PAGINATED_SCHEDULED_PROJECTS,
  async (_, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const scheduledProjectsState = appState.paginatedScheduledProjects;
    const { page, orderBy, selectedServices, stages, trackStages, search } =
      scheduledProjectsState;

    const queryParams: QueryParamsType = {};
    queryParams.page = page;

    if (search.searchQuery.length) {
      queryParams.search_query = search.searchQuery;
    }
    if (stages.length > 0) {
      const filteredStages = stages.filter(
        (stage) => stage !== ScheduledProjectStage.ALL,
      );
      if (filteredStages.length > 0) {
        queryParams.stages = filteredStages;
      }
    }
    if (selectedServices.length > 0) {
      const filteredServices = selectedServices.filter(
        (service) => service !== ProjectType.NO_TYPE,
      );
      if (filteredServices.length > 0) {
        queryParams.services = filteredServices;
      }
    }
    if (orderBy) {
      queryParams.order_by = orderBy;
    }
    if (trackStages.length > 0) {
      const filteredTrackStages = trackStages.filter(
        (trackStage) => trackStage !== TrackStage.ALL,
      );
      if (filteredTrackStages.length > 0) {
        queryParams.track_stages = filteredTrackStages;
      }
    }
    if (search.collaboratorFilter.length > 0) {
      queryParams.collaborator_filter = search.collaboratorFilter;
    }

    const params = `?${queryString.stringify(queryParams, {
      arrayFormat: "comma",
    })}`;

    const response = await makeBackendGetCallWithJsonResponse<{
      data: PaginatedScheduledProject[];
      num_of_pages: number;
      count: number;
      page: number;
    }>(PAGINATED_SCHEDULED_PROJECTS, params);
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const paginatedScheduledProjectsSlice = createSlice({
  name: "paginatedScheduledProjects",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<OrderByType>) => {
      state.orderBy = action.payload;
    },
    setSelectedServices: (state, action: PayloadAction<ProjectType[]>) => {
      state.selectedServices = action.payload;
    },
    setStages: (state, action: PayloadAction<ScheduledProjectStage[]>) => {
      state.stages = action.payload;
    },
    setTrackStages: (state, action: PayloadAction<TrackStage[]>) => {
      state.trackStages = action.payload;
    },
    removeCachedScheduledProject: (state, action: PayloadAction<number>) => {
      state.data[state.page] = state.data[state.page].filter(
        (project) => project.id !== action.payload,
      );
    },
    resetOrderBy: (state) => {
      state.orderBy = "-priority";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSearchQuery, (state, action) => {
      state.search.searchQuery = action.payload;
    });
    builder.addCase(setCollaboratorFilter, (state, action) => {
      state.search.collaboratorFilter = action.payload;
    });
    builder.addCase(fetchPaginatedScheduledProjects.pending, (state) => {
      state.isDataLoading = true;
    });
    builder.addCase(manageScheduledProject.fulfilled, (state, action) => {
      if (!state.data[state.page]) return;
      state.data[state.page] = state.data[state.page].map((sp) => {
        if (sp.id === action.payload.id) {
          return {
            ...sp,
            accepted: action.payload.accepted,
            refunded: action.payload.refunded,
            project_acceptances: action.payload.project_acceptances,
          };
        }
        return sp;
      });
    });
    builder
      .addCase(fetchPaginatedScheduledProjects.fulfilled, (state, action) => {
        state.data[state.page] = action.payload.data;
        state.numberOfPages = action.payload.num_of_pages;
        state.count = action.payload.count;
        state.page = action.payload.page;
        state.isDataLoading = false;
      })
      .addMatcher(
        isAnyOf(fetchPaginatedScheduledProjects.rejected),
        (state) => {
          state.data[state.page] = [];
          state.isDataLoading = false;
        },
      );
  },
});

export const {
  setStages,
  setTrackStages,
  setPage,
  setOrderBy,
  setSelectedServices,
  removeCachedScheduledProject,
  resetOrderBy,
} = paginatedScheduledProjectsSlice.actions;

export default paginatedScheduledProjectsSlice.reducer;
