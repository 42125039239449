import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getProjectById } from "../../../store/actions/projectsMap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getIndividualProjectOverviewRoute } from "../../../store/utils/routeGetters";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { SignInModal } from "../../components/SignInModal/SignInModal";
import { Text } from "../../core-ui/components/Text/Text";
import "./ProjectScreen.css";

/**
 * @deprecated This page is only maintained for backwards compatibility.
 * This page will attempt to redirect to /project-overview/<scheduled_project_id>?project=<project_id>.
 */
const ProjectScreen = () => {
  const { project_id: projectId } = useParams<{ project_id: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLoading } = useAppSelector((state) => state.projectsMapStore);
  const [hasError, setHasError] = useState(false);

  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code") ?? undefined;

  useEffect(() => {
    if (isNaN(+projectId)) {
      return;
    }
    dispatch(
      getProjectById({
        project_id: +projectId,
        code,
      }),
    )
      .unwrap()
      .then((projectById) => {
        // Redirect to the project overview page with the associated scheduled project ID.
        if (projectById.scheduled_project_id) {
          history.replace(
            getIndividualProjectOverviewRoute(
              +projectId,
              projectById.scheduled_project_id,
              code,
            ),
          );
        } else {
          setHasError(true);
        }
      })
      .catch(() => setHasError(true));
  }, [projectId, dispatch, isAuthenticated, code]);

  if (isLoading) return <LoadingScreen width={300} height={300} />;

  if (!isAuthenticated && !isLoading && !code) {
    return <SignInModal label={"Sign In"} />;
  }

  if (hasError || isNaN(+projectId)) {
    return (
      <div
        style={{
          marginTop: "1em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>
          {hasError ? "Error fetching project details" : "Invalid project Id"}
        </Text>
      </div>
    );
  }

  return null;
};

export default ProjectScreen;
