import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useState } from "react";
import { useGeolocationCountryCode } from "../../../hooks/useGeolocationCountryCode";
import { useAppSelector } from "../../../store/hooks";
import { Button } from "../../core-ui/components/Button/Button";
import { CheckBoxVariant } from "../../core-ui/components/CheckBox/CheckBox";
import { PhoneInput } from "../../core-ui/components/PhoneNumber/PhoneInput";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { FieldContainer, FieldInput } from "../SignInForm/SignInForm.styles";
import {
  AuthModalCheckbox,
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { AuthNavigation } from "./AuthNavigation";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useTheme } from "@mui/material";

const StudioContact = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const { isUpdatingProfile } = useAppSelector((state) => state.accountInfo);
  const { countryCode, loading } = useGeolocationCountryCode();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [getEmailFromEngineer, setGetEmailFromEngineer] = useState(false);
  const [getPhoneNumberFromEngineer, setGetPhoneNumberFromEngineer] =
    useState(false);
  const { nextStep } = useAuthNavigationSteps();

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <FieldContainer>
          <Text variant={TextStyleVariant.H6}>Studio contact information</Text>
          <Box sx={{ marginTop: "16px" }}>
            <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Email</Text>
            <FieldInput
              sx={{ marginTop: "16px" }}
              defaultValue=""
              fullWidth={true}
              size="small"
              onChange={(e) => {
                const email = e.target.value;
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                setEmail(email);
                if (emailPattern.test(email)) {
                  setIsValidEmail(true);
                } else {
                  setIsValidEmail(false);
                }
              }}
              InputProps={{
                endAdornment: isValidEmail ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : null,
              }}
            />
          </Box>
          <AuthModalCheckbox
            checked={getEmailFromEngineer}
            label="Same as engineer profile"
            variant={CheckBoxVariant.OUTLINED}
            onClick={() => setGetEmailFromEngineer(!getEmailFromEngineer)}
          />
        </FieldContainer>
        <FieldContainer>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Phone number</Text>
          <PhoneInput
            disabled={isUpdatingProfile || loading}
            size="small"
            isValidPhoneNumber={isValidPhoneNumber}
            defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
            value={phoneNumber}
            onChange={(value: string) => {
              setPhoneNumber(value);
              setIsValidPhoneNumber(matchIsValidTel(value));
            }}
          />
          <AuthModalCheckbox
            checked={getPhoneNumberFromEngineer}
            label="Same as engineer profile"
            variant={CheckBoxVariant.OUTLINED}
            onClick={() =>
              setGetPhoneNumberFromEngineer(!getPhoneNumberFromEngineer)
            }
          />
        </FieldContainer>
        <Button style={{ width: "100%" }} onClick={() => nextStep()}>
          Create my studio
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};

export default StudioContact;
