import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./BottomNav.css";

export const BOTTOM_TAB_BAR_OVERLAY_ID = "bottomTabBarOverlayDiv";
export const BOTTOM_TAB_BAR_FOCUSED_OVERLAY_ID =
  "bottomTabBarFocusedOverlayDiv";
export const DRAWER_CONTAINER_ID = "mobile-drawer-container";
export const DEFAULT_TAB_OVERLAY_CLASS = "overlay-children-view";
export const NO_PADDING_TAB_OVERLAY_CLASS = "overlay-children-view no-padding";
export const PROJECT_WORKFLOW_SIDE_NAV_STICKY_BUTTON_CONTAINER_ID =
  "project-workflow-side-nav-sticky-buttons";
export const PROJECT_WORKFLOW_STICKY_FOOTER_CONTENT_CLASS =
  "project-workflow-sticky-footer-content";
export const SIDE_PANEL_BOTTOM_DRAWER_CONTAINER_ID =
  "side-panel-bottom-drawer-container";

export const useBottomTabBarOverlayView = (
  isMobile: boolean,
  children: ReactNode,
  containerClassName?: string,
  id = BOTTOM_TAB_BAR_OVERLAY_ID,
) => {
  const [el, setEl] = useState<Element | null>(null);

  useEffect(() => {
    if (!isMobile) return;
    const target = document.getElementById(id);
    if (target) {
      target.classList.add("show-bottom-tab-bar-overlay");
    }
    setEl(target);
    return () => {
      if (target) {
        target.classList.remove("show-bottom-tab-bar-overlay");
      }
    };
  }, [el, isMobile, id]);

  if (!el || !isMobile) return null;
  return createPortal(<div className={containerClassName}>{children}</div>, el);
};
