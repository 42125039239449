import styled from "styled-components";

export const StyledPlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 2 * var(--playback-footer-height));
`;

export const StyledPlaylistText = styled.p`
  color: ${({ theme }) => theme.colorPalette.White};
  font-size: ${({ theme }) => theme.textSizeMd};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  line-height: 18px;
  padding-bottom: 8px;
`;
