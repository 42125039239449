import {
  faTwitter,
  faSoundcloud,
  faInstagram,
  faFacebook,
  faTwitch,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { OptionType } from "../elements/DropDownSelector/DropdownSelector";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export enum SocialMediaListEnum {
  soundcloud,
  instagram,
  twitter,
  facebook,
  twitch,
  tiktok,
  youtube,
}

export interface SocialMediaLink {
  icon: IconProp;
  label: string;
  baseLink: string;
}

export const SocialMediaMap = new Map<SocialMediaListEnum, SocialMediaLink>([
  [
    SocialMediaListEnum.soundcloud,
    {
      icon: faSoundcloud,
      label: "Soundcloud",
      baseLink: "https://soundcloud.com/",
    },
  ],
  [
    SocialMediaListEnum.instagram,
    {
      icon: faInstagram,
      label: "Instagram",
      baseLink: "https://instagram.com/",
    },
  ],
  [
    SocialMediaListEnum.twitter,
    {
      icon: faTwitter,
      label: "Twitter",
      baseLink: "https://twitter.com/",
    },
  ],
  [
    SocialMediaListEnum.facebook,
    {
      icon: faFacebook,
      label: "Facebook",
      baseLink: "https://facebook.com/",
    },
  ],
  [
    SocialMediaListEnum.twitch,
    {
      icon: faTwitch,
      label: "Twitch",
      baseLink: "https://twitch.com/",
    },
  ],
  [
    SocialMediaListEnum.tiktok,
    {
      icon: faTiktok,
      label: "Tik Tok",
      baseLink: "https://www.tiktok.com/",
    },
  ],
  [
    SocialMediaListEnum.youtube,
    {
      icon: faYoutube,
      label: "Youtube",
      baseLink: "https://www.youtube.com/",
    },
  ],
]);

export const SocialMediaSelectorOptions: OptionType[] = Array.from(
  SocialMediaMap.entries(),
).map(([k, v]) => ({ value: k, label: v.label }));
