import { ReactNode } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  MasteringProjectSteps,
  MixingProjectSteps,
  ProjectType,
  ProjectUserType,
  ProjectWorkflowSteps,
  ScheduledProjectPaywallTypes,
} from "../../../store/models/project";
import {
  RecordingSession,
  SessionSteps,
  SessionWorkflowSteps,
} from "../../../store/models/recordingSession";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import User, { UserLite } from "../../../store/models/user";
import { getProjectWorkflowStep } from "../../../utils/projectUtils";
import { CenteredSoundWaveLoader } from "../CenteredSoundWaveLoader/CenteredSoundWaveLoader";
import { PROJECT_WORKFLOW_SIDE_NAV_STICKY_BUTTON_CONTAINER_ID } from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import { SidePanel } from "../SidePanel/SidePanel";
import { ProjectHistory } from "./components/ProjectHistory/ProjectHistory";
import { ProjectPanelHeader } from "./components/ProjectPanelHeader";
import { ProjectPanelTrackHeader } from "./components/ProjectPanelTrackHeader";
import { ProjectWorkflowStepper } from "./components/ProjectWorkflowStepper/ProjectWorkflowStepper";
import { TaskMessageType } from "./components/ProjectWorkflowTasksRow/ProjectWorkflowTaskMessage";
import { ProjectWorkflowTasksRow } from "./components/ProjectWorkflowTasksRow/ProjectWorkflowTasksRow";
import {
  ProjectPanelContentContainer,
  ProjectPanelSeparator,
  ProjectWorkflowStickyFooter,
  WORKFLOW_SIDE_PANEL_PADDING,
} from "./ProjectWorkflowPanel.styles";

export enum ProjectWorkflowPanelVariant {
  BOOKING_REQUESTED = "booking_requested",
  MAIN_FLOW = "main_flow",
  SESSION_REQUESTED = "session_requested",
  SESSION_MAIN_FLOW = "session_main_flow",
}

interface ProjectWorkflowPanelProps {
  albumTitle?: string | undefined;
  collaborator: User | UserLite | undefined | null;
  children?: ReactNode;
  isCancelledSession?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  isProjectComplete?: boolean;
  isRefunded?: boolean;
  noSidePanel?: boolean;
  projectId?: number | null;
  projectStep?: MixingProjectSteps | MasteringProjectSteps | SessionSteps;
  workflowStep?: SessionWorkflowSteps | ProjectWorkflowSteps;
  projectType: ProjectType;
  userType?: ProjectUserType | null;
  variant: ProjectWorkflowPanelVariant;
  onClose: () => void;
  isInProgressProject?: boolean;
  outstandingBalance?: number;
  hideTrackButtons?: boolean;
  overrideTaskMessage?: TaskMessageType;
  recordingSession?: RecordingSession | null;
  recordingSessionBookingDetails?: Pick<
    RecordingSession,
    "recording_session_request_id" | "users"
  > | null;
  scheduledProject?: ScheduledProject | null;
  paywallOption?: ScheduledProjectPaywallTypes;
  showEngineerActionDropdown?: boolean;
}

/**
 * Reusable Panel that displays the project workflow steps
 */
export const ProjectWorkflowPanel = ({
  albumTitle = "Untitled",
  collaborator,
  children,
  isCancelledSession = false,
  isLoading = false,
  isOpen,
  isProjectComplete = false,
  isRefunded = false,
  noSidePanel = false,
  projectId,
  projectStep,
  projectType,
  userType,
  variant,
  onClose,
  workflowStep,
  isInProgressProject = false,
  outstandingBalance = 0,
  hideTrackButtons = false,
  overrideTaskMessage,
  recordingSession,
  recordingSessionBookingDetails,
  scheduledProject,
  paywallOption,
  showEngineerActionDropdown,
}: ProjectWorkflowPanelProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();

  let projectWorkflowStep:
    | ProjectWorkflowSteps
    | SessionWorkflowSteps
    | undefined = workflowStep;
  if (projectStep != null) {
    projectWorkflowStep = getProjectWorkflowStep(projectType, projectStep);
  }

  const renderProjectWorkflowContent = () => {
    if (!isOpen) {
      return null;
    }

    if (isLoading) {
      return <CenteredSoundWaveLoader />;
    }

    if (projectWorkflowStep === undefined) {
      throw new Error(
        "Please pass in either `workflowStep` or `projectStep` to this component!",
      );
    }

    return (
      <>
        <ProjectPanelHeader
          albumTitle={
            projectType === ProjectType.RECORDING ? "Session" : albumTitle
          }
          artist={collaborator}
          isLoading={isLoading}
          outstandingBalance={outstandingBalance}
          isInProgressProject={isInProgressProject}
          paywallOption={paywallOption}
          userType={userType}
        />
        <ProjectPanelTrackHeader
          isLoading={isLoading}
          projectId={projectId}
          hideTrackButtons={hideTrackButtons}
        />
        <ProjectWorkflowStepper
          isProjectComplete={isProjectComplete}
          projectId={projectId}
          projectType={projectType}
          recordingSession={recordingSession}
          recordingSessionBookingDetails={recordingSessionBookingDetails}
          scheduledProject={scheduledProject}
          variant={variant}
          workflowStep={projectWorkflowStep}
          isCancelledSession={isCancelledSession}
          isRefunded={isRefunded}
        />
        <ProjectWorkflowTasksRow
          projectId={projectId}
          projectType={projectType}
          userType={userType}
          variant={variant}
          workflowStep={projectWorkflowStep}
          isNotFunded={isInProgressProject}
          isCancelledSession={isCancelledSession}
          isRefunded={isRefunded}
          overrideTaskMessage={overrideTaskMessage}
          showEngineerActionDropdown={showEngineerActionDropdown}
        />
        <ProjectPanelSeparator $margin="16px 0 24px 0" />
        {children}
        {variant === ProjectWorkflowPanelVariant.MAIN_FLOW && (
          <ProjectHistory
            projectId={projectId}
            projectWorkflowStep={projectWorkflowStep}
          />
        )}
        {isOpen && (
          <ProjectWorkflowStickyFooter
            id={PROJECT_WORKFLOW_SIDE_NAV_STICKY_BUTTON_CONTAINER_ID}
            $navHeight={0}
            $noSidePanel={noSidePanel}
          />
        )}
      </>
    );
  };

  if (noSidePanel) {
    return (
      <ProjectPanelContentContainer className="container">
        {renderProjectWorkflowContent()}
      </ProjectPanelContentContainer>
    );
  }
  return (
    <SidePanel
      fillScreen={!isDesktop}
      isOpen={isOpen}
      onClose={onClose}
      sidePanelStyle={{
        padding: WORKFLOW_SIDE_PANEL_PADDING,
        ...(isDesktop && { maxWidth: "800px" }),
      }}
    >
      {renderProjectWorkflowContent()}
    </SidePanel>
  );
};
