import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { getDebugEventPrefix } from "../../utils/analyticsUtils";
import { EmailLeadSource } from "../models/base";
import Engineer from "../models/engineer";
import { AUTH_FAILED, INVALID_USERNAME_ON_SIGNUP } from "../models/exceptions";
import { Genre, GenreArray } from "../models/genres";
import Listener from "../models/listener";
import Other from "../models/other";
import Photo from "../models/photo";
import { Studio } from "../models/studio";
import { MajorLabelEnum } from "../models/trophy";
import User, { LocalUTMParams, MockUser } from "../models/user";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  BETTERMODE_JWT_TOKEN,
  CHECK_SUPERUSER,
  EMAIL_LEAD,
  EMPIRE_JWT_TOKEN,
  FORGOT_PASSWORD,
  GENRES,
  LOAD_USER,
  LOGIN,
  LOGIN_GOOGLE,
  LOGIN_UMG,
  REGISTER,
  RESEND_EMAIL_VERIFICATION,
  RESET_PASSWORD,
  STRIPE_ACCOUNT_PAYOUT,
  STRIPE_ACCOUNT_SETUP,
  STUDIO_SEPARATE_STRIPE_OPT_IN,
  UPDATE_PROFILE,
  UPDATE_USER_LOCATION,
  UPLOAD_PHOTO,
  USER_A_AND_R,
  USER_ARTIST,
  USER_ENGINEER,
  USER_FEEDBACK,
  USER_LISTENER,
  USER_LOCATION_SUPPORT_STATUS,
  USER_OTHER,
  USER_PRODUCER,
  USER_STUDIO_MANAGER,
  VERIFY_AR,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from "../utils/routes";
import { userVerifiedOrOnboardedBeforeLaunchDate } from "../utils/utils";
import { Error, receiveErrors } from "./errorStore";
import { fetchStudioRooms, getMyStudios, updateStudio } from "./studio";
import { fetchProfile } from "./users";

// Define a type for the slice state
interface AccountState {
  isAuthenticated: boolean;
  isLoading: boolean;
  isUpdatingProfile: boolean;
  isProvidingFeedback: boolean;
  user?: User;
  localUTMParams: LocalUTMParams;
  isLoadingGenres: boolean;
  genres: GenreArray;
  // Note we need to store this as a separate piece of state due to circularly updates that occur with the user model.
  meetsStripeRequirements: string | null | undefined;
  isRefreshingEngineer: boolean;
  userStudios: Studio[];
  userStudiosLoading: boolean;
  anonymousId?: string;
}

function setUserContext(payload: User) {
  Sentry.setUser({
    id: String(payload.id),
    name: payload.first_name + " " + payload.last_name,
    username: payload.username,
    email: payload.email,
    isEngineer: payload.engineer && !payload.engineer?.deleted,
    isArtist: payload.artist && !payload.artist?.deleted,
    isProducer: payload.producer && !payload.producer?.deleted,
    isAandR: payload.aandr && !payload.aandr?.deleted,
    isSuperuser: payload.is_superuser,
    isStudioManager: payload.studio_manager && !payload.studio_manager?.deleted,
    dateJoined: payload.date_joined,
    location: payload.location,
  });
}

// Define the initial state using that type
const initialState: AccountState = {
  isAuthenticated: false,
  isLoading: true,
  isUpdatingProfile: false,
  isProvidingFeedback: false,
  isLoadingGenres: false,
  userStudiosLoading: false,
  user: undefined,
  localUTMParams: {
    utm_source: localStorage.getItem("utm_source"),
    utm_medium: localStorage.getItem("utm_medium"),
    utm_campaign: localStorage.getItem("utm_campaign"),
    utm_content: localStorage.getItem("utm_content"),
    utm_term: localStorage.getItem("utm_term"),
  },
  genres: [],
  meetsStripeRequirements: undefined,
  isRefreshingEngineer: false,
  userStudios: [],
};

export const loadUser = createAsyncThunk(
  LOAD_USER,
  async (_: void, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<User>(
      LOAD_USER,
      "",
    );
    if (result.statusCode === 202) {
      return thunkAPI.fulfillWithValue(null);
    }
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface refreshStripeCredentialsParams {
  studio_id?: number | undefined;
}

export const refreshStripeCredentials = createAsyncThunk(
  STRIPE_ACCOUNT_SETUP,
  async (args: refreshStripeCredentialsParams, thunkAPI) => {
    const params = args.studio_id ? `?studio_id=${args.studio_id}` : "";
    const result = await makeBackendGetCallWithJsonResponse<User | Studio>(
      STRIPE_ACCOUNT_SETUP,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface setupStripeAccountParams {
  country_code?: string;
  studio_id?: number;
}

interface StripeAccountSetupResponse {
  available: number;
  currency: string;
  pending: number;
}

export const setupStripeAccount = createAsyncThunk(
  STRIPE_ACCOUNT_SETUP + "/post",
  async (args: setupStripeAccountParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<{
      url: string;
    }>(STRIPE_ACCOUNT_SETUP, args);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getStripeBalanceParams {
  studio_id?: number | undefined;
}

export const getStripeBalance = createAsyncThunk(
  STRIPE_ACCOUNT_PAYOUT,
  async (args: getStripeBalanceParams, thunkAPI) => {
    const params = args.studio_id ? `?studio_id=${args.studio_id}` : "";
    const result =
      await makeBackendGetCallWithJsonResponse<StripeAccountSetupResponse>(
        STRIPE_ACCOUNT_PAYOUT,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface optInToStudioStripeArgs {
  stripe_opt_in?: boolean;
  studio_id?: number;
}
interface optInToStudioStripeResponse {
  stripe_opt_in: boolean;
  studio_id: number;
}

export const optInToStudioSeparateStripe = createAsyncThunk(
  STUDIO_SEPARATE_STRIPE_OPT_IN,
  async (args: optInToStudioStripeArgs, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<optInToStudioStripeResponse>(
        STUDIO_SEPARATE_STRIPE_OPT_IN,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getBettermodeJWTToken = createAsyncThunk(
  BETTERMODE_JWT_TOKEN,
  async (_: void, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<{
      jwt_token: string;
    }>(BETTERMODE_JWT_TOKEN, "");
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getEmpireJWTToken = createAsyncThunk(
  EMPIRE_JWT_TOKEN,
  async (_: void, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<{
      jwt_token: string;
    }>(EMPIRE_JWT_TOKEN, "");
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface payoutStripeBalanceParams {
  amount_to_withdraw: number;
  studio_id?: number;
}

export const payoutStripeBalance = createAsyncThunk(
  STRIPE_ACCOUNT_PAYOUT + "/post",
  async (args: payoutStripeBalanceParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<{
      transfer: boolean;
    }>(STRIPE_ACCOUNT_PAYOUT, args);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

function isUserObject(object: any | undefined): object is User {
  return object.date_joined !== undefined;
}

export const getStripeRequirement = (
  action: PayloadAction<User | null | Studio>,
) => {
  if (!action.payload) return;
  if (!action.payload?.meets_stripe_requirements) {
    if (isUserObject(action.payload)) {
      return userVerifiedOrOnboardedBeforeLaunchDate(action.payload)
        ? action.payload?.date_joined
        : action.payload?.meets_stripe_requirements;
    } else {
      return action.payload?.meets_stripe_requirements;
    }
  }
  return action.payload?.meets_stripe_requirements;
};

export interface uploadPhotoParam {
  data: string;
  username: string;
}

export const uploadPhoto = createAsyncThunk(
  UPLOAD_PHOTO,
  async ({ data }: uploadPhotoParam, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Photo>(
      UPLOAD_PHOTO,
      {
        data,
      },
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface loginParams {
  username: string;
  password: string;
}

export const startLogin = createAsyncThunk(
  LOGIN,
  async (args: loginParams, thunkAPI) => {
    const { username: rawName, password } = args;
    const body = {
      username: rawName.toLowerCase(),
      password,
    };
    const result = await makeBackendPostCallWithJsonResponse<AuthResponse>(
      LOGIN,
      body,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface googleLoginParams {
  authorization_code: string;
}
interface GoogleAuthResponse {
  user: User;
  token: string;
  created: boolean;
}

export const startGoogleLogin = createAsyncThunk(
  LOGIN_GOOGLE,
  async (args: googleLoginParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<GoogleAuthResponse>(
        LOGIN_GOOGLE,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface umgLoginParams {
  access_token: string;
}

export const startLoginUmg = createAsyncThunk(
  LOGIN_UMG,
  async (args: umgLoginParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<AuthResponse>(
      LOGIN_UMG,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface signUpParams {
  username: string;
  password: string;
  email: string;
  isEngineer?: boolean;
}

export interface AuthResponse {
  user: User;
  token: string;
}

export const signUp = createAsyncThunk(
  REGISTER,
  async (args: signUpParams, thunkAPI) => {
    const { username: rawName, password, email: rawEmail, isEngineer } = args;
    const body = {
      username: rawName.toLowerCase(),
      password,
      email: rawEmail.toLowerCase(),
    };
    const result = await makeBackendPostCallWithJsonResponse<AuthResponse>(
      REGISTER,
      body,
    );
    if (result.success) {
      localStorage.setItem("token", result.resultJson.token);
      window.analytics.track(
        getDebugEventPrefix +
          (isEngineer ? "register_as_engineer" : "register_as_artist"),
        {
          user_id: `${getDebugEventPrefix}${result.resultJson.user.id}`,
          username: `${result.resultJson.user.username}`,
          email: `${result.resultJson.user.email}`,
        },
      );
      await thunkAPI.dispatch(
        updateUserArtist({
          is_primary_type: false,
          deleted: true,
        }),
      );
      return result.resultJson;
    }
    if (result.resultJson.code === AUTH_FAILED) {
      result.resultJson.code = INVALID_USERNAME_ON_SIGNUP;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface loadGenresParams {
  user_id: number;
}

export interface UserMusicGenre {
  created: string;
  deleted: string | null;
  genre: Genre;
}

interface LoadGenresResponse {
  genres: UserMusicGenre[];
}

export const loadGenres = createAsyncThunk(
  GENRES,
  async (args: loadGenresParams, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<LoadGenresResponse>(
      GENRES,
      "?user_id=" + args.user_id,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface postGenresParams {
  genres: GenreArray;
}

export const postGenres = createAsyncThunk(
  GENRES,
  async (args: postGenresParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse(GENRES, args);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface UpdateUserAccountTypeParams {
  is_primary_type?: boolean;
  deleted?: boolean;
}

export interface userEngineerParams extends UpdateUserAccountTypeParams {
  not_booking?: boolean;
}

export const updateUserEngineer = createAsyncThunk<
  User,
  userEngineerParams,
  { rejectValue: { errors: Error } }
>(USER_ENGINEER, async (args: userEngineerParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_ENGINEER,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface GetUserEngineerParams {
  user_id?: number;
  username?: string;
  engineer_id?: number;
}

export const getUserEngineer = createAsyncThunk(
  USER_ENGINEER + "get",
  async (args: GetUserEngineerParams, thunkAPI) => {
    let params = "";
    if (args.username) {
      params = `?username=${args.username}`;
    }
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    if (args.engineer_id) {
      params = `?engineer_id=${args.engineer_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<Engineer>(
      USER_ENGINEER,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const updateUserArtist = createAsyncThunk<
  User,
  UpdateUserAccountTypeParams,
  { rejectValue: { errors: Error } }
>(USER_ARTIST, async (args: userEngineerParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_ARTIST,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface VerifyPhoneNumberArgs {
  verification: string;
}

export const verifyAccountPhoneNumber = createAsyncThunk(
  VERIFY_PHONE,
  async (args: VerifyPhoneNumberArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<User>(
      VERIFY_PHONE,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface VerifyEmailArgs {
  verification: string;
  user_id: string;
  send_verification_code?: boolean;
}

export const verifyAccountEmail = createAsyncThunk(
  VERIFY_EMAIL,
  async (args: VerifyEmailArgs, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<User>(
      VERIFY_EMAIL,
      `?${queryString.stringify(args, { skipEmptyString: true, skipNull: true })}`,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const verifyAREmail = createAsyncThunk(
  VERIFY_AR,
  async (args: VerifyEmailArgs, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse(
      VERIFY_AR,
      "?user_id=" + args.user_id + "&verification=" + args.verification,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const updateUserProducer = createAsyncThunk<
  User,
  UpdateUserAccountTypeParams,
  { rejectValue: { errors: Error } }
>(USER_PRODUCER, async (args: UpdateUserAccountTypeParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_PRODUCER,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export const updateUserStudioManager = createAsyncThunk<
  User,
  UpdateUserAccountTypeParams,
  { rejectValue: { errors: Error } }
>(USER_STUDIO_MANAGER, async (args: UpdateUserAccountTypeParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_STUDIO_MANAGER,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface userAAndRParams extends UpdateUserAccountTypeParams {
  admin_email?: string;
  admin_to_verify?: boolean;
  major_label?: MajorLabelEnum;
  sub_label?: number[];
}

export const updateUserAAndR = createAsyncThunk<
  User,
  userAAndRParams,
  { rejectValue: { errors: Error } }
>(USER_A_AND_R, async (args: userAAndRParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_A_AND_R,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface emailLeadParams {
  email: string;
  link: string;
  email_lead_source: EmailLeadSource;
  full_name?: string;
  instagram?: string;
  country?: string;
  type?: string;
  artist_username?: string;
  project_id?: number;
}

export const postEmailLead = createAsyncThunk(
  EMAIL_LEAD,
  async (args: emailLeadParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<{
      existing_account: boolean;
    }>(EMAIL_LEAD, args);
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface updateProfileParams {
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  birth_date?: string;
  bio?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_term?: string | null;
  timezone_shift_minutes?: number;
  timezone?: string;
  phone_number?: string;
  soundcloud_username?: string;
  instagram_username?: string;
  twitter_username?: string;
  facebook_username?: string;
  twitch_username?: string;
  tiktok_username?: string;
  youtube_username?: string;
  long_bio?: string;
  country?: string;
  city?: string;
  region?: string;
  banner_color?: number;
  disable_sms_notifications?: boolean;
}

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE,
  async (args: updateProfileParams, thunkAPI) => {
    let body = args;
    if (args.username) {
      body = {
        ...body,
        username: body.username?.toLowerCase(),
      };
    }
    if (args.email) {
      body = {
        ...body,
        email: args.email?.toLowerCase(),
      };
    }
    if (args.phone_number) {
      body = {
        ...body,
        phone_number: args.phone_number?.replace(/\s/g, "").trim(),
      };
    }
    const result = await makeBackendPostCallWithJsonResponse<User>(
      UPDATE_PROFILE,
      body,
    );
    if (result.success) {
      if (result.resultJson.utm_source) {
        thunkAPI.dispatch(
          storeLocalUTMParams({
            utm_source: result.resultJson.utm_source,
            utm_medium: result.resultJson.utm_medium,
            utm_campaign: result.resultJson.utm_campaign,
            utm_content: result.resultJson.utm_content,
            utm_term: result.resultJson.utm_term,
          }),
        );
      }
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface userFeedbackParams {
  text: string;
  category: string;
  callback_number?: string;
  submitter_email?: string;
}

export const sendPasswordReset = createAsyncThunk(
  FORGOT_PASSWORD,
  async (args: { email: string }, thunkAPI) => {
    const body = {
      email: args.email.toLowerCase(),
    };
    const result = await makeBackendPostCallWithJsonResponse(
      FORGOT_PASSWORD,
      body,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const resetPassword = createAsyncThunk(
  RESET_PASSWORD,
  async (
    args: { user_id: string; reset_code: string; new_password: string },
    thunkAPI,
  ) => {
    const result = await makeBackendPostCallWithJsonResponse(
      RESET_PASSWORD,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const provideUserFeedback = createAsyncThunk(
  USER_FEEDBACK,
  async (args: userFeedbackParams, thunkAPI) => {
    if (args.callback_number === "") {
      args.callback_number = undefined;
    }
    if (args.submitter_email === "") {
      args.submitter_email = undefined;
    }
    const result = await makeBackendPostCallWithJsonResponse(
      USER_FEEDBACK,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getIsSuperuser = createAsyncThunk(
  CHECK_SUPERUSER,
  async (_: void, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<{
      is_superuser: boolean;
    }>(CHECK_SUPERUSER, "");
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const resendEmailVerification = createAsyncThunk(
  RESEND_EMAIL_VERIFICATION,
  async (_: void, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse(
      RESEND_EMAIL_VERIFICATION,
      {},
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface locationParams {
  latitude?: number;
  longitude?: number;
}

interface locationStatusResponse {
  country?: string;
  country_code?: string;
  morning_supported: boolean;
  evening_supported: boolean;
  studio_supported: boolean;
}

export const getUserLocationSupportStatus = createAsyncThunk(
  USER_LOCATION_SUPPORT_STATUS,
  async ({ latitude, longitude }: locationParams, thunkAPI) => {
    const params =
      latitude && longitude
        ? `?latitude=${latitude}&longitude=${longitude}`
        : "";
    const result =
      await makeBackendGetCallWithJsonResponse<locationStatusResponse>(
        USER_LOCATION_SUPPORT_STATUS,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    return thunkAPI.rejectWithValue(errors);
  },
);

export const updateUserLocation = createAsyncThunk(
  UPDATE_USER_LOCATION,
  async ({ latitude, longitude }: locationParams) => {
    const params =
      latitude && longitude
        ? `?latitude=${latitude}&longitude=${longitude}`
        : "";
    const result = await makeBackendGetCallWithJsonResponse(
      UPDATE_USER_LOCATION,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
  },
);

export const updateUserListener = createAsyncThunk<
  User,
  UpdateUserAccountTypeParams,
  { rejectValue: { errors: Error } }
>(USER_LISTENER, async (args: UpdateUserAccountTypeParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_LISTENER,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface GetUserListenerParams {
  user_id?: number;
  listener_id?: number;
}

export const getUserListener = createAsyncThunk(
  USER_LISTENER + "get",
  async (args: GetUserListenerParams, thunkAPI) => {
    let params = "";
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    if (args.listener_id) {
      params = `?listener_id=${args.listener_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<Listener>(
      USER_LISTENER,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface UpdateUserOtherParams extends UpdateUserAccountTypeParams {
  account_type_description?: string;
}

export const updateUserOther = createAsyncThunk<
  User,
  UpdateUserOtherParams,
  { rejectValue: { errors: Error } }
>(USER_OTHER, async (args: UpdateUserOtherParams, thunkAPI) => {
  const result = await makeBackendPostCallWithJsonResponse<User>(
    USER_OTHER,
    args,
  );
  if (result.success) {
    return result.resultJson;
  }
  const errors = { errors: result.resultJson };
  thunkAPI.dispatch(receiveErrors(errors));
  return thunkAPI.rejectWithValue(errors);
});

export interface GetUserOtherParams {
  user_id?: number;
  other_id?: number;
}

export const getUserOther = createAsyncThunk(
  USER_OTHER + "get",
  async (args: GetUserOtherParams, thunkAPI) => {
    let params = "";
    if (args.user_id) {
      params = `?user_id=${args.user_id}`;
    }
    if (args.other_id) {
      params = `?other_id=${args.other_id}`;
    }
    const result = await makeBackendGetCallWithJsonResponse<Other>(
      USER_OTHER,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const accountInfoSlice = createSlice({
  name: "accountInfo",
  initialState,
  reducers: {
    storeLocalUTMParams: (state, action: PayloadAction<LocalUTMParams>) => {
      state.localUTMParams = action.payload;
      // If we have valid UTM params, store them in cache.
      if (action.payload.utm_source) {
        localStorage.setItem("utm_source", action.payload.utm_source ?? "");
        localStorage.setItem("utm_medium", action.payload.utm_medium ?? "");
        localStorage.setItem("utm_campaign", action.payload.utm_campaign ?? "");
        localStorage.setItem("utm_content", action.payload.utm_content ?? "");
        localStorage.setItem("utm_term", action.payload.utm_term ?? "");
      }
    },
    loadMockUser: (state) => {
      state.user = MockUser;
      state.isAuthenticated = true;
    },
    logout: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("darkMode");
      localStorage.removeItem("selectedProfile");
      localStorage.removeItem("streamToken");
      localStorage.removeItem(LOCAL_STORAGE_KEYS.STREAM_TOKEN);
      return initialState;
    },
    storeAnonymousId: (state, action: PayloadAction<string>) => {
      state.anonymousId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStudioRooms.fulfilled, (state, action) => {
      const args = action.meta.arg;
      const studio_id = args.studio_id;
      const studio = state.userStudios.find((s) => s.id === studio_id);

      if (studio) {
        studio.studio_rooms = action.payload;
        state.userStudios = state.userStudios.map((s) =>
          s.id === studio_id ? studio : s,
        );
      }
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      const currentUser = state.user;
      const userProfile = action.payload;
      if (currentUser && userProfile.id === currentUser.id) {
        state.user = _.merge({}, currentUser, userProfile);
      }
    });
    builder.addCase(getUserEngineer.pending, (state) => {
      state.isRefreshingEngineer = true;
    });
    builder.addCase(getUserEngineer.rejected, (state) => {
      state.isRefreshingEngineer = false;
    });
    builder.addCase(
      getUserEngineer.fulfilled,
      (state, action: PayloadAction<Engineer>) => {
        if (state.user && state.user?.engineer?.id === action.payload.id) {
          state.user = {
            ...state.user,
            engineer: action.payload,
          };
        }
        state.isRefreshingEngineer = false;
      },
    );
    builder.addCase(uploadPhoto.fulfilled, (state, action) => {
      const photo: Photo = action.payload;
      const user = state.user;
      if (!user) return;
      user.photo = photo;
      state.user = user;
    });
    builder.addCase(uploadPhoto.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadPhoto.rejected, (state) => {
      state.isLoading = false;
      const user = state.user;
      if (!user) return;
      user.photo = undefined;
      state.user = user;
    });
    builder.addCase(provideUserFeedback.fulfilled, (state) => {
      state.isProvidingFeedback = false;
    });
    builder.addCase(provideUserFeedback.pending, (state) => {
      state.isProvidingFeedback = true;
    });
    builder.addCase(provideUserFeedback.rejected, (state) => {
      state.isProvidingFeedback = false;
    });
    builder.addCase(loadGenres.fulfilled, (state, action) => {
      state.isLoadingGenres = false;
      state.genres = action.payload.genres.map((genreObject) => {
        return genreObject.genre;
      });
    });
    builder.addCase(loadGenres.pending, (state) => {
      state.isLoadingGenres = true;
    });
    builder.addCase(loadGenres.rejected, (state) => {
      state.isLoadingGenres = false;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.isUpdatingProfile = true;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.isUpdatingProfile = false;
    });
    builder.addCase(refreshStripeCredentials.fulfilled, (state, action) => {
      state.meetsStripeRequirements = getStripeRequirement(action);
    });
    builder.addCase(getMyStudios.pending, (state) => {
      state.userStudiosLoading = true;
    });
    builder.addCase(
      updateStudio.fulfilled,
      (state, action: PayloadAction<Studio>) => {
        const studio = action.payload;
        const userStudios = state.userStudios;
        const index = userStudios.findIndex((s) => s.id === studio.id);
        if (index === -1) {
          userStudios.push(studio);
        } else {
          userStudios[index] = studio;
        }
        state.userStudios = userStudios;
      },
    );
    builder.addCase(getMyStudios.rejected, (state) => {
      state.userStudiosLoading = false;
      state.userStudios = [];
    });
    builder.addCase(
      getMyStudios.fulfilled,
      (state, action: PayloadAction<Studio[]>) => {
        state.userStudios = action.payload;
        state.userStudiosLoading = false;
      },
    );
    builder.addCase(
      loadUser.fulfilled,
      (_state, action: PayloadAction<User | null>) => {
        // If the payload comes back as null, we are not logged in and want to clear any previous user values for safety
        if (!action.payload) {
          localStorage.removeItem("token");
          localStorage.removeItem("darkMode");
          localStorage.removeItem("selectedProfile");
          localStorage.removeItem("streamToken");
          localStorage.removeItem(LOCAL_STORAGE_KEYS.STREAM_TOKEN);
        }
      },
    );
    builder.addCase(
      optInToStudioSeparateStripe.fulfilled,
      (state, action: PayloadAction<optInToStudioStripeResponse>) => {
        const stripeOptIn = action.payload.stripe_opt_in;
        const studioId = action.payload.studio_id;
        const userStudios = state.userStudios;
        const index = userStudios.findIndex((s) => s.id === studioId);
        if (index !== -1) {
          state.userStudios[index].separate_stripe_account_opt_in = stripeOptIn;
        }
      },
    );
    builder.addCase(getStripeBalance.pending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(getStripeBalance.fulfilled, getStripeBalance.rejected),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateProfile.fulfilled,
        verifyAccountPhoneNumber.fulfilled,
        verifyAccountEmail.fulfilled,
      ),
      (state, action) => {
        if (!_.isEqual(state.user, action.payload)) {
          state.user = action.payload;
        }
        state.isUpdatingProfile = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        loadUser.fulfilled,
        updateUserEngineer.fulfilled,
        updateUserProducer.fulfilled,
        updateUserStudioManager.fulfilled,
        updateUserArtist.fulfilled,
        updateUserAAndR.fulfilled,
        updateUserOther.fulfilled,
        updateUserListener.fulfilled,
      ),
      (state, action: PayloadAction<User | null>) => {
        const payload = action.payload;
        if (!payload) {
          state.isAuthenticated = false;
          state.isLoading = false;
          Sentry.setUser(null);
        } else {
          state.user = payload;
          state.meetsStripeRequirements = getStripeRequirement(action);
          state.isAuthenticated = true;
          state.isLoading = false;
          setUserContext(payload);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        startLogin.fulfilled,
        signUp.fulfilled,
        startLoginUmg.fulfilled,
        startGoogleLogin.fulfilled,
      ),
      (state, action) => {
        localStorage.setItem("token", action.payload.token);
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.isLoading = false;
        setUserContext(action.payload.user);
      },
    );
    builder.addMatcher(
      isAnyOf(
        startLogin.pending,
        signUp.pending,
        loadUser.pending,
        startLoginUmg.pending,
        updateUserAAndR.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(isAnyOf(updateUserAAndR.rejected), (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(
        startLogin.rejected,
        signUp.rejected,
        loadUser.rejected,
        startLoginUmg.rejected,
      ),
      (state) => {
        localStorage.removeItem("token");
        localStorage.removeItem("darkMode");
        localStorage.removeItem("selectedProfile");
        localStorage.removeItem("streamToken");
        localStorage.removeItem(LOCAL_STORAGE_KEYS.STREAM_TOKEN);
        Sentry.setUser(null);
        return { anonymousId: state.anonymousId, ...initialState };
      },
    );
  },
});

export const { logout, loadMockUser, storeLocalUTMParams, storeAnonymousId } =
  accountInfoSlice.actions;

export default accountInfoSlice.reducer;
