// needed for table body level scope DnD setup
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type UniqueIdentifier,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import {
  faPause,
  faPlay,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import { CSSProperties, MouseEvent, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetFooterPlayerRef } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useIsInProgressProject } from "../../../hooks/partialPaymentHooks";
import {
  IsArtistUploadStep,
  IsListenStep,
} from "../../../hooks/useIsUploadStep";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useQueryParam } from "../../../hooks/useQueryParam";
import {
  updateProjectsOrderIndex,
  updateScheduledProjectsTracks,
} from "../../../store/actions/scheduledprojects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getStepDescription,
  Project,
  ProjectQueryParam,
  tableServiceTypeReadableName,
} from "../../../store/models/project";
import {
  getCurrentTrackBlobMp3UrlIsLoading,
  getCurrentTrackFromPlaylist,
} from "../../../store/selectors/abPlayerSelectors";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  redirectToProjectListenWithCode,
  redirectToProjectUploadWithCode,
} from "../../../store/utils/projectTableUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  getPrereqProjectTitle,
  isProjectInReviewStepOrBeyond,
  isUserCollaboratorOnProject,
} from "../../../utils/projectUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { ProjectListRow } from "../../elements/ProjectListRow/ProjectListRow";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { isProjectOnAssetReviewStep } from "../CompleteTransitionView/utils/isProjectOnAssetReviewStep";
import { MainProjectWorkflowPanel } from "../MainProjectWorkflowPanel/MainProjectWorkflowPanel";
import { Table } from "../ScheduledProjectTable/ScheduledProjectTable.styles";
import { getDisabledRowText } from "../ScheduledProjectTable/utils";
import { ToolTipTextArea } from "../ToolTipTextArea/ToolTipTextArea";
import {
  ActionButtonCell,
  CenteredCell,
  PlayButton,
  RowDraggerButton,
  TableHeadCell,
  TableHeaderRow,
  TableRow,
} from "./NewProjectsTable.styles";
import ProjectActionButton from "./ProjectActionButton";

// Extend table state so it can be shared among children
declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    // Represent loading state when making a network request to reorder the `order index`
    reorderLoading: boolean;
    isPlayingTrackId?: number | null;
    handlePlayMusic: (projectId: number, index: number) => void;
    isFooterPlaying: boolean;
    isCurrentTrackMp3IsLoading?: boolean;
  }
}

// Extend types so that we can share props among cells of the same row
declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface CellContext<TData extends RowData, TValue> {
    isListenStep: boolean;
    isUploadStep: boolean;
    isAssetReview: boolean;
    isInProgressProject: boolean;
    isRowDisabled: boolean;
  }
}
const checkIfWeShouldDisableRow = (row: Project) => {
  return !row.prereq_project_is_completed;
};

interface RowDragHandleCellProps {
  rowId: string;
  disabled: boolean;
}

// Cell Component
const RowDragHandleCell = ({ rowId, disabled }: RowDragHandleCellProps) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    <RowDraggerButton {...attributes} {...listeners} disabled={disabled}>
      <FontAwesomeIcon icon={faArrowUpArrowDown} size="sm" />
    </RowDraggerButton>
  );
};

// Row Component
const DraggableRow = ({
  row,
  code,
  isTableLoading,
  isScheduledProjectRejected,
}: {
  row: Row<Project>;
  code?: string;
  isTableLoading?: boolean;
  isScheduledProjectRejected?: boolean;
}) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  });
  const project = row.original;

  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const query = useQueryParam(ProjectQueryParam.PROJECT_ID);
  const user = useAppSelector((state) => state.accountInfo.user);
  const { isInProgressProject } = useIsInProgressProject(project);
  const isUploadStep = IsArtistUploadStep(project);
  const isListenStep = IsListenStep(project);
  const isAssetReview = isProjectOnAssetReviewStep(
    project.step,
    project.service_type,
  );
  const isUserOnProject = isUserCollaboratorOnProject(user, project.users);
  const isRowDisabled =
    checkIfWeShouldDisableRow(project) || isScheduledProjectRejected || false;
  const handleProjectNavigation = () => {
    if (isRowDisabled || locationPathname.includes("pending")) {
      return;
    }
    if (!code) {
      query.set(project.id.toString());
      return;
    }
    if (isUploadStep) {
      redirectToProjectUploadWithCode(project.id, code, history);
      return;
    }
    if (isListenStep && !isInProgressProject) {
      redirectToProjectListenWithCode(project.id, code, history);
      return;
    }
    if (isAssetReview && !project.assets_approved && !isUserOnProject) {
      return;
    }
    emitAnalyticsTrackingEvent("go_to_project_from_table", {
      project_id: `${project.id}`,
    });
    query.set(project.id.toString());
  };

  let customTransform: null | Transform = null;
  if (transform) {
    customTransform = {
      ...transform,
      scaleY: 1,
    };
  }

  const style: CSSProperties = {
    transform: CSS.Transform.toString(customTransform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      $disabled={isRowDisabled}
      $isTableLoading={isTableLoading}
      onClick={handleProjectNavigation}
    >
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id}>
          {flexRender(cell.column.columnDef.cell, {
            ...cell.getContext(),
            isAssetReview,
            isInProgressProject,
            isListenStep,
            isUploadStep,
            isRowDisabled,
          })}
        </td>
      ))}
    </TableRow>
  );
};

export interface NewProjectsTableProps {
  data: Project[];
  scheduledProjectId: number;
  handlePaymentFlow: () => void;
  code?: string;
  handlePlayMusic: (index: number) => void;
  refunded: boolean;
  allowTrackPreview?: boolean;
}

export const NewProjectsTable = ({
  data,
  scheduledProjectId,
  handlePaymentFlow,
  code,
  handlePlayMusic,
  refunded,
  allowTrackPreview = true,
}: NewProjectsTableProps) => {
  const dispatch = useAppDispatch();
  const { projectsOrderIndexUpdateLoading, scheduledProject } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const { user: currentUser } = useAppSelector((state) => state.accountInfo);
  const footerRef = useGetFooterPlayerRef();
  const currentTrack = useAppSelector(getCurrentTrackFromPlaylist());
  const isCurrentTrackMp3IsLoading = useAppSelector(
    getCurrentTrackBlobMp3UrlIsLoading(),
  );
  const projectQuery = useQueryParam(ProjectQueryParam.PROJECT_ID);
  const projectId = projectQuery.get();

  const { isFooterPlaying } = useAppSelector((state) => state.abPlayerStore);
  // Wider breakpoints than the default ones so all table columns could be appropriately fitted.
  const isTablet = useMediaQuery("(min-width: 640px)");
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const getTrackStageSize = () => {
    if (isDesktop) return 215;
    if (isTablet) return 130;
    return 100;
  };

  const handlePlayButtonClick = (projectId: number, index: number) => {
    if (!allowTrackPreview) {
      handlePaymentFlow();
      return;
    }
    if (projectId === currentTrack?.id) {
      void footerRef.current?.playPause();
      return;
    }
    handlePlayMusic(index);
  };
  // Keep track of projects before making the request to update order_index
  const prevProjectsRef = useRef<Project[]>([]);
  const columns = useMemo<ColumnDef<Project>[]>(() => {
    const result: ColumnDef<Project>[] = [
      {
        accessorKey: "title",
        header: "Title",
        cell: ({ row }) => {
          const project = row.original;
          const userIsCollaborator = isUserCollaboratorOnProject(
            currentUser,
            project?.users,
          );
          return (
            <ProjectListRow
              allowEdit={!refunded && userIsCollaborator}
              project={project}
              hideAlts
            />
          );
        },
      },
      {
        header: "Track Stage",
        cell: ({ row, isRowDisabled }) => {
          const project = row.original;
          const getStepText = () => {
            if (project.assets_approved) {
              return "Approved";
            }

            if (
              isProjectOnAssetReviewStep(project.step, project.service_type)
            ) {
              return "Asset Approval";
            }

            return getStepDescription(project.step, project.service_type);
          };

          return (
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <Text>{getStepText()}</Text>
              {isRowDisabled && !isDesktop && (
                <ToolTipTextArea
                  text={getDisabledRowText(
                    getPrereqProjectTitle(
                      project.prereq_project_id,
                      scheduledProject?.projects,
                    ),
                  )}
                  tooltipTextStyle={{
                    maxWidth: "200px",
                    right: 0,
                    textWrap: "wrap",
                    width: "200px",
                  }}
                />
              )}
            </div>
          );
        },
        size: getTrackStageSize(),
      },
      {
        id: "play_button",
        cell: ({ table, row, isRowDisabled }) => {
          const trackId = row.original.id;
          const { step, service_type } = row.original;
          const isAtLeastAtReviewStep = isProjectInReviewStepOrBeyond(
            service_type,
            step,
          );

          if (
            table.options.meta?.isPlayingTrackId === trackId &&
            table.options.meta.isCurrentTrackMp3IsLoading
          ) {
            return (
              <CenteredCell>
                <SoundWaveLoader
                  width={50}
                  height={50}
                  styles={{ margin: "auto" }}
                />
              </CenteredCell>
            );
          }
          return (
            <CenteredCell>
              {!isAtLeastAtReviewStep ? (
                <ToolTipTextArea text="Playback will be available once the the project is in the review stage.">
                  <PlayButton disabled>
                    <FontAwesomeIcon
                      icon={
                        table.options.meta?.isFooterPlaying &&
                        table.options.meta?.isPlayingTrackId === trackId
                          ? faPause
                          : faPlay
                      }
                    />
                  </PlayButton>
                </ToolTipTextArea>
              ) : (
                <PlayButton
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    if (refunded) return;
                    table.options.meta!.handlePlayMusic(trackId, row.index);
                  }}
                  disabled={table.options.meta?.reorderLoading || isRowDisabled}
                >
                  <FontAwesomeIcon
                    icon={
                      table.options.meta?.isFooterPlaying &&
                      table.options.meta?.isPlayingTrackId === trackId
                        ? faPause
                        : faPlay
                    }
                  />
                </PlayButton>
              )}
            </CenteredCell>
          );
        },
        size: isDesktop ? 135 : 50,
        enableSorting: false,
      },
    ];

    if (isTablet || isDesktop) {
      result.splice(1, 0, {
        accessorFn: (originalRow) =>
          tableServiceTypeReadableName.get(originalRow.service_type),
        header: "Service",
        size: isDesktop ? 140 : 115,
      });
    }

    if (isDesktop) {
      result.splice(2, 0, {
        header: "Price",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div>
              {PennyDollarFormatter().format(+row.original.total_price)}
            </div>
          );
        },
      });
      result.push({
        header: "Action",
        enableSorting: false,
        cell: ({
          row,
          table,
          isAssetReview,
          isInProgressProject,
          isListenStep,
          isUploadStep,
          isRowDisabled,
        }) => {
          return (
            <ActionButtonCell>
              <ProjectActionButton
                project={row.original}
                code={code}
                handlePaymentFlow={handlePaymentFlow}
                disabled={
                  Boolean(table.options.meta?.reorderLoading) ||
                  isRowDisabled ||
                  refunded ||
                  Boolean(row.original.refunded)
                }
                isAssetReview={isAssetReview}
                isInProgressProject={isInProgressProject}
                isListenStep={isListenStep}
                isUploadStep={isUploadStep}
                isLabelProject={scheduledProject?.is_label_project}
              />
              {isRowDisabled && (
                <ToolTipTextArea
                  text={getDisabledRowText(
                    getPrereqProjectTitle(
                      row.original.prereq_project_id,
                      scheduledProject?.projects,
                    ),
                  )}
                  tooltipTextStyle={{
                    maxWidth: "200px",
                    right: 0,
                    textWrap: "wrap",
                    width: "200px",
                  }}
                />
              )}
            </ActionButtonCell>
          );
        },
        size: 170,
      });
    }

    result.unshift({
      id: "drag-handle",
      cell: ({ row, table, isRowDisabled }) => (
        <CenteredCell>
          {!code && (
            <RowDragHandleCell
              rowId={row.id}
              disabled={
                table.getState().sorting.length > 0 ||
                table.options.meta?.reorderLoading ||
                isRowDisabled
              }
            />
          )}
        </CenteredCell>
      ),
      size: 50,
    });
    return result;
  }, [code, handlePaymentFlow, isDesktop, isTablet]);

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data.map(({ id }) => id.toString()),
    [data],
  );

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(), //required because row indexes will change
    getSortedRowModel: getSortedRowModel(),
    isMultiSortEvent: () => true,
    meta: {
      reorderLoading: projectsOrderIndexUpdateLoading,
      isPlayingTrackId: currentTrack?.id,
      handlePlayMusic: handlePlayButtonClick,
      isFooterPlaying: isFooterPlaying,
      isCurrentTrackMp3IsLoading: isCurrentTrackMp3IsLoading,
    },
  });

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      const reorderedData = arrayMove(data, oldIndex, newIndex);
      const reorderedDataWithOrderIndex: Project[] = reorderedData.map(
        (row, idx) => {
          return {
            ...row,
            order_index: idx,
          };
        },
      );

      // `reorderedDataWithOrderIndex` may not include all projects in the scheduled project
      // i.e. Some projects may be in the Mixing tab while others are in the Mastering tab
      const originalProjects = scheduledProject?.projects ?? [];
      const totalProjects = [...reorderedDataWithOrderIndex];

      // Include any missing projects in the reordered list
      // The `ProjectSortIndexUpdateAPI` will handle re-ordering related pre-req projects
      originalProjects.forEach((project) => {
        const isProjectIncluded = reorderedDataWithOrderIndex.some(
          ({ id }) => id === project.id,
        );
        if (!isProjectIncluded) {
          totalProjects.push(project);
        }
      });

      // Before making a network request, store current state in a ref
      // So that we can restore the state later if the network request fails
      prevProjectsRef.current = data;

      // Optimistic update the row order
      dispatch(updateScheduledProjectsTracks(totalProjects));

      // Make network request for the new row order
      dispatch(
        updateProjectsOrderIndex({
          scheduled_project_id: scheduledProjectId,
          projects_to_update: totalProjects.map((data) => data.id),
        }),
      )
        .unwrap()
        .catch(() => {
          toast.error(
            "An error occurred while re-ordering tracks. Please try again!",
          );

          // Restore the previously saved order if the network request fails
          dispatch(updateScheduledProjectsTracks(prevProjectsRef.current));
        })
        .finally(() => {
          prevProjectsRef.current = [];
        });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <Table $loading={table.options.meta?.reorderLoading} $isFixed>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const renderSortingIcon = () => {
                  const sortOrder = header.column.getIsSorted();
                  if (!sortOrder) {
                    return null;
                  }

                  return (
                    <FontAwesomeIcon
                      icon={sortOrder === "asc" ? faSortDown : faSortUp}
                    />
                  );
                };

                return (
                  <TableHeadCell
                    key={header.id}
                    colSpan={header.colSpan}
                    $disabled={table.options.meta?.reorderLoading}
                    $widthSize={
                      header.id === "title" ? "auto" : `${header.getSize()}px`
                    }
                    onClick={
                      !table.options.meta?.reorderLoading
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                  >
                    <div>
                      {header.isPlaceholder ? null : (
                        <>
                          <span>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </span>

                          {renderSortingIcon()}
                        </>
                      )}
                    </div>
                  </TableHeadCell>
                );
              })}
            </TableHeaderRow>
          ))}
        </thead>
        <tbody>
          <SortableContext
            items={dataIds}
            strategy={verticalListSortingStrategy}
          >
            {table.getRowModel().rows.map((row) => (
              <DraggableRow
                key={row.id}
                row={row}
                code={code}
                isTableLoading={table.options.meta?.reorderLoading}
                isScheduledProjectRejected={refunded}
              />
            ))}
          </SortableContext>
        </tbody>
      </Table>
      <MainProjectWorkflowPanel
        isOpen={Boolean(projectId)}
        onClose={() => {
          projectQuery.remove();
        }}
        projectId={projectId ? parseInt(projectId) : null}
        code={code}
      />
    </DndContext>
  );
};
