import { useEffect, useMemo, useState } from "react";
import User from "../store/models/user";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  PaginatedAdminActionsResponse,
  getPaginatedAdminActionItems,
} from "../store/actions/adminDashboardStore";
import { ProjectType } from "../store/models/project";
import { ProjectTypeNumbers } from "../stories/elements/ActionItemRow/ActionItemRow";
import { Transaction, TransactionItem } from "../store/models/transaction";

export const useGetPaginatedAdminActions = (
  completedPurchaseOrders: boolean,
  pageSize?: number,
) => {
  const [paginatedAdminActions, setPaginatedAdminActions] =
    useState<PaginatedAdminActionsResponse>({
      data: [],
      num_pages: 1,
      count: 1,
    });
  const [page, setPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const loggedInUser: undefined | User = useAppSelector(
    (state) => state.accountInfo.user,
  );

  useEffect(() => {
    if (!loggedInUser) {
      return;
    }
    const fetchPlatformActivity = async () => {
      setIsLoading(true);
      const response = await dispatch(
        getPaginatedAdminActionItems({
          completed: completedPurchaseOrders,
          page: page,
          page_size: pageSize,
        }),
      ).unwrap();

      if (response) {
        setPaginatedAdminActions({
          data: response.data,
          num_pages: response.num_pages,
          count: response.count,
        });
      }

      return () => {
        setPaginatedAdminActions({
          data: [],
          num_pages: 1,
          count: 1,
        });
      };
    };

    fetchPlatformActivity()
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, loggedInUser, page, completedPurchaseOrders, pageSize]);

  return {
    paginatedAdminActions,
    isLoading,
    page,
    setPage,
  };
};

export const useProjectTypeToCountMap = (
  transaction: Transaction | undefined,
): Map<ProjectType, ProjectTypeNumbers> => {
  const projectTypeToCountMap: Map<ProjectType, ProjectTypeNumbers> =
    useMemo(() => {
      if (!transaction) return new Map<ProjectType, ProjectTypeNumbers>();
      const projectTypeToCountMap = new Map<ProjectType, ProjectTypeNumbers>();
      transaction?.items?.forEach((transactionItem: TransactionItem) => {
        if (!transactionItem.project) return;
        if (!projectTypeToCountMap.has(transactionItem.project.service_type)) {
          projectTypeToCountMap.set(transactionItem.project.service_type, {
            count: 1,
            subTotal: +transactionItem.amount,
            firstProject: transactionItem.project,
          });
          return;
        }
        projectTypeToCountMap.get(
          transactionItem.project.service_type,
        )!.count += 1;
        projectTypeToCountMap.get(
          transactionItem.project.service_type,
        )!.subTotal += +transactionItem.amount;
      });
      return projectTypeToCountMap;
    }, [transaction]);
  return projectTypeToCountMap;
};
