export default interface AccountTypeBase {
  id: number;
  created?: string;
  deleted?: string | null;
  is_primary_type?: string | null;
  user_id: number;
}

export enum EmailLeadSource {
  UNKNOWN_EMAIL_LEAD_SOURCE,
  SONIC_MATCH_LANDING,
  ONBOARDING_GEOFILTER,
  SOUND_OF_TOMORROW_COMPETITION,
  SOUND_OF_TOMORROW_VOTE,
}
