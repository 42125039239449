import User from "../../store/models/user";
import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { FETCH_USER_PROFILE } from "../../store/utils/routes";

export const fetchUser = async (user_id: string): Promise<User> => {
  const result = await makeBackendGetCallWithJsonResponse<User>(
    FETCH_USER_PROFILE,
    `?user_id=${user_id}`,
  );
  if (result.success) {
    return result.resultJson;
  }
  return Promise.reject(result);
};
