import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useAccountStatus } from "../../../hooks/accountHooks";
import { useTransactionProjects } from "../../../hooks/transactionHook";
import { useBillingInfoSubmitters } from "../../../hooks/useBillingInfoSubmitters";
import { useIsUMGAandR } from "../../../hooks/useIsAandR";
import { usePendingPurchaseOrderApprovers } from "../../../hooks/usePendingPurchaseOrderApprover";
import {
  PendingBudgetManager,
  PostPurchaseOrderArgs,
  markTransactionPaid,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Transaction } from "../../../store/models/transaction";
import { MajorLabelEnum, UMGSubLabelEnum } from "../../../store/models/trophy";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { validateEmailList } from "../../../utils/utils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  ContactInfoModal,
  MISSING_PHONE_NUMBER_MESSAGE,
} from "../ContactInfoModal/ContactInfoModal";
import { MyBudgetManagersForm } from "../MyBudgetManagersForm/MyBudgetManagersForm";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import "./PurchaseOrderCheckoutForm.css";

export interface PurchaseOrderCheckoutFormProps {
  transaction: Transaction;
  isMobile: boolean;
  markedPaid: boolean;
  setMarkedPaid: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
  projectTitle?: string;
  artistName?: string;
  disableBooking?: boolean;
}

export const PurchaseOrderCheckoutForm = ({
  transaction,
  projectTitle,
  artistName,
  isMobile,
  children,
  markedPaid,
  setMarkedPaid,
  disableBooking,
}: PurchaseOrderCheckoutFormProps) => {
  const dispatch = useAppDispatch();
  const { missingPhoneNumber } = useAccountStatus();
  const [showMissingPhoneNumberModal, setShowMissingPhoneNumberModal] =
    useState(false);
  const [collaboratorEmails, setCollaboratorEmails] = useState<string[]>([]);
  const [submittingPurchaseOrder, setSubmittingPurchaseOrder] = useState(false);
  const projects = useTransactionProjects(transaction);
  const {
    handleRemoveBillingInfoSubmitterEmail,
    handleBillingInfoSubmitterEmailChange,
    billingInfoSubmitters,
  } = useBillingInfoSubmitters();
  const {
    budgetApprovers,
    toggleApprovalRequired,
    onChange: onChangeBudgetApprover,
    removeApprover,
  } = usePendingPurchaseOrderApprovers();
  const [showStripePaymentForm, setShowStripePaymentForm] = useState(false);
  const { user, isAuthenticated } = useAppSelector(
    (state) => state.accountInfo,
  );
  const [showUnauthenticatedModal, setShowUnauthenticatedModal] =
    useState(false);
  const isUMGAandR = useIsUMGAandR(user);
  const [postPurchaseArgs, setPostPurchaseArgs] =
    useState<PostPurchaseOrderArgs>({});
  const labelAssociations = useAppSelector(
    (state) => state.accountInfo.user?.aandr?.label,
  );
  // Ensures that label and sub-label information are set for UMG A&Rs.
  useEffect(() => {
    if (!isUMGAandR) return;
    if (!labelAssociations) return;
    if (!labelAssociations.length) return;
    setPostPurchaseArgs({
      ...postPurchaseArgs,
      major_label: MajorLabelEnum.UMG,
      umg_sub_label: labelAssociations[0].umg_sub_label,
    });
  }, [isUMGAandR, labelAssociations]);

  const handlePurchaseOrderSubmit = async (
    postPurchaseArgs: PostPurchaseOrderArgs,
  ) => {
    if (!isAuthenticated) {
      setShowUnauthenticatedModal(true);
      toast.error("You need to be logged in to submit a purchase order.");
    }
    if (missingPhoneNumber) {
      setShowMissingPhoneNumberModal(true);
      toast.error(
        "You need to add a phone number to your account to submit a purchase order.",
      );
    }
    if (submittingPurchaseOrder) return;
    if (!validateEmailList(collaboratorEmails)) {
      toast.error("Invalid collaborator email provided.");
      return;
    }
    if (
      budgetApprovers.length === 0 ||
      !budgetApprovers.some((approver) => approver.approval_required)
    ) {
      toast.error("You need to submit at least one required budget approver.");
      return;
    }
    emitAnalyticsTrackingEvent(
      "mark_paid_scheduled_project",
      {
        transaction_id: `${transaction.id}`,
        value: transaction.total_price ?? 0,
      },
      user?.id,
    );
    emitAnalyticsTrackingEvent(
      "book_with_purchase_order",
      {
        transaction_id: `${transaction.id}`,
      },
      user?.id,
    );
    const budgetManagers: PendingBudgetManager[] = [];
    budgetApprovers.forEach((approver) => {
      budgetManagers.push({
        email: approver.email,
        can_approve_budget: true,
        approval_required: approver.approval_required,
        can_submit_billing_info: false,
      } as PendingBudgetManager);
    });
    billingInfoSubmitters.forEach((submitter) => {
      const found = budgetManagers.find(
        (budgetManager) => budgetManager.email === submitter,
      );
      if (found) {
        found.can_submit_billing_info = true;
      } else {
        budgetManagers.push({
          email: submitter,
          can_approve_budget: false,
          can_submit_billing_info: true,
          approval_required: false,
        } as PendingBudgetManager);
      }
    });
    try {
      setSubmittingPurchaseOrder(true);
      await dispatch(
        markTransactionPaid({
          transaction_id: transaction.id,
          title: projectTitle,
          artist_name: artistName,
          booked_with_purchase_order: true,
          emailsList: collaboratorEmails,
          cost_center: postPurchaseArgs.cost_center,
          work_breakdown_structure: postPurchaseArgs.work_breakdown_structure,
          general_ledger: postPurchaseArgs.general_ledger,
          major_label: postPurchaseArgs.major_label,
          umg_sub_label: postPurchaseArgs.umg_sub_label,
          budget_managers: budgetManagers,
        }),
      );
      setSubmittingPurchaseOrder(false);
      setMarkedPaid(true);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      } else {
        toast.error("An error occurred while submitting the purchase order.");
      }
      setSubmittingPurchaseOrder(false);
    }
  };

  return (
    <>
      {markedPaid && children ? (
        children
      ) : (
        <MyBudgetManagersForm
          budgetManagerFormUsedInCheckout={true}
          setPostPurchaseArgs={setPostPurchaseArgs}
          postPurchaseArgs={postPurchaseArgs}
          billingInfoSubmitters={billingInfoSubmitters}
          setCollaboratorEmails={setCollaboratorEmails}
          onChangeBudgetApprover={onChangeBudgetApprover}
          handleRemoveBillingInfoSubmitterEmail={
            handleRemoveBillingInfoSubmitterEmail
          }
          handleBillingInfoSubmitterEmailChange={
            handleBillingInfoSubmitterEmailChange
          }
          budgetApprovers={budgetApprovers}
          collaboratorEmails={collaboratorEmails}
          toggleApprovalRequired={toggleApprovalRequired}
          removeApprover={removeApprover}
          hasMixingOrMastering={projects.some(
            (project) => project.service_type !== ProjectType.RECORDING,
          )}
        >
          <div className="purchase-order-footer">
            {!isMobile && children && (
              <Button
                disabled={markedPaid}
                fullWidth={false}
                onClick={() => {
                  setShowStripePaymentForm(true);
                }}
                variant={ButtonVariant.OUTLINED}
              >
                Pay with Card
              </Button>
            )}
            <Button
              fullWidth={false}
              onClick={() => {
                void handlePurchaseOrderSubmit(postPurchaseArgs);
              }}
              variant={ButtonVariant.PRIMARY}
              disabled={markedPaid || disableBooking}
              loading={submittingPurchaseOrder}
            >
              {isUMGAandR &&
              postPurchaseArgs.umg_sub_label !== UMGSubLabelEnum.IGA
                ? "Request Budget Approvals"
                : "Request Budget Approvals"}
            </Button>
          </div>
        </MyBudgetManagersForm>
      )}
      <UnauthenticatedModal
        showModal={showUnauthenticatedModal}
        closeModal={() => {
          setShowUnauthenticatedModal(false);
        }}
        message={"You need to be logged in to submit a purchase order."}
      />
      {showMissingPhoneNumberModal && (
        <ContactInfoModal
          disableDismiss={missingPhoneNumber}
          overwriteShowModal={true}
          customHeading={MISSING_PHONE_NUMBER_MESSAGE}
        />
      )}
      <BaseModal
        open={showStripePaymentForm && !markedPaid}
        setOpen={setShowStripePaymentForm}
        header={"Checkout"}
      >
        {children}
      </BaseModal>
    </>
  );
};
