import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "./fetchUser";

const useFetchUserQuery = (userId: string, pollingInterval: number) => {
  return useQuery({
    queryKey: ["fetch_user"],
    queryFn: async () => fetchUser(userId),
    refetchInterval: pollingInterval,
    enabled: Boolean(userId),
  });
};

export default useFetchUserQuery;
