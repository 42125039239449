import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { AuthSteps, FLOW_STEPS } from "../../constants/authSteps";
import {
  currentFlowAtom,
  currentSignUpStepAtom,
} from "../../stories/components/Auth/atoms";
import { useAppSelector } from "../../store/hooks";
import {
  selectEmailVerified,
  selectPhoneVerified,
} from "../../store/selectors/userInfoSelectors";

/**
 * @description
 * This will return the steps for the current flow and the function to navigate to the previous step.
 */
export const useAuthNavigationSteps = () => {
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const isPhoneVerified = useAppSelector(selectPhoneVerified);
  const setStep = useSetAtom(currentSignUpStepAtom);
  const currentFlow = useAtomValue(currentFlowAtom);
  const currentStep = useAtomValue(currentSignUpStepAtom);

  const flowSteps = useMemo(() => {
    return FLOW_STEPS[currentFlow]?.steps.flat() || [];
  }, [currentFlow]);

  const nextStep = (options?: { skipVerification?: boolean }) => {
    const currentStepIndex = flowSteps.indexOf(currentStep);

    if (options?.skipVerification) {
      setStep(flowSteps[flowSteps.length - 1]);
    } else if (currentStepIndex < flowSteps.length - 1) {
      setStep(flowSteps[currentStepIndex + 1]);
    }

    if (
      flowSteps[currentStepIndex + 1] === AuthSteps.VERIFY_EMAIL &&
      isEmailVerified
    ) {
      setStep(flowSteps[currentStepIndex + 2]);
    }

    if (
      flowSteps[currentStepIndex + 1] === AuthSteps.VERIFY_PHONE &&
      isPhoneVerified
    ) {
      setStep(flowSteps[currentStepIndex + 2]);
    }
  };

  const prevStep = () => {
    const currentStepIndex = flowSteps.indexOf(currentStep);

    if (currentStepIndex > 0) {
      const prevStepIndex = currentStepIndex - 1;

      if (
        flowSteps[prevStepIndex] === AuthSteps.VERIFY_EMAIL &&
        isEmailVerified
      ) {
        setStep(flowSteps[prevStepIndex - 1]);
      } else if (
        flowSteps[prevStepIndex] === AuthSteps.VERIFY_PHONE &&
        isPhoneVerified
      ) {
        setStep(flowSteps[prevStepIndex - 2]);
      } else {
        setStep(flowSteps[prevStepIndex]);
      }
    }
  };

  return { nextStep, prevStep, flowSteps };
};
