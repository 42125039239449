import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme as muiUseTheme, useMediaQuery } from "@mui/material";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { AuthFlows } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";
import { useUsernameCheck } from "../../../hooks/usernameCheck";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { RootState } from "../../../store";
import {
  signUp,
  startLogin,
  startLoginUmg,
  updateProfile,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getDashboardRoute } from "../../../store/utils/routeGetters";
import {
  emitAnalyticsTrackingEvent,
  getDebugEventPrefix,
} from "../../../utils/analyticsUtils";
import { loginRequest } from "../../../utils/umgAuthConfig";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { authModalOpenAtom, currentFlowAtom } from "../Auth/atoms";
import { PasswordField } from "../PasswordField/PasswordField";
import {
  FieldContainer,
  FieldInput,
  FormContainer,
  UsernameChecksContainer,
  UsernameRequirement,
} from "./SignInForm.styles";

export interface signUpErrorProps {
  code: number;
  username: string;
  email: string;
  password: string;
}

export interface SignInFormProps {
  primary?: boolean;
  register: boolean;
  registerUsername?: boolean;
  showWarning?: boolean;
  defaultValueEmail?: string;
  defaultValueUsername?: string;
}
export const SignInForm = ({
  register,
  showWarning = true,
  registerUsername = false,
  ...props
}: SignInFormProps) => {
  const theme = useTheme();
  const { breakpoints } = muiUseTheme();
  const dispatch = useAppDispatch();
  const { nextStep } = useAuthNavigationSteps();
  const setFlow = useSetAtom(currentFlowAtom);
  const { search } = useLocation();
  const { user } = useAppSelector((state: RootState) => state.accountInfo);
  const [username, setUsername] = useState<string>(
    register ? user?.username ?? props.defaultValueUsername ?? "" : "",
  );
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>(props.defaultValueEmail ?? "");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<signUpErrorProps>();
  const { instance, accounts, inProgress } = useMsal();
  const isUmgAuthenticated = useIsAuthenticated();
  const [enteredUMGEmail, setEnteredUMGEmail] = useState<boolean>(false);
  const [umgAccessToken, setUmgAccessToken] = useState("");
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const [hasSpace, hasSpecialCharacter] = useUsernameCheck(username);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { handleGoogleClick } = useGoogleAuth();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const history = useHistory();

  useSetPageTitle(register ? "Sign Up" : "Sign In");

  const handleSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setLoading(true);
      if (register) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(username)) {
          setSignUpError({
            code: 400,
            username: "Username cannot be an email address",
            email: "",
            password: "",
          });
          setLoading(false);
          return;
        }
        emitAnalyticsTrackingEvent(getDebugEventPrefix + "register", {
          username: `${username}`,
          email: `${email}`,
          ...localUTMParams,
        });
        await dispatch(
          signUp({
            username: username,
            password: password,
            email: email,
          }),
        )
          .unwrap()
          .then(() => {
            setFlow(AuthFlows.STANDARD_SIGNUP);
          })
          .catch((err) => {
            setSignUpError(err.errors);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        await dispatch(startLogin({ username: username, password }))
          .unwrap()
          .then((result) => {
            emitAnalyticsTrackingEvent(
              getDebugEventPrefix + "login",
              {
                user_id: `${getDebugEventPrefix}${result.user.id}`,
                username: `${username}`,
                ...localUTMParams,
              },
              result.user.id,
            );
            setLoading(false);
            if (isMobile) history.push(getDashboardRoute());
            setAuthModalOpen(false);
          })
          .catch((err) => {
            setSignUpError(err.errors);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [dispatch, username, password, email, register],
  );

  useEffect(() => {
    if (!isUmgAuthenticated) return;
    if (!accounts.length) return;
    if (inProgress !== InteractionStatus.None) return;
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setUmgAccessToken(response.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          void instance.acquireTokenRedirect(request);
        }
        console.log(error);
      });
  }, [isUmgAuthenticated, accounts, inProgress]);

  useEffect(() => {
    if (!umgAccessToken) return;
    void dispatch(startLoginUmg({ access_token: umgAccessToken }))
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent(getDebugEventPrefix + "login_umg", {
          username: `${username}`,
          ...localUTMParams,
        });
        setFlow(AuthFlows.STANDARD_SIGNUP);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [umgAccessToken]);

  const handleUmgLogin = () => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      toast.error(
        "Something went wrong with the login request. Please contact support.",
      );
      console.log(e);
    });
  };

  const handleUpdateUsername = useCallback(() => {
    if (!username || username.length < 1) return;
    setLoading(true);
    dispatch(updateProfile({ username: username }))
      .unwrap()
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        setSignUpError(err.errors);
      });
    setLoading(false);
  }, [username, user, dispatch]);

  const usernameRequirement = (label: string, isPassing: boolean) => (
    <UsernameRequirement>
      <FontAwesomeIcon
        icon={isPassing ? faClose : faCheck}
        color={
          isPassing ? theme.colorPalette.Red600 : theme.colorPalette.Green600
        }
      />
      <Text
        variant={TextStyleVariant.P3}
        color={isPassing ? TextColor.FAILED : TextColor.SUCCESS}
      >
        {label}
      </Text>
    </UsernameRequirement>
  );

  if (isUmgAuthenticated) {
    // Triggers acquiring an access token.
    return <SoundWaveLoader width={100} height={100} />;
  }

  return (
    <FormContainer>
      {
        // Username field (if logging in or signing up username step)
        ((register && registerUsername) || !register) && (
          <>
            <FieldContainer>
              <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
                {register ? "Choose a username" : "Username or Email"}
              </Text>
              <FieldInput
                defaultValue={
                  user?.username ? user.username : props.defaultValueUsername
                }
                disabled={loading}
                fullWidth={true}
                size={"small"}
                onChange={(e) => {
                  const value = e.target.value;
                  setSignUpError(undefined);
                  setUsername(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (register && registerUsername && username.length > 0) {
                      handleUpdateUsername();
                    } else {
                      void handleSubmit(e);
                    }
                  }
                }}
                error={Boolean(signUpError?.username)}
                helperText={signUpError?.username}
              />
              {username && register && registerUsername && (
                <UsernameChecksContainer>
                  {usernameRequirement("No spaces allowed", hasSpace)}
                  {usernameRequirement(
                    "No special characters (dashes and underscores allowed)",
                    hasSpecialCharacter,
                  )}
                </UsernameChecksContainer>
              )}
            </FieldContainer>
            {register && registerUsername && (
              <Button
                fullWidth={true}
                loading={loading}
                variant={ButtonVariant.PRIMARY}
                disabled={!username || hasSpace || hasSpecialCharacter}
                onClick={handleUpdateUsername}
              >
                Create your account
              </Button>
            )}
          </>
        )
      }
      {
        // Email field (if signing up)
        register && !registerUsername && (
          <FieldContainer>
            <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Email</Text>
            <FieldInput
              defaultValue={props.defaultValueEmail}
              fullWidth={true}
              size={"small"}
              onChange={(e) => {
                const email = e.target.value;
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                setEmail(email);
                if (
                  email.toLowerCase().includes("@umusic.com") ||
                  email.toLowerCase().includes("@umgconsult.com")
                ) {
                  setEnteredUMGEmail(true);
                } else {
                  setEnteredUMGEmail(false);
                }
                if (emailPattern.test(email)) {
                  setIsValidEmail(true);
                } else {
                  setIsValidEmail(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (email.length > 0 && isValidEmail) {
                    void handleSubmit(e);
                  }
                }
              }}
              InputProps={{
                endAdornment: isValidEmail ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : null,
              }}
              error={Boolean(signUpError?.email)}
              helperText={signUpError?.email}
            />
          </FieldContainer>
        )
      }
      {
        // Password field (if signing up or logging in)
        !enteredUMGEmail && !registerUsername && (
          <>
            <Box>
              <PasswordField
                registering={register}
                showWarning={showWarning}
                newPassword={register}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={Boolean(signUpError?.password) && !password.length}
                helperText={!password.length ? signUpError?.password : ""}
                loading={loading}
                handleSubmit={handleSubmit}
                isSubmitDisabled={
                  register
                    ? !email || !isValidEmail || !password.length
                    : !username.length || !password.length
                }
              />
            </Box>
          </>
        )
      }
      {
        // Google SSO
        !register && (
          <Button
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
            style={{ gap: "8px", minWidth: "162px", maxHeight: "44px" }}
            onClick={handleGoogleClick}
          >
            <img
              alt={"google logo icon"}
              src={GoogleLogoIcon}
              width={18}
              height={18}
            />
            Continue with Google
          </Button>
        )
      }
      {
        // UMG SSO
        enteredUMGEmail && (
          <Button
            className={"mt-3"}
            variant={ButtonVariant.PRIMARY}
            fullWidth={true}
            loading={loading}
            onClick={handleUmgLogin}
          >
            Sign in with UMG SSO
          </Button>
        )
      }
    </FormContainer>
  );
};
