import AccountTypeBase from "./base";
import { BannerColor } from "./profile";
import { Studio } from "./studio";
import { MajorLabelEnum, UMGSubLabelEnum } from "./trophy";
import User, { MockUser, MockUser1 } from "./user";

export enum TeamRole {
  NO_ROLE,
  STUDIO_MANAGER,
  ENGINEER,
  ADMIN,
  AANDR,
}

export const TEAM_ROLES = [
  { value: TeamRole.NO_ROLE, label: "Undefined Role" },
  { value: TeamRole.STUDIO_MANAGER, label: "Studio Manager" },
  { value: TeamRole.ENGINEER, label: "Affiliated Engineer" },
  { value: TeamRole.ADMIN, label: "Admin" },
  { value: TeamRole.AANDR, label: "A&R" },
];

export const STUDIO_TEAM_ROLES = [
  { value: TeamRole.STUDIO_MANAGER, label: "Studio Manager" },
  { value: TeamRole.ENGINEER, label: "Engineer" },
];

export const LABEL_TEAM_ROLES = [
  { value: TeamRole.ADMIN, label: "Admin" },
  { value: TeamRole.AANDR, label: "A&R" },
];

export const getRoleForRoleEnum = (role: TeamRole): string => {
  switch (role) {
    case TeamRole.NO_ROLE:
      return "Undefined Role";
    case TeamRole.STUDIO_MANAGER:
      return "Studio Manager";
    case TeamRole.ENGINEER:
      return "Engineer";
    case TeamRole.ADMIN:
      return "Admin";
    case TeamRole.AANDR:
      return "AandR";
    default:
      return "Undefined Role";
  }
};
export interface Admin extends AccountTypeBase {
  admin_verified: string;
}

export interface LabelAssociation {
  major_label: MajorLabelEnum;
  umg_sub_label: UMGSubLabelEnum;
}

export interface AffiliationInvite {
  id: number;
  accepted: string | null;
  declined: string | null;
  revoked: string | null;
  email?: string;
  invitee?: User;
  role: TeamRole;
}
export interface Team {
  id: number;
  managers: User[];
  members: User[];
  pending_invites: AffiliationInvite[];
  studio?: Studio;
  label?: LabelAssociation;
  studio_id?: number;
}

export const MockAdmin = {
  id: 1,
  user_id: 3,
  admin_verified: "2021-11-16T22:28:38.351243",
};

export const MockAffiliationInviteWithEmail: AffiliationInvite = {
  id: 1,
  accepted: null,
  declined: null,
  revoked: null,
  role: 2,
  email: "test_@engineear.com",
};

export const MockAffiliationInviteWithUser: AffiliationInvite = {
  id: 2,
  accepted: null,
  declined: null,
  revoked: null,
  role: 3,
  invitee: MockUser1,
};
export const MockUserAdmin: User = {
  is_superuser: null,
  id: 3,
  user_id: 0,
  username: "IGA admin",
  last_name: "Taswell",
  first_name: "Reginald",
  date_joined: "2021-11-08T21:57:18.303696",
  email: "taswell@iga.com",
  is_active: true,
  admin: MockAdmin,
  last_online: "2021-11-08T21:57:18.303696",
  profile: {
    display_name: "Reginald 'IGA admin' Taswell",
    long_bio: "Long bio",
    bio: "I'm an admin",
    soundcloud_username: "",
    twitch_username: "",
    twitter_username: "",
    facebook_username: "",
    instagram_username: "",
    youtube_username: "",
    tiktok_username: "",
    location: "",
    country: "United States",
    city: "Los Angeles",
    genres_string: "",
    birth_date: "",
    banner_color: BannerColor.SKYLIGHT_BLUE,
  },
};

export const MockLabel: LabelAssociation = {
  major_label: MajorLabelEnum.UMG,
  umg_sub_label: UMGSubLabelEnum.IGA,
};

export const MockTeam: Team = {
  id: 1,
  managers: [MockUserAdmin, MockUser],
  members: [MockUser1, MockUser1, MockUser1, MockUser1, MockUser1, MockUser1],
  pending_invites: [
    MockAffiliationInviteWithEmail,
    MockAffiliationInviteWithUser,
  ],
  label: MockLabel,
};

export interface AffiliationInviteForTeam {
  id: number;
  role: TeamRole;
  studio?: Studio;
  label_team?: Team;
  inviter: User;
  accepted: string | null;
  declined: string | null;
  revoked: string | null;
}
