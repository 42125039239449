import {
  LabelItemContainer,
  LabelItemPhoto,
  LabelSelectionFormBody,
  LabelSelectionFormContainer,
} from "./AuthModal.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateUserAAndR } from "../../../store/actions/accountInfo";
import {
  getImageSourceAndText,
  MajorLabelEnum,
  UMGSubLabelEnum,
  UMGSubLabelList,
} from "../../../store/models/trophy";
import { toast } from "react-toastify";
import React from "react";
import { Button } from "../../core-ui/components/Button/Button";
import { AuthNavigation } from "./AuthNavigation";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const LabelSelection = () => {
  const dispatch = useAppDispatch();
  const { nextStep } = useAuthNavigationSteps();
  const { isLoading } = useAppSelector((state) => state.accountInfo);
  const [selectedOption, setSelectedOption] =
    React.useState<UMGSubLabelEnum | null>(null);
  const { isMobile } = useMediaQueryBreakpoint();

  const handleLabelSelection = () => {
    if (selectedOption === null) return;
    dispatch(
      updateUserAAndR({
        is_primary_type: true,
        deleted: false,
        major_label: MajorLabelEnum.UMG,
        sub_label: [selectedOption],
      }),
    )
      .unwrap()
      .then(() => {
        nextStep();
      })
      .catch(() => {
        toast.error("Error setting sub-label. Please try again.");
      });
  };

  return (
    <LabelSelectionFormContainer>
      <AuthNavigation />
      <Text variant={TextStyleVariant.H3}>Choose your label</Text>
      <Text variant={TextStyleVariant.P1}>
        Select your primary label association
      </Text>
      <LabelSelectionFormBody>
        {UMGSubLabelList.map((option: UMGSubLabelEnum, index: number) => {
          const { source, text } = getImageSourceAndText(
            option,
            MajorLabelEnum.UMG,
          );
          return (
            <LabelItemContainer key={index}>
              <LabelItemPhoto
                key={index}
                onClick={() => {
                  if (selectedOption === option) {
                    setSelectedOption(null);
                  } else {
                    setSelectedOption(option);
                  }
                }}
                $isSelected={selectedOption === option}
              >
                {option === UMGSubLabelEnum.UMG_UNKNOWN && (
                  <Text
                    variant={TextStyleVariant.P1}
                    weight={TEXT_WEIGHT.SEMI_BOLD}
                    color={TextColor.BLACK}
                  >
                    {text ?? ""}
                  </Text>
                )}
                {option !== UMGSubLabelEnum.UMG_UNKNOWN && (
                  <img
                    src={source}
                    alt={text ?? ""}
                    width={isMobile ? 150 : 200}
                    height={isMobile ? 150 : 200}
                  />
                )}
              </LabelItemPhoto>
              <Text
                variant={TextStyleVariant.P1}
                weight={TEXT_WEIGHT.SEMI_BOLD}
              >
                {text ?? ""}
              </Text>
            </LabelItemContainer>
          );
        })}
      </LabelSelectionFormBody>
      <Button
        onClick={() => handleLabelSelection()}
        disabled={isLoading || selectedOption === null}
        loading={isLoading}
      >
        Continue
      </Button>
    </LabelSelectionFormContainer>
  );
};
