import styled from "styled-components";

export const BillingInfoInput = styled.input`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.separatorColor};
  background: ${({ theme }) => theme.backgroundColor};
  height: 44px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  flex: 1;
`;

export const BillingInfoInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const SuccessTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
