import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { getDocUrl } from "../../../constants/googleStorage";
import { useOnboardingStepForUmgUser } from "../../../hooks/useIsAandR";
import { useQuery } from "../../../hooks/useQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS, SOUND_OF_TOMORROW_LANDING } from "../../../routes";
import { postEmailLead } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { EmailLeadSource } from "../../../store/models/base";
import { getProfileScreenRoute } from "../../../store/utils/routeGetters";
import soundOfTomorrowLogo from "../../assets/SoundOfTomorrow_logo.png";
import waveform from "../../assets/signup-waveform.svg";
import { ForgotPassword } from "../../components/ForgotPassword/ForgotPassword";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { SignInForm } from "../../components/SignInForm/SignInForm";
import "./SignUpScreen.css";

const props = {
  primary: true,
  register: true,
  defaultValueEmail: "",
  defaultValueUsername: "",
};

export const SignUpScreen = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const username = useAppSelector((state) => state.accountInfo?.user?.username);
  const isValidAAndREmail = useAppSelector(
    (state) => state.accountInfo.user?.is_valid_aandr_email,
  );
  const onboardingStepForUmgUser = useOnboardingStepForUmgUser(user);
  const dispatch = useAppDispatch();

  useSetPageTitle("Sign Up");

  const query = useQuery();
  const emailParam = query.get("email");
  const fullnameParam = query.get("fullname");
  const utmSource = query.get("utm_source");
  const typeParam = query.get("type");
  const [postingEmailLead, setPostingEmailLead] = useState(false);

  props.defaultValueEmail = emailParam ?? "";
  props.defaultValueUsername = fullnameParam
    ? fullnameParam.toLowerCase().replace(" ", ".")
    : "";

  useEffect(() => {
    if (!emailParam) return;
    setPostingEmailLead(true);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      postEmailLead({
        email: emailParam.toLowerCase(),
        full_name: fullnameParam ?? "",
        email_lead_source: EmailLeadSource.SOUND_OF_TOMORROW_COMPETITION,
        link: SOUND_OF_TOMORROW_LANDING,
        type: typeParam ?? "",
        country: timezone,
      }),
    )
      .unwrap()
      .then((result) => {
        if (result.existing_account) {
          window.location.href = `/signin?email=${emailParam}&utm_source=${utmSource}`;
        }
      })
      .finally(() => {
        setPostingEmailLead(false);
      });
  }, [emailParam, fullnameParam, utmSource, typeParam]);

  if (postingEmailLead) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && isValidAAndREmail && onboardingStepForUmgUser !== -1) {
    return (
      <Redirect
        to={{
          pathname: SCREENS.AANDR_SETUP_SCREEN,
          state: { showPrimaryAccountTypeModal: true },
        }}
      />
    );
  } else if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: getProfileScreenRoute(username ?? ""),
          state: { showTutorial: true, showPrimaryAccountTypeModal: true },
        }}
      />
    );
  }

  const signUpScreenFooter = (
    <div className="sign-up-footer">
      <ForgotPassword />
      <p className="p-2">
        {"By signing up, you are agreeing to the "}
        <a
          style={{ color: "black", textDecoration: "underline" }}
          href={getDocUrl("EngineEarsTermsOfUse.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        {" and "}
        <a
          style={{ color: "black", textDecoration: "underline" }}
          href={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );

  return (
    <div className="sign-up-screen-container">
      <div className="sign-up-form-container">
        {/* TODO: Delete this once the competition is over. */}
        {utmSource === "soundoftomorrow" && (
          <div>
            <p className="soundoftomorrow-instruction">
              <strong>
                Sign up on the EngineEars platform to finish registration.
              </strong>
            </p>
            <img
              width={300}
              className=""
              src={soundOfTomorrowLogo}
              alt="Sound of Tomorrow Logo"
            />
          </div>
        )}
        <img className="waveform" src={waveform} alt="Waveform element" />
        <h3 id="sign-up-screen-title">Sign Up</h3>
        <div>
          <SignInForm {...props} />
          <a href="/signin" className="login-link">
            <div>Already a member?</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Login here →</div>
          </a>
        </div>
        {signUpScreenFooter}
      </div>
    </div>
  );
};
