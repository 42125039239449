import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Team, TeamRole } from "../store/models/admins";
import User from "../store/models/user";
import { useIsAdmin } from "./useIsAdmin";
import { getTeams } from "../store/actions/team";
import { useIsStudioManager } from "./bookingHooks/useStudioEngineerUser";
import { selectTeamFields } from "../store/selectors/teamSelectors";

export const useGetTeams = (): {
  associatedTeams: Team[];
  selectedTeam: Team | undefined;
  loading: boolean;
  updating: boolean;
  refetch: () => Promise<void>;
} => {
  const dispatch = useAppDispatch();
  const loggedInUser: undefined | User = useAppSelector(
    (state) => state.accountInfo.user,
  );
  const { associatedTeams, selectedTeam, loading, updating } =
    useAppSelector(selectTeamFields);
  const userIsAdmin = useIsAdmin(loggedInUser);
  const userIsStudioManager = useIsStudioManager(loggedInUser);
  const isGetTeamsEnabled = useMemo(
    () => userIsAdmin || userIsStudioManager,
    [userIsAdmin, userIsStudioManager],
  );
  const fetchTeams = useCallback(async () => {
    // Only Admins and studio managers have teams for now.
    if (!isGetTeamsEnabled) {
      return;
    }
    await dispatch(getTeams()).unwrap();
  }, [dispatch, isGetTeamsEnabled]);

  useEffect(() => {
    fetchTeams().catch(() => {});
  }, [dispatch, fetchTeams]);

  return {
    associatedTeams,
    selectedTeam,
    loading,
    updating,
    refetch: fetchTeams,
  };
};

export const useGetTeamOrg = (team: Team | undefined, isLabel: boolean) => {
  return useMemo(() => {
    if (!team) return [];
    const org: { user: User; role: TeamRole }[] = [];
    team.managers.forEach((manager: User) => {
      org.push({
        user: manager,
        role: isLabel ? TeamRole.ADMIN : TeamRole.STUDIO_MANAGER,
      });
    });
    team.members.forEach((member: User) => {
      org.push({
        user: member,
        role: isLabel ? TeamRole.AANDR : TeamRole.ENGINEER,
      });
    });
    return org;
  }, [team, isLabel]);
};
