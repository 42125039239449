import addOnButton from "../../assets/add-on-button.svg";
import { SocialMediaListEnum, SocialMediaMap } from "../../types/types";
import { RemoveIconLottie } from "../RemoveIconLottie/RemoveIconLottie";
import "./SocialMediaButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

export const AddOnButton = (props: { onClickHandler: () => void }) => {
  return (
    <button className="icon-button" onClick={props.onClickHandler}>
      <img alt="add service" src={addOnButton} />
    </button>
  );
};

export interface SocialMediaButtonProps {
  username: string;
  editMode: boolean;
  enumerator: SocialMediaListEnum;
  useWhiteIcon?: boolean | undefined;
  onEditModeClick: (enumerator: SocialMediaListEnum) => void;
  onDelete: (enumerator: SocialMediaListEnum) => void;
}

export const SocialMediaButton = ({
  username,
  editMode,
  enumerator,
  useWhiteIcon,
  onEditModeClick,
  onDelete,
}: SocialMediaButtonProps) => {
  const socialLink = SocialMediaMap.get(enumerator)!;

  if (editMode)
    return (
      <div className="icon-button-container">
        <button
          className="icon-button-edit"
          onClick={() => {
            onEditModeClick(enumerator);
          }}
        >
          <FontAwesomeIcon
            fixedWidth
            fontSize={"38px"}
            inverse={useWhiteIcon}
            icon={socialLink.icon}
          />
        </button>
        <RemoveIconLottie onClick={() => onDelete(enumerator)} />
      </div>
    );

  return (
    <div className="icon-button-container">
      <Button
        variant={ButtonVariant.TEXT}
        href={socialLink.baseLink + username}
        className="icon-button"
      >
        <FontAwesomeIcon
          fixedWidth
          fontSize={"38px"}
          inverse={useWhiteIcon}
          icon={socialLink.icon}
        />
      </Button>
    </div>
  );
};
