import {
  ProjectUserType,
  ProjectWorkflowSteps,
} from "../../../../../store/models/project";
import { SessionWorkflowSteps } from "../../../../../store/models/recordingSession";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { NudgeButton } from "../../../../elements/NudgeButton/NudgeButton";
import { ProjectWorkflowPanelVariant } from "../../ProjectWorkflowPanel";
import { ProjectWorkflowActionsDropdown } from "./ProjectWorkflowActionsDropdown";
import { ProjectWorkflowExportInstructions } from "./ProjectWorkflowExportInstructions";
import {
  ProjectWorkflowTaskMessage,
  ProjectWorkflowTaskMessageProps,
} from "./ProjectWorkflowTaskMessage";
import {
  ProjectExportActionsContainer,
  ProjectTaskRowContainer,
} from "./ProjectWorkflowTasksRow.styles";

export const ProjectWorkflowTasksRow = ({
  projectId,
  projectType,
  userType,
  variant,
  workflowStep,
  isNotFunded,
  isCancelledSession,
  isRefunded,
  overrideTaskMessage,
  showEngineerActionDropdown,
}: ProjectWorkflowTaskMessageProps) => {
  const showNudgeButton =
    (variant === ProjectWorkflowPanelVariant.MAIN_FLOW &&
      userType === ProjectUserType.ENGINEER &&
      (workflowStep === ProjectWorkflowSteps.MIX_REVIEW ||
        workflowStep === ProjectWorkflowSteps.FILE_TRANSFER)) ||
    (variant === ProjectWorkflowPanelVariant.MAIN_FLOW &&
      userType === ProjectUserType.ARTIST &&
      (workflowStep === ProjectWorkflowSteps.IN_MIX ||
        workflowStep === ProjectWorkflowSteps.MIX_FINISHED)) ||
    (variant === ProjectWorkflowPanelVariant.SESSION_MAIN_FLOW &&
      userType === ProjectUserType.ARTIST &&
      workflowStep === SessionWorkflowSteps.SESSION_COMPLETE &&
      !isCancelledSession);

  return (
    <>
      <Text bold variant={TextStyleVariant.P2}>
        Next steps:
      </Text>
      <ProjectTaskRowContainer>
        <ProjectWorkflowTaskMessage
          isRefunded={isRefunded}
          projectId={projectId}
          projectType={projectType}
          userType={userType}
          variant={variant}
          workflowStep={workflowStep}
          overrideTaskMessage={overrideTaskMessage}
        />

        <ProjectExportActionsContainer>
          <ProjectWorkflowExportInstructions
            projectId={projectId}
            projectType={projectType}
            workflowStep={workflowStep}
            userType={userType}
            variant={variant}
          />
          {showNudgeButton && (
            <NudgeButton
              iconOnly={true}
              projectId={projectId}
              userType={userType}
              isInProgressProject={isNotFunded}
            />
          )}
          <ProjectWorkflowActionsDropdown
            projectId={projectId}
            projectType={projectType}
            userType={userType}
            variant={variant}
            workflowStep={workflowStep}
            isNotFunded={isNotFunded}
            showEngineerActionDropdown={showEngineerActionDropdown}
          />
        </ProjectExportActionsContainer>
      </ProjectTaskRowContainer>
    </>
  );
};
