import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from "react";
import Modal from "react-modal";
import { ThemeContext } from "styled-components";
import { Button, ButtonVariant } from "../Button/Button";
import {
  ModalBody,
  ModalCard,
  ModalFooter,
  ModalHeader,
  ModalHeaderContainer,
  closeIconStyle,
  modalStyle,
} from "./BaseModal.styles";

export interface BaseModalProps {
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  header: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonDisabled?: boolean;
  cancelText?: string;
  confirmText?: string;
  showModalFooter?: boolean;
  loading?: boolean;
  centerModalHeader?: boolean;
  style?: CSSProperties;
  modalBodyStyle?: CSSProperties;
  showCloseButton?: boolean;
  showModalHeader?: boolean;
}

Modal.setAppElement(document.getElementById("root") ?? "root");

export const BaseModal = ({
  children,
  open,
  header,
  setOpen,
  onConfirm,
  onCancel,
  cancelText = "Cancel",
  confirmButtonDisabled = false,
  confirmText = "Confirm",
  showModalFooter = false,
  loading = false,
  centerModalHeader = false,
  style,
  modalBodyStyle,
  showCloseButton = true,
  showModalHeader = true,
}: BaseModalProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Modal style={modalStyle} isOpen={open} contentLabel={header}>
      <ModalCard style={style}>
        {showModalHeader && (
          <ModalHeaderContainer>
            {centerModalHeader && (
              <div style={{ visibility: "hidden", pointerEvents: "none" }} />
            )}
            <ModalHeader>{header}</ModalHeader>
            {showCloseButton && (
              <FontAwesomeIcon
                size={"xl"}
                icon={faClose}
                color={themeContext?.textPrimaryColor}
                style={closeIconStyle}
                onClick={() => setOpen(false)}
              />
            )}
          </ModalHeaderContainer>
        )}
        <ModalBody style={modalBodyStyle}>{children}</ModalBody>
        {showModalFooter && (
          <ModalFooter>
            {onCancel && (
              <Button
                loading={loading}
                variant={ButtonVariant.OUTLINED}
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            )}
            {onConfirm && (
              <Button
                disabled={confirmButtonDisabled}
                loading={loading}
                variant={ButtonVariant.PRIMARY}
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalCard>
    </Modal>
  );
};
