import { Link as NavLink, LinkProps as NavLinkProps } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

interface LinkProps
  extends Omit<NavLinkProps<Record<string, unknown>>, "ref" | "to"> {
  disabled?: boolean;
  to: string;
}

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = (props: LinkProps) => {
  const { children, to, disabled, ...other } = props;

  const pathname = window?.location?.pathname || "/";

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with zero or more periods with exactly one slash, and that anything else is external.
  const internal = /^\.*\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <MuiLink component={NavLink} to={disabled ? pathname : to} {...other}>
        {children}
      </MuiLink>
    );
  }
  return (
    <MuiLink component={"a"} href={disabled ? pathname : to} {...other}>
      {children}
    </MuiLink>
  );
};

export type { LinkProps };
export default Link;
