import {
  ADD_MUSO_ASSOCIATION,
  MUSO_ASSOCIATION,
  MUSO_AUTH_TOKEN,
  MUSO_GET_ROSTER,
} from "../utils/routes";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { MusoAssociation, MusoProfileEntity } from "../models/muso";
import { MUSO_AUTH_INFORMATION_NOT_FOUND } from "../models/exceptions";
import { receiveErrors } from "./errorStore";

interface MusoAssociationState {
  musoAssociation?: MusoAssociation | null;
  loading: boolean;
  activeMusoUserId: string | null;
}

export interface fetchMusoAssociationParams {
  user_id: number;
}

export const fetchMusoAssociation = createAsyncThunk(
  MUSO_ASSOCIATION,
  async (args: fetchMusoAssociationParams, thunkAPI) => {
    const params = `?user_id=${args.user_id}`;
    const result = await makeBackendGetCallWithJsonResponse<MusoAssociation>(
      MUSO_ASSOCIATION,
      params,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface addMusoAssociationParams {
  muso_profile_id: string;
}

export const addMusoAssociation = createAsyncThunk(
  ADD_MUSO_ASSOCIATION,
  async (args: addMusoAssociationParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<MusoAssociation>(
      ADD_MUSO_ASSOCIATION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface MusoAuthTokenResponse {
  muso_user_id: string | null;
  muso_association: MusoAssociation | null;
}

export const getMusoAuthToken = createAsyncThunk(
  MUSO_AUTH_TOKEN + "/get",
  async (_, thunkAPI) => {
    const result =
      await makeBackendGetCallWithJsonResponse<MusoAuthTokenResponse>(
        MUSO_AUTH_TOKEN,
        "",
      );

    if (result.success) {
      return result.resultJson;
    }

    // The backend throws a 404 if the muso auth information is not found.
    // Handle this 404 gracefully.
    if (
      result.statusCode === 404 &&
      result.resultJson.code === MUSO_AUTH_INFORMATION_NOT_FOUND
    ) {
      return {
        muso_user_id: null,
        muso_association: null,
      } as MusoAuthTokenResponse;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface createMusoAuthTokenParams {
  authentication_code: string;
}

export const createMusoAuthToken = createAsyncThunk(
  MUSO_AUTH_TOKEN + "/post",
  async (args: createMusoAuthTokenParams, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<MusoAuthTokenResponse>(
        MUSO_AUTH_TOKEN,
        args,
      );

    if (result.success) {
      return result.resultJson;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getMusoUserRoster = createAsyncThunk(
  MUSO_GET_ROSTER,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<
      MusoProfileEntity[]
    >(MUSO_GET_ROSTER, "");

    if (result.success) {
      return result.resultJson;
    }

    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const initialState: MusoAssociationState = {
  musoAssociation: undefined,
  loading: false,
  activeMusoUserId: null,
};

export const musoAssociationSlice = createSlice({
  name: "musoAssociation",
  initialState,
  reducers: {
    clearMusoAssociation: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getMusoAuthToken.fulfilled, createMusoAuthToken.fulfilled),
      (state, action) => {
        state.activeMusoUserId = action.payload.muso_user_id;
        state.musoAssociation = action.payload.muso_association;
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchMusoAssociation.pending,
        addMusoAssociation.pending,
        getMusoAuthToken.pending,
        createMusoAuthToken.pending,
      ),
      (state) => {
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchMusoAssociation.rejected,
        addMusoAssociation.rejected,
        getMusoAuthToken.rejected,
        createMusoAuthToken.rejected,
      ),
      (state) => {
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchMusoAssociation.fulfilled, addMusoAssociation.fulfilled),
      (state, action) => {
        state.loading = false;
        if (Object.keys(action.payload).length === 0) return;
        state.musoAssociation = action.payload;
      },
    );
  },
});

export const { clearMusoAssociation } = musoAssociationSlice.actions;
export default musoAssociationSlice.reducer;
