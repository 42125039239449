import { faListCheck } from "@fortawesome/pro-solid-svg-icons";
import {
  MasteringTaskMessage,
  MixingTaskMessage,
  ProjectType,
  ProjectUserType,
} from "../../../../../store/models/project";
import { RecordingTaskMessage } from "../../../../../store/models/recordingSession";
import { Text } from "../../../../core-ui/components/Text/Text";
import { ToolTipTextArea } from "../../../ToolTipTextArea/ToolTipTextArea";
import { ProjectWorkflowPanelVariant } from "../../ProjectWorkflowPanel";
import { ProjectWorkflowStepperProps } from "../ProjectWorkflowStepper/ProjectWorkflowStepper";
import {
  ProjectTaskIcon,
  ProjectTaskMessageContainer,
} from "./ProjectWorkflowTasksRow.styles";

export interface TaskMessageType {
  message: string;
  tooltipText?: string;
}

export interface ProjectWorkflowTaskMessageProps
  extends ProjectWorkflowStepperProps {
  projectId: number | null | undefined;
  userType: ProjectUserType | null | undefined;
  isNotFunded?: boolean | null;
  isCancelledSession?: boolean;
  isRefunded?: boolean;
  overrideTaskMessage?: TaskMessageType;
  showEngineerActionDropdown?: boolean;
}

export const ProjectWorkflowTaskMessage = ({
  projectType,
  userType,
  variant,
  workflowStep,
  overrideTaskMessage,
  isCancelledSession,
  isRefunded = false,
}: ProjectWorkflowTaskMessageProps) => {
  let taskMessage = overrideTaskMessage;

  if (!taskMessage) {
    if (variant === ProjectWorkflowPanelVariant.BOOKING_REQUESTED) {
      taskMessage = { message: bookingRequestedMessage };
    } else if (projectType === ProjectType.RECORDING && isCancelledSession) {
      taskMessage = { message: cancelledSessionMessage };
    } else if (isRefunded) {
      taskMessage = { message: cancelledProjectMessage };
    } else {
      const projectUserType = userType ?? ProjectUserType.ARTIST;
      taskMessage = TaskMessage[projectType][projectUserType][workflowStep];
    }
  }

  if (!taskMessage) {
    return null;
  }

  return (
    <ProjectTaskMessageContainer>
      <ProjectTaskIcon icon={faListCheck} />
      {taskMessage.tooltipText ? (
        <ToolTipTextArea text={taskMessage.tooltipText}>
          <Text bold>{taskMessage.message}</Text>
        </ToolTipTextArea>
      ) : (
        <Text bold>{taskMessage.message}</Text>
      )}
    </ProjectTaskMessageContainer>
  );
};

/**
 * Task Messages shown in the workflow panel based on the Project Type
 * @TODO handle Atmos messaging
 * @TODO fix type declaration. We're using Record<number, string> to silence TS error because of different Task Message shapes,
 * but should look into improving it
 */
const TaskMessage: Record<
  ProjectType,
  Record<ProjectUserType, Record<number, TaskMessageType>>
> = {
  [ProjectType.NO_TYPE]: MixingTaskMessage,
  [ProjectType.RECORDING]: RecordingTaskMessage,
  [ProjectType.MIXING]: MixingTaskMessage,
  [ProjectType.MASTERING]: MasteringTaskMessage,
  [ProjectType.TWO_TRACK_MIXING]: MixingTaskMessage,
  [ProjectType.ATMOS_MIXING]: MixingTaskMessage,
};

const bookingRequestedMessage = "Accept, reject, or reschedule booking";
const cancelledSessionMessage = "Rebook a new session";
const cancelledProjectMessage = "No action required";
