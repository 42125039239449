import { Redirect } from "react-router-dom";
import { SCREENS } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import waveform from "../../assets/signup-waveform.svg";
import soundOfTomorrowLogo from "../../assets/SoundOfTomorrow_logo.png";
import { SignInForm } from "../../components/SignInForm/SignInForm";
import { ForgotPassword } from "../../components/ForgotPassword/ForgotPassword";
import "./SignInScreen.css";
import { useOnboardingStepForUmgUser } from "../../../hooks/useIsAandR";
import { useQuery } from "../../../hooks/useQuery";

const props = {
  label: "Sign In",
  primary: true,
  register: false,
  defaultValueEmail: "",
};

export const SignInScreen = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const user = useAppSelector((state) => state.accountInfo?.user);
  const isValidAAndREmail = useAppSelector(
    (state) => state.accountInfo.user?.is_valid_aandr_email,
  );
  const onboardingStepForUmgUser = useOnboardingStepForUmgUser(user);

  const query = useQuery();
  const emailParam = query.get("email");
  const utmSource = query.get("utm_source");
  props.defaultValueEmail = emailParam ?? "";

  if (isAuthenticated && isValidAAndREmail && onboardingStepForUmgUser !== -1) {
    return (
      <Redirect
        to={{
          pathname: SCREENS.AANDR_SETUP_SCREEN,
          state: { showTutorial: false },
        }}
      />
    );
  } else if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: SCREENS.HOME_SCREEN,
          state: { showTutorial: false },
        }}
      />
    );
  }

  return (
    <div className="sign-in-screen-container">
      <div className="sign-up-form-container">
        {/* TODO: Delete this once the competition is over. */}
        {utmSource === "soundoftomorrow" && (
          <div>
            <p className="soundoftomorrow-instruction">
              <strong>
                Sign into the EngineEars platform with your existing account to
                finish registration.
              </strong>
            </p>
            <img
              width={300}
              className=""
              src={soundOfTomorrowLogo}
              alt="Sound of Tomorrow Logo"
            />
          </div>
        )}
        <img className="waveform" src={waveform} alt="Waveform element" />
        <h3 id="sign-up-screen-title">Sign In</h3>
        <div>
          <SignInForm {...props} />
          <a href={SCREENS.SIGN_UP_SCREEN} className="login-link">
            <div>Not yet a member?</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Sign up here →</div>
          </a>
        </div>
        <ForgotPassword />
      </div>
    </div>
  );
};
