import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  CALENDAR_INTEGRATION_CHECK,
  GOOGLE_TOKEN,
  REMOVE_CALENDAR_INTEGRATION,
} from "../utils/routes";
import { receiveErrors } from "./errorStore";

export interface CalendarState {
  calendarIntegrated: boolean;
  email: null | string;
}

const initialState: CalendarState = {
  calendarIntegrated: false,
  email: null,
};

interface CalendarIntegrationResponse {
  calendar_integrated: boolean;
  email: string | null;
}

export const getCalendarIntegrationStatus = createAsyncThunk(
  CALENDAR_INTEGRATION_CHECK,
  async (args: { studio_id: number | undefined }, thunkAPI) => {
    const params = args.studio_id ? `?studio_id=${args.studio_id}` : "";
    const result =
      await makeBackendGetCallWithJsonResponse<CalendarIntegrationResponse>(
        CALENDAR_INTEGRATION_CHECK,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface GoogleTokenArgs {
  code: string;
  studio_id?: number;
}

export const connectGoogleTokens = createAsyncThunk(
  GOOGLE_TOKEN,
  async (args: GoogleTokenArgs, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<CalendarIntegrationResponse>(
        GOOGLE_TOKEN,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const removeCalendarIntegration = createAsyncThunk(
  REMOVE_CALENDAR_INTEGRATION,
  async (args: { studio_id: number | undefined }, thunkAPI) => {
    const result =
      await makeBackendPostCallWithJsonResponse<CalendarIntegrationResponse>(
        REMOVE_CALENDAR_INTEGRATION,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

const calendarServiceSlice = createSlice({
  name: "calendarService",
  initialState: initialState,
  reducers: {
    resetCalendarState: (state) => {
      state.calendarIntegrated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        connectGoogleTokens.rejected,
        getCalendarIntegrationStatus.rejected,
      ),
      (state) => {
        state.calendarIntegrated = false;
        state.email = null;
      },
    );
    builder.addMatcher(
      isAnyOf(
        connectGoogleTokens.fulfilled,
        getCalendarIntegrationStatus.fulfilled,
        removeCalendarIntegration.fulfilled,
      ),
      (state, action) => {
        state.calendarIntegrated = action.payload.calendar_integrated;
        if (action.payload.email) {
          state.email = action.payload.email;
        }
      },
    );
  },
});

export const { resetCalendarState } = calendarServiceSlice.actions;
export default calendarServiceSlice.reducer;
