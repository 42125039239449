import React, { FC, useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./DirectMessageButton.css";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { BaseModal } from "../BaseModal/BaseModal";
import { EditableTextArea } from "../../elements/EditableTextArea/EditableTextArea";
import { Card } from "../../elements/Card/Card";
import { useAppSelector } from "../../../store/hooks";
import {
  ContactInfoModal,
  OnUpdateArgs,
} from "../ContactInfoModal/ContactInfoModal";
import useModal from "../../../hooks/useModal";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export enum DirectMessageButtonState {
  SEND_MESSAGE_REQUEST = "Send message request",
  MESSAGE_REQUEST_PENDING = "Message request pending",
  ACCEPT_MESSAGE_REQUEST = "Accept message request",
  MESSAGE_REQUEST_REJECTED = "Message request rejected",
  MESSAGE = "Message",
  LOADING = "Loading",
}

export interface DirectMessageButtonProps {
  handleOnClick: (message: string | undefined) => void;
  buttonState: DirectMessageButtonState;
  customLabel?: string;
  showIcon?: boolean;
  customClassName?: string;
}

export const DirectMessageButton: FC<DirectMessageButtonProps> = ({
  handleOnClick,
  buttonState,
  customLabel,
  customClassName,
  showIcon = true,
}) => {
  const currentUser = useAppSelector((state) => state.accountInfo.user);
  const [message, setMessage] = useState("");
  const {
    isOpen: isLoginModalOpen,
    openModal: openLoginModal,
    closeModal: closeLoginModal,
  } = useModal();
  const {
    isOpen: isDirectMessageModalOpen,
    openModal: openDirectMessageModal,
    closeModal: closeDirectMessageModal,
  } = useModal();
  const {
    isOpen: isContactInfoModalOpen,
    openModal: openContactInfoModal,
    closeModal: closeContactInfoModal,
  } = useModal();

  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );

  // TODO: Replace with phone number verification when throughput issues have been fixed.
  const showAddPhoneNumber = useMemo(() => {
    if (!currentUser) return false;
    if (!currentUser?.phone_number?.phone_number) return true;
    return !currentUser.phone_number.phone_number;
  }, [currentUser]);

  const onClick = useCallback(() => {
    if (!currentUser && !isLoginModalOpen) {
      return openLoginModal();
    }
    if (showAddPhoneNumber) {
      return openContactInfoModal();
    }
    if (
      buttonState === DirectMessageButtonState.SEND_MESSAGE_REQUEST &&
      !isDirectMessageModalOpen &&
      !isContactInfoModalOpen
    ) {
      emitAnalyticsTrackingEvent(
        "message_request_initiated",
        localUTMParams,
        currentUser?.id,
      );
      openDirectMessageModal();
    } else {
      handleOnClick(undefined);
    }
  }, [
    isDirectMessageModalOpen,
    buttonState,
    showAddPhoneNumber,
    currentUser,
    isContactInfoModalOpen,
  ]);

  const onContactInfoModalClosed = useCallback(
    (updates?: OnUpdateArgs) => {
      closeContactInfoModal();
      // TODO: Replace with phoneNumberAdded when sms throughput issues have been fixed.
      if (
        updates?.contactInfoUpdated &&
        currentUser?.phone_number?.phone_number
      ) {
        openDirectMessageModal();
      } else {
        handleOnClick(undefined);
      }
    },
    [
      openDirectMessageModal,
      handleOnClick,
      currentUser?.phone_number?.phone_number,
    ],
  );

  const isDisabled = useMemo(
    () => !message.length || !message.trim().length,
    [message],
  );
  const handleSubmit = useCallback(() => {
    handleOnClick(message);
    closeDirectMessageModal();
  }, [handleOnClick, closeDirectMessageModal, message]);
  return (
    <React.Fragment>
      {isContactInfoModalOpen && (
        <ContactInfoModal
          onClose={onContactInfoModalClosed}
          overwriteShowModal={isContactInfoModalOpen}
          customHeading="Get notified when your message request is accepted."
        />
      )}
      {isLoginModalOpen && (
        <UnauthenticatedModal
          closeModal={closeLoginModal}
          showModal={isLoginModalOpen}
          message={"Please log in or sign up to send a message request."}
        />
      )}
      <Button
        disabled={
          buttonState === DirectMessageButtonState.MESSAGE_REQUEST_PENDING ||
          buttonState === DirectMessageButtonState.LOADING
        }
        loading={buttonState === DirectMessageButtonState.LOADING}
        className={customClassName ? customClassName : "direct-message-button"}
        variant={ButtonVariant.OUTLINED}
        onClick={onClick}
        labelIcon={
          showIcon ? (
            <FontAwesomeIcon
              icon={faEnvelope}
              height={12}
              width={18}
              color="--var(text-secondary-color)"
            />
          ) : null
        }
      >
        {customLabel &&
        buttonState === DirectMessageButtonState.SEND_MESSAGE_REQUEST
          ? customLabel
          : buttonState}
      </Button>
      <BaseModal
        modalIsOpen={isDirectMessageModalOpen}
        closeModal={closeDirectMessageModal}
        label={"message-request-modal"}
      >
        <Card customClassName={"direct-message-card"}>
          <p className={"h7-semi-bold"}>Submit message request</p>
          <EditableTextArea
            editMode={true}
            initialValue={message}
            placeholder="I am interested in working with you!..."
            onChange={setMessage}
            characterCount={500}
            numberOfLines={6}
            handleSubmit={isDisabled ? undefined : handleSubmit}
            textAreaProps={{
              style: {
                minHeight: "75px",
              },
            }}
            spanProps={{
              style: {
                bottom: "unset",
              },
            }}
          />
          <Button
            disabled={isDisabled}
            onClick={handleSubmit}
            className="submit-message-request-button"
          >
            Submit Message Request
          </Button>
        </Card>
      </BaseModal>
    </React.Fragment>
  );
};
