import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { DEFAULT_TAB_WIDTH } from "../../core-ui/components/Tabs/Tab/Tab.styles";
import { Tabs } from "../../core-ui/components/Tabs/Tabs";
import { TEXT_SIZE, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";

export interface ProjectOverviewTabsProps {
  contentContainer: React.RefObject<HTMLDivElement>;
  headers: (string | undefined)[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

export const ProjectOverviewTabs = ({
  contentContainer,
  headers,
  selectedTab,
  setSelectedTab,
}: ProjectOverviewTabsProps) => {
  const [tabWidth, setTabWidth] = useState(DEFAULT_TAB_WIDTH);
  const numberOfTabs = headers.length;

  const calculateTabWidth = useCallback(() => {
    if (!contentContainer.current) {
      return setTabWidth(DEFAULT_TAB_WIDTH);
    }
    const containerTabWidth =
      contentContainer.current.offsetWidth / numberOfTabs;

    setTabWidth(Math.min(containerTabWidth, DEFAULT_TAB_WIDTH));
  }, [numberOfTabs, contentContainer]);

  const onResize = useDebounceCallback(calculateTabWidth, 100);

  useResizeObserver({ ref: contentContainer, onResize });

  return (
    <Tabs
      tabStyle={{
        size: TEXT_SIZE.MEDIUM,
        weight: TEXT_WEIGHT.SEMI_BOLD,
      }}
      tabs={headers}
      tabWidth={tabWidth}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};
