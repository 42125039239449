import { useCallback, useState } from "react";
import { submitBillingInfo } from "../../../store/actions/transactions";
import { useAppDispatch } from "../../../store/hooks";
import {
  Transaction,
  TransactionStatus,
} from "../../../store/models/transaction";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import { Button } from "../../elements/Button/button";
import "./PurchaseOrderRow.css";

export interface PurchaseOrderRowProps {
  transaction: Transaction;
}

export const PurchaseOrderRow = ({ transaction }: PurchaseOrderRowProps) => {
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>("");
  const dispatch = useAppDispatch();

  const status =
    transaction.transaction_status === TransactionStatus.PAID
      ? "paid"
      : transaction.transaction_status === TransactionStatus.PARTIALLY_PAID
        ? "partially paid"
        : "not paid";

  const handleSubmit = useCallback(() => {
    if (!purchaseOrderNumber) return;
    if (!transaction.purchase_order) return;
    void dispatch(
      submitBillingInfo({
        order_number: purchaseOrderNumber,
        purchase_order_id: transaction.purchase_order.id,
      }),
    );
  }, [purchaseOrderNumber]);

  return (
    <tr>
      <td># {transaction.id}</td>
      <td>{DollarFormatter().format(Number(transaction.total_price) ?? 0)}</td>
      <td className="submit-purchase-order">
        <input
          type="text"
          name="purchaseOrderNumber"
          value={purchaseOrderNumber}
          placeholder="Enter PO number"
          onChange={(e) => setPurchaseOrderNumber(e.target.value)}
        />{" "}
        {transaction.transaction_status !== TransactionStatus.PAID && (
          <Button
            size={"small"}
            label={"submit"}
            primary
            onClick={handleSubmit}
          />
        )}
      </td>
      <td className="transaction-status">{status}</td>
    </tr>
  );
};
