import {
  faAngleDown,
  faAngleUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";
import { Button, ButtonVariant } from "../Button/Button";
import {
  CheckBoxGroupTitle,
  CheckboxLabel,
  CheckboxRoot,
  DropdownMenuContent,
  DropdownMenuLabel,
  Separator,
} from "./Select.styles";

type SelectOptionType<T> = OptionType<T>;

export type OptionValueType<T> = SelectOptionType<T>["value"];

interface DropdownContextType {
  closeDropdownMenu: () => void;
}

export const DropdownContext = createContext<DropdownContextType | null>(null);

interface SelectProps {
  children: ReactNode;
  dropdownLabel?: string;
  dropdownZIndex?: number;
  preventCloseDropdownOnSelect?: boolean;
  triggerLabel?: string;
  triggerButton?: React.JSX.Element;
}

export const Select = (props: SelectProps) => {
  const {
    children,
    dropdownLabel,
    dropdownZIndex,
    // By default, picking an option in the dropdown will close the dropdown
    // Setting this prop to `true` would prevent that behavior
    preventCloseDropdownOnSelect,
    triggerLabel,
    triggerButton,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdownMenu = useCallback(() => {
    if (!preventCloseDropdownOnSelect) {
      setDropdownOpen(false);
    }
  }, [preventCloseDropdownOnSelect]);

  const menuTrigger = triggerButton ? (
    triggerButton
  ) : (
    <Button
      labelIcon={<FontAwesomeIcon icon={faAngleDown} />}
      variant={ButtonVariant.GHOST}
    >
      {triggerLabel}
    </Button>
  );

  return (
    <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenu.Trigger asChild>{menuTrigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent $zIndex={dropdownZIndex}>
          {dropdownLabel && (
            <>
              <DropdownMenuLabel>
                <span>{dropdownLabel}</span>
                <FontAwesomeIcon icon={faAngleUp} />
              </DropdownMenuLabel>
              <Separator />
            </>
          )}
          <DropdownContext.Provider value={{ closeDropdownMenu }}>
            {children}
          </DropdownContext.Provider>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

interface CommonCheckboxGroupProps<T> {
  title?: string;
  options: SelectOptionType<T>[];
  // If 2 checkboxes (from different groups) have the same `value`, only the semantically 1st one can be selected
  // This property is used to scope the checkboxes, essentially differatiating them
  scope?: string;
}

export interface MultipleCheckboxGroupProps<T>
  extends CommonCheckboxGroupProps<T> {
  multiple: true;
  initialSelected?: OptionValueType<T>[];
  selected?: OptionValueType<T>[];
  onSelect?: (value: OptionValueType<T>[]) => void;
}

export interface SingleCheckboxGroup<T> extends CommonCheckboxGroupProps<T> {
  multiple?: false;
  initialSelected?: OptionValueType<T>;
  selected?: OptionValueType<T> | null;
  onSelect?: (value: OptionValueType<T> | null) => void;
}

type CheckboxGroupProps<T> =
  | MultipleCheckboxGroupProps<T>
  | SingleCheckboxGroup<T>;

export const CheckboxGroup = <T extends string | number>(
  props: CheckboxGroupProps<T>,
) => {
  // Some props couldn't be de-structured because it's used to discriminate union types
  // TS 4.5.5 hasn't supported de-structure for them: https://stackoverflow.com/questions/69023997/typescript-discriminated-union-narrowing-not-working
  const { options, title, multiple, scope } = props;
  // If this component is used inside <Select />, dropdownMenuContext should be present
  const dropdownMenuContext = useContext(DropdownContext);

  const [internalSelected, setInternalSelected] = useState(() => {
    if (props.initialSelected) {
      return multiple ? props.initialSelected : [props.initialSelected];
    } else {
      return [];
    }
  });

  const isControlled = props.selected !== undefined;
  const hasOnSelect = Boolean(props.onSelect);

  useEffect(() => {
    if (!hasOnSelect && isControlled) {
      console.error(
        "Warning: You provided a `selected` props to a CheckboxGroup without an `onSelect` handler. This will result in a read-only `selected` value. Please also set the `onSelect` props",
      );
    }
  }, [hasOnSelect, isControlled]);

  let selected = internalSelected;
  if (props.selected !== undefined) {
    if (multiple) {
      selected = props.selected;
    } else {
      selected = props.selected === null ? [] : [props.selected];
    }
  }

  const handleOnCheckedChange = (
    checked: boolean,
    value: OptionValueType<T>,
  ) => {
    // If placed this component inside <Select />, on checked change trigger dropdown closure by default
    // If this behavior is not desired, pass `preventCloseDropdownOnSelect` to <Select />
    dropdownMenuContext?.closeDropdownMenu();

    if (multiple === true) {
      let newSelected: OptionValueType<T>[] = [];
      if (checked) {
        if (!selected.includes(value)) {
          newSelected = [...selected, value];
        }
      } else {
        newSelected = selected.filter((item) => item !== value);
      }

      if (!isControlled) {
        setInternalSelected(newSelected);
      }

      props.onSelect?.(newSelected);
    } else {
      if (!isControlled) {
        if (checked) {
          setInternalSelected([value]);
        } else {
          setInternalSelected([]);
        }
      }

      if (checked) {
        props.onSelect?.(value);
      } else {
        props.onSelect?.(null);
      }
    }
  };

  return (
    <div>
      {title && <CheckBoxGroupTitle>{title}</CheckBoxGroupTitle>}
      {options.map(({ value, label }) => (
        <CheckboxLabel
          key={value}
          htmlFor={scope ? `${scope}-${value}` : `${value}`}
        >
          <CheckboxRoot
            checked={selected.includes(value)}
            id={scope ? `${scope}-${value}` : `${value}`}
            onCheckedChange={(checked: boolean) =>
              handleOnCheckedChange(checked, value)
            }
          >
            <Checkbox.Indicator>
              <FontAwesomeIcon icon={faCheck} />
            </Checkbox.Indicator>
          </CheckboxRoot>
          {label}
        </CheckboxLabel>
      ))}
    </div>
  );
};
