import { useTheme } from "@mui/material";
import { getDocUrl } from "../../../constants/googleStorage";
import { Text, TEXT_COLOR } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import Link from "../../elements/Link/Link";

const FooterContent = () => {
  const theme = useTheme();
  return (
    <Text variant={TextStyleVariant.P2} color={TEXT_COLOR.SECONDARY}>
      {"By joining, you agree to the EngineEars "}
      <Link
        style={{
          color: theme.palette.text.secondary,
          textDecoration: "underline",
        }}
        to={getDocUrl("EngineEarsTermsOfUse.pdf")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </Link>
      {". Please read our "}
      <Link
        style={{
          color: theme.palette.text.secondary,
          textDecoration: "underline",
        }}
        to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </Link>
      .
    </Text>
  );
};

export default FooterContent;
