export enum Genre {
  NO_GENRE,
  HIP_HOP,
  RNB,
  ROCK,
  ALTERNATIVE,
  JAZZ,
  FOLK,
  COUNTRY,
  ELECTRONIC,
  POP,
  REGGAE,
  LATIN,
  AFROBEAT,
  TECHNO,
  GOSPEL,
  HOUSE,
  AMAPIANO,
  REGGAETON,
  PUNK,
  ORCHESTRAL,
}

export const genreToStringMap = new Map<Genre, string>([
  [Genre.NO_GENRE, "no genre"],
  [Genre.HIP_HOP, "hip hop"],
  [Genre.RNB, "rnb"],
  [Genre.ROCK, "rock"],
  [Genre.ALTERNATIVE, "alternative"],
  [Genre.JAZZ, "jazz"],
  [Genre.FOLK, "folk"],
  [Genre.COUNTRY, "country"],
  [Genre.ELECTRONIC, "electronic"],
  [Genre.POP, "pop"],
  [Genre.REGGAE, "reggae"],
  [Genre.LATIN, "latin"],
  [Genre.AFROBEAT, "afrobeat"],
  [Genre.TECHNO, "techno"],
  [Genre.GOSPEL, "gospel"],
  [Genre.HOUSE, "house"],
  [Genre.AMAPIANO, "amapiano"],
  [Genre.REGGAETON, "reggaeton"],
  [Genre.PUNK, "punk"],
  [Genre.ORCHESTRAL, "orchestral"],
]);

export type GenreArray = Genre[];
