import Profile from "../store/models/profile";
import { SocialMediaListEnum } from "../stories/types/types";
import { useMemo } from "react";

export const useSocialMediaMap = (
  profile?: Profile | null,
): Map<SocialMediaListEnum, string> => {
  return useMemo(() => {
    const map = new Map<SocialMediaListEnum, string>();
    if (profile?.twitter_username) {
      map.set(SocialMediaListEnum.twitter, profile.twitter_username);
    }
    if (profile?.instagram_username) {
      map.set(SocialMediaListEnum.instagram, profile.instagram_username);
    }
    if (profile?.soundcloud_username) {
      map.set(SocialMediaListEnum.soundcloud, profile.soundcloud_username);
    }
    if (profile?.facebook_username) {
      map.set(SocialMediaListEnum.facebook, profile.facebook_username);
    }
    if (profile?.twitch_username) {
      map.set(SocialMediaListEnum.twitch, profile?.twitch_username);
    }
    if (profile?.tiktok_username) {
      map.set(SocialMediaListEnum.tiktok, profile.tiktok_username);
    }
    if (profile?.youtube_username) {
      map.set(SocialMediaListEnum.youtube, profile.youtube_username);
    }
    return map;
  }, [profile]);
};
