import { useCallback, useEffect, useMemo, useState } from "react";
import { Breakpoint } from "../utils/breakpoints";

const addMediaMatchListener = (
  mediaMatch: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (mediaMatch.addEventListener !== undefined) {
    mediaMatch.addEventListener("change", handler);
    return;
  }

  // support for older browsers (https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#instance_methods)
  mediaMatch.addListener(handler);
};

const removeMediaMatchListener = (
  mediaMatch: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (mediaMatch.removeEventListener !== undefined) {
    mediaMatch.removeEventListener("change", handler);
    return;
  }

  // support for older browsers (https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#instance_methods)
  mediaMatch.removeListener(handler);
};

export const useMediaQuery = (query: string) => {
  const mediaMatch = useMemo(() => {
    return window.matchMedia(query);
  }, [query]);
  const [matches, setMatches] = useState(mediaMatch.matches);
  const handler = useCallback((event: MediaQueryListEvent) => {
    setMatches(event.matches);
  }, []);

  useEffect(() => {
    addMediaMatchListener(mediaMatch, handler);
    return () => {
      removeMediaMatchListener(mediaMatch, handler);
    };
  }, [mediaMatch, handler]);
  return useMemo(() => {
    return matches;
  }, [matches]);
};

export const useMediaQueryBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(null);

  useEffect(() => {
    const queryRemoveEventListeners = Object.values(Breakpoint).map(
      ($breakpoint) => {
        const query = window.matchMedia($breakpoint);
        const handleQueryChange = (event: MediaQueryListEvent) => {
          if (event.matches) {
            setBreakpoint($breakpoint);
          }
        };

        addMediaMatchListener(query, handleQueryChange);

        if (query.matches) {
          setBreakpoint($breakpoint);
        }

        return () => {
          removeMediaMatchListener(query, handleQueryChange);
        };
      },
    );

    return () => {
      queryRemoveEventListeners.forEach((queryRemoveEventListener) => {
        queryRemoveEventListener();
      });
    };
  }, []);

  return useMemo(
    () => ({
      isMobile: breakpoint === Breakpoint.Mobile,
      isTablet: breakpoint === Breakpoint.Tablet,
      isDesktop: breakpoint === Breakpoint.Desktop,
    }),
    [breakpoint],
  );
};
