import Pagination from "rc-pagination";
import { useCallback, useEffect, useMemo } from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import {
  getAllTransactions,
  setTransactionsPage,
} from "../../../store/actions/transactions";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  Transaction,
  TransactionStatus,
} from "../../../store/models/transaction";
import { PurchaseOrderRow } from "../../components/PurchaseOrderRow/PurchaseOrderRow";
import { TableRowSkeleton } from "../../components/TableRowSkeleton/TableRowSkeleton";

import "./PurchaseOrderScreen.css";

const PurchaseOrderScreen = () => {
  const currentLoggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { currentPage, totalPages, count, isLoading } = useAppSelector(
    (state) => state.transactionStore,
  );
  const transactions = useAppSelector(
    (state) => state.transactionStore.transactions,
  );
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("(max-width: 989px)");

  useSetPageTitle(undefined);

  const DEFAULT_PAGE_SIZE = 10;

  const getTransactions = useCallback(
    (page: number) => {
      if (!currentLoggedInUser) return;
      const params = {
        user_id: currentLoggedInUser.id,
        transaction_status: TransactionStatus.PURCHASE_ORDER_REQUIRED,
        page: page,
      };
      dispatch(getAllTransactions({ ...params }));
    },
    [currentLoggedInUser, currentPage],
  );

  useEffect(() => {
    getTransactions(1); // Initial fetch.
  }, []);

  const loadingSkeleton = useMemo(() => {
    const Loaders = [];
    for (let i = 0; i < DEFAULT_PAGE_SIZE; i++) {
      Loaders.push(<TableRowSkeleton isMobile={isMobile} />);
    }
    return Loaders;
  }, [DEFAULT_PAGE_SIZE, isMobile]);

  return (
    <div className="purchase-order-page-container">
      <div className="h3 purchase-order-main-page mt-5">Purchase Orders</div>

      {isLoading && loadingSkeleton}
      {!isLoading && (
        <table>
          <tr>
            <th>Transaction number</th>
            <th>Total</th>
            <th></th>
            <th>Status</th>
          </tr>
          <tbody>
            {transactions[currentPage] !== undefined &&
              transactions[currentPage].map((transaction: Transaction) => {
                return <PurchaseOrderRow transaction={transaction} />;
              })}
          </tbody>
        </table>
      )}
      <div className="paginator-container">
        <Pagination
          current={currentPage}
          total={count}
          pageSize={DEFAULT_PAGE_SIZE}
          showSizeChanger={true}
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
          }}
          onChange={async (page) => {
            if (page < 1 || page > totalPages) return;
            dispatch(setTransactionsPage(page));
            getTransactions(page);
          }}
        />
      </div>
    </div>
  );
};
export default PurchaseOrderScreen;
