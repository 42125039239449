import artistLandingHeaderIcon from "../../assets/artist-landing-header-icon.svg";
import headerLogo from "../../assets/header-logo.svg";
import "./MaintenanceScreen.css";
import AppScreenContent from "../../components/AppScreenContent/AppScreenContent";
import { ReactNode } from "react";

export interface MaintenanceScreenProps {
  title?: ReactNode;
  subtitle?: ReactNode;
}

export const MaintenanceScreen = ({
  title = (
    <h5>
      The website is currently undergoing maintenance to rollout new features.
    </h5>
  ),
  subtitle = <p className="h6">We&#39;ll be back live very soon!</p>,
}: MaintenanceScreenProps) => {
  return (
    <AppScreenContent className="maintenance-screen">
      <img src={artistLandingHeaderIcon} alt="Waveform element" />
      <img
        alt="uploader logo"
        src={headerLogo}
        width={300}
        className="uploader-logo"
      />
      {title}
      {subtitle}
    </AppScreenContent>
  );
};
