import { ReactElement } from "react";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { MusoCredit } from "../../../store/models/muso";
import { CreditTableRow } from "../../elements/CreditTableRow/CreditsTableRow";
import "./MusoSelectedCreditsTable.css";

export interface MusoSelectedCreditsTableProps {
  selectedCredits: MusoCredit[];
  disabled: boolean;
  visibleCheckMarks: boolean;
  onChecked?: (data: MusoCredit) => void;
  checkedList?: string[];
  onRowClicked?: (data: MusoCredit) => void;
}

export const MusoSelectedCreditsTable = ({
  selectedCredits,
  disabled,
  visibleCheckMarks,
  onChecked,
  checkedList,
  onRowClicked,
}: MusoSelectedCreditsTableProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();

  const desktopTableHeaders = (
    <>
      <th>Album</th>
      <th>Role</th>
      <th>Release Date</th>
    </>
  );

  interface CreditRowProps {
    children?: ReactElement | Array<ReactElement>;
    creditData: MusoCredit;
  }
  const CreditRow = ({
    children,
    creditData,
  }: CreditRowProps): ReactElement => {
    return onRowClicked ? (
      <tr
        className="clickable-credit-row"
        onClick={() => onRowClicked(creditData)}
      >
        {children}
      </tr>
    ) : (
      <tr>{children}</tr>
    );
  };

  return (
    <table className="muso-credit-table">
      <thead>
        <tr className="muso-credit-table-row">
          {visibleCheckMarks && <th>&nbsp;</th>}
          <th>&nbsp;</th>
          <th>Track Name</th>
          <th>Artists</th>
          {isDesktop && desktopTableHeaders}
        </tr>
      </thead>
      <tbody>
        {selectedCredits.map((credit_data, idx) => {
          return (
            <CreditRow
              key={idx + credit_data.muso_credit_id}
              creditData={credit_data}
            >
              <CreditTableRow
                creditData={credit_data}
                visibleCheckMarks={visibleCheckMarks}
                disabledCheckMarks={disabled}
                onChecked={onChecked}
                selectable={true}
                checkedList={checkedList}
              />
            </CreditRow>
          );
        })}
      </tbody>
    </table>
  );
};
