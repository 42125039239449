import { useEffect, useState } from "react";
import { useQuery } from "../../../hooks/useQuery";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { getBettermodeJWTToken } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { isOnboardedEngineerOrStudioManagerSelector } from "../../../store/selectors/userInfoSelectors";
import AppScreenContent from "../../components/AppScreenContent/AppScreenContent";

export const CommunityScreen = ({ ..._props }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);
  const isOnboarded = useAppSelector(
    isOnboardedEngineerOrStudioManagerSelector,
  );
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<string>("");
  const [frameUri, setFrameUri] = useState<string>(
    "https://engineears.bettermode.io/spaces?layout=default",
  );
  const query = useQuery();
  const path = query.get("path");

  useSetPageTitle("Community");

  useEffect(() => {
    if (!isAuthenticated) return;
    if (jwtToken) return;
    if (loadingToken) return;
    setLoadingToken(true);
    dispatch(getBettermodeJWTToken())
      .unwrap()
      .then((data) => {
        setJwtToken(data.jwt_token);
      })
      .finally(() => {
        setLoadingToken(false);
      });
  }, [dispatch, isAuthenticated, jwtToken, loadingToken]);

  useEffect(() => {
    console.log("path", path);
    if (!isAuthenticated) {
      setFrameUri(`https://members.engineears.com/${path ?? ""}`);
    }
    if (!jwtToken) return;

    setFrameUri(
      `https://members.engineears.com/api/auth/sso?jwt=${jwtToken}&redirect_uri=/${path ?? ""}`,
    );
  }, [jwtToken, isOnboarded]);

  return (
    <AppScreenContent
      isLoading={isAuthenticated && !jwtToken}
      className="container-fluid community-screen"
    >
      <iframe
        src={frameUri}
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe>
    </AppScreenContent>
  );
};
